/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import GeneralActionTypes from './general.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	isDetailsEdit: false,
	isOffcanvasOpen: false,
	getCountryListResponse: [],
	getStateListResponse: [],

	getCityListResponse: [],
	getDistrictListResponse: [],

	getPermanentCityListResponse: [],
	getPermanentDistrictListResponse: [],

	fetchAllBranchesResponse: null,
	fetchAllBranchesArr: [],

	fetchAllMembersListResponse: null,
	fetchAllMembersListArr: [],

	fetchAllProductTypesResponse: null,
	fetchAllProductTypesArr: [],

	fetchAllBranchOfficerResponse: null,
	fetchAllBranchOfficerArr: [],

	fetchAllCenterResponse: null,
	fetchAllCenterArr: [],

	fetchallGroupResponse: null,
	fetchallGroupArr: [],

	fetchCenterGroupResponse: null,
	fetchCenterGroupArr: [],

	fetchGroupMemberResponse: null,
	fetchGroupMemberArr: [],

	getPinCodeListResponse: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GeneralActionTypes.IS_EDIT_DETAILS_START:
			return { ...state, isDetailsEdit: action.payload };

		case GeneralActionTypes.IS_OPEN_OFFCANVAS_START:
			return { ...state, isOffcanvasOpen: action.payload };

		case GeneralActionTypes.GET_COUNTRY_LIST_SUCCESS:
			return {
				...state,
				getCountryListResponse: docodeResponse(action.payload, 'GetCountryListKey'),
			};

		case GeneralActionTypes.GET_COUNTRY_LIST_FAILURE:
			return { ...state, getCountryListResponse: [] };

		case GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_CHANGED:
			return { ...state, getCountryListResponse: [] };

		case GeneralActionTypes.GET_STATE_LIST_SUCCESS:
			return {
				...state,
				getStateListResponse: docodeResponse(action.payload, 'CountryStateListGetKey'),
			};

		case GeneralActionTypes.GET_STATE_LIST_FAILURE:
			return { ...state, getStateListResponse: [] };

		case GeneralActionTypes.GET_STATE_LIST_RESPONSE_CHANGED:
			return { ...state, getStateListResponse: [] };

		case GeneralActionTypes.GET_CITY_LIST_SUCCESS:
			return {
				...state,
				getCityListResponse: docodeResponse(action.payload, 'StateCityListGetKey'),
			};

		case GeneralActionTypes.GET_CITY_LIST_FAILURE:
			return { ...state, getCityListResponse: [] };

		case GeneralActionTypes.GET_CITY_LIST_RESPONSE_CHANGED:
			return { ...state, getCityListResponse: [] };

		case GeneralActionTypes.GET_PERMANENT_CITY_LIST_SUCCESS:
			return {
				...state,
				getPermanentCityListResponse: docodeResponse(action.payload, 'StateCityListGetKey'),
			};

		case GeneralActionTypes.GET_PERMANENT_CITY_LIST_FAILURE:
			return { ...state, getPermanentCityListResponse: [] };

		case GeneralActionTypes.GET_PERMANENT_CITY_LIST_RESPONSE_CHANGED:
			return { ...state, getPermanentCityListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_SUCCESS:
			return {
				...state,
				getDistrictListResponse: docodeResponse(action.payload, 'DistrictCityListGetKey'),
			};

		case GeneralActionTypes.GET_DISTRICT_LIST_FAILURE:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_CHANGED:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.GET_PERMANENT_DISTRICT_LIST_SUCCESS:
			return {
				...state,
				getPermanentDistrictListResponse: docodeResponse(
					action.payload,
					'DistrictCityListGetKey',
				),
			};

		case GeneralActionTypes.GET_PERMANENT_DISTRICT_LIST_FAILURE:
			return { ...state, getPermanentDistrictListResponse: [] };

		case GeneralActionTypes.GET_PERMANENT_DISTRICT_LIST_RESPONSE_CHANGED:
			return { ...state, getPermanentDistrictListResponse: [] };

		case GeneralActionTypes.GET_PINCODE_LIST_SUCCESS:
			return {
				...state,
				getPinCodeListResponse: action.payload.PostOffice,
			};
		case GeneralActionTypes.GET_PINCODE_LIST_FAILURE:
			return { ...state, getPinCodeListResponse: [] };

		case GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_CHANGED:
			return { ...state, getPinCodeListResponse: [] };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_SUCCESS:
			return {
				...state,
				fetchAllBranchesResponse: action.payload,
				fetchAllBranchesArr: docodeResponse(action.payload, 'FetchAllBranchListKey'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCHES_FAILURE:
			return { ...state, fetchAllBranchesResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchesResponse: null };

		case GeneralActionTypes.FETCH_ALL_MEMBERS_SUCCESS:
			return {
				...state,
				fetchAllMembersListResponse: action.payload,
				fetchAllMembersListArr: docodeResponse(action.payload, 'FetchAllMembersKey'),
			};

		case GeneralActionTypes.FETCH_ALL_MEMBERS_FAILURE:
			return { ...state, fetchAllMembersListResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_MEMBERS_RESPONSE_CHANGED:
			return { ...state, fetchAllMembersListResponse: null };

		case GeneralActionTypes.UPDATE_MEMBERS_FOR_MAPPING_START:
			return { ...state, fetchAllMembersListArr: action.payload };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_SUCCESS:
			return {
				...state,
				fetchAllProductTypesResponse: action.payload,
				fetchAllProductTypesArr: docodeResponse(action.payload, 'FetchAllProductTypesKey'),
			};

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_FAILURE:
			return { ...state, fetchAllProductTypesResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_CHANGED:
			return { ...state, fetchAllProductTypesResponse: null };

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_SUCCESS:
			return {
				...state,
				fetchAllBranchOfficerResponse: action.payload,
				fetchAllBranchOfficerArr: docodeResponse(action.payload, 'GetAllBranchOfficerList'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_FAILURE:
			return { ...state, fetchAllBranchOfficerResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchOfficerResponse: null };

		case GeneralActionTypes.FETCH_ALL_CENTERS_SUCCESS:
			return {
				...state,
				fetchAllCenterResponse: action.payload,
				fetchAllCenterArr: docodeResponse(action.payload, 'GetAllCentersList'),
			};

		case GeneralActionTypes.FETCH_ALL_CENTERS_FAILURE:
			return { ...state, fetchAllCenterResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_CHANGED:
			return { ...state, fetchAllCenterResponse: null };

		case GeneralActionTypes.RESET_ALL_REDUCER_STATE_START:
			return INITIAL_STATE;

		case GeneralActionTypes.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				fetchallGroupResponse: action.payload,
				fetchallGroupArr: docodeResponse(action.payload, 'GetAllGroupsListkey'),
			};

		case GeneralActionTypes.FETCH_ALL_GROUP_FAILURE:
			return { ...state, fetchallGroupResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED:
			return { ...state, fetchallGroupResponse: null };

		case GeneralActionTypes.FETCH_CENTER_GROUPS_SUCCESS:
			return {
				...state,
				fetchCenterGroupResponse: action.payload,
				fetchCenterGroupArr: docodeResponse(action.payload, 'FetchAllGroupListKey'),
			};

		case GeneralActionTypes.FETCH_CENTER_GROUPS_FAILURE:
			return { ...state, fetchCenterGroupResponse: action.payload };

		case GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_CHANGED:
			return { ...state, fetchCenterGroupResponse: null };

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_SUCCESS:
			return {
				...state,
				fetchGroupMemberResponse: action.payload,
				fetchGroupMemberArr: docodeResponse(action.payload, 'FetchGroupMembersListKey'),
			};

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_FAILURE:
			return { ...state, fetchGroupMemberResponse: action.payload };

		case GeneralActionTypes.FETCH_GROUP_MEMBERS_RESPONSE_CHANGED:
			return { ...state, fetchGroupMemberResponse: null };

		default:
			return state;
	}
};
