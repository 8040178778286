/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import routeCollectionActionTypes from './routeCollection.types';
import { addRouteCollection } from './routeCollection.service';

import {
	addRouteCollectionDetailsSuccess,
	addRouteCollectionDetailsFailure,
	addRouteCollectionDetailsResponseChanged,
} from './routeCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD APPLICANT COLLECTION DETAILS  ******************** */
export function* addRouteCollectionDetails({ payload }) {
	try {
		const key = 'AddRouteCollectionDetailsKey';
		const jsonData = {
			fetchCenterEnrollMembersList: payload.fetchCenterEnrollMembersList,
			CollectionDateTime: payload.collectionDate,
			CollectionAmount: payload.collectionAmount,
			CompanyID: payload.companyID,
			CollectedFrom: 'web',
			CollectedMode: payload.collectedMode,
			FiveHundred: payload.fiveHundred,
			TwoHundred: payload.twoHundred,
			Hundred: payload.hundred,
			Fifty: payload.fifty,
			Twenty: payload.twenty,
			Ten: payload.ten,
			Five: payload.five,
			Two: payload.two,
			One: payload.one,
			TotalDenominationAmount: payload.totalDenominationAmount,
			ReceiptNumber: payload.receiptNumber,
			ChequeNumber: payload.chequeNumber,
			CreatedBy: payload.UserID,
			UserName: payload.UserName,
			Level: payload.UserLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addRouteCollection, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addRouteCollectionDetailsFailure(responseData.data));
		} else {
			yield put(addRouteCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addRouteCollectionDetailsFailure(error));
	}
}

export function* resetAddRouteCollectionDetailsResponse() {
	yield put(addRouteCollectionDetailsResponseChanged());
}

export function* onAddRouteCollectionDetails() {
	yield takeLatest(
		routeCollectionActionTypes.ADD_ROUTE_COLLECTION_START,
		addRouteCollectionDetails,
	);
}

export function* onAddRouteCollectionDetailsResponseReset() {
	yield takeLatest(
		routeCollectionActionTypes.ADD_ROUTE_COLLECTION_RESPONSE_RESET,
		resetAddRouteCollectionDetailsResponse,
	);
}
/** *********************  END ADD ROUTE COLLECTION INFO  ******************** */

export function* routeCollectionSaga() {
	yield all([call(onAddRouteCollectionDetails), call(onAddRouteCollectionDetailsResponseReset)]);
}
