/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import MemberActionTypes from './member.types';
import {
	memberDetailsAdd,
	memberSecurityDetailsAdd,
	memberProofDetailsAdd,
	memberAddressDetailsAdd,
	memberEnrollmentDetailsAdd,
	memberChitRequestDetailsAdd,
	memberDetailsUpdate,
	memberAddressDetailsUpdate,
	memberBankAccountDetailsUpdate,
	memberProofDetailsUpdate,
	memberSecurityDetailsUpdate,
	memberOfficialDetailsUpdate,
	memberEnrollmentDetailsUpdate,
	memberChitRequestDetailsUpdate,
	memberIsApproveDetailsUpdate,
	memberListGet,
	memberInfoGet,
	memberEnrollmentInfoGet,
	memberEnrollmentListGet,
	memberChitRequestInfoGet,
	memberSecurityInfoListGet,
	memberAddressListGet,
	memberAddressInfoGet,
	memberDetailsDelete,
	fetchProductEnrollmentMembersList,
	fetchCenterEnrollmentMembersList,
	fetchSearchMemberListLoad,
	fetchAdvanceSearchMemberListLoad,
} from './member.service';

import {
	addMemberDetailsSuccess,
	addMemberDetailsFailure,
	addMemberDetailsResponseChanged,
	addMemberSecurityDetailsSuccess,
	addMemberSecurityDetailsFailure,
	addMemberSecurityDetailsResponseChanged,
	addMemberProofDetailsSuccess,
	addMemberProofDetailsFailure,
	addMemberProofDetailsResponseChanged,
	addMemberAddressDetailsSuccess,
	addMemberAddressDetailsFailure,
	addMemberAddressDetailsResponseChanged,
	addMemberEnrollmentDetailsSuccess,
	addMemberEnrollmentDetailsFailure,
	addMemberEnrollmentDetailsResponseChanged,
	addMemberChitRequestDetailsSuccess,
	addMemberChitRequestDetailsFailure,
	addMemberChitRequestDetailsResponseChanged,
	updateMemberDetailsSuccess,
	updateMemberDetailsFailure,
	updateMemberDetailsResponseChanged,
	updateMemberAddressDetailsSuccess,
	updateMemberAddressDetailsFailure,
	updateMemberAddressDetailsResponseChanged,
	updateMemberBankAccountDetailsSuccess,
	updateMemberBankAccountDetailsFailure,
	updateMemberBankAccountDetailsResponseChanged,
	updateMemberProofDetailsSuccess,
	updateMemberProofDetailsFailure,
	updateMemberProofDetailsResponseChanged,
	updateMemberSecurityDetailsSuccess,
	updateMemberSecurityDetailsFailure,
	updateMemberSecurityDetailsResponseChanged,
	updateMemberOfficialDetailsSuccess,
	updateMemberOfficialDetailsFailure,
	updateMemberOfficialDetailsResponseChanged,
	updateMemberEnrollmentDetailsSuccess,
	updateMemberEnrollmentDetailsFailure,
	updateMemberEnrollmentDetailsResponseChanged,
	updateMemberChitRequestDetailsSuccess,
	updateMemberChitRequestDetailsFailure,
	updateMemberChitRequestDetailsResponseChanged,
	updateMemberIsApproveDetailsSuccess,
	updateMemberIsApproveDetailsFailure,
	updateMemberIsApproveDetailsResponseChanged,
	deleteMemberDetailsSuccess,
	deleteMemberDetailsFailure,
	deleteMemberDetailsResponseChanged,
	getMemberListSuccess,
	getMemberListFailure,
	getMemberListResponseChanged,
	fetchSearchMemberListSuccess,
	fetchSearchMemberListFailure,
	fetchSearchMemberListResponseChanged,
	fetchAdvanceSearchMemberListSuccess,
	fetchAdvanceSearchMemberListFailure,
	fetchAdvanceSearchMemberListResponseChanged,
	getMemberInfoSuccess,
	getMemberInfoFailure,
	getMemberInfoResponseChanged,
	getMemberEnrollmentInfoSuccess,
	getMemberEnrollmentInfoFailure,
	getMemberEnrollmentInfoResponseChanged,
	getMemberEnrollmentListSuccess,
	getMemberEnrollmentListFailure,
	getMemberEnrollmentListResponseChanged,
	getMemberChitRequestInfoSuccess,
	getMemberChitRequestInfoFailure,
	getMemberChitRequestInfoResponseChanged,
	getMemberSecurityInfoListSuccess,
	getMemberSecurityInfoListFailure,
	getMemberSecurityInfoListResponseChanged,
	getMemberAddressListSuccess,
	getMemberAddressListFailure,
	getMemberAddressListResponseChanged,
	getMemberAddressInfoSuccess,
	getMemberAddressInfoFailure,
	getMemberAddressInfoResponseChanged,
	fetchProductEnrollmentMembersListSuccess,
	fetchProductEnrollmentMembersListFailure,
	fetchProductEnrollmentMembersListResponseChanged,
	fetchCenterEnrollmentMembersListSuccess,
	fetchCenterEnrollmentMembersListFailure,
	fetchCenterEnrollmentMembersListResponseChanged,
} from './member.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START ADD MEMBER DETAILS  ******************** */
export function* addMemberDetails({ payload }) {
	try {
		const key = 'AddMemberDetailsKey';
		const jsonData = {
			MemberName: payload.memberName,
			MemberPhoneNumber: payload.memberPhoneNumber,
			MemberDateOfBirth: payload.memberDateOfBirth,
			MemberAge: payload.memberAge,
			MemberGender: payload.memberGender,
			MemberEmailID: payload.memberEmailID,
			MemberFatherName: payload.memberFatherName,
			MemberMaritalStatus: payload.memberMaritalStatus,
			MemberSpouseName: payload.memberSpouseName,
			MemberSpouseDateOfBirth: payload.memberSpouseDateOfBirth,
			MemberType: payload.memberType,
			MemberPhoto: payload.memberPhoto,
			MemberSignaturePhoto: payload.memberSignaturePhoto,
			MemberUID: payload.memberUID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberDetailsFailure(responseData.data));
		} else {
			yield put(addMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberDetailsFailure(error));
	}
}

export function* resetAddMemberResponse() {
	yield put(addMemberDetailsResponseChanged());
}

export function* onAddMemberDetails() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_DETAILS_START, addMemberDetails);
}

export function* onAddMemberDetailsResponseReset() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_RESET, resetAddMemberResponse);
}

/** *********************  START ADD MEMBER DETAILS  ******************** */

/** *********************  START ADD MEMBER SECURITY DETAILS  ******************** */
export function* addMemberSecurityDetails({ payload }) {
	try {
		const key = 'AddMemberSecurityDetailsKey';
		const jsonData = {
			SecurityName: payload.securityName,
			ContactNumber: payload.securityContactNumber,
			MemberID: payload.memberID,
			Address1: payload.securityAddressLine1,
			Address2: payload.securityAddressLine2,
			City: payload.securityCity,
			State: payload.securityState,
			District: payload.securityDistrict,
			Country: payload.securityCountry,
			PinCode: payload.securityPinCode,
			LpgIDNumber: payload.securityLpgIDNumber,
			AadharNumber: payload.securityAadharNumber,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberSecurityDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberSecurityDetailsFailure(responseData.data));
		} else {
			yield put(addMemberSecurityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberSecurityDetailsFailure(error));
	}
}

export function* resetAddMemberSecurityResponse() {
	yield put(addMemberSecurityDetailsResponseChanged());
}

export function* onAddMemberSecurityDetails() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_START, addMemberSecurityDetails);
}

export function* onAddMemberSecurityDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_RESPONSE_RESET,
		resetAddMemberSecurityResponse,
	);
}
/** *********************  START ADD MEMBER SECURITY DETAILS  ******************** */

/** *********************  START ADD MEMBER PROOF DETAILS  ******************** */
export function* addMemberProofDetails({ payload }) {
	try {
		const key = 'AddMemberProofDetailsKey';
		const jsonData = {
			MemberAadharNumber: payload.aadharNumber,
			MemberPanCardNo: payload.panNumber,
			MemberDrivingLicenceNumber: payload.memberDrivingLicenceNumber,
			MemberVoterIDNumber: payload.memberVoterIDNumber,
			MemberLpgIDNumber: payload.memberLpgIDNumber,
			MemberPhoto: payload.memberPhoto,
			MemberSignature: payload.memberSignaturePhoto,
			MemberAadharFront: payload.uploadAadharFront,
			MemberAadharBack: payload.uploadAadharBack,
			MemberPanCardFront: payload.uploadPanFront,
			MemberPanCardBack: payload.uploadPanBack,
			MemberDLFront: payload.uploadDLFront,
			MemberDLBack: payload.uploadDLBack,
			MemberVoterIdFront: payload.uploadVoterIDFront,
			MemberVoterIdBack: payload.uploadVoterIDBack,
			MemberLPGFront: payload.uploadMemberLpgID,
			ModifiedBy: payload.userID,
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberProofDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberProofDetailsFailure(responseData.data));
		} else {
			yield put(addMemberProofDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberProofDetailsFailure(error));
	}
}

export function* resetAddMemberProofResponse() {
	yield put(addMemberProofDetailsResponseChanged());
}

export function* onAddMemberProofDetails() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_START, addMemberProofDetails);
}

export function* onAddMemberProofDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_RESPONSE_RESET,
		resetAddMemberProofResponse,
	);
}
/** *********************  START ADD MEMBER PROOF DETAILS  ******************** */

/** *********************  START ADD MEMBER ADDRESS DETAILS  ******************** */
export function* addMemberAddressDetails({ payload }) {
	try {
		const key = 'AddMemberAddressDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberDoorNo: payload.addressDoorNo,
			MemberAddress1: payload.addressLineOne,
			MemberAddress2: payload.addressLineTwo,
			MemberArea: payload.addressArea,
			MemberCity: payload.addressCity,
			MemberState: payload.addressState,
			MemberCountry: payload.addressCountry,
			MemberPinCode: payload.addressPinCode,
			MemberDistrict: payload.addressDistrict,
			MemberRuralUrban: payload.addressRuralUrban,
			MemberHouseType: payload.addressHouseType,
			IsPermanentAddress: payload.isPermanentAddress,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberAddressDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberAddressDetailsFailure(responseData.data));
		} else {
			yield put(addMemberAddressDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberAddressDetailsFailure(error));
	}
}

export function* resetAddMemberAddressResponse() {
	yield put(addMemberAddressDetailsResponseChanged());
}

export function* onAddMemberAddressDetails() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_START, addMemberAddressDetails);
}

export function* onAddMemberAddressDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET,
		resetAddMemberAddressResponse,
	);
}
/** *********************  START ADD MEMBER ADDRESS DETAILS  ******************** */

/** *********************  START ADD MEMBER ENROLLMENT DETAILS  ******************** */
export function* addMemberEnrollmentDetails({ payload }) {
	try {
		const key = 'AddMemberEnrollmentDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberUID: payload.memberUID,
			EnrollmentNumber: payload.memberEnrollmentNo,
			EnrollmentDate: payload.memberEnrollmentDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PassBookIssueDate: payload.memberPassBookIssuedOn,
			PassBookIssuedAt: payload.memberPassBookIssuedAt,
			Name: payload.memberEnrollName,
			Age: payload.memberEnrollAge,
			Gender: payload.memberEnrollGender,
			Area: payload.memberEnrollArea,
			FatherHusbandPropName: payload.memberEnrollFatherHusbandProp,
			Occupation: payload.memberEnrollOccupation,
			ResPhone1: payload.memberEnrollResdPhoneNo1,
			ResPhone2: payload.memberEnrollResdPhoneNo2,
			CollectionAddressID: payload.memberCollectionAddressID,
			PermanentAddressID: payload.memberPermanentAddressID,
			NomineeName: payload.memberNomineeName,
			NomineeRelationship: payload.memberNomineeRelationship,
			NomineeAge: payload.memberNomineeAge,
			SuretyInfo: payload.memberSuretyInfo,
			OtherRemarks: payload.memberMDOtherRemarks,
			ProductID: payload.memberProductID,
			ProductAmount: payload.memberProductAmount,
			NoOfInstalments: payload.memberNoOfInstalments,
			InstalmentAmount: payload.memberInstalmentAmount,
			NoOfTickets: payload.memberNoOfTickets,
			CollectionMode: payload.memberCollectionMode,
			CollectionSupervisior: payload.memberCollectionSupervisior,
			AgentID: payload.memberAgentID,
			AgentAmount: payload.memberAgentAmount,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberEnrollmentDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberEnrollmentDetailsFailure(responseData.data));
		} else {
			yield put(addMemberEnrollmentDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberEnrollmentDetailsFailure(error));
	}
}

export function* resetAddMemberEnrollmentResponse() {
	yield put(addMemberEnrollmentDetailsResponseChanged());
}

export function* onAddMemberEnrollmentDetails() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_START,
		addMemberEnrollmentDetails,
	);
}

export function* onAddMemberEnrollmentDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
		resetAddMemberEnrollmentResponse,
	);
}
/** *********************  START ADD MEMBER ENROLLMENT DETAILS  ******************** */

/** *********************  START ADD MEMBER CHIT REQUEST DETAILS  ******************** */
export function* addMemberChitRequestDetails({ payload }) {
	try {
		const key = 'AddMemberChitRequestDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			NomineeName: payload.memberNomineeName,
			NomineeRelationship: payload.memberNomineeRelationship,
			NomineeAge: payload.memberNomineeAge,
			SecurityInfo: payload.memberSecurityInfo,
			SecurityID: payload.memberSecurityID,
			OtherRemarks: payload.memberMDOtherRemarks,
			ProductID: payload.memberProductID,
			ProductAmount: payload.memberProductAmount,
			NoOfInstalments: payload.memberNoOfInstalments,
			InstalmentAmount: payload.memberInstalmentAmount,
			NoOfTickets: payload.memberNoOfTickets,
			CollectionMode: payload.memberCollectionMode,
			CollectionSupervisior: payload.memberCollectionSupervisior,
			AgentID: payload.memberAgentID,
			AgentAmount: payload.memberAgentAmount,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberChitRequestDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberChitRequestDetailsFailure(responseData.data));
		} else {
			yield put(addMemberChitRequestDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberChitRequestDetailsFailure(error));
	}
}

export function* resetAddMemberChitRequestResponse() {
	yield put(addMemberChitRequestDetailsResponseChanged());
}

export function* onAddMemberChitRequestDetails() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_START,
		addMemberChitRequestDetails,
	);
}

export function* onAddMemberChitRequestDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
		resetAddMemberChitRequestResponse,
	);
}
/** *********************  START ADD MEMBER CHIT REQUEST DETAILS  ******************** */

/** *********************  START UPDATE MEMBER DETAILS  ******************** */
export function* updateMemberDetails({ payload }) {
	try {
		const key = 'UpdateMemberDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberName: payload.memberName,
			MemberPhoneNumber: payload.memberPhoneNumber,
			MemberDateOfBirth: payload.memberDateOfBirth,
			MemberAge: payload.memberAge,
			MemberGender: payload.memberGender,
			MemberEmailID: payload.memberEmailID,
			MemberFatherName: payload.memberFatherName,
			MemberMaritalStatus: payload.memberMaritalStatus,
			MemberSpouseName: payload.memberSpouseName,
			MemberSpouseDateOfBirth: payload.memberSpouseDateOfBirth,
			MemberType: payload.memberType,
			MemberPhoto: payload.memberPhoto,
			MemberSignaturePhoto: payload.memberSignaturePhoto,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberDetailsFailure(error));
	}
}

export function* resetUpdateMemberResponse() {
	yield put(updateMemberDetailsResponseChanged());
}

export function* onUpdateMemberDetails() {
	yield takeLatest(MemberActionTypes.UPDATE_MEMBER_DETAILS_START, updateMemberDetails);
}

export function* onUpdateMemberDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_RESET,
		resetUpdateMemberResponse,
	);
}
/** *********************  START UPDATE MEMBER DETAILS  ******************** */

/** *********************  START UPDATE MEMBER ADDRESS DETAILS  ******************** */
export function* updateMemberAddressDetails({ payload }) {
	try {
		const key = 'UpdateMemberAddressDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberAddressID: payload.memberAddressID,
			MemberDoorNo: payload.addressDoorNo,
			MemberAddress1: payload.addressLineOne,
			MemberAddress2: payload.addressLineTwo,
			MemberArea: payload.addressArea,
			MemberCity: payload.addressCity,
			MemberState: payload.addressState,
			MemberCountry: payload.addressCountry,
			MemberPinCode: payload.addressPinCode,
			MemberDistrict: payload.addressDistrict,
			MemberRuralUrban: payload.addressRuralUrban,
			MemberHouseType: payload.addressHouseType,
			IsPermanentAddress: payload.isPermanentAddress,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			ModifiedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberAddressDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberAddressDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberAddressDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberAddressDetailsFailure(error));
	}
}

export function* resetUpdateMemberAddressResponse() {
	yield put(updateMemberAddressDetailsResponseChanged());
}

export function* onUpdateMemberAddressDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_START,
		updateMemberAddressDetails,
	);
}

export function* onUpdateMemberAddressDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET,
		resetUpdateMemberAddressResponse,
	);
}
/** *********************  START UPDATE MEMBER ADDRESS DETAILS  ******************** */

/** *********************  START UPDATE MEMBER BANK ACCOUNT DETAILS  ******************** */
export function* updateMemberBankAccountDetails({ payload }) {
	try {
		const key = 'UpdateMemberBankAccountDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberBankName: payload.bankName,
			MemberBankBranchName: payload.bankBranchName,
			MemberBankAccountNumber: payload.bankAccountNumber,
			MemberBankAccountName: payload.bankAccountName,
			MemberBankIFSCcode: payload.bankIfsc,
			MemberBankAccountType: payload.memberBankAccountType,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			ModifiedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberBankAccountDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberBankAccountDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberBankAccountDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberBankAccountDetailsFailure(error));
	}
}

export function* resetUpdateMemberBankAccountResponse() {
	yield put(updateMemberBankAccountDetailsResponseChanged());
}

export function* onUpdateMemberBankAccountDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_START,
		updateMemberBankAccountDetails,
	);
}

export function* onUpdateMemberBankAccountDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
		resetUpdateMemberBankAccountResponse,
	);
}
/** *********************  START UPDATE MEMBER BANK ACCOUNT DETAILS  ******************** */

/** *********************  START UPDATE MEMBER OFFICIAL DETAILS  ******************** */
export function* updateMemberOfficialDetails({ payload }) {
	try {
		const key = 'UpdateMemberOfficialDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberOccupation: payload.memberOccupation,
			MemberDateOfJoining: payload.memberDateOfJoining,
			MemberBranchID: payload.branchID,
			MemberCenterID: payload.centerID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			ModifiedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberOfficialDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberOfficialDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberOfficialDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberOfficialDetailsFailure(error));
	}
}

export function* resetUpdateMemberOfficialResponse() {
	yield put(updateMemberOfficialDetailsResponseChanged());
}

export function* onUpdateMemberOfficialDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_START,
		updateMemberOfficialDetails,
	);
}

export function* onUpdateMemberOfficialDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_RESET,
		resetUpdateMemberOfficialResponse,
	);
}
/** *********************  START UPDATE MEMBER OFFICIAL DETAILS  ******************** */

/** *********************  START UPDATE MEMBER PROOF DETAILS  ******************** */
export function* updateMemberProofDetails({ payload }) {
	try {
		const key = 'UpdateMemberProofDetailsKey';
		const jsonData = {
			MemberAadharNumber: payload.aadharNumber,
			MemberPanCardNo: payload.panNumber,
			MemberDrivingLicenceNumber: payload.memberDrivingLicenceNumber,
			MemberVoterIDNumber: payload.memberVoterIDNumber,
			MemberLpgIDNumber: payload.memberLpgIDNumber,
			MemberPhoto: payload.memberPhoto,
			MemberSignature: payload.memberSignaturePhoto,
			MemberAadharFront: payload.uploadAadharFront,
			MemberAadharBack: payload.uploadAadharBack,
			MemberPanCardFront: payload.uploadPanFront,
			MemberPanCardBack: payload.uploadPanBack,
			MemberDLFront: payload.uploadDLFront,
			MemberDLBack: payload.uploadDLBack,
			MemberVoterIdFront: payload.uploadVoterIDFront,
			MemberVoterIdBack: payload.uploadVoterIDBack,
			MemberLPGFront: payload.uploadMemberLpgID,
			ModifiedBy: payload.userID,
			MemberID: payload.memberID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberProofDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberProofDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberProofDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberProofDetailsFailure(error));
	}
}

export function* resetUpdateMemberProofResponse() {
	yield put(updateMemberProofDetailsResponseChanged());
}

export function* onUpdateMemberProofDetails() {
	yield takeLatest(MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_START, updateMemberProofDetails);
}

export function* onUpdateMemberProofDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_RESET,
		resetUpdateMemberProofResponse,
	);
}
/** *********************  START UPDATE MEMBER PROOF DETAILS  ******************** */

/** *********************  START UPDATE MEMBER SECURITY DETAILS  ******************** */
export function* updateMemberSecurityDetails({ payload }) {
	try {
		const key = 'UpdateMemberSecurityDetailsKey';
		const jsonData = {
			SecurityName: payload.securityName,
			ContactNumber: payload.securityContactNumber,
			MemberID: payload.memberID,
			Address1: payload.securityAddressLine1,
			Address2: payload.securityAddressLine2,
			City: payload.securityCity,
			State: payload.securityState,
			District: payload.securityDistrict,
			Country: payload.securityCountry,
			PinCode: payload.securityPinCode,
			LpgIDNumber: payload.securityLpgIDNumber,
			AadharNumber: payload.securityAadharNumber,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberSecurityDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberSecurityDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberSecurityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberSecurityDetailsFailure(error));
	}
}

export function* resetUpdateMemberSecurityResponse() {
	yield put(updateMemberSecurityDetailsResponseChanged());
}

export function* onUpdateMemberSecurityDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_START,
		updateMemberSecurityDetails,
	);
}

export function* onUpdateMemberSecurityDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_RESET,
		resetUpdateMemberSecurityResponse,
	);
}
/** *********************  START UPDATE MEMBER SECURITY DETAILS  ******************** */

/** *********************  START UPDATE MEMBER ENROLLMENT DETAILS  ******************** */
export function* updateMemberEnrollmentDetails({ payload }) {
	try {
		const key = 'UpdateMemberEnrollmentDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			MemberEnrollmentID: payload.enrollmentID,
			EnrollmentNumber: payload.memberEnrollmentNo,
			EnrollmentDate: payload.memberEnrollmentDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			PassBookIssueDate: payload.memberPassBookIssuedOn,
			PassBookIssuedAt: payload.memberPassBookIssuedAt,
			Name: payload.memberEnrollName,
			Age: payload.memberEnrollAge,
			Gender: payload.memberEnrollGender,
			Area: payload.memberEnrollArea,
			FatherHusbandPropName: payload.memberEnrollFatherHusbandProp,
			Occupation: payload.memberEnrollOccupation,
			ResPhone1: payload.memberEnrollResdPhoneNo1,
			ResPhone2: payload.memberEnrollResdPhoneNo2,
			CollectionAddressID: payload.memberCollectionAddressID,
			PermanentAddressID: payload.memberPermanentAddressID,
			NomineeName: payload.memberNomineeName,
			NomineeRelationship: payload.memberNomineeRelationship,
			NomineeAge: payload.memberNomineeAge,
			SuretyInfo: payload.memberSuretyInfo,
			OtherRemarks: payload.memberMDOtherRemarks,
			ProductID: payload.memberProductID,
			ProductAmount: payload.memberProductAmount,
			NoOfInstalments: payload.memberNoOfInstalments,
			InstalmentAmount: payload.memberInstalmentAmount,
			NoOfTickets: payload.memberNoOfTickets,
			CollectionMode: payload.memberCollectionMode,
			CollectionSupervisior: payload.memberCollectionSupervisior,
			AgentID: payload.memberAgentID,
			AgentAmount: payload.memberAgentAmount,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberEnrollmentDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberEnrollmentDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberEnrollmentDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberEnrollmentDetailsFailure(error));
	}
}

export function* resetUpdateMemberEnrollmentResponse() {
	yield put(updateMemberEnrollmentDetailsResponseChanged());
}

export function* onUpdateMemberEnrollmentDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_START,
		updateMemberEnrollmentDetails,
	);
}

export function* onUpdateMemberEnrollmentDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
		resetUpdateMemberEnrollmentResponse,
	);
}
/** *********************  START UPDATE MEMBER ENROLLMENT DETAILS  ******************** */

/** *********************  START UPDATE MEMBER CHIT REQUEST DETAILS  ******************** */
export function* updateMemberChitRequestDetails({ payload }) {
	try {
		const key = 'UpdateMemberChitRequestDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			NomineeName: payload.memberNomineeName,
			NomineeRelationship: payload.memberNomineeRelationship,
			NomineeAge: payload.memberNomineeAge,
			SecurityInfo: payload.memberSecurityInfo,
			SecurityID: payload.memberSecurityID,
			OtherRemarks: payload.memberMDOtherRemarks,
			ProductID: payload.memberProductID,
			ProductAmount: payload.memberProductAmount,
			NoOfInstalments: payload.memberNoOfInstalments,
			InstalmentAmount: payload.memberInstalmentAmount,
			NoOfTickets: payload.memberNoOfTickets,
			CollectionMode: payload.memberCollectionMode,
			CollectionSupervisior: payload.memberCollectionSupervisior,
			AgentID: payload.memberAgentID,
			AgentAmount: payload.memberAgentAmount,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberChitRequestDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberChitRequestDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberChitRequestDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberChitRequestDetailsFailure(error));
	}
}

export function* resetUpdateMemberChitRequestResponse() {
	yield put(updateMemberChitRequestDetailsResponseChanged());
}

export function* onUpdateMemberChitRequestDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_START,
		updateMemberChitRequestDetails,
	);
}

export function* onUpdateMemberChitRequestDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
		resetUpdateMemberChitRequestResponse,
	);
}
/** *********************  START UPDATE MEMBER CHIT REQUEST DETAILS  ******************** */

/** *********************  START UPDATE MEMBER ISAPPROVE DETAILS  ******************** */
export function* updateMemberIsApproveDetails({ payload }) {
	try {
		const key = 'UpdateMemberIsApproveDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			IsApprove: payload.isApprove,
			ApprovedBy: payload.approvedBy,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			ModifiedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberIsApproveDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberIsApproveDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberIsApproveDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberIsApproveDetailsFailure(error));
	}
}

export function* resetUpdateMemberIsApproveResponse() {
	yield put(updateMemberIsApproveDetailsResponseChanged());
}

export function* onUpdateMemberIsApproveDetails() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_START,
		updateMemberIsApproveDetails,
	);
}

export function* onUpdateMemberIsApproveDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_RESET,
		resetUpdateMemberIsApproveResponse,
	);
}
/** *********************  START UPDATE MEMBER ISAPPROVE DETAILS  ******************** */

/** *********************  START GET MEMBER LIST  ******************** */
export function* GetAllMemberList({ payload }) {
	try {
		const key = 'FetchMemberListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberListFailure(responseData.data));
		} else {
			yield put(getMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberListFailure(error));
	}
}

export function* resetGetMemberListResponse() {
	yield put(getMemberListResponseChanged());
}

export function* onGetMemberListDetails() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_LIST_START, GetAllMemberList);
}

export function* onGetMemberListResponseReset() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_LIST_RESPONSE_RESET, resetGetMemberListResponse);
}
/** *********************  END GET MEMBER LIST ******************** */

/** *********************  START FETCH SEARCH MEMBER LIST  ******************** */
export function* fetchSearchMemberList({ payload }) {
	try {
		const key = 'FetchSearchMemberListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			BranchID: payload.userAccessBranchID,
			SearchValue: payload.SearchValue,
			Limit: payload.Limit,
			UserID: payload.UserID,
			UserLevel: payload.Level,
			UserRole: payload.UserRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchMemberListLoad, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSearchMemberListFailure(responseData.data));
		} else {
			yield put(fetchSearchMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSearchMemberListFailure(error));
	}
}

export function* resetFetchSearchMemberListResponse() {
	yield put(fetchSearchMemberListResponseChanged());
}

export function* onFetchSearchMemberList() {
	yield takeLatest(MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_START, fetchSearchMemberList);
}

export function* onFetchSearchMemberListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_RESET,
		resetFetchSearchMemberListResponse,
	);
}
/** *********************  END FETCH SEARCH MEMBER LIST ******************** */

/** *********************  START FETCH ADVANCE SEARCH MEMBER LIST  ******************** */
export function* fetchAdvanceSearchMemberList({ payload }) {
	try {
		const key = 'FetchAdvanceSearchMemberListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			memberName: payload.memberName,
			memberPhoneNumber: payload.memberPhoneNumber,
			memberFatherName: payload.memberFatherName,
			memberSpouseName: payload.memberSpouseName,
			memberArea: payload.memberArea,
			memberAddress2: payload.memberAddress2,
			memberBusinessName: payload.memberBusinessName,
			Limit: 0,
			UserID: payload.UserID,
			UserLevel: payload.Level,
			UserRole: payload.UserRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAdvanceSearchMemberListLoad, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAdvanceSearchMemberListFailure(responseData.data));
		} else {
			yield put(fetchAdvanceSearchMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAdvanceSearchMemberListFailure(error));
	}
}

export function* resetFetchAdvanceSearchMemberListResponse() {
	yield put(fetchAdvanceSearchMemberListResponseChanged());
}

export function* onFetchAdvanceSearchMemberList() {
	yield takeLatest(
		MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_START,
		fetchAdvanceSearchMemberList,
	);
}

export function* onFetchAdvanceSearchMemberListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_RESET,
		resetFetchSearchMemberListResponse,
	);
}
/** *********************  END FETCH ADVANCE SEARCH MEMBER LIST ******************** */

/** *********************  START GET MEMBER Info  ******************** */
export function* GetMemberInfo({ payload }) {
	try {
		const key = 'FetchMemberInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberInfoGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberInfoFailure(responseData.data));
		} else {
			yield put(getMemberInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberInfoFailure(error));
	}
}

export function* resetGetMemberInfoResponse() {
	yield put(getMemberInfoResponseChanged());
}

export function* onGetMemberInfoDetails() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_INFO_START, GetMemberInfo);
}

export function* onGetMemberInfoResponseReset() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_INFO_RESPONSE_RESET, resetGetMemberInfoResponse);
}
/** *********************  END GET MEMBER INFO ******************** */

/** *********************  START GET MEMBER ENROLLMENT Info  ******************** */
export function* fetchMemberEnrollmentInfo({ payload }) {
	try {
		const key = 'FetchMemberEnrollmentInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
			MemberEnrollmentID: payload.MemberEnrollmentID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberEnrollmentInfoGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberEnrollmentInfoFailure(responseData.data));
		} else {
			yield put(getMemberEnrollmentInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberEnrollmentInfoFailure(error));
	}
}

export function* resetGetMemberEnrollmentInfoResponse() {
	yield put(getMemberEnrollmentInfoResponseChanged());
}

export function* onGetMemberEnrollmentInfoDetails() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_START,
		fetchMemberEnrollmentInfo,
	);
}

export function* onGetMemberEnrollmentInfoResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
		resetGetMemberEnrollmentInfoResponse,
	);
}
/** *********************  END GET MEMBER ENROLLMENT INFO ******************** */

/** *********************  START FETCH MEMBER ENROLLMENT LIST  ******************** */
export function* fetchMemberEnrollmentList({ payload }) {
	try {
		const key = 'FetchMemberEnrollmentListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberEnrollmentListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberEnrollmentListFailure(responseData.data));
		} else {
			yield put(getMemberEnrollmentListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberEnrollmentListFailure(error));
	}
}

export function* resetGetMemberEnrollmentListResponse() {
	yield put(getMemberEnrollmentListResponseChanged());
}

export function* onGetMemberEnrollmentListDetails() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_START,
		fetchMemberEnrollmentList,
	);
}

export function* onGetMemberEnrollmentListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_RESET,
		resetGetMemberEnrollmentListResponse,
	);
}
/** *********************  END GET MEMBER ENROLLMENT LIST ******************** */

/** *********************  START GET MEMBER CHIT REQUEST Info  ******************** */
export function* fetchMemberChitRequestInfo({ payload }) {
	try {
		const key = 'FetchMemberChitRequestInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberChitRequestInfoGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberChitRequestInfoFailure(responseData.data));
		} else {
			yield put(getMemberChitRequestInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberChitRequestInfoFailure(error));
	}
}

export function* resetGetMemberChitRequestInfoResponse() {
	yield put(getMemberChitRequestInfoResponseChanged());
}

export function* onGetMemberChitRequestInfoDetails() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_START,
		fetchMemberChitRequestInfo,
	);
}

export function* onGetMemberChitRequestInfoResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
		resetGetMemberChitRequestInfoResponse,
	);
}
/** *********************  END GET MEMBER CHIT REQUEST INFO ******************** */

/** *********************  START GET MEMBER SECURITY INFO LIST  ******************** */
export function* fetchMemberSecurityInfoList({ payload }) {
	try {
		const key = 'FetchMemberSecurityInfoListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberSecurityInfoListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberSecurityInfoListFailure(responseData.data));
		} else {
			yield put(getMemberSecurityInfoListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberSecurityInfoListFailure(error));
	}
}

export function* resetGetMemberSecurityInfoListResponse() {
	yield put(getMemberSecurityInfoListResponseChanged());
}

export function* onGetMemberSecurityInfoListDetails() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_START,
		fetchMemberSecurityInfoList,
	);
}

export function* onGetMemberSecurityInfoListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_RESPONSE_RESET,
		resetGetMemberSecurityInfoListResponse,
	);
}
/** *********************  END GET MEMBER SECURITY INFO LIST ******************** */

/** *********************  START GET MEMBER ADDRESS LIST  ******************** */
export function* fetchMemberAddressList({ payload }) {
	try {
		const key = 'FetchMemberAddressListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberAddressListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberAddressListFailure(responseData.data));
		} else {
			yield put(getMemberAddressListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberAddressListFailure(error));
	}
}

export function* resetGetMemberAddressListResponse() {
	yield put(getMemberAddressListResponseChanged());
}

export function* onGetMemberAddressListDetails() {
	yield takeLatest(MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_START, fetchMemberAddressList);
}

export function* onGetMemberAddressListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_RESPONSE_RESET,
		resetGetMemberAddressListResponse,
	);
}
/** *********************  END GET MEMBER ADDRESS LIST ******************** */

/** *********************  START GET MEMBER ADDRESS INFO  ******************** */
export function* fetchMemberAddressInfo({ payload }) {
	try {
		const key = 'FetchMemberAddressInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			MemberID: payload.MemberID,
			MemberAddressID: payload.MemberAddressID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberAddressInfoGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberAddressInfoFailure(responseData.data));
		} else {
			yield put(getMemberAddressInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberAddressInfoFailure(error));
	}
}

export function* resetGetMemberAddressInfoResponse() {
	yield put(getMemberAddressInfoResponseChanged());
}

export function* onGetMemberAddressInfoDetails() {
	yield takeLatest(MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_START, fetchMemberAddressInfo);
}

export function* onGetMemberAddressInfoResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_RESPONSE_RESET,
		resetGetMemberAddressInfoResponse,
	);
}
/** *********************  END GET MEMBER ADDRESS INFO ******************** */

/** *********************  START FETCH PRODUCT ENROLLMENT MEMBERS LIST  ******************** */
export function* loadProductEnrollmentMembersList({ payload }) {
	try {
		const key = 'FetchProductEnrollmentMembersKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			ProductID: payload.productID,
			UserID: payload.userID,
			CollectionPercentage: payload.collectionPercentage,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchProductEnrollmentMembersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductEnrollmentMembersListFailure(responseData.data));
		} else {
			yield put(fetchProductEnrollmentMembersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductEnrollmentMembersListFailure(error));
	}
}

export function* resetFetchProductEnrollmentMembersListResponse() {
	yield put(fetchProductEnrollmentMembersListResponseChanged());
}

export function* onFetchProductEnrollmentMembersList() {
	yield takeLatest(
		MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_START,
		loadProductEnrollmentMembersList,
	);
}

export function* onFetchProductEnrollmentMembersListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET,
		resetFetchProductEnrollmentMembersListResponse,
	);
}
/** *********************  END FETCH PRODUCT ENROLLMENT MEMBERS LIST ******************** */

/** *********************  START FETCH CENTER ENROLLMENT MEMBERS LIST  ******************** */
export function* loadCenterEnrollmentMembersList({ payload }) {
	try {
		const key = 'FetchCenterEnrollMembersListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CenterID: payload.getCenterID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterEnrollmentMembersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterEnrollmentMembersListFailure(responseData.data));
		} else {
			yield put(fetchCenterEnrollmentMembersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterEnrollmentMembersListFailure(error));
	}
}

export function* resetFetchCenterEnrollmentMembersListResponse() {
	yield put(fetchCenterEnrollmentMembersListResponseChanged());
}

export function* onFetchCenterEnrollmentMembersList() {
	yield takeLatest(
		MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_START,
		loadCenterEnrollmentMembersList,
	);
}

export function* onFetchCenterEnrollmentMembersListResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET,
		resetFetchCenterEnrollmentMembersListResponse,
	);
}
/** *********************  END FETCH CENTER ENROLLMENT MEMBERS LIST ******************** */

/** *********************  START DELETE MEMBER DETAILS  ******************** */
export function* memberDelete({ payload }) {
	try {
		const key = 'DeleteMemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberID: payload.memberID,
			MemberName: payload.memberName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteMemberDetailsFailure(responseData.data));
		} else {
			yield put(deleteMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteMemberDetailsFailure(error));
	}
}

export function* resetDeleteMemberDetailsResponse() {
	yield put(deleteMemberDetailsResponseChanged());
}

export function* onDeleteMemberDetail() {
	yield takeLatest(MemberActionTypes.DELETE_MEMBER_DETAILS_START, memberDelete);
}

export function* onDeleteMemberResponseReset() {
	yield takeLatest(
		MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_RESET,
		resetDeleteMemberDetailsResponse,
	);
}
/** *********************  END DELETE MEMBER DETAILS ******************** */

export function* memberMasterSaga() {
	yield all([
		call(onAddMemberDetails),
		call(onAddMemberDetailsResponseReset),
		call(onAddMemberSecurityDetails),
		call(onAddMemberSecurityDetailsResponseReset),
		call(onAddMemberProofDetails),
		call(onAddMemberProofDetailsResponseReset),
		call(onAddMemberAddressDetails),
		call(onAddMemberAddressDetailsResponseReset),
		call(onAddMemberEnrollmentDetails),
		call(onAddMemberEnrollmentDetailsResponseReset),
		call(onAddMemberChitRequestDetails),
		call(onAddMemberChitRequestDetailsResponseReset),
		call(onUpdateMemberDetails),
		call(onUpdateMemberDetailsResponseReset),
		call(onUpdateMemberAddressDetails),
		call(onUpdateMemberAddressDetailsResponseReset),
		call(onUpdateMemberBankAccountDetails),
		call(onUpdateMemberBankAccountDetailsResponseReset),
		call(onUpdateMemberProofDetails),
		call(onUpdateMemberProofDetailsResponseReset),
		call(onUpdateMemberSecurityDetails),
		call(onUpdateMemberSecurityDetailsResponseReset),
		call(onUpdateMemberOfficialDetails),
		call(onUpdateMemberOfficialDetailsResponseReset),
		call(onUpdateMemberEnrollmentDetails),
		call(onUpdateMemberEnrollmentDetailsResponseReset),
		call(onUpdateMemberChitRequestDetails),
		call(onUpdateMemberChitRequestDetailsResponseReset),
		call(onUpdateMemberIsApproveDetails),
		call(onUpdateMemberIsApproveDetailsResponseReset),
		call(onDeleteMemberDetail),
		call(onDeleteMemberResponseReset),
		call(onGetMemberListDetails),
		call(onGetMemberListResponseReset),
		call(onGetMemberInfoDetails),
		call(onGetMemberInfoResponseReset),
		call(onGetMemberEnrollmentInfoDetails),
		call(onGetMemberEnrollmentInfoResponseReset),
		call(onGetMemberEnrollmentListDetails),
		call(onGetMemberEnrollmentListResponseReset),
		call(onGetMemberChitRequestInfoDetails),
		call(onGetMemberChitRequestInfoResponseReset),
		call(onGetMemberSecurityInfoListDetails),
		call(onGetMemberSecurityInfoListResponseReset),
		call(onGetMemberAddressListDetails),
		call(onGetMemberAddressListResponseReset),
		call(onGetMemberAddressInfoDetails),
		call(onGetMemberAddressInfoResponseReset),
		call(onFetchProductEnrollmentMembersList),
		call(onFetchProductEnrollmentMembersListResponseReset),
		call(onFetchCenterEnrollmentMembersList),
		call(onFetchCenterEnrollmentMembersListResponseReset),
		call(onFetchSearchMemberList),
		call(onFetchSearchMemberListResponseReset),
		call(onFetchAdvanceSearchMemberList),
		call(onFetchAdvanceSearchMemberListResponseReset),
	]);
}
