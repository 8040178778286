/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import USERS from '../../common/data/userDummyData';
import { API_URL } from '../../redux/const';
import { selectCurrentDecodedUser } from '../../redux/login/login.selector';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img
				srcSet={`${API_URL}${srcSet}`}
				src={`${API_URL}${src}`}
				alt='Avatar'
				width={128}
				height={128}
			/>
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const loginResponseStatus = useSelector(selectCurrentDecodedUser);

	return (
		<div role='presentation' className='user'>
			{loginResponseStatus !== null &&
			loginResponseStatus !== undefined &&
			loginResponseStatus.data.length > 0 ? (
				<>
					{loginResponseStatus.data[0].userPhoto === '' ||
					loginResponseStatus.data[0].userPhoto === null ||
					loginResponseStatus.data[0].userPhoto === undefined ? null : (
						<UserAvatar
							srcSet={`${loginResponseStatus.data[0].userPhoto}`}
							src={`${loginResponseStatus.data[0].userPhoto}`}
						/>
					)}

					<div className='user-info'>
						<div className='user-name'>{`${loginResponseStatus.data[0].userName}`}</div>
						<div className='user-sub-title'>
							{loginResponseStatus.data[0].userPhoneNumber}
						</div>
					</div>
				</>
			) : (
				<>
					<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					<div className='user-info'>
						<div className='user-name'>{`${USERS.JOHN.name} ${USERS.JOHN.surname}`}</div>
						<div className='user-sub-title'>{USERS.JOHN.position}</div>
					</div>
				</>
			)}
		</div>
	);
};

export default User;
