/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DemandCollectionActionTypes from './demandCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getDemandCollectionListResponse: null,
	getDemandCollectionListArr: [],
	toUpdateDemandCollectionDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_SUCCESS:
			return {
				...state,
				getDemandCollectionListResponse: action.payload,
				getDemandCollectionListArr: docodeResponse(
					action.payload,
					'FetchDemandCollectionListKey',
				),
			};

		case DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_FAILURE:
			return { ...state, getDemandCollectionListResponse: action.payload };

		case DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED:
			return { ...state, getDemandCollectionListResponse: null };

		case DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_ARR_UPDATED_CHANGED:
			return { ...state, getDemandCollectionListArr: action.payload };

		case DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_SUCCESS:
			return {
				...state,
				toUpdateDemandCollectionDetailsResponse: action.payload,
			};

		case DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_FAILURE:
			return { ...state, toUpdateDemandCollectionDetailsResponse: action.payload };

		case DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_CHANGED:
			return { ...state, toUpdateDemandCollectionDetailsResponse: null };

		default:
			return state;
	}
};
