/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ChitMemberReportActionTypes from './chitMembersReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchChitMembersReportResponse: null,
	fetchChitMembersReportArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChitMemberReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_SUCCESS:
			return {
				...state,
				fetchChitMembersReportResponse: action.payload,
				fetchChitMembersReportArr: docodeResponse(
					action.payload,
					'FetchChitMemberReportKey',
				),
			};

		case ChitMemberReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_FAILURE:
			return { ...state, fetchChitMembersReportResponse: action.payload };

		case ChitMemberReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchChitMembersReportResponse: null };

		default:
			return state;
	}
};
