/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DemandFollowUpReportActionTypes from './demandFollowUpReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchDemandFollowUpReportResponse: null,
	fetchDemandFollowUpReportArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_SUCCESS:
			return {
				...state,
				fetchDemandFollowUpReportResponse: action.payload,
				fetchDemandFollowUpReportArr: docodeResponse(
					action.payload,
					'FetchDemandFollowUpReportKey',
				),
			};

		case DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_FAILURE:
			return { ...state, fetchDemandFollowUpReportResponse: action.payload };

		case DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchDemandFollowUpReportResponse: null };

		default:
			return state;
	}
};
