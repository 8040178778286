/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ChitMembersReportActionTypes from './chitMembersReport.types';

/** ***************** FETCH CHIT MEMBERS REPORT START *********************** */
export const fetchChitMembersReportStart = (getData) => ({
	type: ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_START,
	payload: getData,
});

export const fetchChitMembersReportSuccess = (successData) => ({
	type: ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_SUCCESS,
	payload: successData,
});

export const fetchChitMembersReportFailure = (failureData) => ({
	type: ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_FAILURE,
	payload: failureData,
});

export const fetchChitMembersReportResponseResetStart = () => ({
	type: ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_RESPONSE_RESET,
});

export const fetchChitMembersReportResponseChanged = () => ({
	type: ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH CHIT MEMBERS REPORT END *********************** */
