/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import groupCollectionActionTypes from './groupCollection.types';

const INITIAL_STATE = {
	addGroupCollectionResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case groupCollectionActionTypes.ADD_GROUP_COLLECTION_SUCCESS:
			return { ...state, addGroupCollectionResponse: action.payload };

		case groupCollectionActionTypes.ADD_GROUP_COLLECTION_FAILURE:
			return { ...state, addGroupCollectionResponse: action.payload };

		case groupCollectionActionTypes.ADD_GROUP_COLLECTION_RESPONSE_CHANGED:
			return { ...state, addGroupCollectionResponse: null };

		default:
			return state;
	}
};
