/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import repaymentReportActionTypes from './repaymentReport.types';

/** ***************** FETCH REPAYMENT REPORT START *********************** */
export const fetchRepaymentReportStart = (getData) => ({
	type: repaymentReportActionTypes.FETCH_REPAYMENT_REPORT_START,
	payload: getData,
});

export const fetchRepaymentReportSuccess = (successData) => ({
	type: repaymentReportActionTypes.FETCH_REPAYMENT_REPORT_SUCCESS,
	payload: successData,
});

export const fetchRepaymentReportFailure = (failureData) => ({
	type: repaymentReportActionTypes.FETCH_REPAYMENT_REPORT_FAILURE,
	payload: failureData,
});

export const fetchRepaymentReportResponseResetStart = () => ({
	type: repaymentReportActionTypes.FETCH_REPAYMENT_REPORT_RESPONSE_RESET,
});

export const fetchRepaymentReportResponseChanged = () => ({
	type: repaymentReportActionTypes.FETCH_REPAYMENT_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH REPAYMENT REPORT END *********************** */
