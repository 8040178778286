const CenterActionTypes = {
	ADD_CENTER_DETAILS_START: 'add_center_details_start',
	ADD_CENTER_DETAILS_SUCCESS: 'add_center_details_success',
	ADD_CENTER_DETAILS_FAILURE: 'add_center_details_failure',
	ADD_CENTER_DETAILS_RESPONSE_RESET: 'add_center_details_response_reset',
	ADD_CENTER_DETAILS_RESPONSE_CHANGED: 'add_center_details_response_changed',

	UPDATE_CENTER_DETAILS_START: 'update_center_details_start',
	UPDATE_CENTER_DETAILS_SUCCESS: 'update_center_details_success',
	UPDATE_CENTER_DETAILS_FAILURE: 'update_center_details_failure',
	UPDATE_CENTER_DETAILS_RESPONSE_RESET: 'update_center_details_response_reset',
	UPDATE_CENTER_DETAILS_RESPONSE_CHANGED: 'update_center_details_response_changed',

	GET_CENTER_LIST_START: 'get_center_list_start',
	GET_CENTER_LIST_SUCCESS: 'get_center_list_success',
	GET_CENTER_LIST_FAILURE: 'get_center_list_failure',
	GET_CENTER_LIST_RESPONSE_RESET: 'get_center_list_response_reset',
	GET_CENTER_LIST_RESPONSE_CHANGED: 'get_center_list_response_changed',

	FETCH_CENTER_DETAILS_START: 'fetch_center_details_start',
	FETCH_CENTER_DETAILS_SUCCESS: 'fetch_center_details_success',
	FETCH_CENTER_DETAILS_FAILURE: 'fetch_center_details_failure',
	FETCH_CENTER_DETAILS_RESPONSE_RESET: 'fetch_center_details_response_reset',
	FETCH_CENTER_DETAILS_RESPONSE_CHANGED: 'fetch_center_details_response_changed',

	DELETE_CENTER_DETAILS_START: 'delete_center_details_start',
	DELETE_CENTER_DETAILS_SUCCESS: 'delete_center_details_success',
	DELETE_CENTER_DETAILS_FAILURE: 'delete_center_details_failure',
	DELETE_CENTER_DETAILS_RESPONSE_RESET: 'delete_center_details_response_reset',
	DELETE_CENTER_DETAILS_RESPONSE_CHANGED: 'delete_center_details_response_changed',

	FETCH_CENTER_UNIQUE_ID_START: 'fetch_center_unique_id_start',
	FETCH_CENTER_UNIQUE_ID_SUCCESS: 'fetch_center_unique_id_success',
	FETCH_CENTER_UNIQUE_ID_FAILURE: 'fetch_center_unique_id_failure',
	FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET: 'fetch_center_unique_id_response_reset',
	FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED: 'fetch_center_unique_id_response_changed',

	FETCH_BRANCH_CENTER_LIST_START: 'FETCH_BRANCH_CENTER_LIST_START',
	FETCH_BRANCH_CENTER_LIST_SUCCESS: 'FETCH_BRANCH_CENTER_LIST_SUCCESS',
	FETCH_BRANCH_CENTER_LIST_FAILURE: 'FETCH_BRANCH_CENTER_LIST_FAILURE',
	FETCH_BRANCH_CENTER_LIST_RESPONSE_RESET: 'FETCH_BRANCH_CENTER_LIST_RESPONSE_RESET',
	FETCH_BRANCH_CENTER_LIST_RESPONSE_CHANGED: 'FETCH_BRANCH_CENTER_LIST_RESPONSE_CHANGED',

	FETCH_CENTER_BETA_LIST_START: 'FETCH_CENTER_BETA_LIST_START',
	FETCH_CENTER_BETA_LIST_SUCCESS: 'FETCH_CENTER_BETA_LIST_SUCCESS',
	FETCH_CENTER_BETA_LIST_FAILURE: 'FETCH_CENTER_BETA_LIST_FAILURE',
	FETCH_CENTER_BETA_LIST_RESPONSE_RESET: 'FETCH_CENTER_BETA_LIST_RESPONSE_RESET',
	FETCH_CENTER_BETA_LIST_RESPONSE_CHANGED: 'FETCH_CENTER_BETA_LIST_RESPONSE_CHANGED',

	FETCH_ALL_CENTERS_LIST_START: 'FETCH_ALL_CENTERS_LIST_START',
	FETCH_ALL_CENTERS_LIST_SUCCESS: 'FETCH_ALL_CENTERS_LIST_SUCCESS',
	FETCH_ALL_CENTERS_LIST_FAILURE: 'FETCH_ALL_CENTERS_LIST_FAILURE',
	FETCH_ALL_CENTERS_LIST_RESPONSE_RESET: 'FETCH_ALL_CENTERS_LIST_RESPONSE_RESET',
	FETCH_ALL_CENTERS_LIST_RESPONSE_CHANGED: 'FETCH_ALL_CENTERS_LIST_RESPONSE_CHANGED',

	// Save Center Beta to Centers
	SAVE_CENTER_BETA_TO_CENTERS_START: 'save_center_beta_to_center_start',
	SAVE_CENTER_BETA_TO_CENTERS_SUCCESS: 'save_center_beta_to_center_success',
	SAVE_CENTER_BETA_TO_CENTERS_FAILURE: 'save_center_beta_to_center_failure',
	SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_RESET: 'save_center_beta_to_center_response_reset',
	SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_CHANGED: 'save_center_beta_to_centers_response_changed',

	IS_EDIT_CENTER_DETAILS: 'is_edit_center_details',
	UPDATE_CENTERS_FOR_BETA_START: 'update_centers_for_beta_start',
};
export default CenterActionTypes;
