/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CenterActionTypes from './center.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addCenterDetailsResponse: null,
	updateCenterDetailsResponse: null,
	getCenterListResponse: null,
	getCenterListArr: [],
	fetchCenterDetailsResponse: null,
	fetchCenterDetailsArr: [],
	deleteCenterDetailsResponse: null,
	fetchCenterUniqueIDResponse: null,
	fetchCenterUniqueIDArr: [],
	fetchBranchCenterListResponse: null,
	fetchBranchCenterListArr: [],
	fetchCenterBetaListResponse: null,
	fetchCenterBetaListArr: [],
	fetchAllCentersListResponse: null,
	fetchAllCentersListArr: [],
	isCenterEdit: false,
	saveCenterBetaToCentersResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CenterActionTypes.ADD_CENTER_DETAILS_SUCCESS:
			return { ...state, addCenterDetailsResponse: action.payload };

		case CenterActionTypes.ADD_CENTER_DETAILS_FAILURE:
			return { ...state, addCenterDetailsResponse: action.payload };

		case CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addCenterDetailsResponse: null };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_SUCCESS:
			return { ...state, updateCenterDetailsResponse: action.payload };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_FAILURE:
			return { ...state, updateCenterDetailsResponse: action.payload };

		case CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateCenterDetailsResponse: null };

		case CenterActionTypes.GET_CENTER_LIST_SUCCESS:
			return {
				...state,
				getCenterListResponse: action.payload,
				getCenterListArr: docodeResponse(action.payload, 'FetchCenterListKey'),
			};

		case CenterActionTypes.GET_CENTER_LIST_FAILURE:
			return { ...state, getCenterListResponse: action.payload };

		case CenterActionTypes.GET_CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, getCenterListResponse: null };

		case CenterActionTypes.FETCH_CENTER_DETAILS_SUCCESS:
			return {
				...state,
				fetchCenterDetailsResponse: action.payload,
				fetchCenterDetailsArr: docodeResponse(action.payload, 'FetchCenterInfoKey'),
			};

		case CenterActionTypes.FETCH_CENTER_DETAILS_FAILURE:
			return { ...state, fetchCenterDetailsResponse: action.payload };

		case CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchCenterDetailsResponse: null, fetchCenterDetailsArr: [] };

		case CenterActionTypes.DELETE_CENTER_DETAILS_SUCCESS:
			return { ...state, deleteCenterDetailsResponse: action.payload };

		case CenterActionTypes.DELETE_CENTER_DETAILS_FAILURE:
			return { ...state, deleteCenterDetailsResponse: action.payload };

		case CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteCenterDetailsResponse: null };

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_SUCCESS:
			return {
				...state,
				fetchCenterUniqueIDResponse: action.payload,
				fetchCenterUniqueIDArr: docodeResponse(action.payload, 'FetchCenterUniqueIdKey'),
			};

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_FAILURE:
			return { ...state, fetchCenterUniqueIDResponse: action.payload };

		case CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED:
			return { ...state, fetchCenterUniqueIDResponse: null };

		case CenterActionTypes.FETCH_BRANCH_CENTER_LIST_SUCCESS:
			return {
				...state,
				fetchBranchCenterListResponse: action.payload,
				fetchBranchCenterListArr: docodeResponse(
					action.payload,
					'FetchBranchBasedCenterListKey',
				),
			};

		case CenterActionTypes.FETCH_BRANCH_CENTER_LIST_FAILURE:
			return { ...state, fetchBranchCenterListResponse: action.payload };

		case CenterActionTypes.FETCH_BRANCH_CENTER_LIST_RESPONSE_CHANGED:
			return { ...state, fetchBranchCenterListResponse: null };

		case CenterActionTypes.FETCH_CENTER_BETA_LIST_SUCCESS:
			return {
				...state,
				fetchCenterBetaListResponse: action.payload,
				fetchCenterBetaListArr: docodeResponse(action.payload, 'FetchCenterBetaListKey'),
			};

		case CenterActionTypes.FETCH_CENTER_BETA_LIST_FAILURE:
			return { ...state, fetchCenterBetaListResponse: action.payload };

		case CenterActionTypes.FETCH_CENTER_BETA_LIST_RESPONSE_CHANGED:
			return { ...state, fetchCenterBetaListResponse: null };

		case CenterActionTypes.FETCH_ALL_CENTERS_LIST_SUCCESS:
			return {
				...state,
				fetchAllCentersListResponse: action.payload,
				fetchAllCentersListArr: docodeResponse(action.payload, 'FetchAllCentersListKey'),
			};

		case CenterActionTypes.FETCH_ALL_CENTERS_LIST_FAILURE:
			return { ...state, fetchAllCentersListResponse: action.payload };

		case CenterActionTypes.FETCH_ALL_CENTERS_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAllCentersListResponse: null };

		case CenterActionTypes.UPDATE_CENTERS_FOR_BETA_START:
			return { ...state, fetchCenterBetaListArr: action.payload };

		case CenterActionTypes.IS_EDIT_CENTER_DETAILS:
			return { ...state, isCenterEdit: action.payload };

		case CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_SUCCESS:
			return { ...state, saveCenterBetaToCentersResponse: action.payload };

		case CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_FAILURE:
			return { ...state, saveCenterBetaToCentersResponse: action.payload };

		case CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_CHANGED:
			return { ...state, saveCenterBetaToCentersResponse: null };

		default:
			return state;
	}
};
