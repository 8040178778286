/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ChitIndexActionTypes from './chitIndex.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addChitIndexResponse: null,
	addChitIndexArr: [],
	addChitIndexMembersDetailsResponse: null,
	updateChitIndexDetailsResponse: null,
	fetchChitIndexListResponse: null,
	fetchChitIndexListArr: [],
	fetchChitIndexDetailsResponse: null,
	fetchChitIndexDetailsArr: [],
	deleteChitIndexDetailsResponse: null,
	tabChangeValue: 'Auction Details',
	isShowOffCanvas: false,
	isChitIndexEdit: false,
	getChitIndexID: '',
	getChitProductSchedule: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				addChitIndexResponse: action.payload,
				addChitIndexArr: docodeResponse(action.payload, 'AddChitIndexDetailKey'),
			};

		case ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_FAILURE:
			return { ...state, addChitIndexResponse: action.payload };

		case ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, addChitIndexResponse: null };

		case ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_SUCCESS:
			return { ...state, addChitIndexMembersDetailsResponse: action.payload };

		case ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_FAILURE:
			return { ...state, addChitIndexMembersDetailsResponse: action.payload };

		case ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_CHANGED:
			return { ...state, addChitIndexMembersDetailsResponse: null };

		case ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_SUCCESS:
			return { ...state, updateChitIndexDetailsResponse: action.payload };

		case ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_FAILURE:
			return { ...state, updateChitIndexDetailsResponse: action.payload };

		case ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateChitIndexDetailsResponse: null };

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_SUCCESS:
			return {
				...state,
				fetchChitIndexListResponse: action.payload,
				fetchChitIndexListArr: docodeResponse(action.payload, 'FetchChitIndexListKey'),
			};

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_FAILURE:
			return { ...state, fetchChitIndexListResponse: action.payload };

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_RESPONSE_CHANGED:
			return { ...state, fetchChitIndexListResponse: null };

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_SUCCESS:
			return {
				...state,
				fetchChitIndexDetailsResponse: action.payload,
				fetchChitIndexDetailsArr: docodeResponse(action.payload, 'FetchChitIndexInfoKey'),
			};

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_FAILURE:
			return { ...state, fetchChitIndexDetailsResponse: action.payload };

		case ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchChitIndexDetailsResponse: null };

		case ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_SUCCESS:
			return { ...state, deleteChitIndexDetailsResponse: action.payload };

		case ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_FAILURE:
			return { ...state, deleteChitIndexDetailsResponse: action.payload };

		case ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteChitIndexDetailsResponse: null };

		case ChitIndexActionTypes.CHIT_INDEX_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, tabChangeValue: action.payload };

		case ChitIndexActionTypes.CHIT_INDEX_IS_OPEN_OFFCANVAS:
			return { ...state, isShowOffCanvas: action.payload };

		case ChitIndexActionTypes.IS_EDIT_CHIT_INDEX_DETAILS:
			return { ...state, isChitIndexEdit: action.payload };

		case ChitIndexActionTypes.CHIT_INDEX_ID:
			return { ...state, getChitIndexID: action.payload };

		case ChitIndexActionTypes.CHIT_PRODUCT_SCHEDULE:
			return { ...state, getChitProductSchedule: action.payload };

		default:
			return state;
	}
};
