/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import DemandFollowUpReportActionTypes from './demandFollowUpReport.types';

/** ***************** FETCH DEMAND FOLLOW UP REPORT START *********************** */
export const fetchDemandFollowUpReportStart = (getData) => ({
	type: DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_START,
	payload: getData,
});

export const fetchDemandFollowUpReportSuccess = (successData) => ({
	type: DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_SUCCESS,
	payload: successData,
});

export const fetchDemandFollowUpReportFailure = (failureData) => ({
	type: DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_FAILURE,
	payload: failureData,
});

export const fetchDemandFollowUpReportResponseResetStart = () => ({
	type: DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_RESPONSE_RESET,
});

export const fetchDemandFollowUpReportResponseChanged = () => ({
	type: DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH DEMAND FOLLOW UP REPORT END *********************** */
