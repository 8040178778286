import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.sagas';

import { dashboardSaga } from './dashboard/dashboard.saga';
// General Saga
import { generalSaga } from './general/general.saga';

// Masters
import { branchMasterSaga } from './masters/branch/branch.saga';
import { centerMasterSaga } from './masters/center/center.saga';
import { roleMasterSaga } from './masters/role/role.saga';
import { staffMasterSaga } from './masters/staff/staff.saga';
import { groupMasterSaga } from './masters/group/group.saga';
import { memberMasterSaga } from './masters/member/member.saga';
import { productTypeMasterSaga } from './masters/product-type/product-type.saga';
import { productMasterSaga } from './masters/product/product.saga';

// Operations - Collection
import { collectionSaga } from './operations/collection/collection.saga';
import { applicantCollectionSaga } from './operations/collection/applicantCollection/applicantCollection.saga';
import { routeCollectionSaga } from './operations/collection/routeCollection/routeCollection.saga';
import { groupCollectionSaga } from './operations/collection/groupCollection/groupCollection.saga';
import { memberCollectionSaga } from './operations/collection/memberCollection/memberCollection.saga';
import { advanceCollectionSaga } from './operations/collection/advanceCollection/advanceCollection.saga';
import { demandCollectionSaga } from './operations/collection/demandCollection/demandCollection.saga';
import { chitIndexSaga } from './operations/chitIndex/chitIndex.saga';

import { chitMembersReportSaga } from './reports/chitMembersReport/chitMembersReport.saga';
import { chitDemandReportSaga } from './reports/chitDemandReport/chitDemandReport.saga';
import { demandFollowUpReportSaga } from './reports/demandFollowUpReport/demandFollowUpReport.saga';
import { repaymentReportSaga } from './reports/repaymentReport/repaymentReport.saga';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(generalSaga),
		call(branchMasterSaga),
		call(centerMasterSaga),
		call(roleMasterSaga),
		call(staffMasterSaga),
		call(groupMasterSaga),
		call(memberMasterSaga),
		call(productTypeMasterSaga),
		call(productMasterSaga),
		call(collectionSaga),
		call(applicantCollectionSaga),
		call(demandCollectionSaga),
		call(memberCollectionSaga),
		call(advanceCollectionSaga),
		call(chitIndexSaga),
		call(routeCollectionSaga),
		call(groupCollectionSaga),
		call(chitMembersReportSaga),
		call(chitDemandReportSaga),
		call(demandFollowUpReportSaga),
		call(repaymentReportSaga),
		call(dashboardSaga),
	]);
}
