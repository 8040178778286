/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import routeCollectionActionTypes from './routeCollection.types';

const INITIAL_STATE = {
	addRouteCollectionResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case routeCollectionActionTypes.ADD_ROUTE_COLLECTION_SUCCESS:
			return { ...state, addRouteCollectionResponse: action.payload };

		case routeCollectionActionTypes.ADD_ROUTE_COLLECTION_FAILURE:
			return { ...state, addRouteCollectionResponse: action.payload };

		case routeCollectionActionTypes.ADD_ROUTE_COLLECTION_RESPONSE_CHANGED:
			return { ...state, addRouteCollectionResponse: null };

		default:
			return state;
	}
};
