const RoleActionTypes = {
	ADD_ROLE_DETAILS_START: 'add_role_details_start',
	ADD_ROLE_DETAILS_SUCCESS: 'add_role_details_success',
	ADD_ROLE_DETAILS_FAILURE: 'add_role_details_failure',
	ADD_ROLE_DETAILS_RESPONSE_RESET: 'add_role_details_response_reset',
	ADD_ROLE_DETAILS_RESPONSE_CHANGED: 'add_role_details_response_changed',

	UPDATE_ROLE_DETAILS_START: 'update_role_details_start',
	UPDATE_ROLE_DETAILS_SUCCESS: 'update_role_details_success',
	UPDATE_ROLE_DETAILS_FAILURE: 'update_role_details_failure',
	UPDATE_ROLE_DETAILS_RESPONSE_RESET: 'update_role_details_response_reset',
	UPDATE_ROLE_DETAILS_RESPONSE_CHANGED: 'update_role_details_response_changed',

	GET_ROLE_LIST_START: 'get_role_list_start',
	GET_ROLE_LIST_SUCCESS: 'get_role_list_success',
	GET_ROLE_LIST_FAILURE: 'get_role_list_failure',
	GET_ROLE_LIST_RESPONSE_RESET: 'get_role_list_response_reset',
	GET_ROLE_LIST_RESPONSE_CHANGED: 'get_role_list_response_changed',

	FETCH_SEARCH_ROLE_LIST_START: 'FETCH_SEARCH_ROLE_LIST_START',
	FETCH_SEARCH_ROLE_LIST_SUCCESS: 'FETCH_SEARCH_ROLE_LIST_SUCCESS',
	FETCH_SEARCH_ROLE_LIST_FAILURE: 'FETCH_SEARCH_ROLE_LIST_FAILURE',
	FETCH_SEARCH_ROLE_LIST_RESPONSE_RESET: 'FETCH_SEARCH_ROLE_LIST_RESPONSE_RESET',
	FETCH_SEARCH_ROLE_LIST_RESPONSE_CHANGED: 'FETCH_SEARCH_ROLE_LIST_RESPONSE_CHANGED',

	FETCH_ROLE_DETAILS_START: 'fetch_role_details_start',
	FETCH_ROLE_DETAILS_SUCCESS: 'fetch_role_details_success',
	FETCH_ROLE_DETAILS_FAILURE: 'fetch_role_details_failure',
	FETCH_ROLE_DETAILS_RESPONSE_RESET: 'fetch_role_details_response_reset',
	FETCH_ROLE_DETAILS_RESPONSE_CHANGED: 'fetch_role_details_response_changed',

	DELETE_ROLE_DETAILS_START: 'delete_role_details_start',
	DELETE_ROLE_DETAILS_SUCCESS: 'delete_role_details_success',
	DELETE_ROLE_DETAILS_FAILURE: 'delete_role_details_failure',
	DELETE_ROLE_DETAILS_RESPONSE_RESET: 'delete_role_details_response_reset',
	DELETE_ROLE_DETAILS_RESPONSE_CHANGED: 'delete_role_details_response_changed',

	FETCH_ALL_ROLE_START: 'fetch_all_role_start',
	FETCH_ALL_ROLE_SUCCESS: 'fetch_all_role_success',
	FETCH_ALL_ROLE_FAILURE: 'fetch_all_role_failure',
	FETCH_ALL_ROLE_RESPONSE_RESET: 'fetch_all_role_response_reset',
	FETCH_ALL_ROLE_RESPONSE_CHANGED: 'fetch_all_role_response_changed',
};
export default RoleActionTypes;
