/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import DemandFollowUpReportActionTypes from './demandFollowUpReport.types';
import { fetchDemandFollowUpReport } from './demandFollowUpReport.service';

import {
	fetchDemandFollowUpReportSuccess,
	fetchDemandFollowUpReportFailure,
	fetchDemandFollowUpReportResponseChanged,
} from './demandFollowUpReport.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH CHIT DEMAND REPORT  ******************** */
export function* fetchDemandFollowUpReportDetails({ payload }) {
	try {
		const key = 'FetchDemandFollowUpReportKey';
		const jsonData = {
			Year: payload.year,
			Month: payload.month,
			Branch: payload.branchID,
			companyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchDemandFollowUpReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchDemandFollowUpReportFailure(responseData.data));
		} else {
			yield put(fetchDemandFollowUpReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchDemandFollowUpReportFailure(error));
	}
}

export function* resetFetchDemandFollowUpReportResponse() {
	yield put(fetchDemandFollowUpReportResponseChanged());
}

export function* onFetchDemandFollowUpReportDetails() {
	yield takeLatest(
		DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_START,
		fetchDemandFollowUpReportDetails,
	);
}

export function* onFetchDemandFollowUpReportResponseReset() {
	yield takeLatest(
		DemandFollowUpReportActionTypes.FETCH_DEMAND_FOLLOWUP_REPORT_RESPONSE_RESET,
		resetFetchDemandFollowUpReportResponse,
	);
}
/** *********************  END FETCH CHIT DEMAND REPORT ******************** */

export function* demandFollowUpReportSaga() {
	yield all([
		call(onFetchDemandFollowUpReportDetails),
		call(onFetchDemandFollowUpReportResponseReset),
	]);
}
