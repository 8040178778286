/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import JWT from 'expo-jwt';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { signInStart, signInReset } from '../../../redux/login/login.actions';
import { selectCurrentDecodedUser, selectCurrentUser } from '../../../redux/login/login.selector';
import generalTypesAction from '../../../redux/genera.types';
import { dashboardMenu, demoPages } from '../../../menu';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
// import { getAllStaffList } from '../../../redux/staffDashboard/staffDashboard.actions';
// import { selectListStaff } from '../../../redux/staffDashboard/staffDashboard.selector';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);

	// console.log("getCurrentUserLoginDetails: ", getCurrentUserLoginDetails);

	const navigate = useNavigate();
	// const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);
	useEffect(() => {
		if (getCurrentUserLoginDetails != null) {
			if (getCurrentUserLoginDetails.statusCode === '01') {
				navigate(`${dashboardMenu.dashboard.path}`);
			} else if (getCurrentUserLoginDetails.statusCode === '404') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Cancel' size='lg' className='me-1' />
						<span>Mobile Number Not Exist</span>
					</span>,
					'Wrong MobileNumber',
				);
				dispatch(signInReset());
			}
		}
	});
	const formik = useFormik({
		initialValues: {
			phoneNumber: '',
			password: '',
		},
		onSubmit: (values) => {
			if (values.phoneNumber !== '' && values.password !== '') {
				dispatch(signInStart({ values }));
			} else {
				showNotification('Login Opps!', 'Enter the Mobile Number & Password', 'danger');
			}
		},
	});

	const newDate = new Date();
	const date = newDate.getDate();
	const month = newDate.getMonth() + 1;
	const year = newDate.getFullYear();

	return (
		<PageWrapper title={isNewUser ? 'Sign Up' : 'Login'} className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo />
									</Link>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup
											id='login-username'
											isFloating
											label='Mobile Number'>
											<Input
												name='phoneNumber'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur('ph')}
												value={formik.values.phoneNumber}
												isTouched={formik.touched.Department}
												autoComplete='username'
												maxLength='10'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='login-password' isFloating label='Password'>
											<Input
												type='password'
												value={formik.values.password}
												onChange={formik.handleChange}
												isTouched={formik.touched.Department}
												name='password'
												autoComplete='password'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button color='dark' type='submit' className='w-100 py-3'>
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3 link-light')}>
								Privacy policy
							</a>
							<a href='/' className={classNames('link-light text-decoration-none')}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
