/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import applicantCollectionActionTypes from './applicantCollection.types';
import {
	addApplicantCollection,
	fetchApplicantCollectionInfo,
	fetchApplicantEnrollmentCollectionInfo,
} from './applicantCollection.service';

import {
	addApplicantCollectionDetailsSuccess,
	addApplicantCollectionDetailsFailure,
	addApplicantCollectionDetailsResponseChanged,
	getApplicantCollectionInfoSuccess,
	getApplicantCollectionInfoFailure,
	getApplicantCollectionInfoResponseChanged,
	getApplicantEnrollCollectionInfoSuccess,
	getApplicantEnrollCollectionInfoFailure,
	getApplicantEnrollCollectionInfoResponseChanged,
} from './applicantCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD APPLICANT COLLECTION DETAILS  ******************** */
export function* addApplicantCollectionDetails({ payload }) {
	try {
		const key = 'AddApplicantCollectionDetailsKey';
		const jsonData = {
			MemberID: payload.memberID,
			EnrollmentID: payload.enrollmentID,
			CollectionDateTime: payload.collectionDate,
			CollectionAmount: payload.collectionAmount,
			CompanyID: payload.companyID,
			CollectedFrom: 'web',
			CollectedMode: payload.collectedMode,
			FiveHundred: payload.fiveHundred,
			TwoHundred: payload.twoHundred,
			Hundred: payload.hundred,
			Fifty: payload.fifty,
			Twenty: payload.twenty,
			Ten: payload.ten,
			Five: payload.five,
			Two: payload.two,
			One: payload.one,
			TotalDenominationAmount: payload.totalDenominationAmount,
			ReceiptNumber: payload.receiptNumber,
			ChequeNumber: payload.chequeNumber,
			CreatedBy: payload.UserID,
			UserName: payload.UserName,
			Level: payload.UserLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addApplicantCollection, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addApplicantCollectionDetailsFailure(responseData.data));
		} else {
			yield put(addApplicantCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addApplicantCollectionDetailsFailure(error));
	}
}

export function* resetAddApplicantCollectionDetailsResponse() {
	yield put(addApplicantCollectionDetailsResponseChanged());
}

export function* onAddApplicantCollectionDetails() {
	yield takeLatest(
		applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_START,
		addApplicantCollectionDetails,
	);
}

export function* onAddApplicantCollectionDetailsResponseReset() {
	yield takeLatest(
		applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_RESPONSE_RESET,
		resetAddApplicantCollectionDetailsResponse,
	);
}
/** *********************  END ADD APPLICANT COLLECTION INFO  ******************** */

/** *********************  START APPLICANT ENROLLMENT LIST COLLECTION INFO DETAILS  ******************** */
export function* getApplicantCollectionInfo({ payload }) {
	try {
		const key = 'FetchApplicantEnrollmentListCollectionInfoKey';
		const jsonData = {
			InputSearchValue: payload.InputSearchValue,
			searchMemberType: payload.searchMemberType,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchApplicantCollectionInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getApplicantCollectionInfoFailure(responseData.data));
		} else {
			yield put(getApplicantCollectionInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getApplicantCollectionInfoFailure(error));
	}
}

export function* resetGetApplicantCollectionInfoResponse() {
	yield put(getApplicantCollectionInfoResponseChanged());
}

export function* onGetApplicantCollectionInfoDetails() {
	yield takeLatest(
		applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_START,
		getApplicantCollectionInfo,
	);
}

export function* onGetApplicantCollectionInfoResponseReset() {
	yield takeLatest(
		applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_RESPONSE_RESET,
		resetGetApplicantCollectionInfoResponse,
	);
}
/** *********************  END GET APPLICANT ENROLLMENT LIST COLLECTION INFO  ******************** */

/** *********************  START APPLICANT ENROLLMENT LIST COLLECTION INFO DETAILS  ******************** */
export function* getApplicantEnrollCollectionInfo({ payload }) {
	try {
		const key = 'FetchApplicantEnrollmentCollectionInfoKey';
		const jsonData = {
			MemberID: payload.MemberID,
			EnrollmentID: payload.EnrollmentID,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchApplicantEnrollmentCollectionInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getApplicantEnrollCollectionInfoFailure(responseData.data));
		} else {
			yield put(getApplicantEnrollCollectionInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getApplicantEnrollCollectionInfoFailure(error));
	}
}

export function* resetGetApplicantEnrollCollectionInfoResponse() {
	yield put(getApplicantEnrollCollectionInfoResponseChanged());
}

export function* onGetApplicantEnrollCollectionInfoDetails() {
	yield takeLatest(
		applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_START,
		getApplicantEnrollCollectionInfo,
	);
}

export function* onGetApplicantEnrollCollectionInfoResponseReset() {
	yield takeLatest(
		applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_RESET,
		resetGetApplicantEnrollCollectionInfoResponse,
	);
}
/** *********************  END GET APPLICANT ENROLLMENT COLLECTION INFO  ******************** */

export function* applicantCollectionSaga() {
	yield all([
		call(onAddApplicantCollectionDetails),
		call(onAddApplicantCollectionDetailsResponseReset),
		call(onGetApplicantCollectionInfoDetails),
		call(onGetApplicantCollectionInfoResponseReset),
		call(onGetApplicantEnrollCollectionInfoDetails),
		call(onGetApplicantEnrollCollectionInfoResponseReset),
	]);
}
