/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import StaffActionTypes from './staff.types';
import {
	staffDetailsAdd,
	staffDetailsUpdate,
	staffAddressDetailsUpdate,
	staffProofDetailsUpdate,
	staffEmergencyDetailsUpdate,
	staffOfficialDetailsUpdate,
	staffTargetDetailsAdd,
	staffTargetDetailsUpdate,
	staffListGet,
	fetchSearchStaffList,
	staffDetailsFetch,
	staffDetailsDelete,
	allStaffFetch,
	agentStaffFetch,
	staffTargetListFetch,
	staffPublishStatusUpdate,
} from './staff.service';

import {
	addStaffDetailsSuccess,
	addStaffDetailsFailure,
	addStaffDetailsResponseChanged,
	updateStaffDetailsSuccess,
	updateStaffDetailsFailure,
	updateStaffDetailsResponseChanged,
	updateStaffAddressDetailsSuccess,
	updateStaffAddressDetailsFailure,
	updateStaffAddressDetailsResponseChanged,
	updateStaffProofDetailsSuccess,
	updateStaffProofDetailsFailure,
	updateStaffProofDetailsResponseChanged,
	updateStaffEmergencyDetailsSuccess,
	updateStaffEmergencyDetailsFailure,
	updateStaffEmergencyDetailsResponseChanged,
	updateStaffOfficialDetailsSuccess,
	updateStaffOfficialDetailsFailure,
	updateStaffOfficialDetailsResponseChanged,
	addStaffTargetDetailsSuccess,
	addStaffTargetDetailsFailure,
	addStaffTargetDetailsResponseChanged,
	updateStaffTargetDetailsSuccess,
	updateStaffTargetDetailsFailure,
	updateStaffTargetDetailsResponseChanged,
	getStaffListSuccess,
	getStaffListFailure,
	getStaffListResponseChanged,
	searchStaffListSuccess,
	searchStaffListFailure,
	searchStaffListResponseChanged,
	fetchStaffDetailsSuccess,
	fetchStaffDetailsFailure,
	fetchStaffDetailsResponseChanged,
	deleteStaffDetailsSuccess,
	deleteStaffDetailsFailure,
	deleteStaffDetailsResponseChanged,
	updateIsPublishStatusSuccess,
	updateIsPublishStatusFailure,
	updateIsPublishStatusResponseChanged,
	fetchAllStaffSuccess,
	fetchAllStaffFailure,
	fetchAllStaffResponseChanged,
	fetchAgentStaffSuccess,
	fetchAgentStaffFailure,
	fetchAgentStaffResponseChanged,
	fetchStaffTargetListSuccess,
	fetchStaffTargetListFailure,
	fetchStaffTargetListResponseChanged,
} from './staff.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD STAFF DETAILS  ******************** */
export function* createStaff({ payload }) {
	try {
		const key = 'AddStaffDetailsKey';
		const jsonData = {
			UserPhoto: payload.staffPhoto,
			IsAgent: payload.isAgent,
			NextUserUniqueID: payload.staffUniqueID,
			UserName: payload.staffName,
			UserPhoneNumber: payload.staffMobileNumber,
			UserPassword: payload.staffPassword,
			UserResPhoneNumber: payload.staffResPhoneNumber,
			UserEmailID: payload.staffEmailID,
			DOB: payload.staffDateOfBirth,
			FatherName: payload.staffFatherName,
			Gender: payload.staffGender,
			Religion: payload.staffReligion,
			Caste: payload.staffCaste,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addStaffDetailsFailure(responseData.data));
		} else {
			yield put(addStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addStaffDetailsFailure(error));
	}
}

export function* resetAddStaffResponse() {
	yield put(addStaffDetailsResponseChanged());
}

export function* onCreateStaffDetails() {
	yield takeLatest(StaffActionTypes.ADD_STAFF_DETAILS_START, createStaff);
}

export function* onCreateStaffResponseReset() {
	yield takeLatest(StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_RESET, resetAddStaffResponse);
}
/** *********************  END ADD STAFF DETAILS ******************** */

/** *********************  START UPDATE STAFF DETAILS  ******************** */
export function* staffUpdate({ payload }) {
	try {
		const key = 'UpdateStaffDetailsKey';
		const jsonData = {
			StaffID: payload.staffID,
			UserPhoto: payload.staffPhoto,
			IsAgent: payload.isAgent,
			NextUserUniqueID: payload.staffUniqueID,
			UserName: payload.staffName,
			UserPhoneNumber: payload.staffMobileNumber,
			UserPassword: payload.staffPassword,
			UserResPhoneNumber: payload.staffResPhoneNumber,
			UserEmailID: payload.staffEmailID,
			DOB: payload.staffDateOfBirth,
			FatherName: payload.staffFatherName,
			Gender: payload.staffGender,
			Religion: payload.staffReligion,
			Caste: payload.staffCaste,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffDetailsFailure(error));
	}
}

export function* resetUpdateStaffDetailsResponse() {
	yield put(updateStaffDetailsResponseChanged());
}

export function* onUpdateStaffDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_DETAILS_START, staffUpdate);
}

export function* onUpdateStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_RESET,
		resetUpdateStaffDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF DETAILS ******************** */

/** *********************  START UPDATE STAFF ADDRESS DETAILS  ******************** */
export function* staffUpdateAddress({ payload }) {
	try {
		const key = 'UpdateStaffAddressDetailsKey';
		const jsonData = {
			StaffID: payload.staffID,
			staffContactAddressLine1: payload.staffContactAddressLine1,
			staffContactAddressLine2: payload.staffContactAddressLine2,
			staffContactAddressCityName: payload.staffContactAddressCityName,
			staffContactAddressStateName: payload.staffContactAddressStateName,
			staffContactAddressDistrictName: payload.staffContactAddressDistrictName,
			staffContactAddressPinCode: payload.staffContactAddressPinCode,
			staffPermanentAddressLine1: payload.staffPermanentAddressLine1,
			staffPermanentAddressLine2: payload.staffPermanentAddressLine2,
			staffPermanentAddressStateName: payload.staffPermanentAddressStateName,
			staffPermanentAddressCityName: payload.staffPermanentAddressCityName,
			staffPermanentAddressDistrictName: payload.staffPermanentAddressDistrictName,
			staffPermanentAddressPinCode: payload.staffPermanentAddressPinCode,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffAddressDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffAddressDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffAddressDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffAddressDetailsFailure(error));
	}
}

export function* resetUpdateStaffAddressDetailsResponse() {
	yield put(updateStaffAddressDetailsResponseChanged());
}

export function* onUpdateStaffAddressDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_START, staffUpdateAddress);
}

export function* onUpdateStaffAddressResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_RESET,
		resetUpdateStaffAddressDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF ADDRESS DETAILS ******************** */

/** *********************  START UPDATE STAFF PROOF DETAILS  ******************** */
export function* staffUpdateProof({ payload }) {
	try {
		const key = 'UpdateStaffProofDetailsKey';
		const jsonData = {
			StaffID: payload.staffID,
			staffEsiNumber: payload.staffEsiNumber,
			staffEpfNumber: payload.staffEpfNumber,
			staffAadharNumber: payload.staffAadharNumber,
			staffPanNumber: payload.staffPanNumber,
			staffVoterID: payload.staffVoterID,
			staffDrivingLicense: payload.staffDrivingLicense,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffProofDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffProofDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffProofDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffProofDetailsFailure(error));
	}
}

export function* resetUpdateStaffProofDetailsResponse() {
	yield put(updateStaffProofDetailsResponseChanged());
}

export function* onUpdateStaffProofDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_START, staffUpdateProof);
}

export function* onUpdateStaffProofResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_RESPONSE_RESET,
		resetUpdateStaffProofDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF PROOF DETAILS ******************** */

/** *********************  START UPDATE STAFF EMERGENCY DETAILS  ******************** */
export function* staffUpdateEmergency({ payload }) {
	try {
		const key = 'UpdateStaffEmergencyDetailsKey';
		const jsonData = {
			StaffID: payload.staffID,
			emergencyContactName1: payload.emergencyContactName1,
			emergencyContanctPhoneNo1: payload.emergencyContanctPhoneNo1,
			emergencyContanctRelationship1: payload.emergencyContanctRelationship1,
			emergencyContactName2: payload.emergencyContactName2,
			emergencyContanctPhoneNo2: payload.emergencyContanctPhoneNo2,
			emergencyContanctRelationship2: payload.emergencyContanctRelationship2,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffEmergencyDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffEmergencyDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffEmergencyDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffEmergencyDetailsFailure(error));
	}
}

export function* resetUpdateStaffEmergencyDetailsResponse() {
	yield put(updateStaffEmergencyDetailsResponseChanged());
}

export function* onUpdateStaffEmergencyDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_START, staffUpdateEmergency);
}

export function* onUpdateStaffEmergencyResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_RESET,
		resetUpdateStaffEmergencyDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF PROOF DETAILS ******************** */

/** *********************  START UPDATE STAFF OFFICIAL DETAILS  ******************** */
export function* staffUpdateOfficial({ payload }) {
	try {
		const key = 'UpdateStaffOfficialDetailsKey';
		const jsonData = {
			StaffID: payload.staffID,
			staffDateOfJoining: payload.staffDateOfJoining,
			staffDateOfRelieving: payload.staffDateOfRelieving,
			staffExpYears: payload.staffExpYears,
			staffOpeningDate: payload.staffOpeningDate,
			staffOpeningBalanceAmount: payload.staffOpeningBalanceAmount,
			staffDesignation: payload.staffDesignation,
			staffRoleID: payload.staffRoleID,
			staffBranchID: payload.staffBranchID,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffOfficialDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffOfficialDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffOfficialDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffOfficialDetailsFailure(error));
	}
}

export function* resetUpdateStaffOfficialDetailsResponse() {
	yield put(updateStaffOfficialDetailsResponseChanged());
}

export function* onUpdateStaffOfficialDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_START, staffUpdateOfficial);
}

export function* onUpdateStaffOfficialResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_RESET,
		resetUpdateStaffOfficialDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF OFFICIAL DETAILS ******************** */

/** *********************  START ADD STAFF TARGET DETAILS  ******************** */
export function* createStaffTarget({ payload }) {
	try {
		const key = 'AddStaffTargetDetailsKey';
		const jsonData = {
			staffID: payload.staffID,
			staffTargetFromDate: payload.staffTargetFromDate,
			staffTargetToDate: payload.staffTargetToDate,
			staffTarget: payload.staffTarget,
			staffCommission: payload.staffCommission,
			staffBeta: payload.staffBeta,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffTargetDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addStaffTargetDetailsFailure(responseData.data));
		} else {
			yield put(addStaffTargetDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addStaffTargetDetailsFailure(error));
	}
}

export function* resetAddStaffTargetResponse() {
	yield put(addStaffTargetDetailsResponseChanged());
}

export function* onCreateStaffTargetDetails() {
	yield takeLatest(StaffActionTypes.ADD_STAFF_TARGET_DETAILS_START, createStaffTarget);
}

export function* onCreateStaffTargetResponseReset() {
	yield takeLatest(
		StaffActionTypes.ADD_STAFF_TARGET_DETAILS_RESPONSE_RESET,
		resetAddStaffTargetResponse,
	);
}
/** *********************  END ADD STAFF TARGET DETAILS ******************** */

/** *********************  START UPDATE STAFF TARGET DETAILS  ******************** */
export function* staffUpdateTarget({ payload }) {
	try {
		const key = 'UpdateStaffTargetDetailsKey';
		const jsonData = {
			staffTargetID: payload.staffTargetID,
			StaffID: payload.staffID,
			staffTargetFromDate: payload.staffTargetFromDate,
			staffTargetToDate: payload.staffTargetToDate,
			staffTarget: payload.staffTarget,
			staffCommission: payload.staffCommission,
			staffBeta: payload.staffBeta,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			DatabaseName: payload.databaseName,
			UserAccessName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			ModifiedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffTargetDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffTargetDetailsFailure(responseData.data));
		} else {
			yield put(updateStaffTargetDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffTargetDetailsFailure(error));
	}
}

export function* resetUpdateStaffTargetDetailsResponse() {
	yield put(updateStaffTargetDetailsResponseChanged());
}

export function* onUpdateStaffTargetDetails() {
	yield takeLatest(StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_START, staffUpdateTarget);
}

export function* onUpdateStaffTargetResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_RESPONSE_RESET,
		resetUpdateStaffTargetDetailsResponse,
	);
}
/** *********************  END UPDATE STAFF TARGET DETAILS ******************** */

/** *********************  START GET STAFF LIST  ******************** */
export function* staffDetailsListGet({ payload }) {
	try {
		const key = 'FetchStaffListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRoleName: payload.userRole,
			Level: payload.userLevel,
			BranchID: payload.userAccessBranchID,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
			StaffLevel: payload.staffLevel,
			staffRoleName: payload.staffRoleName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffListFailure(responseData.data));
		} else {
			yield put(getStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffListFailure(error));
	}
}

export function* resetGetStaffListResponse() {
	yield put(getStaffListResponseChanged());
}

export function* onGetStaffListDetails() {
	yield takeLatest(StaffActionTypes.GET_STAFF_LIST_START, staffDetailsListGet);
}

export function* onGetStaffListResponseReset() {
	yield takeLatest(StaffActionTypes.GET_STAFF_LIST_RESPONSE_RESET, resetGetStaffListResponse);
}
/** *********************  END GET STAFF LIST ******************** */

/** *********************  START SEARCH STAFF LIST  ******************** */
export function* searchStaffDetailsListGet({ payload }) {
	try {
		const key = 'FetchSearchStaffListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.InputSearch,
			Limit: payload.Limit,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchStaffList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(searchStaffListFailure(responseData.data));
		} else {
			yield put(searchStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(searchStaffListFailure(error));
	}
}

export function* resetSearchStaffListResponse() {
	yield put(searchStaffListResponseChanged());
}

export function* onSearchStaffListDetails() {
	yield takeLatest(StaffActionTypes.FETCH_SEARCH_STAFF_LIST_START, searchStaffDetailsListGet);
}

export function* onSearchStaffListResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_SEARCH_STAFF_LIST_RESPONSE_RESET,
		resetSearchStaffListResponse,
	);
}
/** *********************  END SEARCH STAFF LIST ******************** */

/** *********************  START FETCH STAFF DETAILS  ******************** */
export function* fetchStaffData({ payload }) {
	try {
		const key = 'FetchStaffInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			StaffID: payload.staffID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStaffDetailsFailure(responseData.data));
		} else {
			yield put(fetchStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStaffDetailsFailure(error));
	}
}

export function* resetFetchStaffDetailsResponse() {
	yield put(fetchStaffDetailsResponseChanged());
}

export function* onFetchStaffDetails() {
	yield takeLatest(StaffActionTypes.FETCH_STAFF_DETAILS_START, fetchStaffData);
}

export function* onFetchStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_RESET,
		resetFetchStaffDetailsResponse,
	);
}
/** *********************  END FETCH STAFF DETAILS ******************** */

/** *********************  START DELETE STAFF DETAILS  ******************** */
export function* staffDelete({ payload }) {
	try {
		const key = 'DeleteStaffDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			StaffID: payload.staffID,
			StaffName: payload.staffName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteStaffDetailsFailure(responseData.data));
		} else {
			yield put(deleteStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteStaffDetailsFailure(error));
	}
}

export function* resetDeleteStaffDetailsResponse() {
	yield put(deleteStaffDetailsResponseChanged());
}

export function* onDeleteStaffDetail() {
	yield takeLatest(StaffActionTypes.DELETE_STAFF_DETAILS_START, staffDelete);
}

export function* onDeleteStaffResponseReset() {
	yield takeLatest(
		StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_RESET,
		resetDeleteStaffDetailsResponse,
	);
}
/** *********************  END DELETE STAFF DETAILS ******************** */

/** *********************  START UPDATE STAFF IS PUBLISH STATUS DETAILS  ******************** */
export function* staffIsPublishUpdate({ payload }) {
	try {
		const key = 'UpdateStaffPublisKey';
		const jsonData = {
			StaffID: payload.staffID,
			IsPublish: payload.isPublish ? 1 : 0,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffPublishStatusUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateIsPublishStatusFailure(responseData.data));
		} else {
			yield put(updateIsPublishStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateIsPublishStatusFailure(error));
	}
}

export function* resetUpdatePublishStatusResponse() {
	yield put(updateIsPublishStatusResponseChanged());
}

export function* onUpdatePublishStatus() {
	yield takeLatest(StaffActionTypes.UPDATE_ISPUBLISH_STATUS_START, staffIsPublishUpdate);
}

export function* onUpdatePublishStatusResponseReset() {
	yield takeLatest(
		StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET,
		resetUpdatePublishStatusResponse,
	);
}
/** *********************  END UPDATE STAFF IS PUBLISH STATUS DETAILS ******************** */

/** *********************  START FETCH ALL STAFFS LIST  ******************** */
export function* fetchAllStaffsList({ payload }) {
	try {
		const key = 'FetchAllStaffListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserStaff: payload.userStaff,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allStaffFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllStaffFailure(responseData.data));
		} else {
			yield put(fetchAllStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllStaffFailure(error));
	}
}

export function* resetFetchAllStaffsResponse() {
	yield put(fetchAllStaffResponseChanged());
}

export function* onFetchAllStaffsDetails() {
	yield takeLatest(StaffActionTypes.FETCH_ALL_STAFF_START, fetchAllStaffsList);
}

export function* onFetchAllStaffsResponseReset() {
	yield takeLatest(StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_RESET, resetFetchAllStaffsResponse);
}
/** *********************  END FETCH ALL STAFFS LIST ******************** */

/** *********************  START FETCH AGENT STAFFS LIST  ******************** */
export function* fetchAgentStaffsList({ payload }) {
	try {
		const key = 'FetchAgentStaffListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserStaff: payload.userStaff,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(agentStaffFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAgentStaffFailure(responseData.data));
		} else {
			yield put(fetchAgentStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAgentStaffFailure(error));
	}
}

export function* resetFetchAgentStaffsResponse() {
	yield put(fetchAgentStaffResponseChanged());
}

export function* onFetchAgentStaffsDetails() {
	yield takeLatest(StaffActionTypes.FETCH_AGENT_STAFF_START, fetchAgentStaffsList);
}

export function* onFetchAgentStaffsResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_AGENT_STAFF_RESPONSE_RESET,
		resetFetchAgentStaffsResponse,
	);
}
/** *********************  END FETCH AGENT STAFFS LIST ******************** */

/** *********************  START FETCH STAFF TARGET LIST  ******************** */
export function* fetchStaffTargetList({ payload }) {
	try {
		const key = 'FetchStaffTargetListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			StaffID: payload.StaffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffTargetListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStaffTargetListFailure(responseData.data));
		} else {
			yield put(fetchStaffTargetListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStaffTargetListFailure(error));
	}
}

export function* resetFetchStaffTargetListResponse() {
	yield put(fetchStaffTargetListResponseChanged());
}

export function* onFetchStaffTargetListDetails() {
	yield takeLatest(StaffActionTypes.FETCH_STAFF_TARGET_LIST_START, fetchStaffTargetList);
}

export function* onFetchStaffTargetListResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_STAFF_TARGET_LIST_RESPONSE_RESET,
		resetFetchStaffTargetListResponse,
	);
}
/** *********************  END FETCH STAFF TARGET LIST ******************** */

export function* staffMasterSaga() {
	yield all([
		call(onCreateStaffDetails),
		call(onCreateStaffResponseReset),
		call(onUpdateStaffDetails),
		call(onUpdateStaffResponseReset),
		call(onUpdateStaffAddressDetails),
		call(onUpdateStaffAddressResponseReset),
		call(onUpdateStaffProofDetails),
		call(onUpdateStaffProofResponseReset),
		call(onUpdateStaffEmergencyDetails),
		call(onUpdateStaffEmergencyResponseReset),
		call(onUpdateStaffOfficialDetails),
		call(onUpdateStaffOfficialResponseReset),
		call(onCreateStaffTargetDetails),
		call(onCreateStaffTargetResponseReset),
		call(onUpdateStaffTargetDetails),
		call(onUpdateStaffTargetResponseReset),
		call(onGetStaffListDetails),
		call(onGetStaffListResponseReset),
		call(onSearchStaffListDetails),
		call(onSearchStaffListResponseReset),
		call(onFetchStaffDetails),
		call(onFetchStaffResponseReset),
		call(onDeleteStaffDetail),
		call(onDeleteStaffResponseReset),
		call(onUpdatePublishStatus),
		call(onUpdatePublishStatusResponseReset),
		call(onFetchAllStaffsDetails),
		call(onFetchAllStaffsResponseReset),
		call(onFetchAgentStaffsDetails),
		call(onFetchAgentStaffsResponseReset),
		call(onFetchStaffTargetListDetails),
		call(onFetchStaffTargetListResponseReset),
	]);
}
