/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DemandCollectionActionTypes from './demandCollection.types';
import {
	fetchDemandCollectionList,
	toUpdateDemandCollectionDetails,
} from './demandCollection.service';

import {
	getDemandCollectionListSuccess,
	getDemandCollectionListFailure,
	getDemandCollectionListResponseChanged,
	toUpdateDemandCollectionDetailsSuccess,
	toUpdateDemandCollectionDetailsFailure,
	toUpdateDemandCollectionDetailsResponseChanged,
} from './demandCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START FETCH BRANCH DEMAND COLLECTION DETAILS  ******************** */
export function* getDemandCollectionList({ payload }) {
	try {
		const key = 'FetchDemandCollectionListKey';
		const jsonData = {
			BranchID: payload.branchID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			DueDate: payload.demandDate,
			GroupID: payload.groupID,
			AgentID: payload.agentID,
			UserID: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchDemandCollectionList, encodeData);
		if (responseData.data.statusCode === '102') {
			yield put(getDemandCollectionListFailure(responseData.data));
		} else {
			yield put(getDemandCollectionListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandCollectionListFailure(error));
	}
}

export function* resetGetDemandCollectionListResponse() {
	yield put(getDemandCollectionListResponseChanged());
}

export function* onGetDemandCollectionListDetails() {
	yield takeLatest(
		DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_START,
		getDemandCollectionList,
	);
}

export function* onGetDemandCollectionListResponseReset() {
	yield takeLatest(
		DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_RESPONSE_RESET,
		resetGetDemandCollectionListResponse,
	);
}
/** *********************  END FETCH BRANCH DEMAND COLLECTION DETAILS  ******************** */

/** *********************  START TO UPDATE DEMAND COLLECTION DETAILS  ******************** */
export function* toUpdateDemandCollections({ payload }) {
	try {
		const key = 'ToUpdateDemandCollectionDetailsKey';
		const jsonData = {
			fetchDemandColltionArr: payload.fetchDemandColltionArr,
			repayDate: payload.repayDate,
			BranchID: payload.branchID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			DueDate: payload.demandDate,
			GroupID: payload.groupID,
			AgentID: payload.agentID,
			UserID: payload.UserID,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			totalDenominationAmount: payload.totalDenominationAmount,
			receiptNumber: payload.receiptNumber,
			chequeNumber: payload.chequeNumber,
			collectedMode: payload.collectedMode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(toUpdateDemandCollectionDetails, encodeData);
		if (responseData.data.statusCode === '102') {
			yield put(toUpdateDemandCollectionDetailsFailure(responseData.data));
		} else {
			yield put(toUpdateDemandCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(toUpdateDemandCollectionDetailsFailure(error));
	}
}

export function* resetToUpdateDemandCollectionDetailsResponse() {
	yield put(toUpdateDemandCollectionDetailsResponseChanged());
}

export function* onToUpdateDemandCollectionDetailsDetails() {
	yield takeLatest(
		DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_START,
		toUpdateDemandCollections,
	);
}

export function* onToUpdateDemandCollectionDetailsResponseReset() {
	yield takeLatest(
		DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_RESET,
		resetToUpdateDemandCollectionDetailsResponse,
	);
}
/** *********************  END TO UPDATE DEMAND COLLECTION DETAILS  ******************** */

export function* demandCollectionSaga() {
	yield all([
		call(onGetDemandCollectionListDetails),
		call(onGetDemandCollectionListResponseReset),
		call(onToUpdateDemandCollectionDetailsDetails),
		call(onToUpdateDemandCollectionDetailsResponseReset),
	]);
}
