/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ChitDemandReportActionTypes from './chitDemandReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchChitDemandReportResponse: null,
	fetchChitDemandReportArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_SUCCESS:
			return {
				...state,
				fetchChitDemandReportResponse: action.payload,
				fetchChitDemandReportArr: docodeResponse(
					action.payload,
					'FetchChitDemandReportKey',
				),
			};

		case ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_FAILURE:
			return { ...state, fetchChitDemandReportResponse: action.payload };

		case ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchChitDemandReportResponse: null };

		default:
			return state;
	}
};
