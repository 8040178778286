/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import routeCollectionActionTypes from './routeCollection.types';

/** ***************** ADD ROUTE COLLECTION START *********************** */
export const addRouteCollectionDetailsStart = (getData) => ({
	type: routeCollectionActionTypes.ADD_ROUTE_COLLECTION_START,
	payload: getData,
});

export const addRouteCollectionDetailsSuccess = (successData) => ({
	type: routeCollectionActionTypes.ADD_ROUTE_COLLECTION_SUCCESS,
	payload: successData,
});

export const addRouteCollectionDetailsFailure = (failureData) => ({
	type: routeCollectionActionTypes.ADD_ROUTE_COLLECTION_FAILURE,
	payload: failureData,
});

export const addRouteCollectionDetailsResponseResetStart = () => ({
	type: routeCollectionActionTypes.ADD_ROUTE_COLLECTION_RESPONSE_RESET,
});

export const addRouteCollectionDetailsResponseChanged = () => ({
	type: routeCollectionActionTypes.ADD_ROUTE_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** ADD ROUTE COLLECTION END *********************** */
