/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const chitIndexDetailsAdd = (addData) => {
	const data = {
		AddChitIndexDetailsToken: addData,
	};
	const url = `${API_URL}addChitIndexDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const chitIndexMembersDetailsAdd = (addData) => {
	const data = {
		AddChitIndexMembersDetailsToken: addData,
	};
	const url = `${API_URL}addChitIndexMembersDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const chitIndexDetailsUpdate = (updateData) => {
	const data = {
		UpdateChitIndexDetailsToken: updateData,
	};
	const url = `${API_URL}updateChitIndexDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const chitIndexMembersDetailsUpdate = (updateData) => {
	const data = {
		UpdateChitIndexMembersDetailsToken: updateData,
	};
	const url = `${API_URL}updateChitIndexMembersDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchChitIndexList = (listData) => {
	const data = {
		FetchChitIndexListToken: listData,
	};
	const url = `${API_URL}FetchChitIndexListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const chitIndexDetailsFetch = (fetchData) => {
	const data = {
		FetchChitIndexInfoToken: fetchData,
	};
	const url = `${API_URL}FetchChitIndexInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const chitIndexDetailsDelete = (deleteData) => {
	const data = {
		DeleteChitIndexDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteChitIndexDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
