/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import CollectionActionTypes from './collection.types';

import {
	fetchBranchCollectionUsersList,
	fetchCollectedUserCentersList,
	fetchCollectedCenterGroupList,
	fetchCollectionApproveList,
} from './collection.service';

import {
	getBranchCollectionUsersListSuccess,
	getBranchCollectionUsersListFailure,
	getBranchCollectionUsersListResponseChanged,
	fetchCollectedUsersCentersListSuccess,
	fetchCollectedUsersCentersListFailure,
	fetchCollectedUsersCentersListResponseChanged,
	fetchCollectedCenterGroupsListSuccess,
	fetchCollectedCenterGroupsListFailure,
	fetchCollectedCenterGroupsListResponseChanged,
	fetchCollectionApproveListSuccess,
	fetchCollectionApproveListFailure,
	fetchCollectionApproveListResponseChanged,
} from './collection.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START BRANCH COLLECTION USERS LIST DETAILS  ******************** */
export function* getBranchCollectionUsersList({ payload }) {
	try {
		const key = 'FetchBranchCollectionOfficersKey';
		const jsonData = {
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			CollectionDate: payload.CollectionDate,
			CompanyID: payload.CompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchCollectionUsersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchCollectionUsersListFailure(responseData.data));
		} else {
			yield put(getBranchCollectionUsersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchCollectionUsersListFailure(error));
	}
}

export function* resetGetBranchCollectionUsersListResponse() {
	yield put(getBranchCollectionUsersListResponseChanged());
}

export function* onGetBranchCollectionUsersListDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
		getBranchCollectionUsersList,
	);
}

export function* onGetBranchCollectionUsersListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
		resetGetBranchCollectionUsersListResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

/** *********************  START COLLECTED USERS CENTERS LIST DETAILS  ******************** */
export function* fetchCollectedUsersCentersList({ payload }) {
	try {
		const key = 'FetchCollectedOfficersCentersKey';
		const jsonData = {
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			CollectionDate: payload.CollectionDate,
			CollectedByID: payload.CollectedByID,
			CompanyID: payload.CompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCollectedUserCentersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCollectedUsersCentersListFailure(responseData.data));
		} else {
			yield put(fetchCollectedUsersCentersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCollectedUsersCentersListFailure(error));
	}
}

export function* resetFetchCollectedUsersCentersListResponse() {
	yield put(fetchCollectedUsersCentersListResponseChanged());
}

export function* onFetchCollectedUsersCentersListDetails() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_START,
		fetchCollectedUsersCentersList,
	);
}

export function* onFetchCollectedUsersCentersListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_RESET,
		resetGetBranchCollectionUsersListResponse,
	);
}
/** *********************  END GET COLLECTED USERS CENTERS LIST  ******************** */

/** *********************  START COLLECTED CENTER GROUP LIST DETAILS  ******************** */
export function* fetchCollectedCenterGroupsList({ payload }) {
	try {
		const key = 'FetchCollectedCenterGroupListKey';
		const jsonData = {
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			CenterID: payload.CenterID,
			CollectionDate: payload.CollectionDate,
			CollectedByID: payload.CollectedByID,
			CompanyID: payload.CompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCollectedCenterGroupList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCollectedCenterGroupsListFailure(responseData.data));
		} else {
			yield put(fetchCollectedCenterGroupsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCollectedCenterGroupsListFailure(error));
	}
}

export function* resetGetBranchCollectedCenterGroupsListResponse() {
	yield put(fetchCollectedCenterGroupsListResponseChanged());
}

export function* onFetchCollectedCenterGroupsListDetails() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_START,
		fetchCollectedCenterGroupsList,
	);
}

export function* onFetchCollectedCenterGroupsListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_RESET,
		resetGetBranchCollectedCenterGroupsListResponse,
	);
}
/** *********************  END COLLECTED CENTER GROUP LIST  ******************** */

/** *********************  START COLLECTION APPROVE LIST DETAILS  ******************** */
export function* fetchCollectionApproveListData({ payload }) {
	try {
		const key = 'FetchCollectionsListForApproveKey';
		const jsonData = {
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			CollectionDate: payload.CollectionDate,
			CollectedByID: payload.CollectedByID,
			CompanyID: payload.CompanyID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCollectionApproveList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCollectionApproveListFailure(responseData.data));
		} else {
			yield put(fetchCollectionApproveListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCollectionApproveListFailure(error));
	}
}

export function* resetGetBranchCollectionApproveListResponse() {
	yield put(fetchCollectionApproveListResponseChanged());
}

export function* onFetchCollectionApproveListDetails() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_START,
		fetchCollectionApproveListData,
	);
}

export function* onFetchCollectionApproveListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_RESPONSE_RESET,
		resetGetBranchCollectionApproveListResponse,
	);
}
/** *********************  END COLLECTION APPROVE LIST DETAILS  ******************** */

export function* collectionSaga() {
	yield all([
		call(onGetBranchCollectionUsersListDetails),
		call(onGetBranchCollectionUsersListResponseReset),
		call(onFetchCollectedUsersCentersListDetails),
		call(onFetchCollectedUsersCentersListResponseReset),
		call(onFetchCollectedCenterGroupsListDetails),
		call(onFetchCollectedCenterGroupsListResponseReset),
		call(onFetchCollectionApproveListDetails),
		call(onFetchCollectionApproveListResponseReset),
	]);
}
