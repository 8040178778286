/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import groupCollectionActionTypes from './groupCollection.types';
import { addGroupCollection } from './groupCollection.service';

import {
	addGroupCollectionDetailsSuccess,
	addGroupCollectionDetailsFailure,
	addGroupCollectionDetailsResponseChanged,
} from './groupCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD APPLICANT COLLECTION DETAILS  ******************** */
export function* addGroupCollectionDetails({ payload }) {
	try {
		const key = 'AddGroupCollectionDetailsKey';
		const jsonData = {
			fetchCenterEnrollMembersList: payload.fetchCenterEnrollMembersList,
			CollectionDateTime: payload.collectionDate,
			CollectionAmount: payload.collectionAmount,
			CompanyID: payload.companyID,
			CollectedFrom: 'web',
			CollectedMode: payload.collectedMode,
			FiveHundred: payload.fiveHundred,
			TwoHundred: payload.twoHundred,
			Hundred: payload.hundred,
			Fifty: payload.fifty,
			Twenty: payload.twenty,
			Ten: payload.ten,
			Five: payload.five,
			Two: payload.two,
			One: payload.one,
			TotalDenominationAmount: payload.totalDenominationAmount,
			ReceiptNumber: payload.receiptNumber,
			ChequeNumber: payload.chequeNumber,
			CreatedBy: payload.UserID,
			UserName: payload.UserName,
			Level: payload.UserLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addGroupCollection, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addGroupCollectionDetailsFailure(responseData.data));
		} else {
			yield put(addGroupCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addGroupCollectionDetailsFailure(error));
	}
}

export function* resetAddGroupCollectionDetailsResponse() {
	yield put(addGroupCollectionDetailsResponseChanged());
}

export function* onAddGroupCollectionDetails() {
	yield takeLatest(
		groupCollectionActionTypes.ADD_GROUP_COLLECTION_START,
		addGroupCollectionDetails,
	);
}

export function* onAddGroupCollectionDetailsResponseReset() {
	yield takeLatest(
		groupCollectionActionTypes.ADD_GROUP_COLLECTION_RESPONSE_RESET,
		resetAddGroupCollectionDetailsResponse,
	);
}
/** *********************  END ADD GROUP COLLECTION INFO  ******************** */

export function* groupCollectionSaga() {
	yield all([call(onAddGroupCollectionDetails), call(onAddGroupCollectionDetailsResponseReset)]);
}
