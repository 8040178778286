/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchBranchCollectionUsersList = (listData) => {
	const data = {
		FetchBranchCollectionOfficersToken: listData,
	};
	const url = `${API_URL}FetchBranchCollectionOfficers`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCollectedUserCentersList = (listData) => {
	const data = {
		FetchCollectedOfficerCentersToken: listData,
	};
	const url = `${API_URL}FetchCollectedOfficerCenters`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCollectedCenterGroupList = (listData) => {
	const data = {
		FetchCollectedCenterGroupListToken: listData,
	};
	const url = `${API_URL}FetchCollectedCenterGroupList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCollectionApproveList = (listData) => {
	const data = {
		FetchCollectionsListForApproveToken: listData,
	};
	const url = `${API_URL}FetchCollectionsListForApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
