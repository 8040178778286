import React, { lazy } from 'react';
import { componentsMenu, dashboardMenu, demoPages } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

// USERCONTROLACCESS
// const USERCONTROLACCESS = {
// 	ASSIGN_USER_RIGHTS_PAGE: lazy(() =>
// 		import('../pages/userControlAccess/assignUserRights/assignUserRights.component'),
// 	),
// };

// MASTER
const BRANCH = {
	BRANCH_PAGE: lazy(() => import('../pages/masters/branch/branch.component')),
};

// const CENTER = {
// 	CENTER_PAGE: lazy(() => import('../pages/masters/center/center.component')),
// };

const ROLE = {
	ROLE_PAGE: lazy(() => import('../pages/masters/role/role.component')),
};

const STAFF = {
	STAFF_PAGE: lazy(() => import('../pages/masters/staff/staff.component')),
};

const GROUP = {
	GROUP_PAGE: lazy(() => import('../pages/masters/group/group.component')),
};

const MEMBER = {
	MEMBER_PAGE: lazy(() => import('../pages/masters/member/member.component')),
};

const GROUP_ALLOTMENT = {
	GROUP_ALLOTMENT_PAGE: lazy(() =>
		import('../pages/operations/groupAllotment/groupAllotment.component'),
	),
};

// const PRODUCT_TYPE = {
// 	PRODUCT_TYPE_PAGE: lazy(() => import('../pages/masters/product-type/product-type.component')),
// };

const PRODUCT = {
	PRODUCT_PAGE: lazy(() => import('../pages/masters/product/product.component')),
};

// const CHIT_INDEX = {
// 	CHIT_INDEX_PAGE: lazy(() => import('../pages/operations/chitIndex/chitIndex.component')),
// };

const COLLECTION = {
	COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/applicantCollection/applicantCollection.component'),
	),
};

const ROUTE_COLLECTION = {
	ROUTE_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/routeCollection/routeCollection.component'),
	),
};

const CHIT_COLLECTION = {
	CHIT_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/chitCollection/chitCollection.component'),
	),
};

const DEMAND_COLLECTION = {
	DEMAND_COLLECTION_PAGE: lazy(() =>
		import('../pages/operations/demandCollection/demandCollection.component'),
	),
};

// const OFFICE_COLLECTION = {
// 	OFFICE_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/officeCollection/officeCollection.component'),
// 	),
// };

// const GROUP_COLLECTION = {
// 	GROUP_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/groupCollection/groupCollection.component'),
// 	),
// };

// const TODAY_COLLECTION = {
// 	TODAY_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/todayCollection/todayCollection.component'),
// 	),
// };

// const DEMAND_COLLECTION = {
// 	DEMAND_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/demandCollection/demandCollection.component'),
// 	),
// };

// const ADVANCE_COLLECTION = {
// 	ADVANCE_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/advanceCollection/advanceCollection.component'),
// 	),
// };

// const ARREAR_COLLECTION = {
// 	ARREAR_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/arrearCollection/arrearCollection.component'),
// 	),
// };

const CHIT_COLLECTION_APPROVE = {
	CHIT_COLLECTION_APPROVE_PAGE: lazy(() =>
		import('../pages/operations/collectionApprove/collectionApprove.component'),
	),
};

// const WRITE_OFF_COLLECTION = {
// 	WRITE_OFF_COLLECTION_PAGE: lazy(() =>
// 		import('../pages/operations/writeOffCollection/writeOffCollection.component'),
// 	),
// };

// const PRE_CLOSE_LOAN = {
// 	PRE_CLOSE_LOAN_PAGE: lazy(() =>
// 		import('../pages/operations/preCloseLoan/preCloseLoan.component'),
// 	),
// };

// const CLOSE_LOAN = {
// 	CLOSE_LOAN_PAGE: lazy(() => import('../pages/operations/closeLoan/closeLoan.component')),
// };

const CHIT_INDEX_REPORT = {
	CHIT_INDEX_REPORT_PAGE: lazy(() =>
		import('../pages/reports/chitIndexReport/chitIndexReport.component'),
	),
};

const CHIT_DEMAND_REPORT = {
	CHIT_DEMAND_REPORT_PAGE: lazy(() =>
		import('../pages/reports/chitDemandReport/chitDemandReport.component'),
	),
};

const DEMAND_FOLLOWUP_REPORT = {
	DEMAND_FOLLOWUP_REPORT_PAGE: lazy(() =>
		import('../pages/reports/demandFollowUpReport/demandFollowUpReport.component'),
	),
};

const REPAYMENT_REPORT = {
	REPAYMENT_REPORT_PAGE: lazy(() =>
		import('../pages/reports/repaymentReport/repaymentReport.component'),
	),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const EXTRA = {
	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},

	// userControlAccess
	// {
	// 	path: demoPages.userControlAccess.subMenu.assignUserRights.path,
	// 	element: <USERCONTROLACCESS.ASSIGN_USER_RIGHTS_PAGE />,
	// 	exact: true,
	// },

	// Master
	{
		path: demoPages.masterPages.subMenu.branch.path,
		element: <BRANCH.BRANCH_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.masterPages.subMenu.center.path,
	// 	element: <CENTER.CENTER_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.masterPages.subMenu.role.path,
		element: <ROLE.ROLE_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.staff.path,
		element: <STAFF.STAFF_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.group.path,
		element: <GROUP.GROUP_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.masterPages.subMenu.member.path,
	// 	element: <MEMBER.MEMBER_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.masterPages.subMenu.productType.path,
	// 	element: <PRODUCT_TYPE.PRODUCT_TYPE_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.masterPages.subMenu.product.path,
		element: <PRODUCT.PRODUCT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.masterPages.subMenu.applicant.path,
		element: <MEMBER.MEMBER_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.groupAllotment.path,
		element: <GROUP_ALLOTMENT.GROUP_ALLOTMENT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.collection.path,
		element: <COLLECTION.COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.routeCollection.path,
		element: <ROUTE_COLLECTION.ROUTE_COLLECTION_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.operationPages.subMenu.chitIndex.path,
	// 	element: <CHIT_INDEX.CHIT_INDEX_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.operationPages.subMenu.chitCollection.path,
		element: <CHIT_COLLECTION.CHIT_COLLECTION_PAGE />,
		exact: true,
	},
	{
		path: demoPages.operationPages.subMenu.demandCollection.path,
		element: <DEMAND_COLLECTION.DEMAND_COLLECTION_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.operationPages.subMenu.officeCollection.path,
	// 	element: <OFFICE_COLLECTION.OFFICE_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.groupCollection.path,
	// 	element: <GROUP_COLLECTION.GROUP_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.todayCollection.path,
	// 	element: <TODAY_COLLECTION.TODAY_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.demandCollection.path,
	// 	element: <DEMAND_COLLECTION.DEMAND_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.advanceCollection.path,
	// 	element: <ADVANCE_COLLECTION.ADVANCE_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.arrearCollection.path,
	// 	element: <ARREAR_COLLECTION.ARREAR_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.writeOffCollection.path,
	// 	element: <WRITE_OFF_COLLECTION.WRITE_OFF_COLLECTION_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.operationPages.subMenu.chitCollApprove.path,
		element: <CHIT_COLLECTION_APPROVE.CHIT_COLLECTION_APPROVE_PAGE />,
		exact: true,
	},
	// {
	// 	path: demoPages.operationPages.subMenu.preCloseLoan.path,
	// 	element: <PRE_CLOSE_LOAN.PRE_CLOSE_LOAN_PAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.operationPages.subMenu.closeLoan.path,
	// 	element: <CLOSE_LOAN.CLOSE_LOAN_PAGE />,
	// 	exact: true,
	// },
	{
		path: demoPages.reportPages.subMenu.ChitIndexReport.path,
		element: <CHIT_INDEX_REPORT.CHIT_INDEX_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.ChitDemandReport.path,
		element: <CHIT_DEMAND_REPORT.CHIT_DEMAND_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.DemandFollowUpReport.path,
		element: <DEMAND_FOLLOWUP_REPORT.DEMAND_FOLLOWUP_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.reportPages.subMenu.RepaymentReport.path,
		element: <REPAYMENT_REPORT.REPAYMENT_REPORT_PAGE />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];
const documentation = [
	{
		path: componentsMenu.notification.path,
		element: <EXTRA.NOTIFICATION />,
		exact: true,
	},
	{
		path: componentsMenu.hooks.path,
		element: <EXTRA.HOOKS />,
		exact: true,
	},
];
const contents = [...presentation, ...documentation];

export default contents;
