/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import memberCollectionActionTypes from './memberCollection.types';

/** ***************** GET SEARCH MEMBER CHIT LIST START *********************** */
export const fetchSearchMemberChitListStart = (getData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_START,
	payload: getData,
});

export const getSearchMemberChitListSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_SUCCESS,
	payload: successData,
});

export const getSearchMemberChitListFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchMemberChitListResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_RESPONSE_RESET,
});

export const getSearchMemberChitListResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET SEARCH MEMBER CHIT LIST END *********************** */

/** ***************** GET MEMBER PARTICULAR CHIT INFO START *********************** */
export const fetchMemberParticularChitInfoStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_START,
	payload: getData,
});

export const getMemberParticularChitInfoSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_SUCCESS,
	payload: successData,
});

export const getMemberParticularChitInfoFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_FAILURE,
	payload: failureData,
});

export const fetchMemberParticularChitInfoResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_RESPONSE_RESET,
});

export const getMemberParticularChitInfoResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER PARTICULAR CHIT INFO END *********************** */

/** ***************** POST CHIT COLLECTION INFO START *********************** */
export const postChitCollectionInfoStart = (getData) => ({
	type: memberCollectionActionTypes.POST_CHIT_LIST_START,
	payload: getData,
});

export const postChitCollectionInfoSuccess = (successData) => ({
	type: memberCollectionActionTypes.POST_CHIT_LIST_SUCCESS,
	payload: successData,
});

export const postChitCollectionInfoFailure = (failureData) => ({
	type: memberCollectionActionTypes.POST_CHIT_LIST_FAILURE,
	payload: failureData,
});

export const postChitCollectionInfoResponseResetStart = () => ({
	type: memberCollectionActionTypes.POST_CHIT_LIST_RESPONSE_RESET,
});

export const postChitCollectionInfoResponseChanged = () => ({
	type: memberCollectionActionTypes.POST_CHIT_LIST_RESPONSE_CHANGED,
});
/** ***************** POST CHIT COLLECTION INFO END *********************** */
