/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import GroupActionTypes from './group.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addGroupDetailsResponse: null,
	addMapMembersToGroupDetailsResponse: null,

	updateGroupDetailsResponse: null,

	selectGetGroupListResponse: null,
	selectGetGroupListArr: [],

	selectGetSearchGroupListResponse: null,
	selectGetSearchGroupListArr: [],

	selectGetGroupInfoResponse: null,
	selectGetGroupInfoArr: [],

	selectFetchAllGroupResponse: null,
	selectFetchAllGroupArr: [],

	selectFetchProductGroupResponse: null,
	selectFetchProductGroupArr: [],

	selectFetchCenterGroupResponse: null,
	selectFetchCenterGroupArr: [],

	deleteGroupDetailsResponse: null,

	selectFetchGroupMembersResponse: null,
	selectFetchGroupMembersArr: [],

	selectFetchAllApprovedMemberResponse: null,
	selectFetchAllApprovedMemberArr: [],

	selectAllOfficerResponse: null,
	selectAllOfficerArr: [],

	selectAllGroupDetailsResponse: null,
	selectAllGroupDetailsArr: [],

	selectBranchOfficerResponse: null,
	selectBranchOfficerArr: [],

	fetchGroupUniqueIDResponse: null,
	fetchGroupUniqueIDArr: [],

	fetchGroupID: '',
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GroupActionTypes.ADD_GROUP_DETAILS_SUCCESS:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_FAILURE:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, addGroupDetailsResponse: null };

		case GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_SUCCESS:
			return { ...state, addMapMembersToGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_FAILURE:
			return { ...state, addMapMembersToGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMapMembersToGroupDetailsResponse: null };

		case GroupActionTypes.UPDATE_GROUP_DETAILS_SUCCESS:
			return { ...state, updateGroupDetailsResponse: action.payload };

		case GroupActionTypes.UPDATE_GROUP_DETAILS_FAILURE:
			return { ...state, updateGroupDetailsResponse: action.payload };

		case GroupActionTypes.UPDATE_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateGroupDetailsResponse: null };

		case GroupActionTypes.GET_GROUP_LIST_SUCCESS:
			return {
				...state,
				selectGetGroupListResponse: action.payload,
				selectGetGroupListArr: docodeResponse(action.payload, 'FetchGroupListKey'),
			};

		case GroupActionTypes.GET_GROUP_LIST_FAILURE:
			return { ...state, selectGetGroupListResponse: action.payload };

		case GroupActionTypes.GET_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, selectGetGroupListResponse: null };

		case GroupActionTypes.GET_SEARCH_GROUP_LIST_SUCCESS:
			return {
				...state,
				selectGetSearchGroupListResponse: action.payload,
				selectGetSearchGroupListArr: docodeResponse(
					action.payload,
					'FetchSearchGroupListKey',
				),
			};

		case GroupActionTypes.GET_SEARCH_GROUP_LIST_FAILURE:
			return { ...state, selectGetSearchGroupListResponse: action.payload };

		case GroupActionTypes.GET_SEARCH_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, selectGetSearchGroupListResponse: null };

		case GroupActionTypes.GET_GROUP_INFO_SUCCESS:
			return {
				...state,
				selectGetGroupInfoResponse: action.payload,
				selectGetGroupInfoArr: docodeResponse(action.payload, 'FetchGroupInfoKey'),
			};

		case GroupActionTypes.GET_GROUP_INFO_FAILURE:
			return { ...state, selectGetGroupInfoResponse: action.payload };

		case GroupActionTypes.GET_GROUP_INFO_RESPONSE_CHANGED:
			return { ...state, selectGetGroupInfoResponse: null };

		case GroupActionTypes.DELETE_GROUP_DETAILS_SUCCESS:
			return { ...state, deleteGroupDetailsResponse: action.payload };

		case GroupActionTypes.DELETE_GROUP_DETAILS_FAILURE:
			return { ...state, deleteGroupDetailsResponse: action.payload };

		case GroupActionTypes.DELETE_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteGroupDetailsResponse: null };

		case GroupActionTypes.FETCH_GROUP_UNIQUE_ID_SUCCESS:
			return {
				...state,
				fetchGroupUniqueIDResponse: action.payload,
				fetchGroupUniqueIDArr: docodeResponse(action.payload, 'FetchGroupUniqueIdKey'),
			};

		case GroupActionTypes.FETCH_GROUP_UNIQUE_ID_FAILURE:
			return { ...state, fetchGroupUniqueIDResponse: action.payload };

		case GroupActionTypes.FETCH_GROUP_UNIQUE_ID_RESPONSE_CHANGED:
			return { ...state, fetchGroupUniqueIDResponse: null };

		case GroupActionTypes.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				selectFetchAllGroupResponse: action.payload,
				selectFetchAllGroupArr: docodeResponse(action.payload, 'FetchAllGroupKey'),
			};

		case GroupActionTypes.FETCH_ALL_GROUP_FAILURE:
			return { ...state, selectFetchAllGroupResponse: action.payload };

		case GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED:
			return { ...state, selectFetchAllGroupResponse: null };

		case GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_SUCCESS:
			return {
				...state,
				selectFetchProductGroupResponse: action.payload,
				selectFetchProductGroupArr: docodeResponse(
					action.payload,
					'FetchProductGroupListKey',
				),
			};

		case GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_FAILURE:
			return { ...state, selectFetchProductGroupResponse: action.payload };

		case GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, selectFetchProductGroupResponse: null };

		case GroupActionTypes.FETCH_BRANCH_GROUP_LIST_SUCCESS:
			return {
				...state,
				selectFetchBranchGroupResponse: action.payload,
				selectFetchBranchGroupArr: docodeResponse(
					action.payload,
					'FetchBranchGroupListKey',
				),
			};

		case GroupActionTypes.FETCH_BRANCH_GROUP_LIST_FAILURE:
			return { ...state, selectFetchBranchGroupResponse: action.payload };

		case GroupActionTypes.FETCH_BRANCH_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, selectFetchBranchGroupResponse: null };

		case GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_SUCCESS:
			return {
				...state,
				selectFetchGroupMembersResponse: action.payload,
				selectFetchGroupMembersArr: docodeResponse(
					action.payload,
					'FetchGroupMembersInfoKey',
				),
			};

		case GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_FAILURE:
			return { ...state, selectFetchGroupMembersResponse: action.payload };

		case GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_RESPONSE_CHANGED:
			return { ...state, selectFetchGroupMembersResponse: null };

		case GroupActionTypes.UPDATE_MEMBERS_FOR_GROUP_CHIT_START:
			return { ...state, selectFetchGroupMembersArr: action.payload };

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_SUCCESS:
			return {
				...state,
				selectFetchAllApprovedMemberResponse: action.payload,
				selectFetchAllApprovedMemberArr: docodeResponse(
					action.payload,
					'GetBranchAllOfficerListKey',
				),
			};

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_FAILURE:
			return { ...state, selectFetchAllApprovedMemberResponse: action.payload };

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED:
			return { ...state, selectFetchAllApprovedMemberResponse: null };

		case GroupActionTypes.GET_ALL_OFFICER_SUCCESS:
			return {
				...state,
				selectAllOfficerResponse: action.payload,
				selectAllOfficerArr: docodeResponse(action.payload, 'GetAllOfficerKey'),
			};

		case GroupActionTypes.GET_ALL_OFFICER_FAILURE:
			return { ...state, selectAllOfficerResponse: action.payload };

		case GroupActionTypes.GET_ALL_OFFICER_RESPONSE_CHANGED:
			return { ...state, selectAllOfficerResponse: null };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_SUCCESS:
			return {
				...state,
				selectAllGroupDetailsResponse: action.payload,
				selectAllGroupDetailsArr: docodeResponse(action.payload, 'GetAllGroupDetailsKey'),
			};

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_FAILURE:
			return { ...state, selectAllGroupDetailsResponse: action.payload };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, selectAllGroupDetailsResponse: null };

		case GroupActionTypes.GET_BRANCH_OFFICER_SUCCESS:
			return {
				...state,
				selectBranchOfficerResponse: action.payload,
				selectBranchOfficerArr: docodeResponse(
					action.payload,
					'CENTEROFFICERLISTBASEDONCOMPANY',
				),
			};

		case GroupActionTypes.GET_BRANCH_OFFICER_FAILURE:
			return { ...state, selectBranchOfficerResponse: action.payload };

		case GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_CHANGED:
			return { ...state, selectBranchOfficerResponse: null };

		case GroupActionTypes.FETCH_GROUP_ID_START:
			return { ...state, fetchGroupID: action.payload };

		default:
			return state;
	}
};
