const applicantCollectionActionTypes = {
	ADD_APPLICANT_COLLECTION_START: 'ADD_APPLICANT_COLLECTION_START',
	ADD_APPLICANT_COLLECTION_SUCCESS: 'ADD_APPLICANT_COLLECTION_SUCCESS',
	ADD_APPLICANT_COLLECTION_FAILURE: 'ADD_APPLICANT_COLLECTION_FAILURE',
	ADD_APPLICANT_COLLECTION_RESPONSE_RESET: 'ADD_APPLICANT_COLLECTION_RESPONSE_RESET',
	ADD_APPLICANT_COLLECTION_RESPONSE_CHANGED: 'ADD_APPLICANT_COLLECTION_RESPONSE_CHANGED',

	GET_APPLICANT_COLLECTION_INFO_START: 'GET_APPLICANT_COLLECTION_INFO_START',
	GET_APPLICANT_COLLECTION_INFO_SUCCESS: 'GET_APPLICANT_COLLECTION_INFO_SUCCESS',
	GET_APPLICANT_COLLECTION_INFO_FAILURE: 'GET_APPLICANT_COLLECTION_INFO_FAILURE',
	GET_APPLICANT_COLLECTION_INFO_RESPONSE_RESET: 'GET_APPLICANT_COLLECTION_INFO_RESPONSE_RESET',
	GET_APPLICANT_COLLECTION_INFO_RESPONSE_CHANGED:
		'GET_APPLICANT_COLLECTION_INFO_RESPONSE_CHANGED',

	FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_START:
		'FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_START',
	FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_SUCCESS:
		'FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_SUCCESS',
	FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_FAILURE:
		'FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_FAILURE',
	FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_RESET:
		'FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_RESET',
	FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_CHANGED:
		'FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_CHANGED',

	GET_FIVE_HUNDRED: 'GET_FIVE_HUNDRED',
	GET_TWO_HUNDRED: 'GET_TWO_HUNDRED',
	GET_HUNDRED: 'GET_HUNDRED',
	GET_FIFTY: 'GET_FIFTY',
	GET_TWENTY: 'GET_TWENTY',
	GET_TEN: 'GET_TEN',
	GET_FIVE: 'GET_FIVE',
	GET_TWO: 'GET_TWO',
	GET_ONE: 'GET_ONE',
	GET_TOTAL_DENOMINATION: 'GET_TOTAL_DENOMINATION',
};
export default applicantCollectionActionTypes;
