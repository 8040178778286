/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChitMembersReportActionTypes from './chitMembersReport.types';
import { fetchChitMembersReport } from './chitMembersReport.service';

import {
	fetchChitMembersReportSuccess,
	fetchChitMembersReportFailure,
	fetchChitMembersReportResponseChanged,
} from './chitMembersReport.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH CHIT INDEX LIST  ******************** */
export function* fetchChitMembersReportDetails({ payload }) {
	try {
		const key = 'FetchChitMemberReportKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchChitMembersReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchChitMembersReportFailure(responseData.data));
		} else {
			yield put(fetchChitMembersReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchChitMembersReportFailure(error));
	}
}

export function* resetFetchChitMembersReportResponse() {
	yield put(fetchChitMembersReportResponseChanged());
}

export function* onFetchChitMembersReportDetails() {
	yield takeLatest(
		ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_START,
		fetchChitMembersReportDetails,
	);
}

export function* onFetchChitMembersReportResponseReset() {
	yield takeLatest(
		ChitMembersReportActionTypes.FETCH_CHIT_MEMBERS_REPORT_RESPONSE_RESET,
		resetFetchChitMembersReportResponse,
	);
}
/** *********************  END FETCH CHIT INDEX LIST ******************** */

export function* chitMembersReportSaga() {
	yield all([call(onFetchChitMembersReportDetails), call(onFetchChitMembersReportResponseReset)]);
}
