const DemandCollectionActionTypes = {
	FETCH_DEMAND_COLLECTION_LIST_START: 'FETCH_DEMAND_COLLECTION_LIST_START',
	FETCH_DEMAND_COLLECTION_LIST_SUCCESS: 'FETCH_DEMAND_COLLECTION_LIST_SUCCESS',
	FETCH_DEMAND_COLLECTION_LIST_FAILURE: 'FETCH_DEMAND_COLLECTION_LIST_FAILURE',
	FETCH_DEMAND_COLLECTION_LIST_RESPONSE_RESET: 'FETCH_DEMAND_COLLECTION_LIST_RESPONSE_RESET',
	FETCH_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED: 'FETCH_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED',
	FETCH_DEMAND_COLLECTION_LIST_ARR_UPDATED_CHANGED:
		'FETCH_DEMAND_COLLECTION_LIST_ARR_UPDATED_CHANGED',

	TO_UPDATE_DEMAND_COLLECTION_DETAILS_START: 'TO_UPDATE_DEMAND_COLLECTION_DETAILS_START',
	TO_UPDATE_DEMAND_COLLECTION_DETAILS_SUCCESS: 'TO_UPDATE_DEMAND_COLLECTION_DETAILS_SUCCESS',
	TO_UPDATE_DEMAND_COLLECTION_DETAILS_FAILURE: 'TO_UPDATE_DEMAND_COLLECTION_DETAILS_FAILURE',
	TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_RESET:
		'TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_RESET',
	TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_CHANGED:
		'TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_CHANGED',
	TO_UPDATE_DEMAND_COLLECTION_DETAILS_ARR_UPDATED_CHANGED:
		'TO_UPDATE_DEMAND_COLLECTION_DETAILS_ARR_UPDATED_CHANGED',
};
export default DemandCollectionActionTypes;
