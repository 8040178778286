/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import GeneralActionTypes from './general.types';
import {
	getAllCountriesList,
	getAllStateList,
	getAllCityList,
	getAllPincodeList,
	getDistrictList,
	allBranchesFetch,
	allMembersFetch,
	allProductTypeFetch,
	allBranchOfficerFetch,
	allCenterFetch,
	fetchallGroup,
	fetchGroupCenterFetch,
	fetchGroupMembersFetch,
} from './general.service';
import {
	getCountryListSuccess,
	getCountryListFailure,
	getCountryListResponseChanged,
	getStateListSuccess,
	getStateListFailure,
	getStateListResponseChanged,
	getCityListSuccess,
	getCityListFailure,
	getCityListResponseChanged,
	getPermanentCityListSuccess,
	getPermanentCityListFailure,
	getPermanentCityListResponseChanged,
	getDistrictListSuccess,
	getDistrictListFailure,
	getDistrictListResponseChanged,
	getPermanentDistrictListSuccess,
	getPermanentDistrictListFailure,
	getPermanentDistrictListResponseChanged,
	fetchAllBranchesSuccess,
	fetchAllBranchesFailure,
	fetchAllBranchesResponseChanged,
	fetchAllMembersSuccess,
	fetchAllMembersFailure,
	fetchAllMembersResponseChanged,
	fetchAllProductTypeSuccess,
	fetchAllProductTypeFailure,
	fetchAllProductTypeResponseChanged,
	fetchAllBranchOfficerSuccess,
	fetchAllBranchOfficerFailure,
	fetchAllBranchOfficerResponseChanged,
	fetchAllCenterListSuccess,
	fetchAllCenterListFailure,
	fetchAllCenterListResponseChanged,
	fetchCenterGroupListSuccess,
	fetchCenterGroupListFailure,
	fetchCenterGroupListResponseChanged,
	fetchGroupMemberListSuccess,
	fetchGroupMemberListFailure,
	fetchGroupMemberListResponseChanged,
	fetchAllGroupFailure,
	fetchAllGroupSuccess,
	fetchAllGroupResponseReset,
	getPinCodeListFailure,
	getPinCodeListSuccess,
	getPinCodeListResponseChanged,
} from './general.action';
import { encodeJson } from '../enode-decode';

/** *********************  START COUNTRY LIST  ******************** */
export function* getCountryList() {
	try {
		const jsonData = {
			CountryID: '',
		};
		const countryData = encodeJson(jsonData, 'GetCountryListKey');
		const responseData = yield call(getAllCountriesList, countryData);
		if (responseData.data.statusCode === '500') {
			yield put(getCountryListFailure(responseData.data));
		} else {
			yield put(getCountryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCountryListFailure(error));
	}
}

export function* resetCountryList() {
	yield put(getCountryListResponseChanged());
}

export function* onGetCountryList() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_START, getCountryList);
}

export function* onResetCountryResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_RESET, resetCountryList);
}
/** *********************  END COUNTRY LIST  ******************** */

/** *********************  START STATE LIST  ******************** */
export function* getStateList({ payload }) {
	try {
		const jsonData = {
			CountryID: payload.countryID,
		};
		const stateData = encodeJson(jsonData, 'CountryStateListGetKey');
		const responseData = yield call(getAllStateList, stateData);
		if (responseData.data.statusCode === '500') {
			yield put(getStateListFailure(responseData.data));
		} else {
			yield put(getStateListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStateListFailure(error));
	}
}

export function* resetStateList() {
	yield put(getStateListResponseChanged());
}

export function* onGetStateList() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_START, getStateList);
}

export function* onResetStateResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_RESPONSE_RESET, resetStateList);
}
/** *********************  END STATE LIST  ******************** */

/** *********************  START CITY LIST  ******************** */
export function* getCityList({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		const responseData = yield call(getAllCityList, cityData);
		if (responseData.data.statusCode === '500') {
			yield put(getCityListFailure(responseData.data));
		} else {
			yield put(getCityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCityListFailure(error));
	}
}

export function* resetCityList() {
	yield put(getCityListResponseChanged());
}

export function* onGetCityList() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_START, getCityList);
}

export function* onResetCityResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_RESPONSE_RESET, resetCityList);
}
/** *********************  END CITY LIST  ******************** */

/** *********************  START PERMANENT CITY LIST  ******************** */
export function* getPermanentCityList({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		const responseData = yield call(getAllCityList, cityData);
		if (responseData.data.statusCode === '500') {
			yield put(getPermanentCityListFailure(responseData.data));
		} else {
			yield put(getPermanentCityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPermanentCityListFailure(error));
	}
}

export function* resetPermanentCityList() {
	yield put(getPermanentCityListResponseChanged());
}

export function* onGetPermanentCityList() {
	yield takeLatest(GeneralActionTypes.GET_PERMANENT_CITY_LIST_START, getPermanentCityList);
}

export function* onResetPermanentCityResponseStart() {
	yield takeLatest(
		GeneralActionTypes.GET_PERMANENT_CITY_LIST_RESPONSE_RESET,
		resetPermanentCityList,
	);
}
/** *********************  END PERMANENT CITY LIST  ******************** */

/** *********************  START DISTRICT LIST  ******************** */
export function* districtListGet({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const districtData = encodeJson(jsonData, 'DistrictCityListGetKey');
		const responseData = yield call(getDistrictList, districtData);
		if (responseData.data.statusCode === '500') {
			yield put(getDistrictListFailure(responseData.data));
		} else {
			yield put(getDistrictListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDistrictListFailure(error));
	}
}

export function* resetDistrictList() {
	yield put(getDistrictListResponseChanged());
}

export function* onGetDistrictList() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_START, districtListGet);
}

export function* onResetDistrictResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_RESET, resetDistrictList);
}
/** *********************  END DISTRICT LIST  ******************** */

/** *********************  START PERMANENT DISTRICT LIST  ******************** */
export function* permanentDistrictListGet({ payload }) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const districtData = encodeJson(jsonData, 'DistrictCityListGetKey');
		const responseData = yield call(getDistrictList, districtData);
		if (responseData.data.statusCode === '500') {
			yield put(getPermanentDistrictListFailure(responseData.data));
		} else {
			yield put(getPermanentDistrictListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPermanentDistrictListFailure(error));
	}
}

export function* resetPermanentDistrictList() {
	yield put(getPermanentDistrictListResponseChanged());
}

export function* onGetPermanentDistrictList() {
	yield takeLatest(
		GeneralActionTypes.GET_PERMANENT_DISTRICT_LIST_START,
		permanentDistrictListGet,
	);
}

export function* onResetPermanentDistrictResponseStart() {
	yield takeLatest(
		GeneralActionTypes.GET_PERMANENT_DISTRICT_LIST_RESPONSE_RESET,
		resetPermanentDistrictList,
	);
}
/** *********************  END PERMANENT DISTRICT LIST  ******************** */

/** *********************  START PINCODE LIST  ******************** */
export function* getPincodeList({ payload }) {
	try {
		// const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		const responseData = yield call(getAllPincodeList, payload);
		if (responseData.data.statusCode === '500') {
			yield put(getPinCodeListFailure(responseData.data[0]));
		} else {
			yield put(getPinCodeListSuccess(responseData.data[0]));
		}
	} catch (error) {
		yield put(getCityListFailure(error));
	}
}

export function* resetPincodeList() {
	yield put(getPinCodeListResponseChanged());
}

export function* onGetPincodeList() {
	yield takeLatest(GeneralActionTypes.GET_PINCODE_LIST_START, getPincodeList);
}

export function* onResetPincodeResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_RESET, resetPincodeList);
}
/** *********************  END PINCODE LIST  ******************** */

/** *********************  START FETCH ALL BRANCHES LIST  ******************** */
export function* fetchAllBranchesList({ payload }) {
	try {
		const key = 'FetchAllBranchListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allBranchesFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchesFailure(responseData.data));
		} else {
			yield put(fetchAllBranchesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchesFailure(error));
	}
}

export function* resetFetchAllBranchesListResponse() {
	yield put(fetchAllBranchesResponseChanged());
}

export function* onFetchAllBranchesListDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCHES_START, fetchAllBranchesList);
}

export function* onFetchAllBranchesResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_RESET_START,
		resetFetchAllBranchesListResponse,
	);
}
/** *********************  END FETCH ALL BRANCHES LIST ******************** */

/** *********************  START FETCH ALL MEMBERS LIST  ******************** */
export function* fetchAllMembers({ payload }) {
	try {
		const key = 'FetchAllMembersKey';
		const jsonData = {
			IsApprove: payload.isApprove,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allMembersFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllMembersFailure(responseData.data));
		} else {
			yield put(fetchAllMembersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllMembersFailure(error));
	}
}

export function* resetFetchAllMembersResponse() {
	yield put(fetchAllMembersResponseChanged());
}

export function* onFetchAllMembersDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_MEMBERS_START, fetchAllMembers);
}

export function* onFetchAllMembersResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_MEMBERS_RESPONSE_RESET_START,
		resetFetchAllMembersResponse,
	);
}
/** *********************  END FETCH ALL MEMBERS LIST ******************** */

/** *********************  START FETCH ALL PRODUCT TYPE LIST  ******************** */
export function* fetchAllProductTypesList({ payload }) {
	try {
		const key = 'FetchAllProductTypesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allProductTypeFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductTypeFailure(responseData.data));
		} else {
			yield put(fetchAllProductTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductTypeFailure(error));
	}
}

export function* resetFetchAllProductTypesResponse() {
	yield put(fetchAllProductTypeResponseChanged());
}

export function* onFetchAllProductTypesDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_START, fetchAllProductTypesList);
}

export function* onFetchAllProductTypesResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_RESET,
		resetFetchAllProductTypesResponse,
	);
}
/** *********************  END FETCH ALL PRODUCT TYPE LIST ******************** */

/** *********************  START FETCH STAFF BASED ON BRANCH LIST  ******************** */
export function* fetchAllBranchOfficerList({ payload }) {
	try {
		const key = 'GetAllBranchOfficerList';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allBranchOfficerFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchOfficerFailure(responseData.data));
		} else {
			yield put(fetchAllBranchOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchOfficerFailure(error));
	}
}

export function* resetFetchAllBranchOfficerResponse() {
	yield put(fetchAllBranchOfficerResponseChanged());
}

export function* onFetchAllBranchOfficerDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_START, fetchAllBranchOfficerList);
}

export function* onFetchAllBranchOfficerResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_RESET_START,
		resetFetchAllBranchOfficerResponse,
	);
}
/** *********************  END STAFF BASED ON BRANCH LIST ******************** */

/** *********************  CENTER FETCH BASED ON BRANCH LIST  ******************** */

export function* fetchAllCenterList({ payload }) {
	try {
		const key = 'GetAllCentersList';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allCenterFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllCenterListFailure(responseData.data));
		} else {
			yield put(fetchAllCenterListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllCenterListFailure(error));
	}
}

export function* resetFetchAllCenterResponse() {
	yield put(fetchAllCenterListResponseChanged());
}

export function* onFetchAllCenterDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_CENTERS_START, fetchAllCenterList);
}

export function* onFetchAllCenterResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_RESET_START,
		resetFetchAllCenterResponse,
	);
}
/** *********************  END CENTER FETCH BASED ON BRANCH LIST ******************** */

/** *********************  GROUP FETCH BASED ON CENTER LIST  ******************** */
export function* fetchCenterGroupList({ payload }) {
	try {
		const key = 'FetchAllGroupListKey';
		const jsonData = {
			LocationID: payload.locationID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupCenterFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterGroupListFailure(responseData.data));
		} else {
			yield put(fetchCenterGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterGroupListFailure(error));
	}
}

export function* resetFetchCenterGroupResponse() {
	yield put(fetchCenterGroupListResponseChanged());
}

export function* onFetchCenterGroupDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_CENTER_GROUPS_START, fetchCenterGroupList);
}

export function* onFetchCenterGroupResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_RESET_START,
		resetFetchCenterGroupResponse,
	);
}
/** *********************  END GROUP FETCH BASED ON CENTER LIST ******************** */

/** *********************  MEMBER FETCH BASED ON GROUP LIST  ******************** */
export function* fetchGroupMemberList({ payload }) {
	try {
		const key = 'FetchGroupMembersListKey';
		const jsonData = {
			BranchID: payload.locationID,
			GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupMembersFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupMemberListFailure(responseData.data));
		} else {
			yield put(fetchGroupMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupMemberListFailure(error));
	}
}

export function* resetFetchGroupMemberResponse() {
	yield put(fetchGroupMemberListResponseChanged());
}

export function* onFetchGroupMemberDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_GROUP_MEMBERS_START, fetchGroupMemberList);
}

export function* onFetchGroupMemberResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_GROUP_MEMBERS_RESPONSE_RESET_START,
		resetFetchCenterGroupResponse,
	);
}
/** *********************  END GROUP FETCH BASED ON CENTER LIST ******************** */

/** *********************  START FETCH All GROUP  ******************** */
export function* fetchAllGroupData({ payload }) {
	try {
		const key = 'GetAllGroupsListkey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			LocationID: payload.centerID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchallGroup, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllGroupFailure(responseData.data));
		} else {
			yield put(fetchAllGroupSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllGroupFailure(error));
	}
}

export function* onFetchAllGroupDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_GROUP_START, fetchAllGroupData);
}

export function* onFetchAllGroupResponseReset() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET, fetchAllGroupResponseReset);
}

/** *********************  END FETCH All GROUP  ******************** */

export function* generalSaga() {
	yield all([
		call(onGetCountryList),
		call(onResetCountryResponseStart),
		call(onGetStateList),
		call(onResetStateResponseStart),
		call(onGetCityList),
		call(onResetCityResponseStart),
		call(onGetPermanentCityList),
		call(onResetPermanentCityResponseStart),
		call(onGetPincodeList),
		call(onResetPincodeResponseStart),
		call(onGetDistrictList),
		call(onResetDistrictResponseStart),
		call(onGetPermanentDistrictList),
		call(onResetPermanentDistrictResponseStart),
		call(onFetchAllBranchesListDetails),
		call(onFetchAllBranchesResponseReset),
		call(onFetchAllMembersDetails),
		call(onFetchAllMembersResponseReset),
		call(onFetchAllProductTypesDetails),
		call(onFetchAllProductTypesResponseReset),
		call(onFetchAllBranchOfficerDetails),
		call(onFetchAllBranchOfficerResponseReset),
		call(onFetchAllCenterDetails),
		call(onFetchAllCenterResponseReset),
		call(onFetchAllGroupResponseReset),
		call(onFetchAllGroupDetails),
		call(onFetchCenterGroupDetails),
		call(onFetchGroupMemberDetails),
	]);
}
