/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchSearchMemberChitList = (listData) => {
	const data = {
		FetchMemberChitListToken: listData,
	};
	const url = `${API_URL}FetchMemberChitListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchMemberParticularChitInfo = (listData) => {
	const data = {
		FetchMemberParticularChitInfoToken: listData,
	};
	const url = `${API_URL}FetchMemberParticularChitInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const postChitCollectionDetails = (listData) => {
	const data = {
		ToUpdateChitCollectionDetailsToken: listData,
	};
	const url = `${API_URL}PostChitCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
