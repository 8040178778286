/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import DashboardActionTypes from './dashboard.types';
import { dashboardDetailsFetch, oneWeekDemandDetailsFetch } from './dashboard.service';

import {
	fetchDashboardDetailsSuccess,
	fetchDashboardDetailsFailure,
	fetchDashboardDetailsResponseChanged,
	fetchOneWeekDemandDetailsSuccess,
	fetchOneWeekDemandDetailsFailure,
	fetchOneWeekDemandDetailsResponseChanged,
} from './dashboard.action';
import { encodeJson } from '../enode-decode';

/** *********************  START FETCH DASHBOARD DETAILS  ******************** */
export function* fetchDashboardData({ payload }) {
	try {
		const key = 'FetchDashboardInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchDashboardDetailsFailure(responseData.data));
		} else {
			yield put(fetchDashboardDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchDashboardDetailsFailure(error));
	}
}

export function* resetFetchDashboardDetailsResponse() {
	yield put(fetchDashboardDetailsResponseChanged());
}

export function* onFetchDashboardDetails() {
	yield takeLatest(DashboardActionTypes.FETCH_DASHBOARD_DETAILS_START, fetchDashboardData);
}

export function* onFetchDashboardResponseReset() {
	yield takeLatest(
		DashboardActionTypes.FETCH_DASHBOARD_DETAILS_RESPONSE_RESET,
		resetFetchDashboardDetailsResponse,
	);
}
/** *********************  END FETCH DASHBOARD DETAILS ******************** */

/** *********************  START FETCH ONE WEEK DEMAND DETAILS  ******************** */
export function* fetchOneWeekDemandData({ payload }) {
	try {
		const key = 'FetchOneWeekDemandInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			SetWeekArray: payload.setWeekArray,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(oneWeekDemandDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchOneWeekDemandDetailsFailure(responseData.data));
		} else {
			yield put(fetchOneWeekDemandDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchOneWeekDemandDetailsFailure(error));
	}
}

export function* resetFetchOneWeekDemandDetailsResponse() {
	yield put(fetchOneWeekDemandDetailsResponseChanged());
}

export function* onFetchOneWeekDemandDetails() {
	yield takeLatest(
		DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_START,
		fetchOneWeekDemandData,
	);
}

export function* onFetchOneWeekDemandResponseReset() {
	yield takeLatest(
		DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_RESET,
		resetFetchOneWeekDemandDetailsResponse,
	);
}
/** *********************  END FETCH ONE WEEK DEMAND DETAILS ******************** */

export function* dashboardSaga() {
	yield all([
		call(onFetchDashboardDetails),
		call(onFetchDashboardResponseReset),
		call(onFetchOneWeekDemandDetails),
		call(onFetchOneWeekDemandResponseReset),
	]);
}
