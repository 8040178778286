/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import MemberActionTypes from './member.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	tabChangeValue: 'Personal Details',
	isShowOffCanvas: false,
	isShowAdvanceSearchOffCanvas: false,
	isMemberEdit: false,
	getMemberType: 'Personal',
	getMemberID: '',
	getMemberMaritalStatus: '',

	addMemberDetailsResponse: null,
	addMemberDetailsResponseArr: [],

	addMemberSecurityDetailsResponse: null,
	addMemberSecurityDetailsResponseArr: [],

	addMemberProofDetailsResponse: null,
	addMemberProofDetailsResponseArr: [],

	addMemberAddressDetailsResponse: null,
	addMemberAddressDetailsResponseArr: [],

	addMemberEnrollmentDetailsResponse: null,
	addMemberEnrollmentDetailsResponseArr: [],

	addMemberChitRequestDetailsResponse: null,
	addMemberChitRequestDetailsResponseArr: [],

	updateMemberDetailsResponse: null,
	updateMemberAddressDetailsResponse: null,
	updateMemberBankAccountDetailsResponse: null,
	updateMemberProofDetailsResponse: null,
	updateMemberSecurityDetailsResponse: null,
	updateMemberOfficialDetailsResponse: null,
	updateMemberEnrollmentDetailsResponse: null,
	updateMemberChitRequestDetailsResponse: null,
	updateMemberIsApproveDetailsResponse: null,

	deleteMemberDetailsResponse: null,

	selectGetMemberListResponse: null,
	selectGetMemberListArr: [],

	fetchSearchMemberListResponse: null,
	fetchSearchMemberListArr: [],

	fetchAdvanceSearchMemberListResponse: null,
	fetchAdvanceSearchMemberListArr: [],

	selectGetMemberInfoResponse: null,
	selectGetMemberInfoArr: [],

	fetchMemberEnrollmentDetailsResponse: null,
	fetchMemberEnrollmentDetailsResponseArr: [],

	fetchMemberEnrollmentListResponse: null,
	fetchMemberEnrollmentListResponseArr: [],

	fetchMemberChitRequestDetailsResponse: null,
	fetchMemberChitRequestDetailsResponseArr: [],

	fetchMemberSecurityListResponse: null,
	fetchMemberSecurityListResponseArr: [],

	fetchMemberAddressListResponse: null,
	fetchMemberAddressListResponseArr: [],

	fetchMemberAddressInfoResponse: null,
	fetchMemberAddressInfoResponseArr: [],

	fetchProductEnrollmentMembersListResponse: null,
	fetchProductEnrollmentMembersListResponseArr: [],

	fetchCenterEnrollmentMembersListResponse: null,
	fetchCenterEnrollmentMembersListResponseArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberActionTypes.ADD_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				addMemberDetailsResponse: action.payload,
				addMemberDetailsResponseArr: docodeResponse(action.payload, 'AddMemberDetailsKey'),
			};

		case MemberActionTypes.ADD_MEMBER_DETAILS_FAILURE:
			return { ...state, addMemberDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberDetailsResponse: null };

		case MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_SUCCESS:
			return {
				...state,
				addMemberSecurityDetailsResponse: action.payload,
				addMemberSecurityDetailsResponseArr: docodeResponse(
					action.payload,
					'AddMemberSecurityDetailsKey',
				),
			};

		case MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_FAILURE:
			return { ...state, addMemberSecurityDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberSecurityDetailsResponse: null };

		case MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_SUCCESS:
			return {
				...state,
				addMemberProofDetailsResponse: action.payload,
				addMemberProofDetailsResponseArr: docodeResponse(
					action.payload,
					'AddMemberProofDetailsKey',
				),
			};

		case MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_FAILURE:
			return { ...state, addMemberProofDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberProofDetailsResponse: null };

		case MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_SUCCESS:
			return {
				...state,
				addMemberAddressDetailsResponse: action.payload,
				addMemberAddressDetailsResponseArr: docodeResponse(
					action.payload,
					'AddMemberAddressDetailsKey',
				),
			};

		case MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_FAILURE:
			return { ...state, addMemberAddressDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberAddressDetailsResponse: null };

		case MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_SUCCESS:
			return {
				...state,
				addMemberEnrollmentDetailsResponse: action.payload,
				addMemberEnrollmentDetailsResponseArr: docodeResponse(
					action.payload,
					'AddMemberEnrollmentDetailsKey',
				),
			};

		case MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_FAILURE:
			return { ...state, addMemberEnrollmentDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberEnrollmentDetailsResponse: null };

		case MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS:
			return {
				...state,
				addMemberChitRequestDetailsResponse: action.payload,
				addMemberChitRequestDetailsResponseArr: docodeResponse(
					action.payload,
					'AddMemberChitRequestDetailsKey',
				),
			};

		case MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_FAILURE:
			return { ...state, addMemberChitRequestDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberChitRequestDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_SUCCESS:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_FAILURE:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_SUCCESS:
			return { ...state, updateMemberAddressDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_FAILURE:
			return { ...state, updateMemberAddressDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberAddressDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_SUCCESS:
			return { ...state, updateMemberBankAccountDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_FAILURE:
			return { ...state, updateMemberBankAccountDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberBankAccountDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_SUCCESS:
			return { ...state, updateMemberProofDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_FAILURE:
			return { ...state, updateMemberProofDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberProofDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_SUCCESS:
			return { ...state, updateMemberSecurityDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_FAILURE:
			return { ...state, updateMemberSecurityDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberSecurityDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_SUCCESS:
			return { ...state, updateMemberOfficialDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_FAILURE:
			return { ...state, updateMemberOfficialDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberOfficialDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_SUCCESS:
			return { ...state, updateMemberEnrollmentDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_FAILURE:
			return { ...state, updateMemberEnrollmentDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberEnrollmentDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS:
			return { ...state, updateMemberChitRequestDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_FAILURE:
			return { ...state, updateMemberChitRequestDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberChitRequestDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_SUCCESS:
			return { ...state, updateMemberIsApproveDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_FAILURE:
			return { ...state, updateMemberIsApproveDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberIsApproveDetailsResponse: null };

		case MemberActionTypes.GET_MEMBER_LIST_SUCCESS:
			return {
				...state,
				selectGetMemberListResponse: action.payload,
				selectGetMemberListArr: docodeResponse(action.payload, 'FetchMemberListKey'),
			};

		case MemberActionTypes.GET_MEMBER_LIST_FAILURE:
			return { ...state, selectGetMemberListResponse: action.payload };

		case MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, selectGetMemberListResponse: null };

		case MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CLEAR:
			return { ...state, selectGetMemberListArr: [] };

		case MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_SUCCESS:
			return {
				...state,
				fetchSearchMemberListResponse: action.payload,
				fetchSearchMemberListArr: docodeResponse(
					action.payload,
					'FetchSearchMemberListKey',
				),
			};

		case MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_FAILURE:
			return { ...state, fetchSearchMemberListResponse: action.payload };

		case MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, fetchSearchMemberListResponse: null };

		case MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_CLEAR:
			return { ...state, fetchSearchMemberListArr: [] };

		case MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_SUCCESS:
			return {
				...state,
				fetchAdvanceSearchMemberListResponse: action.payload,
				fetchAdvanceSearchMemberListArr: docodeResponse(
					action.payload,
					'FetchAdvanceSearchMemberListKey',
				),
			};

		case MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_FAILURE:
			return { ...state, fetchAdvanceSearchMemberListResponse: action.payload };

		case MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, fetchAdvanceSearchMemberListResponse: null };

		case MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CLEAR:
			return { ...state, fetchAdvanceSearchMemberListArr: [] };

		case MemberActionTypes.GET_MEMBER_INFO_SUCCESS:
			return {
				...state,
				selectGetMemberInfoResponse: action.payload,
				selectGetMemberInfoArr: docodeResponse(action.payload, 'FetchMemberInfoKey'),
			};

		case MemberActionTypes.GET_MEMBER_INFO_FAILURE:
			return { ...state, selectGetMemberInfoResponse: action.payload };

		case MemberActionTypes.GET_MEMBER_INFO_RESPONSE_CHANGED:
			return { ...state, selectGetMemberInfoResponse: null };

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberEnrollmentDetailsResponse: action.payload,
				fetchMemberEnrollmentDetailsResponseArr: docodeResponse(
					action.payload,
					'FetchMemberEnrollmentInfoKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_FAILURE:
			return { ...state, fetchMemberEnrollmentDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberEnrollmentDetailsResponse: null };

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_SUCCESS:
			return {
				...state,
				fetchMemberEnrollmentListResponse: action.payload,
				fetchMemberEnrollmentListResponseArr: docodeResponse(
					action.payload,
					'FetchMemberEnrollmentListKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_FAILURE:
			return { ...state, fetchMemberEnrollmentListResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_CHANGED:
			return { ...state, fetchMemberEnrollmentListResponse: null };

		case MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberChitRequestDetailsResponse: action.payload,
				fetchMemberChitRequestDetailsResponseArr: docodeResponse(
					action.payload,
					'FetchMemberChitRequestInfoKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_FAILURE:
			return { ...state, fetchMemberChitRequestDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberChitRequestDetailsResponse: null };

		case MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_SUCCESS:
			return {
				...state,
				fetchMemberSecurityListResponse: action.payload,
				fetchMemberSecurityListResponseArr: docodeResponse(
					action.payload,
					'FetchMemberSecurityInfoListKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_FAILURE:
			return { ...state, fetchMemberSecurityListResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_RESPONSE_CHANGED:
			return { ...state, fetchMemberSecurityListResponse: null };

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_SUCCESS:
			return {
				...state,
				fetchMemberAddressListResponse: action.payload,
				fetchMemberAddressListResponseArr: docodeResponse(
					action.payload,
					'FetchMemberAddressListKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_FAILURE:
			return { ...state, fetchMemberAddressListResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_RESPONSE_CHANGED:
			return { ...state, fetchMemberAddressListResponse: null };

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_SUCCESS:
			return {
				...state,
				fetchMemberAddressInfoResponse: action.payload,
				fetchMemberAddressInfoResponseArr: docodeResponse(
					action.payload,
					'FetchMemberAddressInfoKey',
				),
			};

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_FAILURE:
			return { ...state, fetchMemberAddressInfoResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_RESPONSE_CHANGED:
			return { ...state, fetchMemberAddressInfoResponse: null };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_SUCCESS:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_FAILURE:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteMemberDetailsResponse: null };

		case MemberActionTypes.MEMBER_ID:
			return { ...state, getMemberID: action.payload };

		case MemberActionTypes.MEMBER_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, tabChangeValue: action.payload };

		case MemberActionTypes.MEMBER_IS_OPEN_OFFCANVAS:
			return { ...state, isShowOffCanvas: action.payload };

		case MemberActionTypes.MEMBER_IS_OPEN_ADVANCE_SEARCH_OFFCANVAS:
			return { ...state, isShowAdvanceSearchOffCanvas: action.payload };

		case MemberActionTypes.IS_EDIT_MEMBER_DETAILS:
			return { ...state, isMemberEdit: action.payload };

		case MemberActionTypes.MEMBER_TYPE:
			return { ...state, getMemberType: action.payload };

		case MemberActionTypes.MEMBER_MARITAL_STATUS:
			return { ...state, getMemberMaritalStatus: action.payload };

		case MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_SUCCESS:
			return {
				...state,
				fetchProductEnrollmentMembersListResponse: action.payload,
				fetchProductEnrollmentMembersListResponseArr: docodeResponse(
					action.payload,
					'FetchProductEnrollmentMembersKey',
				),
			};

		case MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_FAILURE:
			return { ...state, fetchProductEnrollmentMembersListResponse: action.payload };

		case MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED:
			return { ...state, fetchProductEnrollmentMembersListResponse: null };

		case MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED:
			return { ...state, fetchProductEnrollmentMembersListResponseArr: action.payload };

		case MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED:
			return { ...state, fetchProductEnrollmentMembersListResponseArr: [] };

		case MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_SUCCESS:
			return {
				...state,
				fetchCenterEnrollmentMembersListResponse: action.payload,
				fetchCenterEnrollmentMembersListResponseArr: docodeResponse(
					action.payload,
					'FetchCenterEnrollMembersListKey',
				),
			};

		case MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_FAILURE:
			return { ...state, fetchCenterEnrollmentMembersListResponse: action.payload };

		case MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED:
			return { ...state, fetchCenterEnrollmentMembersListResponse: null };

		case MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED:
			return { ...state, fetchCenterEnrollmentMembersListResponseArr: action.payload };

		case MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED:
			return { ...state, fetchCenterEnrollmentMembersListResponseArr: [] };

		default:
			return state;
	}
};
