/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */
import MemberActionTypes from './member.types';

/** ***************** ADD MEMBER DETAILS START *********************** */
export const addMemberDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_START,
	payload: addData,
});

export const addMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_RESET,
});

export const addMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER DETAILS END *********************** */

/** ***************** ADD MEMBER SECURITY DETAILS START *********************** */
export const addMemberSecurityDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_START,
	payload: addData,
});

export const addMemberSecurityDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberSecurityDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberSecurityDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_RESPONSE_RESET,
});

export const addMemberSecurityDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER SECURITY DETAILS END *********************** */

/** ***************** ADD MEMBER PROOF DETAILS START *********************** */
export const addMemberProofDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_START,
	payload: addData,
});

export const addMemberProofDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberProofDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberProofDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_RESPONSE_RESET,
});

export const addMemberProofDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER PROOF DETAILS END *********************** */

/** ***************** ADD MEMBER ADDRESS DETAILS START *********************** */
export const addMemberAddressDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_START,
	payload: addData,
});

export const addMemberAddressDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberAddressDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberAddressDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET,
});

export const addMemberAddressDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER ADDRESS DETAILS END *********************** */

/** ***************** ADD MEMBER ENROLLMENT DETAILS START *********************** */
export const addMemberEnrollmentDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_START,
	payload: addData,
});

export const addMemberEnrollmentDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberEnrollmentDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberEnrollmentDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
});

export const addMemberEnrollmentDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER ENROLLMENT DETAILS END *********************** */

/** ***************** ADD MEMBER CHIT REQUEST DETAILS START *********************** */
export const addMemberChitRequestDetailsStart = (addData) => ({
	type: MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_START,
	payload: addData,
});

export const addMemberChitRequestDetailsSuccess = (successData) => ({
	type: MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS,
	payload: successData,
});

export const addMemberChitRequestDetailsFailure = (failureData) => ({
	type: MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_FAILURE,
	payload: failureData,
});

export const addMemberChitRequestDetailsResponseResetStart = () => ({
	type: MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
});

export const addMemberChitRequestDetailsResponseChanged = () => ({
	type: MemberActionTypes.ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MEMBER CHIT REQUEST DETAILS END *********************** */

/** ***************** UPDATE MEMBER DETAILS START *********************** */
export const updateMemberDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_START,
	payload: addData,
});

export const updateMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_RESET,
});

export const updateMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER DETAILS END *********************** */

/** ***************** UPDATE MEMBER ADDRESS DETAILS START *********************** */
export const updateMemberAddressDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_START,
	payload: addData,
});

export const updateMemberAddressDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberAddressDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberAddressDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET,
});

export const updateMemberAddressDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER ADDRESS DETAILS END *********************** */

/** ***************** UPDATE MEMBER BANK ACCOUNT DETAILS START *********************** */
export const updateMemberBankAccountDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_START,
	payload: addData,
});

export const updateMemberBankAccountDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberBankAccountDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberBankAccountDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
});

export const updateMemberBankAccountDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER BANK ACCOUNT DETAILS END *********************** */

/** ***************** UPDATE MEMBER PROOF DETAILS START *********************** */
export const updateMemberProofDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_START,
	payload: addData,
});

export const updateMemberProofDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberProofDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberProofDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_RESET,
});

export const updateMemberProofDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER PROOF DETAILS END *********************** */

/** ***************** UPDATE MEMBER SECURITY DETAILS START *********************** */
export const updateMemberSecurityDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_START,
	payload: addData,
});

export const updateMemberSecurityDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberSecurityDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberSecurityDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_RESET,
});

export const updateMemberSecurityDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER SECURITY DETAILS END *********************** */

/** ***************** UPDATE MEMBER OFFICIAL DETAILS START *********************** */
export const updateMemberOfficialDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_START,
	payload: addData,
});

export const updateMemberOfficialDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberOfficialDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberOfficialDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_RESET,
});

export const updateMemberOfficialDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER OFFICIAL DETAILS END *********************** */

/** ***************** UPDATE MEMBER ENROLLMENT DETAILS START *********************** */
export const updateMemberEnrollmentDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_START,
	payload: addData,
});

export const updateMemberEnrollmentDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberEnrollmentDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberEnrollmentDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
});

export const updateMemberEnrollmentDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER ENROLLMENT DETAILS END *********************** */

/** ***************** UPDATE MEMBER CHIT REQUEST DETAILS START *********************** */
export const updateMemberChitRequestDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_START,
	payload: addData,
});

export const updateMemberChitRequestDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberChitRequestDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberChitRequestDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
});

export const updateMemberChitRequestDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER ENROLLMENT DETAILS END *********************** */

/** ***************** UPDATE MEMBER ISAPPROVE DETAILS START *********************** */
export const updateMemberIsApproveDetailsStart = (addData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_START,
	payload: addData,
});

export const updateMemberIsApproveDetailsSuccess = (successData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_SUCCESS,
	payload: successData,
});

export const updateMemberIsApproveDetailsFailure = (failureData) => ({
	type: MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_FAILURE,
	payload: failureData,
});

export const updateMemberIsApproveDetailsResponseResetStart = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_RESET,
});

export const updateMemberIsApproveDetailsResponseChanged = () => ({
	type: MemberActionTypes.UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE MEMBER ISAPPROVE DETAILS END *********************** */

/** ***************** FETCH MEMBER LIST START *********************** */
export const getMemberListStart = (getData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_START,
	payload: getData,
});

export const getMemberListSuccess = (successData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const getMemberListFailure = (failureData) => ({
	type: MemberActionTypes.GET_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const getMemberListResponseResetStart = () => ({
	type: MemberActionTypes.GET_MEMBER_LIST_RESPONSE_RESET,
});

export const getMemberListResponseChanged = () => ({
	type: MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CHANGED,
});

export const getMemberListResponseClear = () => ({
	type: MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CLEAR,
});
/** ***************** FETCH MEMBER LIST END *********************** */

/** ***************** FETCH SEARCH MEMBER LIST START *********************** */
export const fetchSearchMemberListStart = (getData) => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_START,
	payload: getData,
});

export const fetchSearchMemberListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const fetchSearchMemberListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchMemberListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_RESET,
});

export const fetchSearchMemberListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_CHANGED,
});

export const fetchSearchMemberListResponseClear = () => ({
	type: MemberActionTypes.FETCH_SEARCH_MEMBER_LIST_RESPONSE_CLEAR,
});
/** ***************** FETCH SEARCH MEMBER LIST END *********************** */

/** ***************** FETCH ADVANCE SEARCH MEMBER LIST START *********************** */
export const fetchAdvanceSearchMemberListStart = (getData) => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_START,
	payload: getData,
});

export const fetchAdvanceSearchMemberListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const fetchAdvanceSearchMemberListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const fetchAdvanceSearchMemberListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_RESET,
});

export const fetchAdvanceSearchMemberListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CHANGED,
});

export const fetchAdvanceSearchMemberListResponseClear = () => ({
	type: MemberActionTypes.FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CLEAR,
});
/** ***************** FETCH ADVANCE SEARCH MEMBER LIST END *********************** */

/** ***************** FETCH MEMBER INFO START *********************** */
export const getMemberInfoStart = (getData) => ({
	type: MemberActionTypes.GET_MEMBER_INFO_START,
	payload: getData,
});

export const getMemberInfoSuccess = (successData) => ({
	type: MemberActionTypes.GET_MEMBER_INFO_SUCCESS,
	payload: successData,
});

export const getMemberInfoFailure = (failureData) => ({
	type: MemberActionTypes.GET_MEMBER_INFO_FAILURE,
	payload: failureData,
});

export const getMemberInfoResponseResetStart = () => ({
	type: MemberActionTypes.GET_MEMBER_INFO_RESPONSE_RESET,
});

export const getMemberInfoResponseChanged = () => ({
	type: MemberActionTypes.GET_MEMBER_INFO_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER INFO END *********************** */

/** ***************** FETCH MEMBER ENROLLMENT INFO START *********************** */
export const getMemberEnrollmentInfoStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_START,
	payload: getData,
});

export const getMemberEnrollmentInfoSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_SUCCESS,
	payload: successData,
});

export const getMemberEnrollmentInfoFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_FAILURE,
	payload: failureData,
});

export const getMemberEnrollmentInfoResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET,
});

export const getMemberEnrollmentInfoResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER ENROLLMENT INFO END *********************** */

/** ***************** FETCH MEMBER ENROLLMENT LIST START *********************** */
export const getMemberEnrollmentListStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_START,
	payload: getData,
});

export const getMemberEnrollmentListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_SUCCESS,
	payload: successData,
});

export const getMemberEnrollmentListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_FAILURE,
	payload: failureData,
});

export const getMemberEnrollmentListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_RESET,
});

export const getMemberEnrollmentListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER ENROLLMENT LIST END *********************** */

/** ***************** FETCH MEMBER CHIT REQUEST INFO START *********************** */
export const getMemberChitRequestInfoStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_START,
	payload: getData,
});

export const getMemberChitRequestInfoSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS,
	payload: successData,
});

export const getMemberChitRequestInfoFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_FAILURE,
	payload: failureData,
});

export const getMemberChitRequestInfoResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET,
});

export const getMemberChitRequestInfoResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER CHIT REQUEST INFO END *********************** */

/** ***************** FETCH MEMBER SECURITY INFO LIST START *********************** */
export const getMemberSecurityInfoListStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_START,
	payload: getData,
});

export const getMemberSecurityInfoListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_SUCCESS,
	payload: successData,
});

export const getMemberSecurityInfoListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_FAILURE,
	payload: failureData,
});

export const getMemberSecurityInfoListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_RESPONSE_RESET,
});

export const getMemberSecurityInfoListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_SECURITY_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER SECURITY INFO LIST END *********************** */

/** ***************** FETCH MEMBER ADDRESS LIST START *********************** */
export const getMemberAddressListStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_START,
	payload: getData,
});

export const getMemberAddressListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_SUCCESS,
	payload: successData,
});

export const getMemberAddressListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_FAILURE,
	payload: failureData,
});

export const getMemberAddressListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_RESPONSE_RESET,
});

export const getMemberAddressListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER ADDRESS LIST END *********************** */

/** ***************** FETCH MEMBER ADDRESS INFO START *********************** */
export const getMemberAddressInfoStart = (getData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_START,
	payload: getData,
});

export const getMemberAddressInfoSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_SUCCESS,
	payload: successData,
});

export const getMemberAddressInfoFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_FAILURE,
	payload: failureData,
});

export const getMemberAddressInfoResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_RESPONSE_RESET,
});

export const getMemberAddressInfoResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_ADDRESS_INFO_RESPONSE_CHANGED,
});
/** ***************** FETCH MEMBER ADDRESS INFO END *********************** */

/** ***************** FETCH PRODUCT ENROLLMENT MEMBERS INFO START *********************** */
export const fetchProductEnrollmentMembersListStart = (getData) => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_START,
	payload: getData,
});

export const fetchProductEnrollmentMembersListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_SUCCESS,
	payload: successData,
});

export const fetchProductEnrollmentMembersListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchProductEnrollmentMembersListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET,
});

export const fetchProductEnrollmentMembersListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED,
});

export const fetchProductEnrollmentMembersListResponseArrChanged = () => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED,
});

export const updateMembersForGroupMappingStart = (getData) => ({
	type: MemberActionTypes.FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED,
	payload: getData,
});
/** ***************** FETCH PRODUCT ENROLLMENT MEMBERS END *********************** */

/** ***************** FETCH CENTER ENROLLMENT MEMBERS INFO START *********************** */
export const fetchCenterEnrollmentMembersListStart = (getData) => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_START,
	payload: getData,
});

export const fetchCenterEnrollmentMembersListSuccess = (successData) => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_SUCCESS,
	payload: successData,
});

export const fetchCenterEnrollmentMembersListFailure = (failureData) => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchCenterEnrollmentMembersListResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET,
});

export const fetchCenterEnrollmentMembersListResponseChanged = () => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED,
});

export const fetchCenterEnrollmentMembersListResponseArrChanged = () => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED,
});

export const updateMembersForGroupCollectionStart = (getData) => ({
	type: MemberActionTypes.FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED,
	payload: getData,
});
/** ***************** FETCH PRODUCT ENROLLMENT MEMBERS END *********************** */

/** ***************** DELETE MEMBER DETAILS START *********************** */
export const deleteMemberDetailsStart = (deleteData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_START,
	payload: deleteData,
});

export const deleteMemberDetailsSuccess = (successData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteMemberDetailsFailure = (failureData) => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_RESET,
});

export const deleteMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE MEMBER DETAILS END *********************** */

export const memberIDStart = (memberID) => ({
	type: MemberActionTypes.MEMBER_ID,
	payload: memberID,
});

export const memberDetailsEditStart = (isEdit) => ({
	type: MemberActionTypes.IS_EDIT_MEMBER_DETAILS,
	payload: isEdit,
});

export const updateTabValueStart = (updatedTab) => ({
	type: MemberActionTypes.MEMBER_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});

export const updateCanvasValueStart = (canvas) => ({
	type: MemberActionTypes.MEMBER_IS_OPEN_OFFCANVAS,
	payload: canvas,
});

export const isAdvanceSearchStart = (canvas) => ({
	type: MemberActionTypes.MEMBER_IS_OPEN_ADVANCE_SEARCH_OFFCANVAS,
	payload: canvas,
});

export const getMemberTypeStart = (memberType) => ({
	type: MemberActionTypes.MEMBER_TYPE,
	payload: memberType,
});

export const getMemberMaritalStatusStart = (maritalStatus) => ({
	type: MemberActionTypes.MEMBER_MARITAL_STATUS,
	payload: maritalStatus,
});
