/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const roleDetailsAdd = (addData) => {
	const data = {
		AddRoleDetailsToken: addData,
	};
	const url = `${API_URL}addRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const roleDetailsUpdate = (updateData) => {
	const data = {
		UpdateRoleDetailsToken: updateData,
	};
	const url = `${API_URL}updateRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const roleListGet = (listData) => {
	const data = {
		FetchRoleListToken: listData,
	};
	const url = `${API_URL}FetchRoleListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchSearchRoleListInfo = (listData) => {
	const data = {
		FetchSearchRoleListToken: listData,
	};
	const url = `${API_URL}FetchSearchRoleListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const roleDetailsFetch = (fetchData) => {
	const data = {
		FetchRoleInfoToken: fetchData,
	};
	const url = `${API_URL}FetchRoleInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const roleDetailsDelete = (deleteData) => {
	const data = {
		DeleteRoleDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allRolesFetch = (fetchAllRoleData) => {
	const data = {
		AllRoleListFetchToken: fetchAllRoleData,
	};
	const url = `${API_URL}fetchAllRoleList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
