/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import memberCollectionActionTypes from './memberCollection.types';
import {
	fetchSearchMemberChitList,
	fetchMemberParticularChitInfo,
	postChitCollectionDetails,
} from './memberCollection.service';

import {
	getSearchMemberChitListSuccess,
	getSearchMemberChitListFailure,
	getSearchMemberChitListResponseChanged,
	getMemberParticularChitInfoSuccess,
	getMemberParticularChitInfoFailure,
	getMemberParticularChitInfoResponseChanged,
	postChitCollectionInfoSuccess,
	postChitCollectionInfoFailure,
	postChitCollectionInfoResponseChanged,
} from './memberCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START SEARCH MEMBER CHIT LIST DETAILS  ******************** */
export function* getSearchMemberChitList({ payload }) {
	try {
		const key = 'FetchMemberChitListKey';
		const jsonData = {
			searchMemberValue: payload.searchMemberValue,
			searchMemberType: payload.searchMemberType,
			MemberID: payload.MemberID,
			CompanyID: payload.MemberCompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			DatabaseName: payload.DatabaseName,
			ProvisionType: payload.ProvisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchMemberChitList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSearchMemberChitListFailure(responseData.data));
		} else {
			yield put(getSearchMemberChitListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSearchMemberChitListFailure(error));
	}
}

export function* resetGetSearchMemberChitListResponse() {
	yield put(getSearchMemberChitListResponseChanged());
}

export function* onGetSearchMemberChitListDetails() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_START,
		getSearchMemberChitList,
	);
}

export function* onGetSearchMemberChitListResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_RESPONSE_RESET,
		resetGetSearchMemberChitListResponse,
	);
}
/** *********************  END GET SEARCH MEMBER CHIT LIST  ******************** */

/** *********************  START MEMBER PARTICULAR CHIT INFO DETAILS  ******************** */
export function* getMemberParticularChitInfo({ payload }) {
	try {
		const key = 'FetchMemberParticularChitInfoKey';
		const jsonData = {
			ChitIndexMemberID: payload.ChitIndexMemberID,
			MemberID: payload.MemberID,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberParticularChitInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberParticularChitInfoFailure(responseData.data));
		} else {
			yield put(getMemberParticularChitInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberParticularChitInfoFailure(error));
	}
}

export function* resetGetMemberParticularChitInfoResponse() {
	yield put(getMemberParticularChitInfoResponseChanged());
}

export function* onGetMemberParticularChitInfoDetails() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_START,
		getMemberParticularChitInfo,
	);
}

export function* onGetMemberParticularChitInfoResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_RESPONSE_RESET,
		resetGetMemberParticularChitInfoResponse,
	);
}
/** *********************  END GET MEMBER PARTICULAR CHIT INFO  ******************** */

/** *********************  START POST CHIT COLLECTION DETAILS  ******************** */
export function* postChitCollectionInfo({ payload }) {
	try {
		const key = 'ToUpdateChitCollectionDetailsKey';
		const jsonData = {
			memberID: payload.memberID,
			branchID: payload.branchID,
			centerID: payload.centerID,
			groupID: payload.groupID,
			chitDuesID: payload.chitDuesID,
			chitIndexMemberID: payload.chitIndexMemberID,
			enrollmentID: payload.enrollmentID,
			demandDate: payload.demandDate,
			chitInstalmentAmount: payload.chitInstalmentAmount,
			chitDueNo: payload.chitDueNo,
			collectionAmount: payload.collectionAmount,
			collectedMode: payload.collectedMode,
			collectionDate: payload.collectionDate,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			totalDenominationAmount: payload.totalDenominationAmount,
			WalletAmount: payload.WalletAmount,
			repayDate: payload.repayDate,
			CompanyID: payload.companyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(postChitCollectionDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(postChitCollectionInfoFailure(responseData.data));
		} else {
			yield put(postChitCollectionInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(postChitCollectionInfoFailure(error));
	}
}

export function* resetPostChitCollectionInfoResponse() {
	yield put(postChitCollectionInfoResponseChanged());
}

export function* onPostChitCollectionDetails() {
	yield takeLatest(memberCollectionActionTypes.POST_CHIT_LIST_START, postChitCollectionInfo);
}

export function* onPostChitCollectionDetailsResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.POST_CHIT_LIST_RESPONSE_RESET,
		resetPostChitCollectionInfoResponse,
	);
}
/** *********************  END GET SEARCH MEMBER CHIT LIST  ******************** */

export function* memberCollectionSaga() {
	yield all([
		call(onGetSearchMemberChitListDetails),
		call(onGetSearchMemberChitListResponseReset),
		call(onGetMemberParticularChitInfoDetails),
		call(onGetMemberParticularChitInfoResponseReset),
		call(onPostChitCollectionDetails),
		call(onPostChitCollectionDetailsResponseReset),
	]);
}
