/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ChitIndexActionTypes from './chitIndex.types';

/** ***************** ADD CHIT INDEX DETAILS START *********************** */
export const addChitIndexDetailsStart = (addData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_START,
	payload: addData,
});

export const addChitIndexDetailsSuccess = (successData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const addChitIndexDetailsFailure = (failureData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const addChitIndexDetailsResponseResetStart = () => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_RESPONSE_RESET,
});

export const addChitIndexDetailsResponseChanged = () => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD CHIT INDEX DETAILS END *********************** */

/** ***************** ADD CHIT INDEX MEMBERS DETAILS START *********************** */
export const addChitIndexMembersDetailsStart = (addData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_START,
	payload: addData,
});

export const addChitIndexMembersDetailsSuccess = (successData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_SUCCESS,
	payload: successData,
});

export const addChitIndexMembersDetailsFailure = (failureData) => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_FAILURE,
	payload: failureData,
});

export const addChitIndexMembersDetailsResponseResetStart = () => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_RESET,
});

export const addChitIndexMembersDetailsResponseChanged = () => ({
	type: ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD CHIT INDEX DETAILS END *********************** */

/** ***************** UPDATE CHIT INDEX DETAILS START *********************** */
export const updateChitIndexDetailsStart = (updateData) => ({
	type: ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_START,
	payload: updateData,
});

export const updateChitIndexDetailsSuccess = (successData) => ({
	type: ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const updateChitIndexDetailsFailure = (failureData) => ({
	type: ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const updateChitIndexDetailsResponseResetStart = () => ({
	type: ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_RESPONSE_RESET,
});

export const updateChitIndexDetailsResponseChanged = () => ({
	type: ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE CHIT INDEX DETAILS END *********************** */

/** ***************** FETCH CHIT INDEX LIST START *********************** */
export const fetchChitIndexListStart = (getData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_START,
	payload: getData,
});

export const fetchChitIndexListSuccess = (successData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_SUCCESS,
	payload: successData,
});

export const fetchChitIndexListFailure = (failureData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_FAILURE,
	payload: failureData,
});

export const fetchChitIndexListResponseResetStart = () => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_RESPONSE_RESET,
});

export const fetchChitIndexListResponseChanged = () => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH CHIT INDEX LIST END *********************** */

/** ***************** FETCH CHIT INDEX DETAILS START *********************** */
export const fetchChitIndexDetailsStart = (fetchData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_START,
	payload: fetchData,
});

export const fetchChitIndexDetailsSuccess = (successData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchChitIndexDetailsFailure = (failureData) => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchChitIndexDetailsResponseResetStart = () => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_RESPONSE_RESET,
});

export const fetchChitIndexDetailsResponseChanged = () => ({
	type: ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH CHIT INDEX DETAILS END *********************** */

/** ***************** DELETE CHIT INDEX DETAILS START *********************** */
export const deleteChitIndexDetailsStart = (deleteData) => ({
	type: ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_START,
	payload: deleteData,
});

export const deleteChitIndexDetailsSuccess = (successData) => ({
	type: ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteChitIndexDetailsFailure = (failureData) => ({
	type: ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteChitIndexDetailsResponseResetStart = () => ({
	type: ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_RESPONSE_RESET,
});

export const deleteChitIndexDetailsResponseChanged = () => ({
	type: ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE CHIT INDEX DETAILS END *********************** */

export const chitIndexIDStart = (chitIndexID) => ({
	type: ChitIndexActionTypes.CHIT_INDEX_ID,
	payload: chitIndexID,
});

export const chitProductScheduleStart = (chitIndexID) => ({
	type: ChitIndexActionTypes.CHIT_PRODUCT_SCHEDULE,
	payload: chitIndexID,
});

export const chitIndexDetailsEditStart = (isEdit) => ({
	type: ChitIndexActionTypes.IS_EDIT_CHIT_INDEX_DETAILS,
	payload: isEdit,
});

export const updateChitIndexTabValueStart = (updatedTab) => ({
	type: ChitIndexActionTypes.CHIT_INDEX_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});

export const updateChitIndexCanvasValueStart = (canvas) => ({
	type: ChitIndexActionTypes.CHIT_INDEX_IS_OPEN_OFFCANVAS,
	payload: canvas,
});
