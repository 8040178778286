const ProductActionTypes = {
	ADD_PRODUCT_DETAILS_START: 'add_product_details_start',
	ADD_PRODUCT_DETAILS_SUCCESS: 'add_product_details_success',
	ADD_PRODUCT_DETAILS_FAILURE: 'add_product_details_failure',
	ADD_PRODUCT_DETAILS_RESPONSE_RESET: 'add_product_details_response_reset',
	ADD_PRODUCT_DETAILS_RESPONSE_CHANGED: 'add_product_details_response_changed',

	ADD_PRODUCT_SCHEDULE_START: 'add_product_schedule_start',
	ADD_PRODUCT_SCHEDULE_SUCCESS: 'add_product_schedule_success',
	ADD_PRODUCT_SCHEDULE_FAILURE: 'add_product_schedule_failure',
	ADD_PRODUCT_SCHEDULE_RESPONSE_RESET: 'add_product_schedule_response_reset',
	ADD_PRODUCT_SCHEDULE_RESPONSE_CHANGED: 'add_product_schedule_response_changed',

	UPDATE_PRODUCT_DETAILS_START: 'update_product_details_start',
	UPDATE_PRODUCT_DETAILS_SUCCESS: 'update_product_details_success',
	UPDATE_PRODUCT_DETAILS_FAILURE: 'update_product_details_failure',
	UPDATE_PRODUCT_DETAILS_RESPONSE_RESET: 'update_product_details_response_reset',
	UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED: 'update_product_details_response_changed',

	UPDATE_PRODUCT_SCHEDULE_START: 'update_product_schedule_start',
	UPDATE_PRODUCT_SCHEDULE_SUCCESS: 'update_product_schedule_success',
	UPDATE_PRODUCT_SCHEDULE_FAILURE: 'update_product_schedule_failure',
	UPDATE_PRODUCT_SCHEDULE_RESPONSE_RESET: 'update_product_schedule_response_reset',
	UPDATE_PRODUCT_SCHEDULE_RESPONSE_CHANGED: 'update_product_schedule_response_changed',

	UPDATE_PRODUCT_ADD_CHARGES_START: 'update_product_add_charges_start',
	UPDATE_PRODUCT_ADD_CHARGES_SUCCESS: 'update_product_add_charges_success',
	UPDATE_PRODUCT_ADD_CHARGES_FAILURE: 'update_product_add_charges_failure',
	UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_RESET: 'update_product_add_charges_response_reset',
	UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_CHANGED: 'update_product_add_charges_response_changed',

	GET_PRODUCT_LIST_START: 'get_product_list_start',
	GET_PRODUCT_LIST_SUCCESS: 'get_product_list_success',
	GET_PRODUCT_LIST_FAILURE: 'get_product_list_failure',
	GET_PRODUCT_LIST_RESPONSE_RESET: 'get_product_list_response_reset',
	GET_PRODUCT_LIST_RESPONSE_CHANGED: 'get_product_list_response_changed',

	FETCH_SEARCH_PRODUCT_LIST_START: 'FETCH_SEARCH_PRODUCT_LIST_START',
	FETCH_SEARCH_PRODUCT_LIST_SUCCESS: 'FETCH_SEARCH_PRODUCT_LIST_SUCCESS',
	FETCH_SEARCH_PRODUCT_LIST_FAILURE: 'FETCH_SEARCH_PRODUCT_LIST_FAILURE',
	FETCH_SEARCH_PRODUCT_LIST_RESPONSE_RESET: 'FETCH_SEARCH_PRODUCT_LIST_RESPONSE_RESET',
	FETCH_SEARCH_PRODUCT_LIST_RESPONSE_CHANGED: 'FETCH_SEARCH_PRODUCT_LIST_RESPONSE_CHANGED',

	FETCH_PRODUCT_DETAILS_START: 'fetch_product_details_start',
	FETCH_PRODUCT_DETAILS_SUCCESS: 'fetch_product_details_success',
	FETCH_PRODUCT_DETAILS_FAILURE: 'fetch_product_details_failure',
	FETCH_PRODUCT_DETAILS_RESPONSE_RESET: 'fetch_product_details_response_reset',
	FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED: 'fetch_product_details_response_changed',

	FETCH_ALL_PRODUCT_START: 'fetch_all_product_start',
	FETCH_ALL_PRODUCT_SUCCESS: 'fetch_all_product_success',
	FETCH_ALL_PRODUCT_FAILURE: 'fetch_all_product_failure',
	FETCH_ALL_PRODUCT_RESPONSE_RESET: 'fetch_all_product_response_reset',
	FETCH_ALL_PRODUCT_RESPONSE_CHANGED: 'fetch_all_product_response_changed',

	DELETE_PRODUCT_DETAILS_START: 'delete_product_details_start',
	DELETE_PRODUCT_DETAILS_SUCCESS: 'delete_product_details_success',
	DELETE_PRODUCT_DETAILS_FAILURE: 'delete_product_details_failure',
	DELETE_PRODUCT_DETAILS_RESPONSE_RESET: 'delete_product_details_response_reset',
	DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED: 'delete_product_details_response_changed',

	PRODUCT_SCHEDULE_LIST: 'product_schedule_list',
	PRODUCT_SCHEDULE_LIST_RESET: 'product_schedule_list_reset',
	PRODUCT_SCHEDULE_LIST_UPDATE: 'product_schedule_list_update',
	PRODUCT_SCHEDULE_LIST_FETCH: 'product_schedule_list_fetch',
	PRODUCT_SUBMIT_TAB_VALUE_CHANGED: 'product_submit_tab_value_changed',
	PRODUCT_IS_OPEN_OFFCANVAS: 'product_is_open_offcanvas',
	IS_EDIT_PRODUCT_DETAILS: 'is_edit_product_details',
	PRODUCT_ID: 'product_id',
	PRODUCT_TYPE_ID: 'product_type_id',
};
export default ProductActionTypes;
