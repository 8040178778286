/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import CenterActionTypes from './center.types';
import {
	centerDetailsAdd,
	centerDetailsUpdate,
	centerListGet,
	centerDetailsFetch,
	centerDetailsDelete,
	fetchCenterUniqueDetails,
	fetchBranchBasedCenterList,
	fetchCenterBetaList,
	saveCenterBetaToCenters,
	fetchAllCentersList,
} from './center.service';

import {
	addCenterDetailsSuccess,
	addCenterDetailsFailure,
	addCenterDetailsResponseChanged,
	updateCenterDetailsSuccess,
	updateCenterDetailsFailure,
	updateCenterDetailsResponseChanged,
	getCenterDetailsSuccess,
	getCenterDetailsFailure,
	getCenterDetailsResponseChanged,
	fetchCenterDetailsSuccess,
	fetchCenterDetailsFailure,
	fetchCenterDetailsResponseChanged,
	deleteCenterDetailsSuccess,
	deleteCenterDetailsFailure,
	deleteCenterDetailsResponseChanged,
	fetchCenterUniqueIDSuccess,
	fetchCenterUniqueIDFailure,
	fetchCenterUniqueIDResponseChanged,
	fetchBranchBasedCenterListSuccess,
	fetchBranchBasedCenterListFailure,
	fetchBranchBasedCenterListResponseChanged,
	fetchCenterBetaListSuccess,
	fetchCenterBetaListFailure,
	fetchCenterBetaListResponseChanged,
	saveCenterBetaToCentersDetailsSuccess,
	saveCenterBetaToCentersDetailsFailure,
	saveCenterBetaToCentersDetailsResponseChanged,
	fetchAllCentersListSuccess,
	fetchAllCentersListFailure,
	fetchAllCentersListResponseChanged,
} from './center.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD CENTER DETAILS  ******************** */
export function* createCenter({ payload }) {
	try {
		const key = 'AddCenterDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			NextCenterUID: payload.nextUniqueCenterID,
			CenterName: payload.centerName,
			CenterAddress: payload.centerAddress,
			CenterCity: payload.branchCity,
			CenterState: payload.branchState,
			CenterCountry: payload.branchCountry,
			CenterPincode: payload.centerPincode,
			CenterPhoneNumber: payload.centerPhoneNumber,
			CenterLandMark: payload.centerLandMark,
			CenterLatitude: payload.centerMapLatitude,
			CenterLongitude: payload.centerMapLongitude,
			DistanceFromBranch: payload.centerBranchDistance,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addCenterDetailsFailure(responseData.data));
		} else {
			yield put(addCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCenterDetailsFailure(error));
	}
}

export function* resetAddCenterResponse() {
	yield put(addCenterDetailsResponseChanged());
}

export function* onCreateCenterDetails() {
	yield takeLatest(CenterActionTypes.ADD_CENTER_DETAILS_START, createCenter);
}

export function* onCreateCenterResponseReset() {
	yield takeLatest(CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_RESET, resetAddCenterResponse);
}
/** *********************  END ADD CENTER DETAILS ******************** */

/** *********************  START UPDATE CENTER DETAILS  ******************** */
export function* centerUpdate({ payload }) {
	try {
		const key = 'UpdateCenterDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			NextCenterUID: payload.nextUniqueCenterID,
			CenterID: payload.centerID,
			CenterName: payload.centerName,
			CenterAddress: payload.centerAddress,
			CenterCity: payload.branchCity,
			CenterState: payload.branchState,
			CenterCountry: payload.branchCountry,
			CenterPincode: payload.centerPincode,
			CenterPhoneNumber: payload.centerPhoneNumber,
			CenterLandMark: payload.centerLandMark,
			CenterLatitude: payload.centerMapLatitude,
			CenterLongitude: payload.centerMapLongitude,
			DistanceFromBranch: payload.centerBranchDistance,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCenterDetailsFailure(responseData.data));
		} else {
			yield put(updateCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCenterDetailsFailure(error));
	}
}

export function* resetUpdateCenterDetailsResponse() {
	yield put(updateCenterDetailsResponseChanged());
}

export function* onUpdateCenterDetails() {
	yield takeLatest(CenterActionTypes.UPDATE_CENTER_DETAILS_START, centerUpdate);
}

export function* onUpdateCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_RESET,
		resetUpdateCenterDetailsResponse,
	);
}
/** *********************  END UPDATE CENTER DETAILS ******************** */

/** *********************  START GET CENTER LIST  ******************** */
export function* centerDetailsListGet({ payload }) {
	try {
		const key = 'FetchCenterListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterDetailsFailure(responseData.data));
		} else {
			yield put(getCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterDetailsFailure(error));
	}
}

export function* resetGetCenterListResponse() {
	yield put(getCenterDetailsResponseChanged());
}

export function* onGetCenterListDetails() {
	yield takeLatest(CenterActionTypes.GET_CENTER_LIST_START, centerDetailsListGet);
}

export function* onGetCenterListResponseReset() {
	yield takeLatest(CenterActionTypes.GET_CENTER_LIST_RESPONSE_RESET, resetGetCenterListResponse);
}
/** *********************  END GET CENTER LIST ******************** */

/** *********************  START FETCH CENTER DETAILS  ******************** */
export function* fetchCenterData({ payload }) {
	try {
		const key = 'FetchCenterInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterDetailsFailure(responseData.data));
		} else {
			yield put(fetchCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterDetailsFailure(error));
	}
}

export function* resetFetchCenterDetailsResponse() {
	yield put(fetchCenterDetailsResponseChanged());
}

export function* onFetchCenterDetails() {
	yield takeLatest(CenterActionTypes.FETCH_CENTER_DETAILS_START, fetchCenterData);
}

export function* onFetchCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_RESET,
		resetFetchCenterDetailsResponse,
	);
}
/** *********************  END FETCH CENTER DETAILS ******************** */

/** *********************  START DELETE CENTER DETAILS  ******************** */
export function* centerDelete({ payload }) {
	try {
		const key = 'DeleteCenterDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.centerID,
			CenterName: payload.centerName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteCenterDetailsFailure(responseData.data));
		} else {
			yield put(deleteCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteCenterDetailsFailure(error));
	}
}

export function* resetDeleteCenterDetailsResponse() {
	yield put(deleteCenterDetailsResponseChanged());
}

export function* onDeleteCenterDetail() {
	yield takeLatest(CenterActionTypes.DELETE_CENTER_DETAILS_START, centerDelete);
}

export function* onDeleteCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_RESET,
		resetDeleteCenterDetailsResponse,
	);
}
/** *********************  END DELETE CENTER DETAILS ******************** */

/** *********************  START FETCH CENTER UNIQUE ID  ******************** */
export function* getCenterUniqueDetails({ payload }) {
	try {
		const key = 'FetchCenterUniqueIdKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterUniqueDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterUniqueIDFailure(responseData.data));
		} else {
			yield put(fetchCenterUniqueIDSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterUniqueIDFailure(error));
	}
}

export function* resetGetCenterUniqueIDResponse() {
	yield put(fetchCenterUniqueIDResponseChanged());
}

export function* onGetCenterCenterUinqueDetails() {
	yield takeLatest(CenterActionTypes.FETCH_CENTER_UNIQUE_ID_START, getCenterUniqueDetails);
}

export function* onGetCenterCenterUinqueIDResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET,
		resetGetCenterUniqueIDResponse,
	);
}
/** *********************  END FETCH CENTER UNIQUE ID ******************** */

/** *********************  START FETCH BRANCH BASED CENTER LIST  ******************** */
export function* branchBasedCenterListGet({ payload }) {
	try {
		const key = 'FetchBranchBasedCenterListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchBasedCenterList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchBasedCenterListFailure(responseData.data));
		} else {
			yield put(fetchBranchBasedCenterListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchBasedCenterListFailure(error));
	}
}

export function* resetFetchBranchBasedCenterListResponse() {
	yield put(fetchBranchBasedCenterListResponseChanged());
}

export function* onFetchBranchBasedCenterList() {
	yield takeLatest(CenterActionTypes.FETCH_BRANCH_CENTER_LIST_START, branchBasedCenterListGet);
}

export function* onFetchBranchBasedCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_BRANCH_CENTER_LIST_RESPONSE_RESET,
		resetFetchBranchBasedCenterListResponse,
	);
}
/** *********************  END FETCH BRANCH BASED CENTER LIST ******************** */

/** *********************  START FETCH CENTER BETA LIST  ******************** */
export function* centerBetaListGet({ payload }) {
	try {
		const key = 'FetchCenterBetaListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			Limit: payload.Limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterBetaList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterBetaListFailure(responseData.data));
		} else {
			yield put(fetchCenterBetaListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterBetaListFailure(error));
	}
}

export function* resetFetchCenterBetaListResponse() {
	yield put(fetchCenterBetaListResponseChanged());
}

export function* onFetchCenterBetaList() {
	yield takeLatest(CenterActionTypes.FETCH_CENTER_BETA_LIST_START, centerBetaListGet);
}

export function* onFetchCenterBetaListResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_CENTER_BETA_LIST_RESPONSE_RESET,
		resetFetchCenterBetaListResponse,
	);
}
/** *********************  END FETCH CENTER BETA LIST ******************** */

/** *********************  START FETCH ALL CENTERS LIST  ******************** */
export function* fetchAllCentersListGet({ payload }) {
	try {
		const key = 'FetchAllCentersListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAllCentersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllCentersListFailure(responseData.data));
		} else {
			yield put(fetchAllCentersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllCentersListFailure(error));
	}
}

export function* resetFetchAllCentersListResponse() {
	yield put(fetchAllCentersListResponseChanged());
}

export function* onFetchAllCentersList() {
	yield takeLatest(CenterActionTypes.FETCH_ALL_CENTERS_LIST_START, fetchAllCentersListGet);
}

export function* onFetchAllCentersListResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_ALL_CENTERS_LIST_RESPONSE_RESET,
		resetFetchAllCentersListResponse,
	);
}
/** *********************  END FETCH ALL CENTERS LIST ******************** */

/** *********************  SAVE CENTER BETA TO CENTERS - START  ******************** */
export function* saveCenterBetaToCentersDetails({ payload }) {
	try {
		const key = 'SaveCenterBetaToCentersKey';
		const jsonData = {
			CenterBetaList: payload.centerBetaList,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(saveCenterBetaToCenters, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(saveCenterBetaToCentersDetailsFailure(responseData.data));
		} else {
			yield put(saveCenterBetaToCentersDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(saveCenterBetaToCentersDetailsFailure(error));
	}
}

export function* resetSaveCenterBetaToCentersDetailsResponse() {
	yield put(saveCenterBetaToCentersDetailsResponseChanged());
}

export function* onSaveCenterBetaToCentersDetails() {
	yield takeLatest(
		CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_START,
		saveCenterBetaToCentersDetails,
	);
}

export function* onSaveCenterBetaToCentersDetailsResponseReset() {
	yield takeLatest(
		CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_RESET,
		resetSaveCenterBetaToCentersDetailsResponse,
	);
}
/** *********************  SAVE CENTER BETA TO CENTERS - END  ******************** */

export function* centerMasterSaga() {
	yield all([
		call(onCreateCenterDetails),
		call(onCreateCenterResponseReset),
		call(onUpdateCenterDetails),
		call(onUpdateCenterResponseReset),
		call(onGetCenterListDetails),
		call(onGetCenterListResponseReset),
		call(onFetchCenterDetails),
		call(onFetchCenterResponseReset),
		call(onDeleteCenterDetail),
		call(onDeleteCenterResponseReset),
		call(onGetCenterCenterUinqueDetails),
		call(onGetCenterCenterUinqueIDResponseReset),
		call(onFetchBranchBasedCenterList),
		call(onFetchBranchBasedCenterResponseReset),
		call(onFetchCenterBetaList),
		call(onFetchCenterBetaListResponseReset),
		call(onFetchAllCentersList),
		call(onFetchAllCentersListResponseReset),
		call(onSaveCenterBetaToCentersDetails),
		call(onSaveCenterBetaToCentersDetailsResponseReset),
	]);
}
