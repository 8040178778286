const StaffActionTypes = {
	ADD_STAFF_DETAILS_START: 'add_staff_details_start',
	ADD_STAFF_DETAILS_SUCCESS: 'add_staff_details_success',
	ADD_STAFF_DETAILS_FAILURE: 'add_staff_details_failure',
	ADD_STAFF_DETAILS_RESPONSE_RESET: 'add_staff_details_response_reset',
	ADD_STAFF_DETAILS_RESPONSE_CHANGED: 'add_staff_details_response_changed',

	UPDATE_STAFF_DETAILS_START: 'UPDATE_STAFF_DETAILS_START',
	UPDATE_STAFF_DETAILS_SUCCESS: 'UPDATE_STAFF_DETAILS_SUCCESS',
	UPDATE_STAFF_DETAILS_FAILURE: 'UPDATE_STAFF_DETAILS_FAILURE',
	UPDATE_STAFF_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_DETAILS_RESPONSE_CHANGED: 'UPDATE_STAFF_DETAILS_RESPONSE_CHANGED',

	UPDATE_STAFF_ADDRESS_DETAILS_START: 'UPDATE_STAFF_ADDRESS_DETAILS_START',
	UPDATE_STAFF_ADDRESS_DETAILS_SUCCESS: 'UPDATE_STAFF_ADDRESS_DETAILS_SUCCESS',
	UPDATE_STAFF_ADDRESS_DETAILS_FAILURE: 'UPDATE_STAFF_ADDRESS_DETAILS_FAILURE',
	UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_CHANGED: 'UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_CHANGED',

	UPDATE_STAFF_PROOF_DETAILS_START: 'UPDATE_STAFF_PROOF_DETAILS_START',
	UPDATE_STAFF_PROOF_DETAILS_SUCCESS: 'UPDATE_STAFF_PROOF_DETAILS_SUCCESS',
	UPDATE_STAFF_PROOF_DETAILS_FAILURE: 'UPDATE_STAFF_PROOF_DETAILS_FAILURE',
	UPDATE_STAFF_PROOF_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_PROOF_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_PROOF_DETAILS_RESPONSE_CHANGED: 'UPDATE_STAFF_PROOF_DETAILS_RESPONSE_CHANGED',

	UPDATE_STAFF_EMERGENCY_DETAILS_START: 'UPDATE_STAFF_EMERGENCY_DETAILS_START',
	UPDATE_STAFF_EMERGENCY_DETAILS_SUCCESS: 'UPDATE_STAFF_EMERGENCY_DETAILS_SUCCESS',
	UPDATE_STAFF_EMERGENCY_DETAILS_FAILURE: 'UPDATE_STAFF_EMERGENCY_DETAILS_FAILURE',
	UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_CHANGED:
		'UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_CHANGED',

	UPDATE_STAFF_OFFICIAL_DETAILS_START: 'UPDATE_STAFF_OFFICIAL_DETAILS_START',
	UPDATE_STAFF_OFFICIAL_DETAILS_SUCCESS: 'UPDATE_STAFF_OFFICIAL_DETAILS_SUCCESS',
	UPDATE_STAFF_OFFICIAL_DETAILS_FAILURE: 'UPDATE_STAFF_OFFICIAL_DETAILS_FAILURE',
	UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_CHANGED:
		'UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_CHANGED',

	ADD_STAFF_TARGET_DETAILS_START: 'ADD_STAFF_TARGET_DETAILS_START',
	ADD_STAFF_TARGET_DETAILS_SUCCESS: 'ADD_STAFF_TARGET_DETAILS_SUCCESS',
	ADD_STAFF_TARGET_DETAILS_FAILURE: 'ADD_STAFF_TARGET_DETAILS_FAILURE',
	ADD_STAFF_TARGET_DETAILS_RESPONSE_RESET: 'ADD_STAFF_TARGET_DETAILS_RESPONSE_RESET',
	ADD_STAFF_TARGET_DETAILS_RESPONSE_CHANGED: 'ADD_STAFF_TARGET_DETAILS_RESPONSE_CHANGED',

	UPDATE_STAFF_TARGET_DETAILS_START: 'UPDATE_STAFF_TARGET_DETAILS_START',
	UPDATE_STAFF_TARGET_DETAILS_SUCCESS: 'UPDATE_STAFF_TARGET_DETAILS_SUCCESS',
	UPDATE_STAFF_TARGET_DETAILS_FAILURE: 'UPDATE_STAFF_TARGET_DETAILS_FAILURE',
	UPDATE_STAFF_TARGET_DETAILS_RESPONSE_RESET: 'UPDATE_STAFF_TARGET_DETAILS_RESPONSE_RESET',
	UPDATE_STAFF_TARGET_DETAILS_RESPONSE_CHANGED: 'UPDATE_STAFF_TARGET_DETAILS_RESPONSE_CHANGED',

	GET_STAFF_LIST_START: 'get_staff_list_start',
	GET_STAFF_LIST_SUCCESS: 'get_staff_list_success',
	GET_STAFF_LIST_FAILURE: 'get_staff_list_failure',
	GET_STAFF_LIST_RESPONSE_RESET: 'get_staff_list_response_reset',
	GET_STAFF_LIST_RESPONSE_CHANGED: 'get_staff_list_response_changed',

	FETCH_SEARCH_STAFF_LIST_START: 'FETCH_SEARCH_STAFF_LIST_START',
	FETCH_SEARCH_STAFF_LIST_SUCCESS: 'FETCH_SEARCH_STAFF_LIST_SUCCESS',
	FETCH_SEARCH_STAFF_LIST_FAILURE: 'FETCH_SEARCH_STAFF_LIST_FAILURE',
	FETCH_SEARCH_STAFF_LIST_RESPONSE_RESET: 'FETCH_SEARCH_STAFF_LIST_RESPONSE_RESET',
	FETCH_SEARCH_STAFF_LIST_RESPONSE_CHANGED: 'FETCH_SEARCH_STAFF_LIST_RESPONSE_CHANGED',

	FETCH_STAFF_DETAILS_START: 'fetch_staff_details_start',
	FETCH_STAFF_DETAILS_SUCCESS: 'fetch_staff_details_success',
	FETCH_STAFF_DETAILS_FAILURE: 'fetch_staff_details_failure',
	FETCH_STAFF_DETAILS_RESPONSE_RESET: 'fetch_staff_details_response_reset',
	FETCH_STAFF_DETAILS_RESPONSE_CHANGED: 'fetch_staff_details_response_changed',

	DELETE_STAFF_DETAILS_START: 'delete_staff_details_start',
	DELETE_STAFF_DETAILS_SUCCESS: 'delete_staff_details_success',
	DELETE_STAFF_DETAILS_FAILURE: 'delete_staff_details_failure',
	DELETE_STAFF_DETAILS_RESPONSE_RESET: 'delete_staff_details_response_reset',
	DELETE_STAFF_DETAILS_RESPONSE_CHANGED: 'delete_staff_details_response_changed',

	UPDATE_ISPUBLISH_STATUS_START: 'update_isPublish_status_start',
	UPDATE_ISPUBLISH_STATUS_SUCCESS: 'update_isPublish_status_success',
	UPDATE_ISPUBLISH_STATUS_FAILURE: 'update_isPublish_status_failure',
	UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET: 'update_isPublish_status_response_reset',
	UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED: 'update_isPublish_status_response_changed',

	FETCH_ALL_STAFF_START: 'fetch_all_staff_start',
	FETCH_ALL_STAFF_SUCCESS: 'fetch_all_staff_success',
	FETCH_ALL_STAFF_FAILURE: 'fetch_all_staff_failure',
	FETCH_ALL_STAFF_RESPONSE_RESET: 'fetch_all_staff_response_reset',
	FETCH_ALL_STAFF_RESPONSE_CHANGED: 'fetch_all_staff_response_changed',

	FETCH_AGENT_STAFF_START: 'FETCH_AGENT_STAFF_START',
	FETCH_AGENT_STAFF_SUCCESS: 'FETCH_AGENT_STAFF_SUCCESS',
	FETCH_AGENT_STAFF_FAILURE: 'FETCH_AGENT_STAFF_FAILURE',
	FETCH_AGENT_STAFF_RESPONSE_RESET: 'FETCH_AGENT_STAFF_RESPONSE_RESET',
	FETCH_AGENT_STAFF_RESPONSE_CHANGED: 'FETCH_AGENT_STAFF_RESPONSE_CHANGED',

	FETCH_STAFF_TARGET_LIST_START: 'FETCH_STAFF_TARGET_LIST_START',
	FETCH_STAFF_TARGET_LIST_SUCCESS: 'FETCH_STAFF_TARGET_LIST_SUCCESS',
	FETCH_STAFF_TARGET_LIST_FAILURE: 'FETCH_STAFF_TARGET_LIST_FAILURE',
	FETCH_STAFF_TARGET_LIST_RESPONSE_RESET: 'FETCH_STAFF_TARGET_LIST_RESPONSE_RESET',
	FETCH_STAFF_TARGET_LIST_RESPONSE_CHANGED: 'FETCH_STAFF_TARGET_LIST_RESPONSE_CHANGED',

	STAFF_ID: 'STAFF_ID',
	STAFF_IS_OPEN_OFFCANVAS: 'STAFF_IS_OPEN_OFFCANVAS',
	STAFF_SUBMIT_TAB_VALUE_CHANGED: 'STAFF_SUBMIT_TAB_VALUE_CHANGED',
	IS_EDIT_STAFF_DETAILS: 'IS_EDIT_STAFF_DETAILS',
};
export default StaffActionTypes;
