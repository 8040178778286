/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import applicantCollectionActionTypes from './applicantCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addApplicantCollectionResponse: null,
	getApplicantCollectionInfoResponse: null,
	getApplicantCollectionInfoArr: [],
	fetchApplicantEnrollCollectionInfoResponse: null,
	fetchApplicantEnrollCollectionInfoArr: [],
	getFiveHundred: 0,
	getTwoHundred: 0,
	getHundred: 0,
	getFifty: 0,
	getTwenty: 0,
	getTen: 0,
	getFive: 0,
	getTwo: 0,
	getOne: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_SUCCESS:
			return { ...state, addApplicantCollectionResponse: action.payload };

		case applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_FAILURE:
			return { ...state, addApplicantCollectionResponse: action.payload };

		case applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_RESPONSE_CHANGED:
			return { ...state, addApplicantCollectionResponse: null };

		case applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_SUCCESS:
			return {
				...state,
				getApplicantCollectionInfoResponse: action.payload,
				getApplicantCollectionInfoArr: docodeResponse(
					action.payload,
					'FetchApplicantEnrollmentListCollectionInfoKey',
				),
			};

		case applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_FAILURE:
			return { ...state, getApplicantCollectionInfoResponse: action.payload };

		case applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_RESPONSE_CHANGED:
			return { ...state, getApplicantCollectionInfoResponse: null };

		case applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_SUCCESS:
			return {
				...state,
				fetchApplicantEnrollCollectionInfoResponse: action.payload,
				fetchApplicantEnrollCollectionInfoArr: docodeResponse(
					action.payload,
					'FetchApplicantEnrollmentCollectionInfoKey',
				),
			};

		case applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_FAILURE:
			return { ...state, fetchApplicantEnrollCollectionInfoResponse: action.payload };

		case applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_CHANGED:
			return { ...state, fetchApplicantEnrollCollectionInfoResponse: null };

		case applicantCollectionActionTypes.GET_FIVE_HUNDRED:
			return { ...state, getFiveHundred: action.payload };

		case applicantCollectionActionTypes.GET_TWO_HUNDRED:
			return { ...state, getTwoHundred: action.payload };

		case applicantCollectionActionTypes.GET_HUNDRED:
			return { ...state, getHundred: action.payload };

		case applicantCollectionActionTypes.GET_FIFTY:
			return { ...state, getFifty: action.payload };

		case applicantCollectionActionTypes.GET_TWENTY:
			return { ...state, getTwenty: action.payload };

		case applicantCollectionActionTypes.GET_TEN:
			return { ...state, getTen: action.payload };

		case applicantCollectionActionTypes.GET_FIVE:
			return { ...state, getFive: action.payload };

		case applicantCollectionActionTypes.GET_TWO:
			return { ...state, getTwo: action.payload };

		case applicantCollectionActionTypes.GET_ONE:
			return { ...state, getOne: action.payload };

		default:
			return state;
	}
};
