/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const addApplicantCollection = (listData) => {
	const data = {
		AddApplicantCollectionDetailsToken: listData,
	};
	const url = `${API_URL}addApplicantCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchApplicantCollectionInfo = (listData) => {
	const data = {
		FetchApplicantEnrollmentListCollectionInfoToken: listData,
	};
	const url = `${API_URL}FetchApplicantEnrollmentListCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchApplicantEnrollmentCollectionInfo = (listData) => {
	const data = {
		FetchApplicantEnrollmentCollectionInfoToken: listData,
	};
	const url = `${API_URL}FetchApplicantEnrollmentCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
