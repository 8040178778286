/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BranchActionTypes from './branch.types';

/** ***************** ADD BRANCH DETAILS START *********************** */
export const addBranchDetailsStart = (addData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_START,
	payload: addData,
});

export const addBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const addBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const addBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_RESET,
});

export const addBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD BRANCH DETAILS END *********************** */

/** ***************** ADD BRANCH BANK ACCOUNT DETAILS START *********************** */
export const addBranchBankAccountDetailsStart = (addData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_START,
	payload: addData,
});

export const addBranchBankAccountDetailsSuccess = (successData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS,
	payload: successData,
});

export const addBranchBankAccountDetailsFailure = (failureData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE,
	payload: failureData,
});

export const addBranchBankAccountDetailsResponseResetStart = () => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
});

export const addBranchBankAccountDetailsResponseChanged = () => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD BRANCH BANK ACCOUNT DETAILS END *********************** */

/** ***************** ADD BRANCH BANK CHEQUE BOOK DETAILS START *********************** */
export const addBranchBankChequeBookDetailsStart = (addData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START,
	payload: addData,
});

export const addBranchBankChequeBookDetailsSuccess = (successData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS,
	payload: successData,
});

export const addBranchBankChequeBookDetailsFailure = (failureData) => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE,
	payload: failureData,
});

export const addBranchBankChequeBookDetailsResponseResetStart = () => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET,
});

export const addBranchBankChequeBookDetailsResponseChanged = () => ({
	type: BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD BRANCH BANK ACCOUNT DETAILS END *********************** */

/** ***************** UPDATE BRANCH DETAILS START *********************** */
export const updateBranchDetailsStart = (updateData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_START,
	payload: updateData,
});

export const updateBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const updateBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const updateBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_RESET,
});

export const updateBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE BRANCH DETAILS END *********************** */

/** ***************** UPDATE BRANCH BANK ACCOUNT DETAILS START *********************** */
export const updateBranchBankAccountDetailsStart = (updateData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_START,
	payload: updateData,
});

export const updateBranchBankAccountDetailsSuccess = (successData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateBranchBankAccountDetailsFailure = (failureData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateBranchBankAccountDetailsResponseResetStart = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
});

export const updateBranchBankAccountDetailsResponseChanged = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE BRANCH BANK ACCOUNT DETAILS END *********************** */

/** ***************** UPDATE BRANCH BANK CHEQUE BOOK DETAILS START *********************** */
export const updateBranchBankChequeBookDetailsStart = (updateData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START,
	payload: updateData,
});

export const updateBranchBankChequeBookDetailsSuccess = (successData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS,
	payload: successData,
});

export const updateBranchBankChequeBookDetailsFailure = (failureData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE,
	payload: failureData,
});

export const updateBranchBankChequeBookDetailsResponseResetStart = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET,
});

export const updateBranchBankChequeBookDetailsResponseChanged = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE BRANCH BANK CHEQUE BOOK DETAILS END *********************** */

/** ***************** GET BRANCH DETAILS LIST START *********************** */
export const getBranchDetailsStart = (getData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_START,
	payload: getData,
});

export const getBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.GET_BRANCH_LIST_RESPONSE_RESET,
});

export const getBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.GET_BRANCH_LIST_RESPONSE_CHANGED,
});

/** ***************** GET BRANCH DETAILS LIST END *********************** */

/** ***************** FETCH SEARCH BRANCH DETAILS LIST START *********************** */
export const fetchSearchBranchDetailsStart = (getData) => ({
	type: BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_START,
	payload: getData,
});

export const fetchSearchBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const fetchSearchBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_RESPONSE_RESET,
});

export const fetchSearchBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH SERACH BRANCH DETAILS LIST END *********************** */

/** ***************** FETCH BRANCH DETAILS START *********************** */
export const fetchBranchDetailsStart = (fetchData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_START,
	payload: fetchData,
});

export const fetchBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET,
});

export const fetchBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCH DETAILS END *********************** */

/** ***************** DELETE BRANCH DETAILS START *********************** */
export const deleteBranchDetailsStart = (deleteData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_START,
	payload: deleteData,
});

export const deleteBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_RESET,
});

export const deleteBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE BRANCH DETAILS END *********************** */

/** ***************** DELETE BRANCH BANK DETAILS START *********************** */
export const deleteBranchBankStart = (deleteData) => ({
	type: BranchActionTypes.DELETE_BRANCH_BANK_START,
	payload: deleteData,
});

export const deleteBranchBankSuccess = (successData) => ({
	type: BranchActionTypes.DELETE_BRANCH_BANK_SUCCESS,
	payload: successData,
});

export const deleteBranchBankFailure = (failureData) => ({
	type: BranchActionTypes.DELETE_BRANCH_BANK_FAILURE,
	payload: failureData,
});

export const deleteBranchBankResponseResetStart = () => ({
	type: BranchActionTypes.DELETE_BRANCH_BANK_RESPONSE_RESET,
});

export const deleteBranchBankResponseChanged = () => ({
	type: BranchActionTypes.DELETE_BRANCH_BANK_RESPONSE_CHANGED,
});

/** ***************** DELETE BRANCH BANK DETAILS END *********************** */

/** ***************** DELETE BRANCH BANK CHEQUE BOOK DETAILS START *********************** */
export const deleteBranchBankChequeBookStart = (deleteData) => ({
	type: BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_START,
	payload: deleteData,
});

export const deleteBranchBankChequeBookSuccess = (successData) => ({
	type: BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_SUCCESS,
	payload: successData,
});

export const deleteBranchBankChequeBookFailure = (failureData) => ({
	type: BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_FAILURE,
	payload: failureData,
});

export const deleteBranchBankChequeBookResponseResetStart = () => ({
	type: BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_RESET,
});

export const deleteBranchBankChequeBookResponseChanged = () => ({
	type: BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_CHANGED,
});

/** ***************** DELETE BRANCH BANK CHEQUE BOOK DETAILS END *********************** */

/** ***************** FETCH BRANCH BANK ACCOUNT LIST START *********************** */
export const fetchBranchBankAccountListStart = (getData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_START,
	payload: getData,
});

export const fetchBranchBankAccountListSuccess = (successData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_SUCCESS,
	payload: successData,
});

export const fetchBranchBankAccountListFailure = (failureData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchBankAccountListResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_RESET,
});

export const fetchBranchBankAccountListResponseChanged = () => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCH BANK ACCOUNT LIST END *********************** */

/** ***************** FETCH BRANCH BANK CHEQUE BOOK LIST START *********************** */
export const fetchBranchBankChequeBookListStart = (getData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_START,
	payload: getData,
});

export const fetchBranchBankChequeBookListSuccess = (successData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_SUCCESS,
	payload: successData,
});

export const fetchBranchBankChequeBookListFailure = (failureData) => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchBankChequeBookListResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_RESET,
});

export const fetchBranchBankChequeBookListResponseChanged = () => ({
	type: BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH BRANCH BANK CHEQUE BOOK LIST END *********************** */

export const branchIDStart = (branchID) => ({
	type: BranchActionTypes.BRANCH_ID,
	payload: branchID,
});

export const branchDetailsEditStart = (isEdit) => ({
	type: BranchActionTypes.IS_EDIT_BRANCH_DETAILS,
	payload: isEdit,
});

export const updateBranchTabValueStart = (updatedTab) => ({
	type: BranchActionTypes.BRANCH_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});

export const updateBranchCanvasValueStart = (canvas) => ({
	type: BranchActionTypes.BRANCH_IS_OPEN_OFFCANVAS,
	payload: canvas,
});

export const bankAccountListStart = (item) => ({
	type: BranchActionTypes.BANK_ACCOUNT_LIST_ARR,
	payload: item,
});

export const bankAccountListUpdateStart = (item) => ({
	type: BranchActionTypes.BANK_ACCOUNT_LIST_ARR_UPDATE,
	payload: item,
});

export const bankAccountListRemoveStart = (item) => ({
	type: BranchActionTypes.BANK_ACCOUNT_LIST_ARR_REMOVE,
	payload: item,
});

export const chequeBookListStart = (item) => ({
	type: BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR,
	payload: item,
});

export const chequeBookListUpdateStart = (item) => ({
	type: BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR_UPDATE,
	payload: item,
});

export const chequeBookListRemoveStart = (item) => ({
	type: BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR_REMOVE,
	payload: item,
});
