/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ProductActionTypes from './product.types';

/** ***************** ADD PRODUCT DETAILS START *********************** */
export const addProductDetailsStart = (addData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_START,
	payload: addData,
});

export const addProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const addProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const addProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const addProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT DETAILS END *********************** */

/** ***************** ADD PRODUCT SCHEDULE START ****************** */
export const addProductScheduleStart = (addData) => ({
	type: ProductActionTypes.ADD_PRODUCT_SCHEDULE_START,
	payload: addData,
});

export const addProductScheduleSuccess = (successData) => ({
	type: ProductActionTypes.ADD_PRODUCT_SCHEDULE_SUCCESS,
	payload: successData,
});

export const addProductScheduleFailure = (failureData) => ({
	type: ProductActionTypes.ADD_PRODUCT_SCHEDULE_FAILURE,
	payload: failureData,
});

export const addProductScheduleResponseResetStart = () => ({
	type: ProductActionTypes.ADD_PRODUCT_SCHEDULE_RESPONSE_RESET,
});

export const addProductScheduleResponseChanged = () => ({
	type: ProductActionTypes.ADD_PRODUCT_SCHEDULE_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT SCHEDULE END *********************** */

/** ***************** UPDATE PRODUCT DETAILS START *********************** */
export const updateProductDetailsStart = (updateData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_START,
	payload: updateData,
});

export const updateProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const updateProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT DETAILS END *********************** */

/** ***************** UPDATE PRODUCT SCHEDULE START ****************** */
export const updateProductScheduleStart = (addData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_START,
	payload: addData,
});

export const updateProductScheduleSuccess = (successData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_SUCCESS,
	payload: successData,
});

export const updateProductScheduleFailure = (failureData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_FAILURE,
	payload: failureData,
});

export const updateProductScheduleResponseResetStart = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_RESPONSE_RESET,
});

export const updateProductScheduleResponseChanged = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT SCHEDULE END *********************** */

/** ***************** UPDATE PRODUCT ADDITIONAL CHARGES START *********************** */
export const updateProductAdditionalChargesStart = (updateData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_START,
	payload: updateData,
});

export const updateProductAdditionalChargesSuccess = (successData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_SUCCESS,
	payload: successData,
});

export const updateProductAdditionalChargesFailure = (failureData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_FAILURE,
	payload: failureData,
});

export const updateProductAdditionalChargesResponseResetStart = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_RESET,
});

export const updateProductAdditionalChargesResponseChanged = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT ADDITIONAL CHARGES END *********************** */

/** ***************** GET PRODUCT LIST START *********************** */
export const getProductListStart = (getData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_START,
	payload: getData,
});

export const getProductListSuccess = (successData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_SUCCESS,
	payload: successData,
});

export const getProductListFailure = (failureData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_FAILURE,
	payload: failureData,
});

export const getProductListResponseResetStart = () => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
});

export const getProductListResponseChanged = () => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LIST END *********************** */

/** ***************** FETCH SEARCH PRODUCT LIST START *********************** */
export const fetchSearchProductListStart = (getData) => ({
	type: ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_START,
	payload: getData,
});

export const fetchSearchProductListSuccess = (successData) => ({
	type: ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_SUCCESS,
	payload: successData,
});

export const fetchSearchProductListFailure = (failureData) => ({
	type: ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchProductListResponseResetStart = () => ({
	type: ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_RESPONSE_RESET,
});

export const fetchSearchProductListResponseChanged = () => ({
	type: ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH SEARCH PRODUCT LIST END *********************** */

/** ***************** FETCH PRODUCT DETAILS START *********************** */
export const fetchProductDetailsStart = (fetchData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_START,
	payload: fetchData,
});

export const fetchProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const fetchProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCT DETAILS END *********************** */

/** ***************** DELETE PRODUCT DETAILS START *********************** */
export const deleteProductDetailsStart = (deleteData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_START,
	payload: deleteData,
});

export const deleteProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const deleteProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE PRODUCT DETAILS END *********************** */

/** ***************** FETCH ALL PRODUCTS START *********************** */
export const fetchAllProductsStart = (getData) => ({
	type: ProductActionTypes.FETCH_ALL_PRODUCT_START,
	payload: getData,
});

export const fetchAllProductsSuccess = (successData) => ({
	type: ProductActionTypes.FETCH_ALL_PRODUCT_SUCCESS,
	payload: successData,
});

export const fetchAllProductsFailure = (failureData) => ({
	type: ProductActionTypes.FETCH_ALL_PRODUCT_FAILURE,
	payload: failureData,
});

export const fetchAllProductsResponseResetStart = () => ({
	type: ProductActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET,
});

export const fetchAllProductsResponseChanged = () => ({
	type: ProductActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCTS END *********************** */

export const productScheduleListStart = (item) => ({
	type: ProductActionTypes.PRODUCT_SCHEDULE_LIST,
	payload: item,
});

export const productScheduleListStartReset = () => ({
	type: ProductActionTypes.PRODUCT_SCHEDULE_LIST_RESET,
});

export const productScheduleListUpdateStart = (item) => ({
	type: ProductActionTypes.PRODUCT_SCHEDULE_LIST_UPDATE,
	payload: item,
});

export const productScheduleListFetchStart = (item) => ({
	type: ProductActionTypes.PRODUCT_SCHEDULE_LIST_FETCH,
	payload: item,
});

export const updateProductTabValueStart = (updatedTab) => ({
	type: ProductActionTypes.PRODUCT_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});

export const updateProductCanvasValueStart = (canvas) => ({
	type: ProductActionTypes.PRODUCT_IS_OPEN_OFFCANVAS,
	payload: canvas,
});

export const productDetailsEditStart = (isEdit) => ({
	type: ProductActionTypes.IS_EDIT_PRODUCT_DETAILS,
	payload: isEdit,
});

export const productIDStart = (productID) => ({
	type: ProductActionTypes.PRODUCT_ID,
	payload: productID,
});

export const loadProductTypeIDStart = (productTypeID) => ({
	type: ProductActionTypes.PRODUCT_TYPE_ID,
	payload: productTypeID,
});
