/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectUser = (state) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

export const selectCurrentDecodedUser = createSelector(
	[selectUser],
	(login) => login.loginDeocodedResponse,
);

// export const getLoginDetails = createSelector([selectUser], (login) => {
// 	const { loginDeocodedResponse } = login;

// 	let userID = '';
// 	let companyID = '';
// 	let databaseName = '';
// 	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined) {
// 		const loginData = loginDeocodedResponse.data[0];
// 		userID = loginData.UserID;
// 		companyID = loginData.CompanyID;
// 		databaseName = loginData.DatabaseName;
// 	}
// 	return [userID, companyID, databaseName];
// });

export const getLoginObjDetails = createSelector([selectUser], (login) => {
	const { loginDeocodedResponse, currencySymbols } = login;
	let loginObj = '';
	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined) {
		const loginData = loginDeocodedResponse.data[0];
		loginObj = {
			userID: loginData.userID,
			userName: loginData.userName,
			databaseName: loginData.databaseName,
			userAccessBranchID: loginData.userAccessBranchID,
			userRole: loginData.userRoleName,
			userLevel: loginData.userLevel,
			generalCompanyID: loginData.generalCompanyID,
			limit: 0,
			companyID: loginData.Company[0].companyID,
			currency: currencySymbols,
			UserAccessData: loginData.UserAccessData,
		};
	}
	return loginObj;
});

export const selectLoginStatus = createSelector([selectUser], (login) => login.loginStatus);
