import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './login/login.reducer';
import GeneralReducer from './general/general.reducer';

import getDashboardReducer from './dashboard/dashboard.reducer';

/** ******************* MASTERS ************************** */
import BranchReducer from './masters/branch/branch.reducer';
import CenterReducer from './masters/center/center.reducer';
import RoleReducer from './masters/role/role.reducer';
import StaffReducer from './masters/staff/staff.reducer';
import GroupReducer from './masters/group/group.reducer';
import MemberReducer from './masters/member/member.reducer';
import ProductTypeReducer from './masters/product-type/product-type.reducer';
import ProductReducer from './masters/product/product.reducer';

/** ******************* OPERATIONS - COLLECTION ************************** */
import collectionReducer from './operations/collection/collection.reducer';
import applicantCollectionReducer from './operations/collection/applicantCollection/applicantCollection.reducer';
import routeCollectionReducer from './operations/collection/routeCollection/routeCollection.reducer';
import groupCollectionReducer from './operations/collection/groupCollection/groupCollection.reducer';
import memberCollectionReducer from './operations/collection/memberCollection/memberCollection.reducer';
import advanceCollectionReducer from './operations/collection/advanceCollection/advanceCollection.reducer';
import demandCollectionReducer from './operations/collection/demandCollection/demandCollection.reducer';
import chitIndexReducer from './operations/chitIndex/chitIndex.reducer';

/** ******************* REPORTS ************************** */
import chitMembersReportReducer from './reports/chitMembersReport/chitMembersReport.reducer';
import chitDemandReportReducer from './reports/chitDemandReport/chitDemandReport.reducer';
import DemandFollowUpReportReducer from './reports/demandFollowUpReport/demandFollowUpReport.reducer';
import RepaymentReportReducer from './reports/repaymentReport/repaymentReport.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: userReducer,
	general: GeneralReducer,
	dashboard_info: getDashboardReducer,

	// Masters
	branch_master: BranchReducer,
	center_master: CenterReducer,
	role_master: RoleReducer,
	staff_master: StaffReducer,
	group_master: GroupReducer,
	member_master: MemberReducer,
	product_type: ProductTypeReducer,
	product_master: ProductReducer,

	// operation - collection
	collection: collectionReducer,
	applicantCollection: applicantCollectionReducer,
	routeCollection: routeCollectionReducer,
	groupCollection: groupCollectionReducer,
	demandCollection: demandCollectionReducer,
	memberCollection: memberCollectionReducer,
	advanceCollection: advanceCollectionReducer,
	chit_index: chitIndexReducer,

	// reports
	chitMembersReport: chitMembersReportReducer,
	chitDemandReport: chitDemandReportReducer,
	demandFollowUpReport: DemandFollowUpReportReducer,
	repaymentReport: RepaymentReportReducer,
});

export default persistReducer(persistConfig, rootReducer);
