/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import RepaymentReportActionTypes from './repaymentReport.types';
import { fetchRepaymentReport } from './repaymentReport.service';

import {
	fetchRepaymentReportSuccess,
	fetchRepaymentReportFailure,
	fetchRepaymentReportResponseChanged,
} from './repaymentReport.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH REPAYMENT REPORT  ******************** */
export function* fetchRepaymentReportDetails({ payload }) {
	try {
		const key = 'FetchRepaymentReportKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchRepaymentReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchRepaymentReportFailure(responseData.data));
		} else {
			yield put(fetchRepaymentReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchRepaymentReportFailure(error));
	}
}

export function* resetFetchRepaymentReportResponse() {
	yield put(fetchRepaymentReportResponseChanged());
}

export function* onFetchRepaymentReportDetails() {
	yield takeLatest(
		RepaymentReportActionTypes.FETCH_REPAYMENT_REPORT_START,
		fetchRepaymentReportDetails,
	);
}

export function* onFetchRepaymentReportResponseReset() {
	yield takeLatest(
		RepaymentReportActionTypes.FETCH_REPAYMENT_REPORT_RESPONSE_RESET,
		resetFetchRepaymentReportResponse,
	);
}
/** *********************  END FETCH REPAYMENT REPORT ******************** */

export function* repaymentReportSaga() {
	yield all([call(onFetchRepaymentReportDetails), call(onFetchRepaymentReportResponseReset)]);
}
