/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import memberCollectionActionTypes from './memberCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getMemberChitListResponse: null,
	getMemberChitListArr: [],
	getMemberParticularChitInfoResponse: null,
	getMemberParticularChitInfoArr: [],
	postChitCollectionDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_SUCCESS:
			return {
				...state,
				getMemberChitListResponse: action.payload,
				getMemberChitListArr: docodeResponse(action.payload, 'FetchMemberChitListKey'),
			};

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_FAILURE:
			return { ...state, getMemberChitListResponse: action.payload };

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_CHIT_LIST_RESPONSE_CHANGED:
			return { ...state, getMemberChitListResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_SUCCESS:
			return {
				...state,
				getMemberParticularChitInfoResponse: action.payload,
				getMemberParticularChitInfoArr: docodeResponse(
					action.payload,
					'FetchMemberParticularChitInfoKey',
				),
			};

		case memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_FAILURE:
			return { ...state, getMemberParticularChitInfoResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_PARTICULAR_CHIT_INFO_RESPONSE_CHANGED:
			return { ...state, getMemberParticularChitInfoResponse: null };

		case memberCollectionActionTypes.POST_CHIT_LIST_SUCCESS:
			return { ...state, postChitCollectionDetailsResponse: action.payload };

		case memberCollectionActionTypes.POST_CHIT_LIST_FAILURE:
			return { ...state, postChitCollectionDetailsResponse: action.payload };

		case memberCollectionActionTypes.POST_CHIT_LIST_RESPONSE_CHANGED:
			return { ...state, postChitCollectionDetailsResponse: null };

		default:
			return state;
	}
};
