/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchActionTypes from './branch.types';
import {
	docodeResponse,
	addBankAccountArr,
	updateBankAccountArr,
	removeBankAccountArr,
	addBankChequeBookArr,
	updateBankChequeBookArr,
	removeBankChequeBookArr,
} from '../../common/common.utils';

const INITIAL_STATE = {
	addBranchDetailsResponse: null,
	addBranchBankAccountDetailsResponse: null,
	addBranchBankChequeBookDetailsResponse: null,
	updateBranchDetailsResponse: null,
	updateBranchBankAccountDetailsResponse: null,
	updateBranchBankChequeBookDetailsResponse: null,
	getBranchListResponse: null,
	getBranchListArr: [],
	getSearchBranchListResponse: null,
	getSearchBranchListArr: [],
	fetchBranchDetailsResponse: null,
	fetchBranchDetailsArr: [],
	fetchBranchBankAccountListResponse: null,
	fetchBranchBankAccountListArr: [],
	fetchBranchBankChequeBookListResponse: null,
	fetchBranchBankChequeBookListArr: [],
	deleteBranchDetailsResponse: null,
	deleteBranchBankResponse: null,
	deleteBranchBankChequeBookResponse: null,
	tabChangeValue: 'Branch Details',
	isShowOffCanvas: false,
	isBranchEdit: false,
	getBranchID: '',
	bankAccountListArr: [],
	bankChequeBookListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchActionTypes.ADD_BRANCH_DETAILS_SUCCESS:
			return { ...state, addBranchDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_DETAILS_FAILURE:
			return { ...state, addBranchDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBranchDetailsResponse: null };

		case BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS:
			return { ...state, addBranchBankAccountDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE:
			return { ...state, addBranchBankAccountDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBranchBankAccountDetailsResponse: null };

		case BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS:
			return { ...state, addBranchBankChequeBookDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE:
			return { ...state, addBranchBankChequeBookDetailsResponse: action.payload };

		case BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBranchBankChequeBookDetailsResponse: null };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_SUCCESS:
			return { ...state, updateBranchDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_FAILURE:
			return { ...state, updateBranchDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBranchDetailsResponse: null };

		case BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS:
			return { ...state, updateBranchBankAccountDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE:
			return { ...state, updateBranchBankAccountDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBranchBankAccountDetailsResponse: null };

		case BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS:
			return { ...state, updateBranchBankChequeBookDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE:
			return { ...state, updateBranchBankChequeBookDetailsResponse: action.payload };

		case BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBranchBankChequeBookDetailsResponse: null };

		case BranchActionTypes.GET_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getBranchListResponse: action.payload,
				getBranchListArr: docodeResponse(action.payload, 'FetchBranchListKey'),
			};

		case BranchActionTypes.GET_BRANCH_LIST_FAILURE:
			return { ...state, getBranchListResponse: action.payload };

		case BranchActionTypes.GET_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchListResponse: null };

		case BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getSearchBranchListResponse: action.payload,
				getSearchBranchListArr: docodeResponse(action.payload, 'FetchSearchBranchListKey'),
			};

		case BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_FAILURE:
			return { ...state, getSearchBranchListResponse: action.payload };

		case BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getSearchBranchListResponse: null };

		case BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS:
			return {
				...state,
				fetchBranchDetailsResponse: action.payload,
				fetchBranchDetailsArr: docodeResponse(action.payload, 'FetchBranchInfoKey'),
			};

		case BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE:
			return { ...state, fetchBranchDetailsResponse: action.payload };

		case BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchBranchDetailsResponse: null, fetchBranchDetailsArr: [] };

		case BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_SUCCESS:
			return {
				...state,
				fetchBranchBankAccountListResponse: action.payload,
				fetchBranchBankAccountListArr: docodeResponse(
					action.payload,
					'FetchBranchBankAccountListKey',
				),
			};

		case BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_FAILURE:
			return { ...state, fetchBranchBankAccountListResponse: action.payload };

		case BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_CHANGED:
			return { ...state, fetchBranchBankAccountListResponse: null };

		case BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_SUCCESS:
			return {
				...state,
				fetchBranchBankChequeBookListResponse: action.payload,
				fetchBranchBankChequeBookListArr: docodeResponse(
					action.payload,
					'FetchBranchBankChequeBookListKey',
				),
			};

		case BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_FAILURE:
			return { ...state, fetchBranchBankChequeBookListResponse: action.payload };

		case BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_CHANGED:
			return { ...state, fetchBranchBankChequeBookListResponse: null };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_SUCCESS:
			return { ...state, deleteBranchDetailsResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_FAILURE:
			return { ...state, deleteBranchDetailsResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteBranchDetailsResponse: null };

		case BranchActionTypes.DELETE_BRANCH_BANK_SUCCESS:
			return { ...state, deleteBranchBankResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_BANK_FAILURE:
			return { ...state, deleteBranchBankResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_BANK_RESPONSE_CHANGED:
			return { ...state, deleteBranchBankResponse: null };

		case BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_SUCCESS:
			return { ...state, deleteBranchBankChequeBookResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_FAILURE:
			return { ...state, deleteBranchBankChequeBookResponse: action.payload };

		case BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_CHANGED:
			return { ...state, deleteBranchBankChequeBookResponse: null };

		case BranchActionTypes.BRANCH_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, tabChangeValue: action.payload };

		case BranchActionTypes.BRANCH_IS_OPEN_OFFCANVAS:
			return { ...state, isShowOffCanvas: action.payload };

		case BranchActionTypes.IS_EDIT_BRANCH_DETAILS:
			return { ...state, isBranchEdit: action.payload };

		case BranchActionTypes.BRANCH_ID:
			return { ...state, getBranchID: action.payload };

		case BranchActionTypes.BANK_ACCOUNT_LIST_ARR:
			return {
				...state,
				bankAccountListArr: addBankAccountArr(state.bankAccountListArr, action.payload),
			};

		case BranchActionTypes.BANK_ACCOUNT_LIST_ARR_UPDATE:
			return {
				...state,
				bankAccountListArr: updateBankAccountArr(state.bankAccountListArr, action.payload),
			};

		case BranchActionTypes.BANK_ACCOUNT_LIST_ARR_REMOVE:
			return {
				...state,
				bankAccountListArr: removeBankAccountArr(state.bankAccountListArr, action.payload),
			};

		case BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR:
			return {
				...state,
				bankChequeBookListArr: addBankChequeBookArr(
					state.bankChequeBookListArr,
					action.payload,
				),
			};

		case BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR_UPDATE:
			return {
				...state,
				bankChequeBookListArr: updateBankChequeBookArr(
					state.bankChequeBookListArr,
					action.payload,
				),
			};

		case BranchActionTypes.BANK_CHEQUE_BOOK_LIST_ARR_REMOVE:
			return {
				...state,
				bankChequeBookListArr: removeBankChequeBookArr(
					state.bankChequeBookListArr,
					action.payload,
				),
			};

		default:
			return state;
	}
};
