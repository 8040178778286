/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const staffDetailsAdd = (addData) => {
	const data = {
		AddStaffDetailsToken: addData,
	};
	const url = `${API_URL}addStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffAddressDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffAddressDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffAddressDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffProofDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffProofDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffProofDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffEmergencyDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffEmergencyDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffEmergencyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffOfficialDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffOfficialDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffOfficialDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffTargetDetailsAdd = (addData) => {
	const data = {
		AddStaffTargetDetailsToken: addData,
	};
	const url = `${API_URL}addStaffTargetDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffTargetDetailsUpdate = (updateData) => {
	const data = {
		UpdateStaffTargetDetailsToken: updateData,
	};
	const url = `${API_URL}updateStaffTargetDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffListGet = (listData) => {
	const data = {
		FetchStaffListToken: listData,
	};
	const url = `${API_URL}FetchStaffListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchSearchStaffList = (listData) => {
	const data = {
		FetchSearchStaffListToken: listData,
	};
	const url = `${API_URL}FetchSearchStaffListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsFetch = (fetchData) => {
	const data = {
		FetchStaffInfoToken: fetchData,
	};
	const url = `${API_URL}FetchStaffInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffDetailsDelete = (deleteData) => {
	const data = {
		DeleteStaffDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffPublishStatusUpdate = (deleteData) => {
	const data = {
		UpdateStaffPublishToken: deleteData,
	};
	const url = `${API_URL}updatePublishStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allStaffFetch = (fetchData) => {
	const data = {
		FetchAllStaffListToken: fetchData,
	};
	const url = `${API_URL}FetchAllStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const agentStaffFetch = (fetchData) => {
	const data = {
		FetchAgentStaffListToken: fetchData,
	};
	const url = `${API_URL}FetchAgentStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffTargetListFetch = (fetchData) => {
	const data = {
		FetchStaffTargetListToken: fetchData,
	};
	const url = `${API_URL}FetchStaffTargetList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
