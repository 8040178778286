/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import collectionActionTypes from './collection.types';

/** ***************** GET BRANCH COLLECTION USERS LIST START *********************** */
export const fetchBranchCollectionUsersListStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
	payload: getData,
});

export const getBranchCollectionUsersListSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS,
	payload: successData,
});

export const getBranchCollectionUsersListFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchCollectionUsersListResponseResetStart = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
});

export const getBranchCollectionUsersListResponseChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST END *********************** */

/** ***************** FETCH COLLECTED USER CENTERS LIST START *********************** */
export const fetchCollectedUsersCentersListStart = (getData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_START,
	payload: getData,
});

export const fetchCollectedUsersCentersListSuccess = (successData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_SUCCESS,
	payload: successData,
});

export const fetchCollectedUsersCentersListFailure = (failureData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchCollectedUsersCentersListResponseResetStart = () => ({
	type: collectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_RESET,
});

export const fetchCollectedUsersCentersListResponseChanged = () => ({
	type: collectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH COLLECTED USER CENTERS LIST END *********************** */

/** ***************** FETCH COLLECTED CENTER GROUPS LIST START *********************** */
export const fetchCollectedCenterGroupsListStart = (getData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_START,
	payload: getData,
});

export const fetchCollectedCenterGroupsListSuccess = (successData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_SUCCESS,
	payload: successData,
});

export const fetchCollectedCenterGroupsListFailure = (failureData) => ({
	type: collectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_FAILURE,
	payload: failureData,
});

export const fetchCollectedCenterGroupsListResponseResetStart = () => ({
	type: collectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_RESET,
});

export const fetchCollectedCenterGroupsListResponseChanged = () => ({
	type: collectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH COLLECTED CENTER GROUPS LIST END *********************** */

/** ***************** FETCH COLLECTION APPROVE LIST START *********************** */
export const fetchCollectionApproveListStart = (getData) => ({
	type: collectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_START,
	payload: getData,
});

export const fetchCollectionApproveListSuccess = (successData) => ({
	type: collectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_SUCCESS,
	payload: successData,
});

export const fetchCollectionApproveListFailure = (failureData) => ({
	type: collectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_FAILURE,
	payload: failureData,
});

export const fetchCollectionApproveListResponseResetStart = () => ({
	type: collectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_RESPONSE_RESET,
});

export const fetchCollectionApproveListResponseChanged = () => ({
	type: collectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH COLLECTED CENTER GROUPS LIST END *********************** */
