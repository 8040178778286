/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import groupCollectionActionTypes from './groupCollection.types';

/** ***************** ADD GROUP COLLECTION START *********************** */
export const addGroupCollectionDetailsStart = (getData) => ({
	type: groupCollectionActionTypes.ADD_GROUP_COLLECTION_START,
	payload: getData,
});

export const addGroupCollectionDetailsSuccess = (successData) => ({
	type: groupCollectionActionTypes.ADD_GROUP_COLLECTION_SUCCESS,
	payload: successData,
});

export const addGroupCollectionDetailsFailure = (failureData) => ({
	type: groupCollectionActionTypes.ADD_GROUP_COLLECTION_FAILURE,
	payload: failureData,
});

export const addGroupCollectionDetailsResponseResetStart = () => ({
	type: groupCollectionActionTypes.ADD_GROUP_COLLECTION_RESPONSE_RESET,
});

export const addGroupCollectionDetailsResponseChanged = () => ({
	type: groupCollectionActionTypes.ADD_GROUP_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** ADD GROUP COLLECTION END *********************** */
