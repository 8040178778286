const ChitIndexActionTypes = {
	ADD_CHIT_INDEX_DETAILS_START: 'add_chit_index_details_start',
	ADD_CHIT_INDEX_DETAILS_SUCCESS: 'add_chit_index_details_success',
	ADD_CHIT_INDEX_DETAILS_FAILURE: 'add_chit_index_details_failure',
	ADD_CHIT_INDEX_DETAILS_RESPONSE_RESET: 'add_chit_index_details_response_reset',
	ADD_CHIT_INDEX_DETAILS_RESPONSE_CHANGED: 'add_chit_index_details_response_changed',

	ADD_CHIT_INDEX_MEMBERS_DETAILS_START: 'add_chit_index_members_details_start',
	ADD_CHIT_INDEX_MEMBERS_DETAILS_SUCCESS: 'add_chit_index_members_details_success',
	ADD_CHIT_INDEX_MEMBERS_DETAILS_FAILURE: 'add_chit_index_members_details_failure',
	ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_RESET: 'add_chit_index_members_details_response_reset',
	ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_CHANGED:
		'add_chit_index_members_details_response_changed',

	UPDATE_CHIT_INDEX_DETAILS_START: 'update_chit_index_details_start',
	UPDATE_CHIT_INDEX_DETAILS_SUCCESS: 'update_chit_index_details_success',
	UPDATE_CHIT_INDEX_DETAILS_FAILURE: 'update_chit_index_details_failure',
	UPDATE_CHIT_INDEX_DETAILS_RESPONSE_RESET: 'update_chit_index_details_response_reset',
	UPDATE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED: 'update_chit_index_details_response_changed',

	UPDATE_CHIT_INDEX_MEMBERS_DETAILS_START: 'update_chit_index_members_details_start',
	UPDATE_CHIT_INDEX_MEMBERS_DETAILS_SUCCESS: 'update_chit_index_members_details_success',
	UPDATE_CHIT_INDEX_MEMBERS_DETAILS_FAILURE: 'update_chit_index_members_details_failure',
	UPDATE_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_RESET:
		'update_chit_index_members_details_response_reset',
	UPDATE_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_CHANGED:
		'update_chit_index_members_details_response_changed',

	FETCH_CHIT_INDEX_LIST_START: 'fetch_chit_index_list_start',
	FETCH_CHIT_INDEX_LIST_SUCCESS: 'fetch_chit_index_list_success',
	FETCH_CHIT_INDEX_LIST_FAILURE: 'fetch_chit_index_list_failure',
	FETCH_CHIT_INDEX_LIST_RESPONSE_RESET: 'fetch_chit_index_list_response_reset',
	FETCH_CHIT_INDEX_LIST_RESPONSE_CHANGED: 'fetch_chit_index_list_response_changed',

	FETCH_CHIT_INDEX_DETAILS_START: 'fetch_chit_index_details_start',
	FETCH_CHIT_INDEX_DETAILS_SUCCESS: 'fetch_chit_index_details_success',
	FETCH_CHIT_INDEX_DETAILS_FAILURE: 'fetch_chit_index_details_failure',
	FETCH_CHIT_INDEX_DETAILS_RESPONSE_RESET: 'fetch_chit_index_details_response_reset',
	FETCH_CHIT_INDEX_DETAILS_RESPONSE_CHANGED: 'fetch_chit_index_details_response_changed',

	DELETE_CHIT_INDEX_DETAILS_START: 'delete_chit_index_details_start',
	DELETE_CHIT_INDEX_DETAILS_SUCCESS: 'delete_chit_index_details_success',
	DELETE_CHIT_INDEX_DETAILS_FAILURE: 'delete_chit_index_details_failure',
	DELETE_CHIT_INDEX_DETAILS_RESPONSE_RESET: 'delete_chit_index_details_response_reset',
	DELETE_CHIT_INDEX_DETAILS_RESPONSE_CHANGED: 'delete_chit_index_details_response_changed',

	CHIT_INDEX_SUBMIT_TAB_VALUE_CHANGED: 'chit_index_submit_tab_value_changed',
	CHIT_INDEX_IS_OPEN_OFFCANVAS: 'chit_index_is_open_offcanvas',
	IS_EDIT_CHIT_INDEX_DETAILS: 'is_edit_chit_index_details',
	CHIT_INDEX_ID: 'chitIndex_id',
	CHIT_PRODUCT_SCHEDULE: 'CHIT_PRODUCT_SCHEDULE',
};
export default ChitIndexActionTypes;
