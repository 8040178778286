/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DashboardActionTypes from './dashboard.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	fetchDashboardDetailsResponse: null,
	fetchDashboardDetailsArr: [],
	fetchOneWeekDemandDetailsResponse: null,
	fetchOneWeekDemandDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS:
			return {
				...state,
				fetchDashboardDetailsResponse: action.payload,
				fetchDashboardDetailsArr: docodeResponse(action.payload, 'FetchDashboardInfoKey'),
			};

		case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_FAILURE:
			return { ...state, fetchDashboardDetailsResponse: action.payload };

		case DashboardActionTypes.FETCH_DASHBOARD_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchDashboardDetailsResponse: null };

		case DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_SUCCESS:
			return {
				...state,
				fetchOneWeekDemandDetailsResponse: action.payload,
				fetchOneWeekDemandDetailsArr: docodeResponse(
					action.payload,
					'FetchOneWeekDemandInfoKey',
				),
			};

		case DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_FAILURE:
			return { ...state, fetchOneWeekDemandDetailsResponse: action.payload };

		case DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchOneWeekDemandDetailsResponse: null };

		default:
			return state;
	}
};
