/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CollectionActionTypes from './collection.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getBranchCollectionUsersListResponse: null,
	getBranchCollectionUsersListArr: [],
	getCollectedUserCentersListResponse: null,
	getCollectedUserCentersListArr: [],
	getCollectedCenterGroupsListResponse: null,
	getCollectedCenterGroupsListArr: [],
	getCollectionApproveListResponse: null,
	getCollectionApproveListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE:
			return { ...state, getBranchCollectionUsersListResponse: action.payload };

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED:
			return { ...state, getBranchCollectionUsersListResponse: null };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS:
			return {
				...state,
				getBranchCollectionUsersListResponse: action.payload,
				getBranchCollectionUsersListArr: docodeResponse(
					action.payload,
					'FetchBranchCollectionOfficersKey',
				),
			};

		case CollectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_FAILURE:
			return { ...state, getCollectedUserCentersListResponse: action.payload };

		case CollectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_CHANGED:
			return { ...state, getCollectedUserCentersListResponse: null };

		case CollectionActionTypes.FETCH_COLLECTED_USER_CENTERS_LIST_SUCCESS:
			return {
				...state,
				getCollectedUserCentersListResponse: action.payload,
				getCollectedUserCentersListArr: docodeResponse(
					action.payload,
					'FetchCollectedOfficersCentersKey',
				),
			};

		case CollectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_FAILURE:
			return { ...state, getCollectedCenterGroupsListResponse: action.payload };

		case CollectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_CHANGED:
			return { ...state, getCollectedCenterGroupsListResponse: null };

		case CollectionActionTypes.FETCH_COLLECTED_CENTER_GROUPS_LIST_SUCCESS:
			return {
				...state,
				getCollectedCenterGroupsListResponse: action.payload,
				getCollectedCenterGroupsListArr: docodeResponse(
					action.payload,
					'FetchCollectedCenterGroupListKey',
				),
			};

		case CollectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_FAILURE:
			return { ...state, getCollectionApproveListResponse: action.payload };

		case CollectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_RESPONSE_CHANGED:
			return { ...state, getCollectionApproveListResponse: null };

		case CollectionActionTypes.FETCH_COLLECTION_APPROVE_LIST_SUCCESS:
			return {
				...state,
				getCollectionApproveListResponse: action.payload,
				getCollectionApproveListArr: docodeResponse(
					action.payload,
					'FetchCollectionsListForApproveKey',
				),
			};

		default:
			return state;
	}
};
