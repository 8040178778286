import React from 'react';

import Header, { HeaderLeft } from '../../../layout/Header/Header';
import { CardLabel, CardTitle } from '../../../components/bootstrap/Card';

import CommonHeaderChat from './CommonHeaderChat';
import CommonHeaderRight from './CommonHeaderRight';

const DashboardHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<div>
					<CardLabel iconColor='info'>
						<CardTitle tag='h4' className='h5'>
							<b>SquareNow Chit fund private limited</b>
						</CardTitle>
					</CardLabel>
				</div>
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
		</Header>
	);
};

export default DashboardHeader;
