const generalTypesAction = {
	LOGIN_KEY: 'FetchStaffLoginInfoKey',
	CENTERBASEDONBRACH: 'CENTERBASEDONBRACH',
	FETCHALLOFFICERUSER: 'FETCHALLOFFICERUSER',
	LOADADMINAREALIST: 'LOADADMINAREALIST',
	GROUPLISTBASEDONLOCATION: 'GROUPLISTBASEDONLOCATION',
	DCRBASEDONBranch: 'DCRBASEDONBranch',
	DCRBASEDONGROUPORDER: 'DCRBASEDONGROUPORDER',
	DCRBASEDONBRANCHORDER: 'DCRBASEDONBRANCHORDER',
	PUBLISHCHECK: 'PUBLISHCHECK',
	ALLCOMPANYECONOMICACTIVITY: 'ALLCOMPANYECONOMICACTIVITY',
	INVESTORDETAILSBASEDONCOMPANY: 'INVESTORDETAILSBASEDONCOMPANY',
	ALLPURPOSEOFLOANLIST: 'ALLPURPOSEOFLOANLIST',
	LOANDISBURSEMENTTOKEN: 'LOANDISBURSEMENTTOKEN',
	FETCHALLGOLDMEMBERDETAILREPORT: 'FETCHALLGOLDMEMBERDETAILREPORT',
	FETCHALLMEMBERDETAILREPORT: 'FETCHALLMEMBERDETAILREPORT',
	ADMIN_AREA_LIST: 'ADMINAREALIST',
	All_BRANCH_COLLECTION: 'ALLBRANCHCOLLECTION',
	GROUPMEMBERLIST: 'GROUPMEMBERLIST',
	REPAYMENTCOLLECTION: 'REPAYMENTCOLLECTION',
	REPAYMENTCOLLECTIONEXCEL: 'REPAYMENTCOLLECTIONEXCEL',
	CENTER_BASED_BRANCH: 'CENTERBASEDBRANCHLOCATION',
	TRAINING_DETAILS_BRANCH: 'TRAININGDETAILSFORBRANCH',
	WALLETCOLLECTIONREPORT: 'WALLETCOLLECTIONREPORT',
	ALLOFFICERUSERBASEDONBRANCH: 'ALLOFFICERUSERBASEDONBRANCH',
	FETCHBRANCHPROGRESSREPORT: 'FETCHBRANCHPROGRESSREPORT',
	BRANCHDCBREPORTKEY: 'BRANCHDCBREPORTKEY',
};

export default generalTypesAction;
