const CollectionActionTypes = {
	GET_BRANCH_COLLECTION_USERS_LIST_START: 'GET_BRANCH_COLLECTION_USERS_LIST_START',
	GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS: 'GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS',
	GET_BRANCH_COLLECTION_USERS_LIST_FAILURE: 'GET_BRANCH_COLLECTION_USERS_LIST_FAILURE',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED',

	FETCH_COLLECTED_USER_CENTERS_LIST_START: 'FETCH_COLLECTED_USER_CENTERS_LIST_START',
	FETCH_COLLECTED_USER_CENTERS_LIST_SUCCESS: 'FETCH_COLLECTED_USER_CENTERS_LIST_SUCCESS',
	FETCH_COLLECTED_USER_CENTERS_LIST_FAILURE: 'FETCH_COLLECTED_USER_CENTERS_LIST_FAILURE',
	FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_RESET:
		'FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_RESET',
	FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_CHANGED:
		'FETCH_COLLECTED_USER_CENTERS_LIST_RESPONSE_CHANGED',

	FETCH_COLLECTED_CENTER_GROUPS_LIST_START: 'FETCH_COLLECTED_CENTER_GROUPS_LIST_START',
	FETCH_COLLECTED_CENTER_GROUPS_LIST_SUCCESS: 'FETCH_COLLECTED_CENTER_GROUPS_LIST_SUCCESS',
	FETCH_COLLECTED_CENTER_GROUPS_LIST_FAILURE: 'FETCH_COLLECTED_CENTER_GROUPS_LIST_FAILURE',
	FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_RESET:
		'FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_RESET',
	FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_CHANGED:
		'FETCH_COLLECTED_CENTER_GROUPS_LIST_RESPONSE_CHANGED',

	FETCH_COLLECTION_APPROVE_LIST_START: 'FETCH_COLLECTION_APPROVE_LIST_START',
	FETCH_COLLECTION_APPROVE_LIST_SUCCESS: 'FETCH_COLLECTION_APPROVE_LIST_SUCCESS',
	FETCH_COLLECTION_APPROVE_LIST_FAILURE: 'FETCH_COLLECTION_APPROVE_LIST_FAILURE',
	FETCH_COLLECTION_APPROVE_LIST_RESPONSE_RESET: 'FETCH_COLLECTION_APPROVE_LIST_RESPONSE_RESET',
	FETCH_COLLECTION_APPROVE_LIST_RESPONSE_CHANGED:
		'FETCH_COLLECTION_APPROVE_LIST_RESPONSE_CHANGED',
};
export default CollectionActionTypes;
