/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ChitDemandReportActionTypes from './chitDemandReport.types';

/** ***************** FETCH CHIT DEMAND REPORT START *********************** */
export const fetchChitDemandReportStart = (getData) => ({
	type: ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_START,
	payload: getData,
});

export const fetchChitDemandReportSuccess = (successData) => ({
	type: ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_SUCCESS,
	payload: successData,
});

export const fetchChitDemandReportFailure = (failureData) => ({
	type: ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_FAILURE,
	payload: failureData,
});

export const fetchChitDemandReportResponseResetStart = () => ({
	type: ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_RESPONSE_RESET,
});

export const fetchChitDemandReportResponseChanged = () => ({
	type: ChitDemandReportActionTypes.FETCH_CHIT_DEMAND_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH CHIT DEMAND REPORT END *********************** */
