/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import DashboardActionTypes from './dashboard.types';

/** ***************** FETCH DASHBOARD DETAILS START *********************** */
export const fetchDashboardDetailsStart = (fetchData) => ({
	type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_START,
	payload: fetchData,
});

export const fetchDashboardDetailsSuccess = (successData) => ({
	type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchDashboardDetailsFailure = (failureData) => ({
	type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchDashboardDetailsResponseResetStart = () => ({
	type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_RESPONSE_RESET,
});

export const fetchDashboardDetailsResponseChanged = () => ({
	type: DashboardActionTypes.FETCH_DASHBOARD_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH DASHBOARD DETAILS END *********************** */

/** ***************** FETCH ONE WEEK DEMAND DETAILS START *********************** */
export const fetchOneWeekDemandDetailsStart = (fetchData) => ({
	type: DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_START,
	payload: fetchData,
});

export const fetchOneWeekDemandDetailsSuccess = (successData) => ({
	type: DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchOneWeekDemandDetailsFailure = (failureData) => ({
	type: DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchOneWeekDemandDetailsResponseResetStart = () => ({
	type: DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_RESET,
});

export const fetchOneWeekDemandDetailsResponseChanged = () => ({
	type: DashboardActionTypes.FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH ONE WEEK DEMAND DETAILS END *********************** */
