/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */
import GroupActionTypes from './group.types';

/** ***************** ADD GROUP DETAILS START *********************** */
export const addGroupDetailsStart = (addData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_START,
	payload: addData,
});

export const addGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const addGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const addGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_RESET,
});

export const addGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD GROUP DETAILS END *********************** */

/** ***************** ADD MAP MEMBERS TO GROUP DETAILS START *********************** */
export const addMapMembersToGroupDetailsStart = (addData) => ({
	type: GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_START,
	payload: addData,
});

export const addMapMembersToGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const addMapMembersToGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const addMapMembersToGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_RESET,
});

export const addMapMembersToGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD MAP MEMBERS TO GROUP DETAILS END *********************** */

/** ***************** UPDATE GROUP DETAILS START *********************** */
export const updateGroupDetailsStart = (addData) => ({
	type: GroupActionTypes.UPDATE_GROUP_DETAILS_START,
	payload: addData,
});

export const updateGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.UPDATE_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const updateGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.UPDATE_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const updateGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.UPDATE_GROUP_DETAILS_RESPONSE_RESET,
});

export const updateGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.UPDATE_GROUP_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE GROUP DETAILS END *********************** */

/** ***************** GET GROUP LIST START *********************** */
export const getGroupListStart = (getData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_START,
	payload: getData,
});

export const getGroupListSuccess = (successData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const getGroupListFailure = (failureData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const getGroupListResponseResetStart = () => ({
	type: GroupActionTypes.GET_GROUP_LIST_RESPONSE_RESET,
});

export const getGroupListResponseChanged = () => ({
	type: GroupActionTypes.GET_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** GET GROUP LIST END *********************** */

/** ***************** SEARCH GROUP LIST START *********************** */
export const getSearchGroupListStart = (getData) => ({
	type: GroupActionTypes.GET_SEARCH_GROUP_LIST_START,
	payload: getData,
});

export const getSearchGroupListSuccess = (successData) => ({
	type: GroupActionTypes.GET_SEARCH_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const getSearchGroupListFailure = (failureData) => ({
	type: GroupActionTypes.GET_SEARCH_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const getSearchGroupListResponseResetStart = () => ({
	type: GroupActionTypes.GET_SEARCH_GROUP_LIST_RESPONSE_RESET,
});

export const getSearchGroupListResponseChanged = () => ({
	type: GroupActionTypes.GET_SEARCH_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** SEARCH GROUP LIST END *********************** */

/** ***************** GET GROUP INFO START *********************** */
export const getGroupInfoStart = (getData) => ({
	type: GroupActionTypes.GET_GROUP_INFO_START,
	payload: getData,
});

export const getGroupInfoSuccess = (successData) => ({
	type: GroupActionTypes.GET_GROUP_INFO_SUCCESS,
	payload: successData,
});

export const getGroupInfoFailure = (failureData) => ({
	type: GroupActionTypes.GET_GROUP_INFO_FAILURE,
	payload: failureData,
});

export const getGroupInfoResponseResetStart = () => ({
	type: GroupActionTypes.GET_GROUP_INFO_RESPONSE_RESET,
});

export const getGroupInfoResponseChanged = () => ({
	type: GroupActionTypes.GET_GROUP_INFO_RESPONSE_CHANGED,
});
/** ***************** GET GROUP INFO END *********************** */

/** ***************** DELETE GROUP DETAILS START *********************** */
export const deleteGroupDetailsStart = (deleteData) => ({
	type: GroupActionTypes.DELETE_GROUP_DETAILS_START,
	payload: deleteData,
});

export const deleteGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.DELETE_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.DELETE_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.DELETE_GROUP_DETAILS_RESPONSE_RESET,
});

export const deleteGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.DELETE_GROUP_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE GROUP DETAILS END *********************** */

/** ***************** FETCH ALL GROUP START *********************** */
export const fetchAllGroupStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_START,
	payload: fetchGroup,
});

export const fetchAllGroupSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_SUCCESS,
	payload: successData,
});

export const fetchAllGroupFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_FAILURE,
	payload: failureData,
});

export const fetchAllGroupResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET_START,
});

export const fetchAllGroupResponseChanged = () => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL GROUP END *********************** */

/** ***************** FETCH PRODUCT GROUP LIST START *********************** */
export const fetchProductGroupListStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_START,
	payload: fetchGroup,
});

export const fetchProductGroupListSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const fetchProductGroupListFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const fetchProductGroupListResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_RESPONSE_RESET,
});

export const fetchProductGroupListResponseChanged = () => ({
	type: GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH PRODUCT GROUP LIST END *********************** */

/** ***************** FETCH BRANCH GROUP LIST START *********************** */
export const fetchBranchGroupListStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_BRANCH_GROUP_LIST_START,
	payload: fetchGroup,
});

export const fetchBranchGroupListSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_BRANCH_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const fetchBranchGroupListFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_BRANCH_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchGroupListResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_BRANCH_GROUP_LIST_RESPONSE_RESET,
});

export const fetchBranchGroupListResponseChanged = () => ({
	type: GroupActionTypes.FETCH_BRANCH_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH BRANCH GROUP LIST END *********************** */

/** ***************** FETCH GROUP UNIQUE ID START *********************** */
export const fetchGroupUniqueIDStart = (fetchGroups) => ({
	type: GroupActionTypes.FETCH_GROUP_UNIQUE_ID_START,
	payload: fetchGroups,
});

export const fetchGroupUniqueIDSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_GROUP_UNIQUE_ID_SUCCESS,
	payload: successData,
});

export const fetchGroupUniqueIDFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_GROUP_UNIQUE_ID_FAILURE,
	payload: failureData,
});

export const fetchGroupUniqueIDResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_GROUP_UNIQUE_ID_RESPONSE_RESET,
});

export const fetchGroupUniqueIDResponseChanged = () => ({
	type: GroupActionTypes.FETCH_GROUP_UNIQUE_ID_RESPONSE_CHANGED,
});
/** ***************** FETCH GROUP UNIQUE ID END *********************** */

/** ***************** FETCH GROUP MEMBERS START *********************** */
export const fetchGroupMembersStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_START,
	payload: fetchGroup,
});

export const fetchGroupMembersSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_SUCCESS,
	payload: successData,
});

export const fetchGroupMembersFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchGroupMembersResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_RESPONSE_RESET_START,
});

export const fetchGroupMembersResponseChanged = () => ({
	type: GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_RESPONSE_CHANGED,
});

export const updateMembersForGroupChitStart = (fetchMembers) => ({
	type: GroupActionTypes.UPDATE_MEMBERS_FOR_GROUP_CHIT_START,
	payload: fetchMembers,
});
/** ***************** FETCH GROUP MEMBERS END *********************** */

/** ***************** FETCH ALL APPROVED MEMBER START *********************** */
export const fetchAllApprovedMemberStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_START,
	payload: fetchGroup,
});

export const fetchAllApprovedMemberSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_SUCCESS,
	payload: successData,
});

export const fetchAllApprovedMemberFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_FAILURE,
	payload: failureData,
});

export const fetchAllApprovedMemberResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START,
});

export const fetchAllApprovedMemberResponseChanged = () => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL APPROVED MEMBER END *********************** */

/** ***************** GET ALL OFFICER LIST START *********************** */
export const getAllOfficerStart = (getData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_START,
	payload: getData,
});

export const getAllOfficerSuccess = (successData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_SUCCESS,
	payload: successData,
});

export const getAllOfficerFailure = (failureData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_FAILURE,
	payload: failureData,
});

export const getAllOfficerResponseResetStart = () => ({
	type: GroupActionTypes.GET_ALL_OFFICER_RESPONSE_RESET,
});

export const getAllOfficerResponseChanged = () => ({
	type: GroupActionTypes.GET_ALL_OFFICER_RESPONSE_CHANGED,
});
/** ***************** GET ALL OFFICER LIST END *********************** */

/** ***************** GET ALL GROUP DETAILS START *********************** */
export const getAllGroupDetailsStart = (getData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_START,
	payload: getData,
});

export const getAllGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const getAllGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const getAllGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_RESET,
});

export const getAllGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL GROUP DETAILS END *********************** */

/** ***************** GET BRANCH OFFICER LIST START *********************** */
export const fetchBranchOfficerStart = (getData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_START,
	payload: getData,
});

export const getBranchOfficerSuccess = (successData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_SUCCESS,
	payload: successData,
});

export const getBranchOfficerFailure = (failureData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_FAILURE,
	payload: failureData,
});

export const fetchBranchOfficerResponseResetStart = () => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_RESET,
});

export const getBranchOfficerResponseChanged = () => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER LIST END *********************** */

export const fetchGroupIDStart = (groupID) => ({
	type: GroupActionTypes.FETCH_GROUP_ID_START,
	payload: groupID,
});
