/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchActionTypes from './branch.types';
import {
	branchDetailsAdd,
	branchBankAccountDetailsAdd,
	branchBankChequeBookDetailsAdd,
	branchDetailsUpdate,
	branchBankAccountDetailsUpdate,
	branchBankChequeBookDetailsUpdate,
	branchListGet,
	fetchSearchBranchListGet,
	branchDetailsFetch,
	branchDetailsDelete,
	branchBankDetailsDelete,
	branchBankChequeBookDetailsDelete,
	branchBankAccountListFetch,
	branchBankChequeBookListFetch,
} from './branch.service';

import {
	addBranchDetailsSuccess,
	addBranchDetailsFailure,
	addBranchDetailsResponseChanged,
	addBranchBankAccountDetailsSuccess,
	addBranchBankAccountDetailsFailure,
	addBranchBankAccountDetailsResponseChanged,
	addBranchBankChequeBookDetailsSuccess,
	addBranchBankChequeBookDetailsFailure,
	addBranchBankChequeBookDetailsResponseChanged,
	updateBranchDetailsSuccess,
	updateBranchDetailsFailure,
	updateBranchDetailsResponseChanged,
	updateBranchBankAccountDetailsSuccess,
	updateBranchBankAccountDetailsFailure,
	updateBranchBankAccountDetailsResponseChanged,
	updateBranchBankChequeBookDetailsSuccess,
	updateBranchBankChequeBookDetailsFailure,
	updateBranchBankChequeBookDetailsResponseChanged,
	getBranchDetailsSuccess,
	getBranchDetailsFailure,
	getBranchDetailsResponseChanged,
	fetchSearchBranchDetailsSuccess,
	fetchSearchBranchDetailsFailure,
	fetchSearchBranchDetailsResponseChanged,
	fetchBranchDetailsSuccess,
	fetchBranchDetailsFailure,
	fetchBranchDetailsResponseChanged,
	deleteBranchDetailsSuccess,
	deleteBranchDetailsFailure,
	deleteBranchDetailsResponseChanged,
	deleteBranchBankSuccess,
	deleteBranchBankFailure,
	deleteBranchBankResponseChanged,
	deleteBranchBankChequeBookSuccess,
	deleteBranchBankChequeBookFailure,
	deleteBranchBankChequeBookResponseChanged,
	fetchBranchBankAccountListSuccess,
	fetchBranchBankAccountListFailure,
	fetchBranchBankAccountListResponseChanged,
	fetchBranchBankChequeBookListSuccess,
	fetchBranchBankChequeBookListFailure,
	fetchBranchBankChequeBookListResponseChanged,
} from './branch.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD BRANCH DETAILS  ******************** */
export function* addBranchDetails({ payload }) {
	try {
		const key = 'AddBranchDetailsKey';
		let branchOpeningType = 'Debit';
		if (payload.checkDrCr === 'Dr') {
			branchOpeningType = 'Debit';
		} else if (payload.checkDrCr === 'Cr') {
			branchOpeningType = 'Credit';
		}
		const jsonData = {
			CompanyID: payload.companyID,
			BranchName: payload.branchName,
			BranchCity: payload.branchCity,
			BranchState: payload.branchState,
			BranchCountry: payload.branchCountry,
			BranchDistrict: payload.branchDistrict,
			NextBranchUID: payload.nextUniqueAreaID,
			BranchAddress: payload.branchAddress,
			BranchStartDate: payload.branchStartDate,
			BranchPhoneNumber: payload.branchPhoneNumber,
			BranchPinCode: payload.branchPincode,
			BranchMobileNumber: payload.branchMobileNumber,
			BranchContactPerson: payload.branchContactPerson,
			BranchContactPersonDesignation: payload.branchContactPersonDesignation,
			BranchEmailID: payload.branchEmailID,
			BranchOpeningDate: payload.branchOpeningDate,
			BranchOpeningAmount: payload.branchOpeningAmount,
			BranchOpeningType: branchOpeningType,
			ModifiedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBranchDetailsFailure(responseData.data));
		} else {
			yield put(addBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBranchDetailsFailure(error));
	}
}

export function* resetAddBranchResponse() {
	yield put(addBranchDetailsResponseChanged());
}

export function* onAddBranchDetails() {
	yield takeLatest(BranchActionTypes.ADD_BRANCH_DETAILS_START, addBranchDetails);
}

export function* onAddBranchDetailsResponseReset() {
	yield takeLatest(BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_RESET, resetAddBranchResponse);
}
/** *********************  END ADD BRANCH DETAILS ******************** */

/** *********************  START ADD BRANCH DETAILS  ******************** */
export function* addBranchBankAccountDetails({ payload }) {
	try {
		const key = 'AddBranchBankAccountDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BankName: payload.bankName,
			BankBranchName: payload.bankBranchName,
			BankAccountNumber: payload.bankAccountNumber,
			BankAccountName: payload.bankAccountName,
			BankIFSCcode: payload.bankIfscCode,
			BankAccountType: payload.bankAccountType,
			BankOpeningDate: payload.bankOpeningDate,
			BankOpeningType: payload.bankOpeningType,
			BankOpeningAmount: payload.bankOpeningAmount,
			UserID: payload.userID,
			UserName: payload.userName,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankAccountDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBranchBankAccountDetailsFailure(responseData.data));
		} else {
			yield put(addBranchBankAccountDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBranchBankAccountDetailsFailure(error));
	}
}

export function* resetAddBranchBankAccountResponse() {
	yield put(addBranchBankAccountDetailsResponseChanged());
}

export function* onAddBranchBankAccountDetails() {
	yield takeLatest(
		BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_START,
		addBranchBankAccountDetails,
	);
}

export function* onAddBranchBankAccountDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
		resetAddBranchBankAccountResponse,
	);
}
/** *********************  END ADD BRANCH BANK ACCOUNT DETAILS ******************** */

/** *********************  START ADD BRANCH BANK CHEQUE BOOK DETAILS  ******************** */
export function* addBranchBankChequeBookDetails({ payload }) {
	try {
		const key = 'AddBranchBankChequeBookDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchBankID: payload.bankAccountID,
			ChequeFromNo: payload.chequeFromNo,
			ChequeEndNo: payload.chequeEndNo,
			ChequeIssueDate: payload.chequeIssueDate,
			UserID: payload.userID,
			UserName: payload.userName,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankChequeBookDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBranchBankChequeBookDetailsFailure(responseData.data));
		} else {
			yield put(addBranchBankChequeBookDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBranchBankChequeBookDetailsFailure(error));
	}
}

export function* resetAddBranchBankChequeBookResponse() {
	yield put(addBranchBankChequeBookDetailsResponseChanged());
}

export function* onAddBranchBankChequeBookDetails() {
	yield takeLatest(
		BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START,
		addBranchBankChequeBookDetails,
	);
}

export function* onAddBranchBankChequeBookDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET,
		resetAddBranchBankChequeBookResponse,
	);
}
/** *********************  END ADD BRANCH BANK CHEQUE BOOK DETAILS ******************** */

/** *********************  START UPDATE BRANCH DETAILS  ******************** */
export function* updateBranchDetails({ payload }) {
	try {
		const key = 'UpdateBranchDetailsKey';
		let branchOpeningType = 'Debit';
		if (payload.checkDrCr === 'Dr') {
			branchOpeningType = 'Debit';
		} else if (payload.checkDrCr === 'Cr') {
			branchOpeningType = 'Credit';
		}
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchName: payload.branchName,
			BranchCity: payload.branchCity,
			BranchState: payload.branchState,
			BranchCountry: payload.branchCountry,
			BranchDistrict: payload.branchDistrict,
			NextUniqueAreaID: payload.nextUniqueAreaID,
			BranchAddress: payload.branchAddress,
			BranchStartDate: payload.branchStartDate,
			BranchPhoneNumber: payload.branchPhoneNumber,
			BranchPinCode: payload.branchPincode,
			BranchMobileNumber: payload.branchMobileNumber,
			BranchContactPerson: payload.branchContactPerson,
			BranchContactPersonDesignation: payload.branchContactPersonDesignation,
			BranchEmailID: payload.branchEmailID,
			BranchOpeningDate: payload.branchOpeningDate,
			BranchOpeningAmount: payload.branchOpeningAmount,
			BranchOpeningType: branchOpeningType,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchDetailsFailure(responseData.data));
		} else {
			yield put(updateBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchDetailsFailure(error));
	}
}

export function* resetUpdateBranchDetailsResponse() {
	yield put(updateBranchDetailsResponseChanged());
}

export function* onUpdateBranchDetails() {
	yield takeLatest(BranchActionTypes.UPDATE_BRANCH_DETAILS_START, updateBranchDetails);
}

export function* onUpdateBranchResponseReset() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_RESET,
		resetUpdateBranchDetailsResponse,
	);
}
/** *********************  END UPDATE BRANCH DETAILS ******************** */

/** *********************  START UPDATE BRANCH BANK ACCOUNT DETAILS  ******************** */
export function* updateBranchBankAccountDetails({ payload }) {
	try {
		const key = 'UpdateBranchBankAccountDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchBankID: payload.branchBankID,
			BankName: payload.bankName,
			BankBranchName: payload.bankBranchName,
			BankAccountNumber: payload.bankAccountNumber,
			BankAccountName: payload.bankAccountName,
			BankIFSCcode: payload.bankIfscCode,
			BankAccountType: payload.bankAccountType,
			BankOpeningDate: payload.bankOpeningDate,
			BankOpeningAmount: payload.bankOpeningAmount,
			BankOpeningType: payload.bankOpeningType,
			UserID: payload.userID,
			UserName: payload.userName,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankAccountDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchBankAccountDetailsFailure(responseData.data));
		} else {
			yield put(updateBranchBankAccountDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchBankAccountDetailsFailure(error));
	}
}

export function* resetUpdateBranchBankAccountDetailsResponse() {
	yield put(updateBranchBankAccountDetailsResponseChanged());
}

export function* onUpdateBranchBankAccountDetails() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_START,
		updateBranchBankAccountDetails,
	);
}

export function* onUpdateBranchBankAccountResponseReset() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET,
		resetUpdateBranchBankAccountDetailsResponse,
	);
}
/** *********************  END UPDATE BRANCH BANK ACCOUNT DETAILS ******************** */

/** *********************  START UPDATE BRANCH BANK CHEQUE BOOK DETAILS  ******************** */
export function* updateBranchBankChequeBookDetails({ payload }) {
	try {
		const key = 'UpdateBranchBankChequeBookDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchChequeBookID: payload.branchChequeBookID,
			BranchBankID: payload.bankAccountID,
			ChequeFromNo: payload.chequeFromNo,
			ChequeEndNo: payload.chequeEndNo,
			ChequeIssueDate: payload.chequeIssueDate,
			UserID: payload.userID,
			UserName: payload.userName,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankChequeBookDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchBankChequeBookDetailsFailure(responseData.data));
		} else {
			yield put(updateBranchBankChequeBookDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchBankChequeBookDetailsFailure(error));
	}
}

export function* resetUpdateBranchBankChequeBookDetailsResponse() {
	yield put(updateBranchBankChequeBookDetailsResponseChanged());
}

export function* onUpdateBranchBankChequeBookDetails() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START,
		updateBranchBankChequeBookDetails,
	);
}

export function* onUpdateBranchBankChequeBookResponseReset() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET,
		resetUpdateBranchBankChequeBookDetailsResponse,
	);
}
/** *********************  END UPDATE BRANCH BANK CHEQUE BOOK DETAILS ******************** */

/** *********************  START GET BRANCH LIST  ******************** */
export function* getBranchList({ payload }) {
	try {
		const key = 'FetchBranchListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchDetailsFailure(responseData.data));
		} else {
			yield put(getBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchDetailsFailure(error));
	}
}

export function* resetGetBranchListResponse() {
	yield put(getBranchDetailsResponseChanged());
}

export function* onGetBranchListDetails() {
	yield takeLatest(BranchActionTypes.GET_BRANCH_LIST_START, getBranchList);
}

export function* onGetBranchListResponseReset() {
	yield takeLatest(BranchActionTypes.GET_BRANCH_LIST_RESPONSE_RESET, resetGetBranchListResponse);
}
/** *********************  END GET BRANCH LIST ******************** */

/** *********************  START FETCH SEARCH BRANCH LIST  ******************** */
export function* fetchSearchBranchList({ payload }) {
	try {
		const key = 'FetchSearchBranchListKey';
		const jsonData = {
			SearchValue: payload.SearchValue,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			DatabaseName: payload.DatabaseName,
			BranchID: payload.userAccessBranchID,
			Limit: '0',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSearchBranchDetailsFailure(responseData.data));
		} else {
			yield put(fetchSearchBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSearchBranchDetailsFailure(error));
	}
}

export function* resetFetchSearchBranchListResponse() {
	yield put(fetchSearchBranchDetailsResponseChanged());
}

export function* onFetchSearchBranchListDetails() {
	yield takeLatest(BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_START, fetchSearchBranchList);
}

export function* onFetchSearchBranchListResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_SEARCH_BRANCH_LIST_RESPONSE_RESET,
		resetFetchSearchBranchListResponse,
	);
}
/** *********************  END FETCH SEARCH BRANCH LIST ******************** */

/** *********************  START FETCH BRANCH DETAILS  ******************** */
export function* fetchBranchDetails({ payload }) {
	try {
		const key = 'FetchBranchInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchDetailsFailure(responseData.data));
		} else {
			yield put(fetchBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchDetailsFailure(error));
	}
}

export function* resetFetchBranchDetailsResponse() {
	yield put(fetchBranchDetailsResponseChanged());
}

export function* onFetchBranchDetails() {
	yield takeLatest(BranchActionTypes.FETCH_BRANCH_DETAILS_START, fetchBranchDetails);
}

export function* onFetchBranchDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET,
		resetFetchBranchDetailsResponse,
	);
}
/** *********************  END FETCH BRANCH DETAILS ******************** */

/** *********************  START DELETE BRANCH DETAILS  ******************** */
export function* deleteBranch({ payload }) {
	try {
		const key = 'DeleteBranchDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchDetailsFailure(responseData.data));
		} else {
			yield put(deleteBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchDetailsFailure(error));
	}
}

export function* resetDeleteBranchDetailsResponse() {
	yield put(deleteBranchDetailsResponseChanged());
}

export function* onDeleteBranchDetails() {
	yield takeLatest(BranchActionTypes.DELETE_BRANCH_DETAILS_START, deleteBranch);
}

export function* onDeleteBranchDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_RESET,
		resetDeleteBranchDetailsResponse,
	);
}
/** *********************  END DELETE BRANCH DETAILS ******************** */

/** *********************  START DELETE BRANCH BANK DETAILS  ******************** */
export function* deleteBranchBank({ payload }) {
	try {
		const key = 'DeleteBranchBankDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchBankID: payload.branchBankID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchBankFailure(responseData.data));
		} else {
			yield put(deleteBranchBankSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchBankFailure(error));
	}
}

export function* resetDeleteBranchBankDetailsResponse() {
	yield put(deleteBranchBankResponseChanged());
}

export function* onDeleteBranchBankDetails() {
	yield takeLatest(BranchActionTypes.DELETE_BRANCH_BANK_START, deleteBranchBank);
}

export function* onDeleteBranchBankDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.DELETE_BRANCH_BANK_RESPONSE_RESET,
		resetDeleteBranchBankDetailsResponse,
	);
}
/** *********************  END DELETE BRANCH BANK DETAILS ******************** */

/** *********************  START DELETE BRANCH BANK CHEQUE BOOK DETAILS  ******************** */
export function* deleteBranchBankChequeBook({ payload }) {
	try {
		const key = 'DeleteBranchBankChequeBookDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchChequeBookID: payload.branchChequeBookID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankChequeBookDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchBankChequeBookFailure(responseData.data));
		} else {
			yield put(deleteBranchBankChequeBookSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchBankChequeBookFailure(error));
	}
}

export function* resetDeleteBranchBankChequeBookDetailsResponse() {
	yield put(deleteBranchBankChequeBookResponseChanged());
}

export function* onDeleteBranchBankChequeBookDetails() {
	yield takeLatest(BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_START, deleteBranchBankChequeBook);
}

export function* onDeleteBranchBankChequeBookDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_RESET,
		resetDeleteBranchBankChequeBookDetailsResponse,
	);
}
/** *********************  END DELETE BRANCH BANK CHEQUE BOOK DETAILS ******************** */

/** *********************  START FETCH BRANCH BANK ACCOUNT LIST  ******************** */
export function* fetchBranchBankAccountList({ payload }) {
	try {
		const key = 'FetchBranchBankAccountListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.getBranchID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankAccountListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchBankAccountListFailure(responseData.data));
		} else {
			yield put(fetchBranchBankAccountListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchBankAccountListFailure(error));
	}
}

export function* resetFetchBranchBankAccountListResponse() {
	yield put(fetchBranchBankAccountListResponseChanged());
}

export function* onFetchBranchBankAccountListDetails() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_START,
		fetchBranchBankAccountList,
	);
}

export function* onFetchBranchBankAccountListResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_RESET,
		resetFetchBranchBankAccountListResponse,
	);
}
/** *********************  END FETCH BRANCH BANK ACCOUNT LIST ******************** */

/** *********************  START FETCH BRANCH BANK CHEQUE BOOK LIST  ******************** */
export function* fetchBranchBankChequeBookList({ payload }) {
	try {
		const key = 'FetchBranchBankChequeBookListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.getBranchID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchBankChequeBookListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchBankChequeBookListFailure(responseData.data));
		} else {
			yield put(fetchBranchBankChequeBookListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchBankChequeBookListFailure(error));
	}
}

export function* resetFetchBranchBankChequeBookListResponse() {
	yield put(fetchBranchBankChequeBookListResponseChanged());
}

export function* onFetchBranchBankChequeBookListDetails() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_START,
		fetchBranchBankChequeBookList,
	);
}

export function* onFetchBranchBankChequeBookListResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_RESET,
		resetFetchBranchBankChequeBookListResponse,
	);
}
/** *********************  END FETCH BRANCH BANK CHEQUE BOOK LIST ******************** */

export function* branchMasterSaga() {
	yield all([
		call(onAddBranchDetails),
		call(onAddBranchDetailsResponseReset),
		call(onAddBranchBankAccountDetails),
		call(onAddBranchBankAccountDetailsResponseReset),
		call(onAddBranchBankChequeBookDetails),
		call(onAddBranchBankChequeBookDetailsResponseReset),
		call(onUpdateBranchDetails),
		call(onUpdateBranchResponseReset),
		call(onUpdateBranchBankAccountDetails),
		call(onUpdateBranchBankAccountResponseReset),
		call(onUpdateBranchBankChequeBookDetails),
		call(onUpdateBranchBankChequeBookResponseReset),
		call(onUpdateBranchResponseReset),
		call(onGetBranchListDetails),
		call(onGetBranchListResponseReset),
		call(onFetchSearchBranchListDetails),
		call(onFetchSearchBranchListResponseReset),
		call(onFetchBranchDetails),
		call(onFetchBranchDetailsResponseReset),
		call(onDeleteBranchDetails),
		call(onDeleteBranchDetailsResponseReset),
		call(onDeleteBranchBankDetails),
		call(onDeleteBranchBankDetailsResponseReset),
		call(onDeleteBranchBankChequeBookDetails),
		call(onDeleteBranchBankChequeBookDetailsResponseReset),
		call(onFetchBranchBankAccountListDetails),
		call(onFetchBranchBankAccountListResponseReset),
		call(onFetchBranchBankChequeBookListDetails),
		call(onFetchBranchBankChequeBookListResponseReset),
	]);
}
