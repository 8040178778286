/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const memberDetailsAdd = (addData) => {
	const data = {
		AddMemberDetailsToken: addData,
	};

	const url = `${API_URL}addMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberSecurityDetailsAdd = (addData) => {
	const data = {
		AddMemberSecurityDetailsToken: addData,
	};

	const url = `${API_URL}addMemberSecurityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberProofDetailsAdd = (addData) => {
	const data = {
		AddMemberProofDetailsToken: addData,
	};

	const url = `${API_URL}addMemberProofDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberAddressDetailsAdd = (addData) => {
	const data = {
		AddMemberAddressDetailsToken: addData,
	};

	const url = `${API_URL}addMemberAddressDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberEnrollmentDetailsAdd = (addData) => {
	const data = {
		AddMemberEnrollmentDetailsToken: addData,
	};

	const url = `${API_URL}addMemberEnrollmentDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberChitRequestDetailsAdd = (addData) => {
	const data = {
		AddMemberChitRequestDetailsToken: addData,
	};

	const url = `${API_URL}addMemberChitRequestDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsDelete = (updateData) => {
	const data = {
		DeleteMemberDetailsToken: updateData,
	};

	const url = `${API_URL}deleteMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberAddressDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberAddressDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberAddressDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberBankAccountDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberBankAccountDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberBankAccountDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberProofDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberProofDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberProofDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberSecurityDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberSecurityDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberSecurityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberOfficialDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberOfficialDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberOfficialDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberEnrollmentDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberEnrollmentDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberEnrollmentDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberChitRequestDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberChitRequestDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberChitRequestDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberIsApproveDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberIsApproveDetailsToken: updateData,
	};

	const url = `${API_URL}updateMemberIsApproveDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberListGet = (listData) => {
	const data = {
		FetchMemberListToken: listData,
	};
	const url = `${API_URL}FetchMemberListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchSearchMemberListLoad = (listData) => {
	const data = {
		FetchSearchMemberListToken: listData,
	};
	const url = `${API_URL}FetchSearchMemberListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchAdvanceSearchMemberListLoad = (listData) => {
	const data = {
		FetchAdvanceSearchMemberListToken: listData,
	};
	const url = `${API_URL}FetchAdvanceSearchMemberListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberInfoGet = (memberData) => {
	const data = {
		FetchMemberInfoToken: memberData,
	};
	const url = `${API_URL}FetchMemberInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberEnrollmentInfoGet = (memberData) => {
	const data = {
		FetchMemberEnrollmentInfoToken: memberData,
	};
	const url = `${API_URL}FetchMemberEnrollmentInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberEnrollmentListGet = (memberData) => {
	const data = {
		FetchMemberEnrollmentListToken: memberData,
	};
	const url = `${API_URL}FetchMemberEnrollmentList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberChitRequestInfoGet = (memberData) => {
	const data = {
		FetchMemberChitRequestInfoToken: memberData,
	};
	const url = `${API_URL}FetchMemberChitRequestInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberSecurityInfoListGet = (memberData) => {
	const data = {
		FetchMemberSecurityInfoListToken: memberData,
	};
	const url = `${API_URL}FetchMemberSecurityInfoList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberAddressListGet = (memberData) => {
	const data = {
		FetchMemberAddressListToken: memberData,
	};
	const url = `${API_URL}FetchMemberAddressList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberAddressInfoGet = (memberData) => {
	const data = {
		FetchMemberAddressInfoToken: memberData,
	};
	const url = `${API_URL}FetchMemberAddressInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchProductEnrollmentMembersList = (memberData) => {
	const data = {
		FetchProductEnrollmentMembersListToken: memberData,
	};
	const url = `${API_URL}FetchProductEnrollmentMembersList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCenterEnrollmentMembersList = (memberData) => {
	const data = {
		FetchCenterEnrollmentMembersListToken: memberData,
	};
	const url = `${API_URL}FetchCenterEnrollmentMembersList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
