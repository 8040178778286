/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import RoleActionTypes from './role.types';
import {
	roleDetailsAdd,
	roleDetailsUpdate,
	roleListGet,
	fetchSearchRoleListInfo,
	roleDetailsFetch,
	roleDetailsDelete,
	allRolesFetch,
} from './role.service';

import {
	addRoleDetailsSuccess,
	addRoleDetailsFailure,
	addRoleDetailsResponseChanged,
	updateRoleDetailsSuccess,
	updateRoleDetailsFailure,
	updateRoleDetailsResponseChanged,
	getRoleListSuccess,
	getRoleListFailure,
	getRoleListResponseChanged,
	fetchSearchRoleListSuccess,
	fetchSearchRoleListFailure,
	fetchSearchRoleListResponseChanged,
	fetchRoleDetailsSuccess,
	fetchRoleDetailsFailure,
	fetchRoleDetailsResponseChanged,
	deleteRoleDetailsSuccess,
	deleteRoleDetailsFailure,
	deleteRoleDetailsResponseChanged,
	fetchAllRoleListSuccess,
	fetchAllRoleListFailure,
	fetchAllRoleListResponseChanged,
} from './role.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD ROLE DETAILS  ******************** */
export function* createRole({ payload }) {
	try {
		const key = 'AddRoleDetailsKey';
		const jsonData = {
			RoleName: payload.roleName,
			RoleLevel: payload.roleLevel,
			IsFunder: payload.isFunder,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(roleDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addRoleDetailsFailure(responseData.data));
		} else {
			yield put(addRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addRoleDetailsFailure(error));
	}
}

export function* resetAddRoleResponse() {
	yield put(addRoleDetailsResponseChanged());
}

export function* onCreateRoleDetails() {
	yield takeLatest(RoleActionTypes.ADD_ROLE_DETAILS_START, createRole);
}

export function* onCreateRoleResponseReset() {
	yield takeLatest(RoleActionTypes.ADD_ROLE_DETAILS_RESPONSE_RESET, resetAddRoleResponse);
}
/** *********************  END ADD ROLE DETAILS ******************** */

/** *********************  START UPDATE ROLE DETAILS  ******************** */
export function* roleUpdate({ payload }) {
	try {
		const key = 'UpdateRoleDetailsKey';
		const jsonData = {
			RoleID: payload.roleID,
			RoleName: payload.roleName,
			RoleLevel: payload.roleLevel,
			OldRoleName: payload.oldRoleName,
			OldRoleLevel: payload.oldRoleLevel,
			IsFunder: payload.isFunder,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(roleDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateRoleDetailsFailure(responseData.data));
		} else {
			yield put(updateRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateRoleDetailsFailure(error));
	}
}

export function* resetUpdateRoleDetailsResponse() {
	yield put(updateRoleDetailsResponseChanged());
}

export function* onUpdateRoleDetails() {
	yield takeLatest(RoleActionTypes.UPDATE_ROLE_DETAILS_START, roleUpdate);
}

export function* onUpdateRoleResponseReset() {
	yield takeLatest(
		RoleActionTypes.UPDATE_ROLE_DETAILS_RESPONSE_RESET,
		resetUpdateRoleDetailsResponse,
	);
}
/** *********************  END UPDATE ROLE DETAILS ******************** */

/** *********************  START GET ROLE LIST  ******************** */
export function* roleDetailsListGet({ payload }) {
	try {
		const key = 'FetchRoleListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.searchValueSet,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(roleListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getRoleListFailure(responseData.data));
		} else {
			yield put(getRoleListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRoleListFailure(error));
	}
}

export function* resetGetRoleListResponse() {
	yield put(getRoleListResponseChanged());
}

export function* onGetRoleListDetails() {
	yield takeLatest(RoleActionTypes.GET_ROLE_LIST_START, roleDetailsListGet);
}

export function* onGetRoleListResponseReset() {
	yield takeLatest(RoleActionTypes.GET_ROLE_LIST_RESPONSE_RESET, resetGetRoleListResponse);
}
/** *********************  END GET ROLE LIST ******************** */

/** *********************  START FETCH SEARCH ROLE LIST  ******************** */
export function* fetchSearchRoleDetailsList({ payload }) {
	try {
		const key = 'FetchSearchRoleListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			InputSearch: payload.InputSearch,
			Limit: payload.Limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchRoleListInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSearchRoleListFailure(responseData.data));
		} else {
			yield put(fetchSearchRoleListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSearchRoleListFailure(error));
	}
}

export function* resetFetchSearchRoleListResponse() {
	yield put(fetchSearchRoleListResponseChanged());
}

export function* onFetchSearchRoleListDetails() {
	yield takeLatest(RoleActionTypes.FETCH_SEARCH_ROLE_LIST_START, fetchSearchRoleDetailsList);
}

export function* onFetchSearchRoleListResponseReset() {
	yield takeLatest(
		RoleActionTypes.FETCH_SEARCH_ROLE_LIST_RESPONSE_RESET,
		resetFetchSearchRoleListResponse,
	);
}
/** *********************  END FETCH SEARCH ROLE LIST ******************** */

/** *********************  START FETCH ROLE DETAILS  ******************** */
export function* fetchRoleData({ payload }) {
	try {
		const key = 'FetchRoleInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			RoleID: payload.roleID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(roleDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchRoleDetailsFailure(responseData.data));
		} else {
			yield put(fetchRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchRoleDetailsFailure(error));
	}
}

export function* resetFetchRoleDetailsResponse() {
	yield put(fetchRoleDetailsResponseChanged());
}

export function* onFetchRoleDetails() {
	yield takeLatest(RoleActionTypes.FETCH_ROLE_DETAILS_START, fetchRoleData);
}

export function* onFetchRoleResponseReset() {
	yield takeLatest(
		RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_RESET,
		resetFetchRoleDetailsResponse,
	);
}
/** *********************  END FETCH ROLE DETAILS ******************** */

/** *********************  START DELETE ROLE DETAILS  ******************** */
export function* roleDelete({ payload }) {
	try {
		const key = 'DeleteRoleDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			RoleID: payload.roleID,
			RoleName: payload.roleName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(roleDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteRoleDetailsFailure(responseData.data));
		} else {
			yield put(deleteRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteRoleDetailsFailure(error));
	}
}

export function* resetDeleteRoleDetailsResponse() {
	yield put(deleteRoleDetailsResponseChanged());
}

export function* onDeleteRoleDetail() {
	yield takeLatest(RoleActionTypes.DELETE_ROLE_DETAILS_START, roleDelete);
}

export function* onDeleteRoleResponseReset() {
	yield takeLatest(
		RoleActionTypes.DELETE_ROLE_DETAILS_RESPONSE_RESET,
		resetDeleteRoleDetailsResponse,
	);
}
/** *********************  END DELETE ROLE DETAILS ******************** */

/** *********************  START FETCH ALL ROLES LIST  ******************** */
export function* fetchAllRolesList({ payload }) {
	try {
		const key = 'FetchAllRolesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allRolesFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllRoleListFailure(responseData.data));
		} else {
			yield put(fetchAllRoleListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllRoleListFailure(error));
	}
}

export function* resetFetchAllRolesResponse() {
	yield put(fetchAllRoleListResponseChanged());
}

export function* onFetchAllRolesDetails() {
	yield takeLatest(RoleActionTypes.FETCH_ALL_ROLE_START, fetchAllRolesList);
}

export function* onFetchAllRolesResponseReset() {
	yield takeLatest(RoleActionTypes.FETCH_ALL_ROLE_RESPONSE_RESET, resetFetchAllRolesResponse);
}
/** *********************  END FETCH ALL ROLES LIST ******************** */

export function* roleMasterSaga() {
	yield all([
		call(onCreateRoleDetails),
		call(onCreateRoleResponseReset),
		call(onUpdateRoleDetails),
		call(onUpdateRoleResponseReset),
		call(onGetRoleListDetails),
		call(onGetRoleListResponseReset),
		call(onFetchSearchRoleListDetails),
		call(onFetchSearchRoleListResponseReset),
		call(onFetchRoleDetails),
		call(onFetchRoleResponseReset),
		call(onDeleteRoleDetail),
		call(onDeleteRoleResponseReset),
		call(onFetchAllRolesDetails),
		call(onFetchAllRolesResponseReset),
	]);
}
