/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import GroupActionTypes from './group.types';
import {
	groupDetailsAdd,
	groupDetailsUpdate,
	addMapMembersToGroupService,
	groupListGet,
	groupSearchListGet,
	groupInfoGet,
	fetchGroupUniqueDetails,
	groupDetailsDelete,
	allGroupFetch,
	fetchGroupMembersInfo,
	allApprovedMemberFetch,
	allOfficerGet,
	allGroupDetailsGet,
	branchOfficerListGet,
	loadProductGroupList,
	loadBranchGroupList,
} from './group.service';

import {
	addGroupDetailsSuccess,
	addGroupDetailsFailure,
	addGroupDetailsResponseChanged,
	addMapMembersToGroupDetailsSuccess,
	addMapMembersToGroupDetailsFailure,
	addMapMembersToGroupDetailsResponseChanged,
	updateGroupDetailsSuccess,
	updateGroupDetailsFailure,
	updateGroupDetailsResponseChanged,
	fetchGroupUniqueIDSuccess,
	fetchGroupUniqueIDFailure,
	fetchGroupUniqueIDResponseChanged,
	fetchAllGroupSuccess,
	fetchAllGroupFailure,
	fetchAllGroupResponseChanged,
	fetchProductGroupListSuccess,
	fetchProductGroupListFailure,
	fetchProductGroupListResponseChanged,
	fetchBranchGroupListSuccess,
	fetchBranchGroupListFailure,
	fetchBranchGroupListResponseChanged,
	fetchGroupMembersSuccess,
	fetchGroupMembersFailure,
	fetchGroupMembersResponseChanged,
	deleteGroupDetailsSuccess,
	deleteGroupDetailsFailure,
	deleteGroupDetailsResponseChanged,
	fetchAllApprovedMemberSuccess,
	fetchAllApprovedMemberFailure,
	fetchAllApprovedMemberResponseChanged,
	getGroupListSuccess,
	getGroupListFailure,
	getGroupListResponseChanged,
	getSearchGroupListSuccess,
	getSearchGroupListFailure,
	getSearchGroupListResponseChanged,
	getGroupInfoSuccess,
	getGroupInfoFailure,
	getGroupInfoResponseChanged,
	getAllOfficerSuccess,
	getAllOfficerFailure,
	getAllOfficerResponseChanged,
	getAllGroupDetailsSuccess,
	getAllGroupDetailsFailure,
	getAllGroupDetailsResponseChanged,
	getBranchOfficerSuccess,
	getBranchOfficerFailure,
	getBranchOfficerResponseChanged,
} from './group.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START ADD GROUP DETAILS  ******************** */
export function* addGroupDetails({ payload }) {
	try {
		const key = 'AddGroupDetailsKey';
		const jsonData = {
			BranchID: payload.branchID,
			ProductID: payload.productID,
			GroupName: payload.groupName,
			GroupPsoNo: payload.groupPsoNo,
			GroupCcNo: payload.groupCcNo,
			GroupStartDate: payload.groupStartDate,
			GroupEndDate: payload.groupEndDate,
			GroupAuctionTime: payload.groupAuctionTime,
			GroupRegisterStatus: payload.groupRegisterStatus,
			NextGroupUID: payload.nextUniqueGroupID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addGroupDetailsFailure(responseData.data));
		} else {
			yield put(addGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addGroupDetailsFailure(error));
	}
}

export function* resetAddGroupResponse() {
	yield put(addGroupDetailsResponseChanged());
}

export function* onAddGroupDetails() {
	yield takeLatest(GroupActionTypes.ADD_GROUP_DETAILS_START, addGroupDetails);
}

export function* onAddGroupDetailsResponseReset() {
	yield takeLatest(GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_RESET, resetAddGroupResponse);
}
/** *********************  START ADD GROUP DETAILS  ******************** */

/** *********************  START ADD MAP MEMBERS TO GROUP DETAILS  ******************** */
export function* addMapMembersToGroupDetails({ payload }) {
	try {
		const key = 'AddMapMembersToGroupKey';
		const jsonData = {
			BranchID: payload.getBranchID,
			CenterID: payload.getCenterID,
			GroupID: payload.fetchGroupID,
			ProductID: payload.getProductID,
			ProductAmount: payload.getProductAmount,
			ChitProposalDate: payload.ChitProposalDate,
			ChitAuctionDateTime: payload.ChitAuctionDateTime,
			GroupStartDate: payload.groupStartDate,
			GroupEndDate: payload.groupEndDate,
			MembersList: payload.membersList,
			ChitSchedule: payload.chitSchedule,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addMapMembersToGroupService, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMapMembersToGroupDetailsFailure(responseData.data));
		} else {
			yield put(addMapMembersToGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMapMembersToGroupDetailsFailure(error));
	}
}

export function* resetAddMapMembersToGroupResponse() {
	yield put(addMapMembersToGroupDetailsResponseChanged());
}

export function* onAddMapMembersToGroupDetails() {
	yield takeLatest(
		GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_START,
		addMapMembersToGroupDetails,
	);
}

export function* onAddMapMembersToGroupDetailsResponseReset() {
	yield takeLatest(
		GroupActionTypes.ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_RESET,
		resetAddMapMembersToGroupResponse,
	);
}
/** *********************  END ADD MAP MEMBERS TO GROUP DETAILS  ******************** */

/** *********************  START UPDATE GROUP DETAILS  ******************** */
export function* updateGroupDetails({ payload }) {
	try {
		const key = 'UpdateGroupDetailsKey';
		const jsonData = {
			GroupID: payload.groupID,
			BranchID: payload.branchID,
			ProductID: payload.productID,
			GroupName: payload.groupName,
			GroupPsoNo: payload.groupPsoNo,
			GroupCcNo: payload.groupCcNo,
			GroupStartDate: payload.groupStartDate,
			GroupEndDate: payload.groupEndDate,
			GroupAuctionTime: payload.groupAuctionTime,
			GroupRegisterStatus: payload.groupRegisterStatus,
			NextGroupUID: payload.nextUniqueGroupID,
			CompanyID: payload.companyID,
			UserName: payload.userName,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateGroupDetailsFailure(responseData.data));
		} else {
			yield put(updateGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateGroupDetailsFailure(error));
	}
}

export function* resetUpdateGroupResponse() {
	yield put(updateGroupDetailsResponseChanged());
}

export function* onUpdateGroupDetails() {
	yield takeLatest(GroupActionTypes.UPDATE_GROUP_DETAILS_START, updateGroupDetails);
}

export function* onUpdateGroupDetailsResponseReset() {
	yield takeLatest(
		GroupActionTypes.UPDATE_GROUP_DETAILS_RESPONSE_RESET,
		resetUpdateGroupResponse,
	);
}
/** *********************  START UPDATE GROUP DETAILS  ******************** */

/** *********************  START GET GROUP LIST  ******************** */
export function* GetAllGroupList({ payload }) {
	try {
		const key = 'FetchGroupListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getGroupListFailure(responseData.data));
		} else {
			yield put(getGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getGroupListFailure(error));
	}
}

export function* resetGetGroupListResponse() {
	yield put(getGroupListResponseChanged());
}

export function* onGetGroupListDetails() {
	yield takeLatest(GroupActionTypes.GET_GROUP_LIST_START, GetAllGroupList);
}

export function* onGetGroupListResponseReset() {
	yield takeLatest(GroupActionTypes.GET_GROUP_LIST_RESPONSE_RESET, resetGetGroupListResponse);
}
/** *********************  END GET GROUP LIST ******************** */

/** *********************  START GET SEARCH GROUP LIST  ******************** */
export function* GetSearchGroupList({ payload }) {
	try {
		const key = 'FetchSearchGroupListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.InputSearch,
			Limit: payload.Limit,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupSearchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSearchGroupListFailure(responseData.data));
		} else {
			yield put(getSearchGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSearchGroupListFailure(error));
	}
}

export function* resetGetSearchGroupListResponse() {
	yield put(getSearchGroupListResponseChanged());
}

export function* onGetSearchGroupListDetails() {
	yield takeLatest(GroupActionTypes.GET_SEARCH_GROUP_LIST_START, GetSearchGroupList);
}

export function* onGetSearchGroupListResponseReset() {
	yield takeLatest(
		GroupActionTypes.GET_SEARCH_GROUP_LIST_RESPONSE_RESET,
		resetGetSearchGroupListResponse,
	);
}
/** *********************  END GET SEARCH GROUP LIST ******************** */

/** *********************  START FETCH PRODUCT GROUP LIST  ******************** */
export function* fetchProductGroupList({ payload }) {
	try {
		const key = 'FetchProductGroupListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			ProductID: payload.productID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loadProductGroupList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductGroupListFailure(responseData.data));
		} else {
			yield put(fetchProductGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductGroupListFailure(error));
	}
}

export function* resetFetchProductGroupListResponse() {
	yield put(fetchProductGroupListResponseChanged());
}

export function* onfetchProductGroupListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_START, fetchProductGroupList);
}

export function* onfetchProductGroupListResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_PRODUCT_GROUP_LIST_RESPONSE_RESET,
		resetFetchProductGroupListResponse,
	);
}
/** *********************  END FETCH PRODUCT GROUP LIST ******************** */

/** *********************  START FETCH BRANCH GROUP LIST  ******************** */
export function* fetchBranchGroupList({ payload }) {
	try {
		const key = 'FetchBranchGroupListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loadBranchGroupList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchGroupListFailure(responseData.data));
		} else {
			yield put(fetchBranchGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchGroupListFailure(error));
	}
}

export function* resetFetchBranchGroupListResponse() {
	yield put(fetchBranchGroupListResponseChanged());
}

export function* onfetchBranchGroupListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_BRANCH_GROUP_LIST_START, fetchBranchGroupList);
}

export function* onfetchBranchGroupListResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_BRANCH_GROUP_LIST_RESPONSE_RESET,
		resetFetchBranchGroupListResponse,
	);
}
/** *********************  END FETCH BRANCH GROUP LIST ******************** */

/** *********************  START GET GROUP Info  ******************** */
export function* GetGroupInfo({ payload }) {
	try {
		const key = 'FetchGroupInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupInfoGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getGroupInfoFailure(responseData.data));
		} else {
			yield put(getGroupInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getGroupInfoFailure(error));
	}
}

export function* resetGetGroupInfoResponse() {
	yield put(getGroupInfoResponseChanged());
}

export function* onGetGroupInfoDetails() {
	yield takeLatest(GroupActionTypes.GET_GROUP_INFO_START, GetGroupInfo);
}

export function* onGetGroupInfoResponseReset() {
	yield takeLatest(GroupActionTypes.GET_GROUP_INFO_RESPONSE_RESET, resetGetGroupInfoResponse);
}
/** *********************  END GET GROUP INFO ******************** */

/** *********************  START FETCH GROUP UNIQUE ID  ******************** */
export function* getGroupUniqueDetails({ payload }) {
	try {
		const key = 'FetchGroupUniqueIdKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupUniqueDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupUniqueIDFailure(responseData.data));
		} else {
			yield put(fetchGroupUniqueIDSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupUniqueIDFailure(error));
	}
}

export function* resetGetGroupUniqueIDResponse() {
	yield put(fetchGroupUniqueIDResponseChanged());
}

export function* onGetGroupCenterUinqueDetails() {
	yield takeLatest(GroupActionTypes.FETCH_GROUP_UNIQUE_ID_START, getGroupUniqueDetails);
}

export function* onGetCenterCenterUinqueIDResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_GROUP_UNIQUE_ID_RESPONSE_RESET,
		resetGetGroupUniqueIDResponse,
	);
}
/** *********************  END FETCH GROUP UNIQUE ID ******************** */

/** *********************  START FETCH ALL GROUP LIST  ******************** */
export function* fetchAllGroupList({ payload }) {
	try {
		const key = 'FetchAllGroupKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allGroupFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllGroupFailure(responseData.data));
		} else {
			yield put(fetchAllGroupSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllGroupFailure(error));
	}
}

export function* resetFetchAllGroupListResponse() {
	yield put(fetchAllGroupResponseChanged());
}

export function* onFetchAllGroupListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_ALL_GROUP_START, fetchAllGroupList);
}

export function* onFetchAllGroupResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET_START,
		resetFetchAllGroupListResponse,
	);
}
/** *********************  END FETCH ALL GROUP LIST ******************** */

/** *********************  START FETCH GROUP MEMBERS LIST  ******************** */
export function* fetchGroupMembersList({ payload }) {
	try {
		const key = 'FetchGroupMembersInfoKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupMembersInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupMembersFailure(responseData.data));
		} else {
			yield put(fetchGroupMembersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupMembersFailure(error));
	}
}

export function* resetFetchGroupMembersResponse() {
	yield put(fetchGroupMembersResponseChanged());
}

export function* onFetchGroupMembersDetails() {
	yield takeLatest(GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_START, fetchGroupMembersList);
}

export function* onFetchGroupMembersResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_GROUP_MEMBERS_LIST_RESPONSE_RESET_START,
		resetFetchGroupMembersResponse,
	);
}
/** *********************  END FETCH GROUP MEMBERS LIST ******************** */

/** *********************  START DELETE GROUP DETAILS  ******************** */
export function* groupDelete({ payload }) {
	try {
		const key = 'DeleteGroupDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			GroupID: payload.groupID,
			GroupName: payload.groupName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteGroupDetailsFailure(responseData.data));
		} else {
			yield put(deleteGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteGroupDetailsFailure(error));
	}
}

export function* resetDeleteGroupDetailsResponse() {
	yield put(deleteGroupDetailsResponseChanged());
}

export function* onDeleteGroupDetail() {
	yield takeLatest(GroupActionTypes.DELETE_GROUP_DETAILS_START, groupDelete);
}

export function* onDeleteGroupResponseReset() {
	yield takeLatest(
		GroupActionTypes.DELETE_GROUP_DETAILS_RESPONSE_RESET,
		resetDeleteGroupDetailsResponse,
	);
}
/** *********************  END DELETE GROUP DETAILS ******************** */

/** *********************  START FETCH ALL APPROVED MEMBER LIST  ******************** */
export function* fetchAllApprovedMemberList({ payload }) {
	try {
		const key = 'GetBranchAllOfficerListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			SearchData: payload.SearchData,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allApprovedMemberFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllApprovedMemberFailure(responseData.data));
		} else {
			yield put(fetchAllApprovedMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllApprovedMemberFailure(error));
	}
}

export function* resetFetchAllApprovedMemberListResponse() {
	yield put(fetchAllApprovedMemberResponseChanged());
}

export function* onFetchAllApprovedMemberListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_START, fetchAllApprovedMemberList);
}

export function* onFetchAllApprovedMemberResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START,
		resetFetchAllApprovedMemberListResponse,
	);
}
/** *********************  END FETCH ALL APPROVED MEMBER LIST ******************** */

/** *********************  START GET ALL OFFICER DETAILS  ******************** */
export function* GetAllOfficerLocation({ payload }) {
	try {
		const key = 'GetAllOfficerKey';
		const jsonData = {
			OfficerID: payload.userID,
			OfficerName: payload.userName,
			OfficerUniqueID: payload.uniqueID,
			CompanyID: payload.companyID,
			LocationID: payload.centerID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allOfficerGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllOfficerFailure(responseData.data));
		} else {
			yield put(getAllOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllOfficerFailure(error));
	}
}

export function* resetGetAllOfficerResponse() {
	yield put(getAllOfficerResponseChanged());
}

export function* onGetAllOfficerDetails() {
	yield takeLatest(GroupActionTypes.GET_ALL_OFFICER_START, GetAllOfficerLocation);
}

export function* onGetAllOfficerResponseReset() {
	yield takeLatest(GroupActionTypes.GET_ALL_OFFICER_RESPONSE_RESET, resetGetAllOfficerResponse);
}
/** *********************  END GET ALL OFFICER LIST ******************** */

/** *********************  START GET ALL OFFICER DETAILS  ******************** */
export function* GetAllGroupDetailsLocation({ payload }) {
	try {
		const key = 'GetAllGroupDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allGroupDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllGroupDetailsFailure(responseData.data));
		} else {
			yield put(getAllGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllGroupDetailsFailure(error));
	}
}

export function* resetGetAllGroupDetailsResponse() {
	yield put(getAllGroupDetailsResponseChanged());
}

export function* onGetAllGroupDetailsDetails() {
	yield takeLatest(GroupActionTypes.GET_ALL_GROUP_DETAILS_START, GetAllGroupDetailsLocation);
}

export function* onGetAllGroupDetailsResponseReset() {
	yield takeLatest(
		GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED,
		resetGetAllGroupDetailsResponse,
	);
}
/** *********************  END GET ALL OFFICER LIST ******************** */

/** *********************  START GET BRANCH OFFICER DETAILS  ******************** */
export function* GetBranchOfficerList({ payload }) {
	try {
		const key = 'CENTEROFFICERLISTBASEDONCOMPANY';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchOfficerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchOfficerFailure(responseData.data));
		} else {
			yield put(getBranchOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchOfficerFailure(error));
	}
}

export function* resetGetBranchOfficerResponse() {
	yield put(getBranchOfficerResponseChanged());
}

export function* onGetBranchOfficerDetails() {
	yield takeLatest(GroupActionTypes.GET_BRANCH_OFFICER_START, GetBranchOfficerList);
}

export function* onGetBranchOfficerResponseReset() {
	yield takeLatest(
		GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_RESET,
		resetGetBranchOfficerResponse,
	);
}
/** *********************  END GET ALL OFFICER LIST ******************** */

export function* groupMasterSaga() {
	yield all([
		call(onAddGroupDetails),
		call(onAddGroupDetailsResponseReset),
		call(onAddMapMembersToGroupDetails),
		call(onAddMapMembersToGroupDetailsResponseReset),
		call(onUpdateGroupDetails),
		call(onUpdateGroupDetailsResponseReset),
		call(onGetGroupListDetails),
		call(onGetGroupListResponseReset),
		call(onGetSearchGroupListDetails),
		call(onGetSearchGroupListResponseReset),
		call(onGetGroupInfoDetails),
		call(onGetGroupInfoResponseReset),
		call(onGetGroupCenterUinqueDetails),
		call(onGetCenterCenterUinqueIDResponseReset),
		call(onFetchAllGroupListDetails),
		call(onFetchAllGroupResponseReset),
		call(onfetchProductGroupListDetails),
		call(onfetchProductGroupListResponseReset),
		call(onfetchBranchGroupListDetails),
		call(onfetchBranchGroupListResponseReset),
		call(onFetchGroupMembersDetails),
		call(onFetchGroupMembersResponseReset),
		call(onDeleteGroupDetail),
		call(onDeleteGroupResponseReset),
		call(onFetchAllApprovedMemberListDetails),
		call(onFetchAllApprovedMemberResponseReset),
		call(onGetAllOfficerDetails),
		call(onGetAllOfficerResponseReset),
		call(onGetAllGroupDetailsDetails),
		call(onGetAllGroupDetailsResponseReset),
		call(onGetBranchOfficerDetails),
		call(onGetBranchOfficerResponseReset),
	]);
}
