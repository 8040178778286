/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import CenterActionTypes from './center.types';

/** ***************** ADD CENTER DETAILS START *********************** */
export const addCenterDetailsStart = (addData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_START,
	payload: addData,
});

export const addCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const addCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const addCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_RESET,
});

export const addCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD CENTER DETAILS END *********************** */

/** ***************** UPDATE CENTER DETAILS START *********************** */
export const updateCenterDetailsStart = (updateData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_START,
	payload: updateData,
});

export const updateCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_RESET,
});

export const updateCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE CENTER DETAILS END *********************** */

/** ***************** GET CENTER DETAILS START *********************** */
export const getCenterDetailsStart = (getData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_START,
	payload: getData,
});

export const getCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_SUCCESS,
	payload: successData,
});

export const getCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.GET_CENTER_LIST_FAILURE,
	payload: failureData,
});

export const getCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.GET_CENTER_LIST_RESPONSE_RESET,
});

export const getCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.GET_CENTER_LIST_RESPONSE_CHANGED,
});

/** ***************** GET CENTER DETAILS END *********************** */

/** ***************** FETCH CENTER DETAILS START *********************** */
export const fetchCenterDetailsStart = (fetchData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_START,
	payload: fetchData,
});

export const fetchCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_RESET,
});

export const fetchCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH CENTER DETAILS END *********************** */

/** ***************** DELETE CENTER DETAILS START *********************** */
export const deleteCenterDetailsStart = (deleteData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_START,
	payload: deleteData,
});

export const deleteCenterDetailsSuccess = (successData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteCenterDetailsFailure = (failureData) => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteCenterDetailsResponseResetStart = () => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_RESET,
});

export const deleteCenterDetailsResponseChanged = () => ({
	type: CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE CENTER DETAILS END *********************** */

/** ***************** FETCH CENTER UNIQUE ID START *********************** */
export const fetchCenterUniqueIDStart = (fetchCenters) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_START,
	payload: fetchCenters,
});

export const fetchCenterUniqueIDSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_SUCCESS,
	payload: successData,
});

export const fetchCenterUniqueIDFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_FAILURE,
	payload: failureData,
});

export const fetchCenterUniqueIDResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET,
});

export const fetchCenterUniqueIDResponseChanged = () => ({
	type: CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_CHANGED,
});
/** ***************** FETCH CENTER UNIQUE ID END *********************** */

/** ***************** FETCH BRANCH BASED CENTER DETAILS START *********************** */
export const fetchBranchBasedCenterListStart = (fetchData) => ({
	type: CenterActionTypes.FETCH_BRANCH_CENTER_LIST_START,
	payload: fetchData,
});

export const fetchBranchBasedCenterListSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_BRANCH_CENTER_LIST_SUCCESS,
	payload: successData,
});

export const fetchBranchBasedCenterListFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_BRANCH_CENTER_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchBasedCenterListResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_BRANCH_CENTER_LIST_RESPONSE_RESET,
});

export const fetchBranchBasedCenterListResponseChanged = () => ({
	type: CenterActionTypes.FETCH_BRANCH_CENTER_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCH BASED CENTER DETAILS END *********************** */

/** ***************** FETCH CENTER BETA LIST START *********************** */
export const fetchCenterBetaListStart = (fetchData) => ({
	type: CenterActionTypes.FETCH_CENTER_BETA_LIST_START,
	payload: fetchData,
});

export const fetchCenterBetaListSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_CENTER_BETA_LIST_SUCCESS,
	payload: successData,
});

export const fetchCenterBetaListFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_CENTER_BETA_LIST_FAILURE,
	payload: failureData,
});

export const fetchCenterBetaListResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_CENTER_BETA_LIST_RESPONSE_RESET,
});

export const fetchCenterBetaListResponseChanged = () => ({
	type: CenterActionTypes.FETCH_CENTER_BETA_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH CENTER BETA LIST END *********************** */

/** ***************** FETCH ALL CENTERS LIST START *********************** */
export const fetchAllCentersListStart = (fetchData) => ({
	type: CenterActionTypes.FETCH_ALL_CENTERS_LIST_START,
	payload: fetchData,
});

export const fetchAllCentersListSuccess = (successData) => ({
	type: CenterActionTypes.FETCH_ALL_CENTERS_LIST_SUCCESS,
	payload: successData,
});

export const fetchAllCentersListFailure = (failureData) => ({
	type: CenterActionTypes.FETCH_ALL_CENTERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchAllCentersListResponseResetStart = () => ({
	type: CenterActionTypes.FETCH_ALL_CENTERS_LIST_RESPONSE_RESET,
});

export const fetchAllCentersListResponseChanged = () => ({
	type: CenterActionTypes.FETCH_ALL_CENTERS_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH ALL CENTERS LIST END *********************** */

export const isCenterDetailsEditStart = (isEdit) => ({
	type: CenterActionTypes.IS_EDIT_CENTER_DETAILS,
	payload: isEdit,
});

export const updateCenterBetaForBeta = (fetchMembers) => ({
	type: CenterActionTypes.UPDATE_CENTERS_FOR_BETA_START,
	payload: fetchMembers,
});

/** ***************** SAVE CENTER BETA TO CENTERS DETAILS - START *********************** */
export const saveCenterBetaToCentersDetailsStart = (addData) => ({
	type: CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_START,
	payload: addData,
});

export const saveCenterBetaToCentersDetailsSuccess = (successData) => ({
	type: CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_SUCCESS,
	payload: successData,
});

export const saveCenterBetaToCentersDetailsFailure = (failureData) => ({
	type: CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_FAILURE,
	payload: failureData,
});

export const saveCenterBetaToCentersDetailsResponseResetStart = () => ({
	type: CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_RESET,
});

export const saveCenterBetaToCentersDetailsResponseChanged = () => ({
	type: CenterActionTypes.SAVE_CENTER_BETA_TO_CENTERS_RESPONSE_CHANGED,
});

/** ***************** SAVE CENTER BETA TO CENTERS DETAILS - END *********************** */
