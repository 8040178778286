/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import StaffActionTypes from './staff.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	tabChangeValue: 'Personal Details',
	addStaffDetailsResponse: null,
	updateStaffDetailsResponse: null,
	updateStaffAddressDetailsResponse: null,
	updateStaffProofDetailsResponse: null,
	updateStaffEmergencyDetailsResponse: null,
	updateStaffOfficialDetailsResponse: null,
	addStaffTargetDetailsResponse: null,
	updateStaffTargetDetailsResponse: null,
	getStaffListResponse: null,
	getStaffListArr: [],
	searchStaffListResponse: null,
	searchStaffListArr: [],
	fetchSearchStaffListResponse: null,
	fetchSearchStaffListArr: [],
	fetchStaffDetailsResponse: null,
	fetchStaffDetailsArr: [],
	deleteStaffDetailsResponse: null,
	updateIsPublishStatus: null,
	fetchAllStaffsResponse: null,
	fetchAllStaffsArr: [],
	fetchAgentStaffsResponse: null,
	fetchAgentStaffsArr: [],
	fetchStaffTargetListResponse: null,
	fetchStaffTargetListArr: [],
	isShowStaffOffCanvas: false,
	isStaffEdit: false,
	getStaffID: '',
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case StaffActionTypes.ADD_STAFF_DETAILS_SUCCESS:
			return { ...state, addStaffDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_DETAILS_FAILURE:
			return { ...state, addStaffDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, addStaffDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_SUCCESS:
			return { ...state, updateStaffDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_FAILURE:
			return { ...state, updateStaffDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_SUCCESS:
			return { ...state, updateStaffAddressDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_FAILURE:
			return { ...state, updateStaffAddressDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffAddressDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_SUCCESS:
			return { ...state, updateStaffProofDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_FAILURE:
			return { ...state, updateStaffProofDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffProofDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_SUCCESS:
			return { ...state, updateStaffEmergencyDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_FAILURE:
			return { ...state, updateStaffEmergencyDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffEmergencyDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_SUCCESS:
			return { ...state, updateStaffOfficialDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_FAILURE:
			return { ...state, updateStaffOfficialDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffOfficialDetailsResponse: null };

		case StaffActionTypes.ADD_STAFF_TARGET_DETAILS_SUCCESS:
			return { ...state, addStaffTargetDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_TARGET_DETAILS_FAILURE:
			return { ...state, addStaffTargetDetailsResponse: action.payload };

		case StaffActionTypes.ADD_STAFF_TARGET_DETAILS_RESPONSE_CHANGED:
			return { ...state, addStaffTargetDetailsResponse: null };

		case StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_SUCCESS:
			return { ...state, updateStaffTargetDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_FAILURE:
			return { ...state, updateStaffTargetDetailsResponse: action.payload };

		case StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateStaffTargetDetailsResponse: null };

		case StaffActionTypes.GET_STAFF_LIST_SUCCESS:
			return {
				...state,
				getStaffListResponse: action.payload,
				getStaffListArr: docodeResponse(action.payload, 'FetchStaffListKey'),
			};

		case StaffActionTypes.GET_STAFF_LIST_FAILURE:
			return { ...state, getStaffListResponse: action.payload };

		case StaffActionTypes.GET_STAFF_LIST_RESPONSE_CHANGED:
			return { ...state, getStaffListResponse: null };

		case StaffActionTypes.FETCH_SEARCH_STAFF_LIST_SUCCESS:
			return {
				...state,
				searchStaffListResponse: action.payload,
				searchStaffListArr: docodeResponse(action.payload, 'FetchSearchStaffListKey'),
			};

		case StaffActionTypes.FETCH_SEARCH_STAFF_LIST_FAILURE:
			return { ...state, searchStaffListResponse: action.payload };

		case StaffActionTypes.FETCH_SEARCH_STAFF_LIST_RESPONSE_CHANGED:
			return { ...state, searchStaffListResponse: null };

		case StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS:
			return {
				...state,
				fetchStaffDetailsResponse: action.payload,
				fetchStaffDetailsArr: docodeResponse(action.payload, 'FetchStaffInfoKey'),
			};

		case StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE:
			return { ...state, fetchStaffDetailsResponse: action.payload };

		case StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchStaffDetailsResponse: null, fetchStaffDetailsArr: [] };

		case StaffActionTypes.DELETE_STAFF_DETAILS_SUCCESS:
			return { ...state, deleteStaffDetailsResponse: action.payload };

		case StaffActionTypes.DELETE_STAFF_DETAILS_FAILURE:
			return { ...state, deleteStaffDetailsResponse: action.payload };

		case StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteStaffDetailsResponse: null };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_SUCCESS:
			return { ...state, updateIsPublishStatus: action.payload };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_FAILURE:
			return { ...state, updateIsPublishStatus: action.payload };

		case StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED:
			return { ...state, updateIsPublishStatus: null };

		case StaffActionTypes.FETCH_ALL_STAFF_SUCCESS:
			return {
				...state,
				fetchAllStaffsResponse: action.payload,
				fetchAllStaffsArr: docodeResponse(action.payload, 'FetchAllStaffListKey'),
			};

		case StaffActionTypes.FETCH_ALL_STAFF_FAILURE:
			return { ...state, fetchAllStaffsResponse: action.payload };

		case StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_CHANGED:
			return { ...state, fetchAllStaffsResponse: null };

		case StaffActionTypes.FETCH_AGENT_STAFF_SUCCESS:
			return {
				...state,
				fetchAgentStaffsResponse: action.payload,
				fetchAgentStaffsArr: docodeResponse(action.payload, 'FetchAgentStaffListKey'),
			};

		case StaffActionTypes.FETCH_AGENT_STAFF_FAILURE:
			return { ...state, fetchAgentStaffsResponse: action.payload };

		case StaffActionTypes.FETCH_AGENT_STAFF_RESPONSE_CHANGED:
			return { ...state, fetchAgentStaffsResponse: null };

		case StaffActionTypes.FETCH_STAFF_TARGET_LIST_SUCCESS:
			return {
				...state,
				fetchStaffTargetListResponse: action.payload,
				fetchStaffTargetListArr: docodeResponse(action.payload, 'FetchStaffTargetListKey'),
			};

		case StaffActionTypes.FETCH_STAFF_TARGET_LIST_FAILURE:
			return { ...state, fetchStaffTargetListResponse: action.payload };

		case StaffActionTypes.FETCH_STAFF_TARGET_LIST_RESPONSE_CHANGED:
			return { ...state, fetchStaffTargetListResponse: null };

		case StaffActionTypes.STAFF_IS_OPEN_OFFCANVAS:
			return { ...state, isShowStaffOffCanvas: action.payload };

		case StaffActionTypes.STAFF_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, tabChangeValue: action.payload };

		case StaffActionTypes.IS_EDIT_STAFF_DETAILS:
			return { ...state, isStaffEdit: action.payload };

		case StaffActionTypes.STAFF_ID:
			return { ...state, getStaffID: action.payload };

		default:
			return state;
	}
};
