/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import loginActionTypes from './login.types';
import { UserLogin } from './login.services';
import { signInSuccess, signInFailure, logOut, signInChanged } from './login.actions';
import { encodeJson } from '../enode-decode';
import generalTypesAction from '../genera.types';

export function* singInWithPhoneNumber({ payload: { values } }) {
	try {
		const jsonData = {
			UserPhoneNumber: values.phoneNumber,
			UserPassword: values.password,
			UserTokenID: '',
		};
		const loginData = encodeJson(jsonData, generalTypesAction.LOGIN_KEY);
		const responseData = yield call(UserLogin, loginData);
		if (responseData.data.statusCode === '500') {
			yield put(signInFailure(responseData.data));
		} else {
			yield put(signInSuccess(responseData.data));
		}
	} catch (error) {
		yield put(signInFailure(error));
	}
}
export function* makeUserLogout() {
	yield put(logOut());
}

export function* makeUserLoginReset() {
	yield put(signInChanged());
}

export function* userLogout() {
	yield takeLatest(loginActionTypes.SIGN_IN_LOGOUT, makeUserLogout);
}

export function* onEmailSignInReset() {
	yield takeLatest(loginActionTypes.SIGN_IN_RESET, makeUserLoginReset);
}

export function* onEmailSignInStart() {
	yield takeLatest(loginActionTypes.SIGN_IN_START, singInWithPhoneNumber);
}

export function* loginSaga() {
	yield all([call(onEmailSignInStart), call(userLogout), call(onEmailSignInReset)]);
}
