/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchChitDemandReport = (listData) => {
	const data = {
		FetchChitDemandReportToken: listData,
	};
	const url = `${API_URL}FetchChitDemandReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
