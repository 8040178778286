const BranchActionTypes = {
	ADD_BRANCH_DETAILS_START: 'add_branch_details_start',
	ADD_BRANCH_DETAILS_SUCCESS: 'add_branch_details_success',
	ADD_BRANCH_DETAILS_FAILURE: 'add_branch_details_failure',
	ADD_BRANCH_DETAILS_RESPONSE_RESET: 'add_branch_details_response_reset',
	ADD_BRANCH_DETAILS_RESPONSE_CHANGED: 'add_branch_details_response_changed',

	ADD_BRANCH_BANK_ACCOUNT_DETAILS_START: 'add_branch_bank_account_details_start',
	ADD_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS: 'add_branch_bank_account_details_success',
	ADD_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE: 'add_branch_bank_account_details_failure',
	ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET:
		'add_branch_bank_account_details_response_reset',
	ADD_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
		'add_branch_bank_account_details_response_changed',

	ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START: 'add_branch_bank_cheque_book_details_start',
	ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS: 'add_branch_bank_cheque_book_details_success',
	ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE: 'add_branch_bank_cheque_book_details_failure',
	ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET:
		'add_branch_bank_cheque_book_details_response_reset',
	ADD_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED:
		'add_branch_bank_cheque_book_details_response_changed',

	UPDATE_BRANCH_DETAILS_START: 'update_branch_details_start',
	UPDATE_BRANCH_DETAILS_SUCCESS: 'update_branch_details_success',
	UPDATE_BRANCH_DETAILS_FAILURE: 'update_branch_details_failure',
	UPDATE_BRANCH_DETAILS_RESPONSE_RESET: 'update_branch_details_response_reset',
	UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED: 'update_branch_details_response_changed',

	UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_START: 'update_branch_bank_account_details_start',
	UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_SUCCESS: 'update_branch_bank_account_details_success',
	UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_FAILURE: 'update_branch_bank_account_details_failure',
	UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_RESET:
		'update_branch_bank_account_details_response_reset',
	UPDATE_BRANCH_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
		'update_branch_bank_account_details_response_changed',

	UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_START: 'update_branch_bank_cheque_book_details_start',
	UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_SUCCESS:
		'update_branch_bank_cheque_book_details_success',
	UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_FAILURE:
		'update_branch_bank_cheque_book_details_failure',
	UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_RESET:
		'update_branch_bank_cheque_book_details_response_reset',
	UPDATE_BRANCH_BANK_CHEQUE_BOOK_DETAILS_RESPONSE_CHANGED:
		'update_branch_bank_cheque_book_details_response_changed',

	GET_BRANCH_LIST_START: 'get_branch_list_start',
	GET_BRANCH_LIST_SUCCESS: 'get_branch_list_success',
	GET_BRANCH_LIST_FAILURE: 'get_branch_list_failure',
	GET_BRANCH_LIST_RESPONSE_RESET: 'get_branch_list_response_reset',
	GET_BRANCH_LIST_RESPONSE_CHANGED: 'get_branch_list_response_changed',

	FETCH_SEARCH_BRANCH_LIST_START: 'FETCH_SEARCH_BRANCH_LIST_START',
	FETCH_SEARCH_BRANCH_LIST_SUCCESS: 'FETCH_SEARCH_BRANCH_LIST_SUCCESS',
	FETCH_SEARCH_BRANCH_LIST_FAILURE: 'FETCH_SEARCH_BRANCH_LIST_FAILURE',
	FETCH_SEARCH_BRANCH_LIST_RESPONSE_RESET: 'FETCH_SEARCH_BRANCH_LIST_RESPONSE_RESET',
	FETCH_SEARCH_BRANCH_LIST_RESPONSE_CHANGED: 'FETCH_SEARCH_BRANCH_LIST_RESPONSE_CHANGED',

	FETCH_BRANCH_BANK_ACCOUNT_LIST_START: 'fetch_branch_bank_account_list_start',
	FETCH_BRANCH_BANK_ACCOUNT_LIST_SUCCESS: 'fetch_branch_bank_account_list_success',
	FETCH_BRANCH_BANK_ACCOUNT_LIST_FAILURE: 'fetch_branch_bank_account_list_failure',
	FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_RESET: 'fetch_branch_bank_account_list_response_reset',
	FETCH_BRANCH_BANK_ACCOUNT_LIST_RESPONSE_CHANGED:
		'fetch_branch_bank_account_list_response_changed',

	FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_START: 'fetch_branch_bank_cheque_book_list_start',
	FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_SUCCESS: 'fetch_branch_bank_cheque_book_list_success',
	FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_FAILURE: 'fetch_branch_bank_cheque_book_list_failure',
	FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_RESET:
		'fetch_branch_bank_cheque_book_list_response_reset',
	FETCH_BRANCH_BANK_CHEQUE_BOOK_LIST_RESPONSE_CHANGED:
		'fetch_branch_bank_cheque_book_list_response_changed',

	FETCH_BRANCH_DETAILS_START: 'fetch_branch_details_start',
	FETCH_BRANCH_DETAILS_SUCCESS: 'fetch_branch_details_success',
	FETCH_BRANCH_DETAILS_FAILURE: 'fetch_branch_details_failure',
	FETCH_BRANCH_DETAILS_RESPONSE_RESET: 'fetch_branch_details_response_reset',
	FETCH_BRANCH_DETAILS_RESPONSE_CHANGED: 'fetch_branch_details_response_changed',

	DELETE_BRANCH_DETAILS_START: 'delete_branch_details_start',
	DELETE_BRANCH_DETAILS_SUCCESS: 'delete_branch_details_success',
	DELETE_BRANCH_DETAILS_FAILURE: 'delete_branch_details_failure',
	DELETE_BRANCH_DETAILS_RESPONSE_RESET: 'delete_branch_details_response_reset',
	DELETE_BRANCH_DETAILS_RESPONSE_CHANGED: 'delete_branch_details_response_changed',

	DELETE_BRANCH_BANK_START: 'delete_branch_bank_start',
	DELETE_BRANCH_BANK_SUCCESS: 'delete_branch_bank_success',
	DELETE_BRANCH_BANK_FAILURE: 'delete_branch_bank_failure',
	DELETE_BRANCH_BANK_RESPONSE_RESET: 'delete_branch_bank_response_reset',
	DELETE_BRANCH_BANK_RESPONSE_CHANGED: 'delete_branch_bank_response_changed',

	DELETE_BRANCH_CHEQUE_BOOK_START: 'delete_branch_cheque_book_start',
	DELETE_BRANCH_CHEQUE_BOOK_SUCCESS: 'delete_branch_cheque_book_success',
	DELETE_BRANCH_CHEQUE_BOOK_FAILURE: 'delete_branch_cheque_book_failure',
	DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_RESET: 'delete_branch_cheque_book_response_reset',
	DELETE_BRANCH_CHEQUE_BOOK_RESPONSE_CHANGED: 'delete_branch_cheque_book_response_changed',

	BRANCH_SUBMIT_TAB_VALUE_CHANGED: 'branch_submit_tab_value_changed',
	BRANCH_IS_OPEN_OFFCANVAS: 'branch_is_open_offcanvas',
	IS_EDIT_BRANCH_DETAILS: 'is_edit_branch_details',
	BRANCH_ID: 'branch_id',

	BANK_ACCOUNT_LIST_ARR: 'bank_account_list_arr',
	BANK_ACCOUNT_LIST_ARR_UPDATE: 'bank_account_list_arr_update',
	BANK_ACCOUNT_LIST_ARR_REMOVE: 'bank_account_list_arr_remove',
	BANK_CHEQUE_BOOK_LIST_ARR: 'bank_cheque_book_list_arr',
	BANK_CHEQUE_BOOK_LIST_ARR_UPDATE: 'bank_cheque_book_list_arr_update',
	BANK_CHEQUE_BOOK_LIST_ARR_REMOVE: 'bank_cheque_book_list_arr_remove',
};
export default BranchActionTypes;
