const DashboardActionTypes = {
	FETCH_DASHBOARD_DETAILS_START: 'FETCH_DASHBOARD_DETAILS_START',
	FETCH_DASHBOARD_DETAILS_SUCCESS: 'FETCH_DASHBOARD_DETAILS_SUCCESS',
	FETCH_DASHBOARD_DETAILS_FAILURE: 'FETCH_DASHBOARD_DETAILS_FAILURE',
	FETCH_DASHBOARD_DETAILS_RESPONSE_RESET: 'FETCH_DASHBOARD_DETAILS_RESPONSE_RESET',
	FETCH_DASHBOARD_DETAILS_RESPONSE_CHANGED: 'FETCH_DASHBOARD_DETAILS_RESPONSE_CHANGED',

	FETCH_ONE_WEEK_DEMAND_DETAILS_START: 'FETCH_ONE_WEEK_DEMAND_DETAILS_START',
	FETCH_ONE_WEEK_DEMAND_DETAILS_SUCCESS: 'FETCH_ONE_WEEK_DEMAND_DETAILS_SUCCESS',
	FETCH_ONE_WEEK_DEMAND_DETAILS_FAILURE: 'FETCH_ONE_WEEK_DEMAND_DETAILS_FAILURE',
	FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_RESET: 'FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_RESET',
	FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_CHANGED:
		'FETCH_ONE_WEEK_DEMAND_DETAILS_RESPONSE_CHANGED',
};
export default DashboardActionTypes;
