/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import applicantCollectionActionTypes from './applicantCollection.types';

/** ***************** ADD APPLICANT COLLECTION START *********************** */
export const addApplicantCollectionDetailsStart = (getData) => ({
	type: applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_START,
	payload: getData,
});

export const addApplicantCollectionDetailsSuccess = (successData) => ({
	type: applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_SUCCESS,
	payload: successData,
});

export const addApplicantCollectionDetailsFailure = (failureData) => ({
	type: applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_FAILURE,
	payload: failureData,
});

export const addApplicantCollectionDetailsResponseResetStart = () => ({
	type: applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_RESPONSE_RESET,
});

export const addApplicantCollectionDetailsResponseChanged = () => ({
	type: applicantCollectionActionTypes.ADD_APPLICANT_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** ADD APPLICANT COLLECTION END *********************** */

/** ***************** GET APPLICANT COLLECTION INFO START *********************** */
export const fetchApplicantCollectionInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_START,
	payload: getData,
});

export const getApplicantCollectionInfoSuccess = (successData) => ({
	type: applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_SUCCESS,
	payload: successData,
});

export const getApplicantCollectionInfoFailure = (failureData) => ({
	type: applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_FAILURE,
	payload: failureData,
});

export const fetchApplicantCollectionInfoResponseResetStart = () => ({
	type: applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_RESPONSE_RESET,
});

export const getApplicantCollectionInfoResponseChanged = () => ({
	type: applicantCollectionActionTypes.GET_APPLICANT_COLLECTION_INFO_RESPONSE_CHANGED,
});
/** ***************** GET APPLICANT COLLECTION INFO END *********************** */

/** ***************** GET APPLICANT ENROLLMENT COLLECTION INFO START *********************** */
export const fetchApplicantEnrollCollectionInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_START,
	payload: getData,
});

export const getApplicantEnrollCollectionInfoSuccess = (successData) => ({
	type: applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_SUCCESS,
	payload: successData,
});

export const getApplicantEnrollCollectionInfoFailure = (failureData) => ({
	type: applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_FAILURE,
	payload: failureData,
});

export const fetchApplicantEnrollCollectionInfoResponseResetStart = () => ({
	type: applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_RESET,
});

export const getApplicantEnrollCollectionInfoResponseChanged = () => ({
	type: applicantCollectionActionTypes.FETCH_APPLICANT_ENROLLMENT_COLLECTION_INFO_RESPONSE_CHANGED,
});
/** ***************** GET APPLICANT ENROLLMENT COLLECTION INFO END *********************** */

/** ***************** GET DENOMINATION INFO START *********************** */
export const getFiveHundredInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_FIVE_HUNDRED,
	payload: getData,
});

export const getTwoHundredInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_TWO_HUNDRED,
	payload: getData,
});

export const getHundredInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_HUNDRED,
	payload: getData,
});

export const getFiftyInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_FIFTY,
	payload: getData,
});

export const getTwentyInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_TWENTY,
	payload: getData,
});

export const getTenInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_TEN,
	payload: getData,
});

export const getFiveInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_FIVE,
	payload: getData,
});

export const getTwoInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_TWO,
	payload: getData,
});

export const getOneInfoStart = (getData) => ({
	type: applicantCollectionActionTypes.GET_ONE,
	payload: getData,
});
/** ***************** GET DENOMINATION INFO END *********************** */
