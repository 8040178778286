/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import DemandCollectionActionTypes from './demandCollection.types';

/** ***************** GET BRANCH DEMAND COLLECTION LIST START *********************** */
export const fetchDemandCollectionListStart = (getData) => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_START,
	payload: getData,
});

export const getDemandCollectionListSuccess = (successData) => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_SUCCESS,
	payload: successData,
});

export const getDemandCollectionListFailure = (failureData) => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_FAILURE,
	payload: failureData,
});

export const fetchDemandCollectionListResponseResetStart = () => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_RESPONSE_RESET,
});

export const getDemandCollectionListResponseChanged = () => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED,
});

export const updateGetDemandCollectionListStart = (getData) => ({
	type: DemandCollectionActionTypes.FETCH_DEMAND_COLLECTION_LIST_ARR_UPDATED_CHANGED,
	payload: getData,
});
/** ***************** GET BRANCH DEMAND COLLECTION LIST END *********************** */

/** ***************** GET TO UPDATE DEMAND COLLECTION DETAILS START *********************** */
export const toUpdateDemandCollectionDetailsStart = (getData) => ({
	type: DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_START,
	payload: getData,
});

export const toUpdateDemandCollectionDetailsSuccess = (successData) => ({
	type: DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_SUCCESS,
	payload: successData,
});

export const toUpdateDemandCollectionDetailsFailure = (failureData) => ({
	type: DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_FAILURE,
	payload: failureData,
});

export const toUpdateDemandCollectionDetailsResponseResetStart = () => ({
	type: DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_RESET,
});

export const toUpdateDemandCollectionDetailsResponseChanged = () => ({
	type: DemandCollectionActionTypes.TO_UPDATE_DEMAND_COLLECTION_DETAILS_RESPONSE_CHANGED,
});

/** ***************** TO UPDATE DEMAND COLLECTION DETAILS END *********************** */
