const MemberActionTypes = {
	// Add New Member
	ADD_MEMBER_DETAILS_START: 'add_member_details_start',
	ADD_MEMBER_DETAILS_SUCCESS: 'add_member_details_success',
	ADD_MEMBER_DETAILS_FAILURE: 'add_member_details_failure',
	ADD_MEMBER_DETAILS_RESPONSE_RESET: 'add_member_details_response_reset',
	ADD_MEMBER_DETAILS_RESPONSE_CHANGED: 'add_member_details_response_changed',

	// Add New Member Security
	ADD_MEMBER_SECURITY_DETAILS_START: 'add_member_security_details_start',
	ADD_MEMBER_SECURITY_DETAILS_SUCCESS: 'add_member_security_details_success',
	ADD_MEMBER_SECURITY_DETAILS_FAILURE: 'add_member_security_details_failure',
	ADD_MEMBER_SECURITY_DETAILS_RESPONSE_RESET: 'add_member_security_details_response_reset',
	ADD_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED: 'add_member_security_details_response_changed',

	// Add New Member Proof
	ADD_MEMBER_PROOF_DETAILS_START: 'add_member_proof_details_start',
	ADD_MEMBER_PROOF_DETAILS_SUCCESS: 'add_member_proof_details_success',
	ADD_MEMBER_PROOF_DETAILS_FAILURE: 'add_member_proof_details_failure',
	ADD_MEMBER_PROOF_DETAILS_RESPONSE_RESET: 'add_member_proof_details_response_reset',
	ADD_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED: 'add_member_proof_details_response_changed',

	// Add New Member Address
	ADD_MEMBER_ADDRESS_DETAILS_START: 'ADD_MEMBER_ADDRESS_DETAILS_START',
	ADD_MEMBER_ADDRESS_DETAILS_SUCCESS: 'ADD_MEMBER_ADDRESS_DETAILS_SUCCESS',
	ADD_MEMBER_ADDRESS_DETAILS_FAILURE: 'ADD_MEMBER_ADDRESS_DETAILS_FAILURE',
	ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET: 'ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET',
	ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED: 'ADD_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED',

	// Add New Member enrollment
	ADD_MEMBER_ENROLLMENT_DETAILS_START: 'add_member_enrollment_details_start',
	ADD_MEMBER_ENROLLMENT_DETAILS_SUCCESS: 'add_member_enrollment_details_success',
	ADD_MEMBER_ENROLLMENT_DETAILS_FAILURE: 'add_member_enrollment_details_failure',
	ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET: 'add_member_enrollment_details_response_reset',
	ADD_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
		'add_member_enrollment_details_response_changed',

	// Add New Member Chit Request
	ADD_MEMBER_CHIT_REQUEST_DETAILS_START: 'add_member_chit_request_details_start',
	ADD_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS: 'add_member_chit_request_details_success',
	ADD_MEMBER_CHIT_REQUEST_DETAILS_FAILURE: 'add_member_chit_request_details_failure',
	ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET:
		'add_member_chit_request_details_response_reset',
	ADD_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
		'add_member_chit_request_details_response_changed',

	// Update Exist Member
	UPDATE_MEMBER_DETAILS_START: 'update_member_details_start',
	UPDATE_MEMBER_DETAILS_SUCCESS: 'update_member_details_success',
	UPDATE_MEMBER_DETAILS_FAILURE: 'update_member_details_failure',
	UPDATE_MEMBER_DETAILS_RESPONSE_RESET: 'update_member_details_response_reset',
	UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED: 'update_member_details_response_changed',

	// Update Exist Member Address
	UPDATE_MEMBER_ADDRESS_DETAILS_START: 'update_member_address_details_start',
	UPDATE_MEMBER_ADDRESS_DETAILS_SUCCESS: 'update_member_address_details_success',
	UPDATE_MEMBER_ADDRESS_DETAILS_FAILURE: 'update_member_address_details_failure',
	UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_RESET: 'update_member_address_details_response_reset',
	UPDATE_MEMBER_ADDRESS_DETAILS_RESPONSE_CHANGED:
		'update_member_address_details_response_changed',

	// Update Exist Member Bank Account
	UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_START: 'update_member_bank_account_details_start',
	UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_SUCCESS: 'update_member_bank_account_details_success',
	UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_FAILURE: 'update_member_bank_account_details_failure',
	UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_RESET:
		'update_member_bank_account_details_response_reset',
	UPDATE_MEMBER_BANK_ACCOUNT_DETAILS_RESPONSE_CHANGED:
		'update_member_bank_account_details_response_changed',

	// Update Exist Member Proof
	UPDATE_MEMBER_PROOF_DETAILS_START: 'update_member_proof_details_start',
	UPDATE_MEMBER_PROOF_DETAILS_SUCCESS: 'update_member_proof_details_success',
	UPDATE_MEMBER_PROOF_DETAILS_FAILURE: 'update_member_proof_details_failure',
	UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_RESET: 'update_member_proof_details_response_reset',
	UPDATE_MEMBER_PROOF_DETAILS_RESPONSE_CHANGED: 'update_member_proof_details_response_changed',

	// Update Exist Member Security
	UPDATE_MEMBER_SECURITY_DETAILS_START: 'update_member_security_details_start',
	UPDATE_MEMBER_SECURITY_DETAILS_SUCCESS: 'update_member_security_details_success',
	UPDATE_MEMBER_SECURITY_DETAILS_FAILURE: 'update_member_security_details_failure',
	UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_RESET: 'update_member_security_details_response_reset',
	UPDATE_MEMBER_SECURITY_DETAILS_RESPONSE_CHANGED:
		'update_member_security_details_response_changed',

	// Update Exist Member Official Details
	UPDATE_MEMBER_OFFICIAL_DETAILS_START: 'update_member_official_details_start',
	UPDATE_MEMBER_OFFICIAL_DETAILS_SUCCESS: 'update_member_official_details_success',
	UPDATE_MEMBER_OFFICIAL_DETAILS_FAILURE: 'update_member__official_details_failure',
	UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_RESET: 'update_member_official_details_response_reset',
	UPDATE_MEMBER_OFFICIAL_DETAILS_RESPONSE_CHANGED:
		'update_member_official_details_response_changed',

	// Update Exist Member enrollment
	UPDATE_MEMBER_ENROLLMENT_DETAILS_START: 'update_member_enrollment_details_start',
	UPDATE_MEMBER_ENROLLMENT_DETAILS_SUCCESS: 'update_member_enrollment_details_success',
	UPDATE_MEMBER_ENROLLMENT_DETAILS_FAILURE: 'update_member_enrollment_details_failure',
	UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET:
		'update_member_enrollment_details_response_reset',
	UPDATE_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
		'update_member_enrollment_details_response_changed',

	// Update Exist Member Chit Request
	UPDATE_MEMBER_CHIT_REQUEST_DETAILS_START: 'update_member_chit_request_details_start',
	UPDATE_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS: 'update_member_chit_request_details_success',
	UPDATE_MEMBER_CHIT_REQUEST_DETAILS_FAILURE: 'update_member_chit_request_details_failure',
	UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET:
		'update_member_chit_request_details_response_reset',
	UPDATE_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
		'update_member_chit_request_details_response_changed',

	// Update Exist Member IsApprove Details
	UPDATE_MEMBER_IS_APPROVE_DETAILS_START: 'update_member_is_approve_details_start',
	UPDATE_MEMBER_IS_APPROVE_DETAILS_SUCCESS: 'update_member_is_approve_details_success',
	UPDATE_MEMBER_IS_APPROVE_DETAILS_FAILURE: 'update_member_is_approve_details_failure',
	UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_RESET:
		'update_member_is_approve_details_response_reset',
	UPDATE_MEMBER_IS_APPROVE_DETAILS_RESPONSE_CHANGED:
		'update_member_is_approve_details_response_changed',

	DELETE_MEMBER_DETAILS_START: 'delete_member_details_start',
	DELETE_MEMBER_DETAILS_SUCCESS: 'delete_member_details_success',
	DELETE_MEMBER_DETAILS_FAILURE: 'delete_member_details_failure',
	DELETE_MEMBER_DETAILS_RESPONSE_RESET: 'delete_member_details_response_reset',
	DELETE_MEMBER_DETAILS_RESPONSE_CHANGED: 'delete_member_details_response_changed',

	// Fetch Member List
	GET_MEMBER_LIST_START: 'get_member_list_start',
	GET_MEMBER_LIST_SUCCESS: 'get_member_list_success',
	GET_MEMBER_LIST_FAILURE: 'get_member_list_failure',
	GET_MEMBER_LIST_RESPONSE_RESET: 'get_member_list_response_reset',
	GET_MEMBER_LIST_RESPONSE_CHANGED: 'get_member_list_response_changed',
	GET_MEMBER_LIST_RESPONSE_CLEAR: 'get_member_list_reponse_clear',

	// Fetch Search Member List
	FETCH_SEARCH_MEMBER_LIST_START: 'fetch_search_member_list_start',
	FETCH_SEARCH_MEMBER_LIST_SUCCESS: 'fetch_search_member_list_success',
	FETCH_SEARCH_MEMBER_LIST_FAILURE: 'fetch_search_member_list_failure',
	FETCH_SEARCH_MEMBER_LIST_RESPONSE_RESET: 'fetch_search_member_list_response_reset',
	FETCH_SEARCH_MEMBER_LIST_RESPONSE_CHANGED: 'fetch_search_member_list_response_changed',
	FETCH_SEARCH_MEMBER_LIST_RESPONSE_CLEAR: 'fetch_search_member_list_response_clear',

	// Fetch Advance Search Member List
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_START: 'fetch_advance_search_member_list_start',
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_SUCCESS: 'fetch_advance_search_member_list_success',
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_FAILURE: 'fetch_advance_search_member_list_failure',
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_RESET:
		'fetch_advance_search_member_list_response_reset',
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CHANGED:
		'fetch_advance_search_member_list_response_changed',
	FETCH_ADVANCE_SEARCH_MEMBER_LIST_RESPONSE_CLEAR:
		'fetch_advance_search_member_list_response_clear',

	// Fetch Member Info
	GET_MEMBER_INFO_START: 'get_member_Info_start',
	GET_MEMBER_INFO_SUCCESS: 'get_member_Info_success',
	GET_MEMBER_INFO_FAILURE: 'get_member_Info_failure',
	GET_MEMBER_INFO_RESPONSE_RESET: 'get_member_Info_response_reset',
	GET_MEMBER_INFO_RESPONSE_CHANGED: 'get_member_Info_response_changed',

	// Fetch Member enrollment
	FETCH_MEMBER_ENROLLMENT_DETAILS_START: 'fetch_member_enrollment_details_start',
	FETCH_MEMBER_ENROLLMENT_DETAILS_SUCCESS: 'fetch_member_enrollment_details_success',
	FETCH_MEMBER_ENROLLMENT_DETAILS_FAILURE: 'fetch_member_enrollment_details_failure',
	FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_RESET:
		'fetch_member_enrollment_details_response_reset',
	FETCH_MEMBER_ENROLLMENT_DETAILS_RESPONSE_CHANGED:
		'fetch_member_enrollment_details_response_changed',

	// Fetch Member Enrollment list
	FETCH_MEMBER_ENROLLMENT_LIST_START: 'FETCH_MEMBER_ENROLLMENT_LIST_START',
	FETCH_MEMBER_ENROLLMENT_LIST_SUCCESS: 'FETCH_MEMBER_ENROLLMENT_LIST_SUCCESS',
	FETCH_MEMBER_ENROLLMENT_LIST_FAILURE: 'FETCH_MEMBER_ENROLLMENT_LIST_FAILURE',
	FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_RESET: 'FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_RESET',
	FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_CHANGED: 'FETCH_MEMBER_ENROLLMENT_LIST_RESPONSE_CHANGED',

	// Fetch Member Chit Request
	FETCH_MEMBER_CHIT_REQUEST_DETAILS_START: 'fetch_member_chit_request_details_start',
	FETCH_MEMBER_CHIT_REQUEST_DETAILS_SUCCESS: 'fetch_member_chit_request_details_success',
	FETCH_MEMBER_CHIT_REQUEST_DETAILS_FAILURE: 'fetch_member_chit_request_details_failure',
	FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_RESET:
		'fetch_member_chit_request_details_response_reset',
	FETCH_MEMBER_CHIT_REQUEST_DETAILS_RESPONSE_CHANGED:
		'fetch_member_chit_request_details_response_changed',

	// Fetch Exist Member Security List
	FETCH_MEMBER_SECURITY_LIST_START: 'fetch_member_security_list_start',
	FETCH_MEMBER_SECURITY_LIST_SUCCESS: 'fetch_member_security_list_success',
	FETCH_MEMBER_SECURITY_LIST_FAILURE: 'fetch_member_security_list_failure',
	FETCH_MEMBER_SECURITY_LIST_RESPONSE_RESET: 'fetch_member_security_list_response_reset',
	FETCH_MEMBER_SECURITY_LIST_RESPONSE_CHANGED: 'fetch_member_security_list_response_changed',

	// Fetch Exist Member Address List
	FETCH_MEMBER_ADDRESS_LIST_START: 'FETCH_MEMBER_ADDRESS_LIST_START',
	FETCH_MEMBER_ADDRESS_LIST_SUCCESS: 'FETCH_MEMBER_ADDRESS_LIST_SUCCESS',
	FETCH_MEMBER_ADDRESS_LIST_FAILURE: 'FETCH_MEMBER_ADDRESS_LIST_FAILURE',
	FETCH_MEMBER_ADDRESS_LIST_RESPONSE_RESET: 'FETCH_MEMBER_ADDRESS_LIST_RESPONSE_RESET',
	FETCH_MEMBER_ADDRESS_LIST_RESPONSE_CHANGED: 'FETCH_MEMBER_ADDRESS_LIST_RESPONSE_CHANGED',

	// Fetch Exist Member Address Info
	FETCH_MEMBER_ADDRESS_INFO_START: 'FETCH_MEMBER_ADDRESS_INFO_START',
	FETCH_MEMBER_ADDRESS_INFO_SUCCESS: 'FETCH_MEMBER_ADDRESS_INFO_SUCCESS',
	FETCH_MEMBER_ADDRESS_INFO_FAILURE: 'FETCH_MEMBER_ADDRESS_INFO_FAILURE',
	FETCH_MEMBER_ADDRESS_INFO_RESPONSE_RESET: 'FETCH_MEMBER_ADDRESS_INFO_RESPONSE_RESET',
	FETCH_MEMBER_ADDRESS_INFO_RESPONSE_CHANGED: 'FETCH_MEMBER_ADDRESS_INFO_RESPONSE_CHANGED',

	// Fetch Product Enrollment members List
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_START: 'fetch_product_enrollment_mmebers_list_start',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_SUCCESS: 'fetch_product_enrollment_mmebers_list_success',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_FAILURE: 'fetch_product_enrollment_mmebers_list_failure',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET:
		'fetch_product_enrollment_mmebers_list_response_reset',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED:
		'fetch_product_enrollment_mmebers_list_response_changed',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED:
		'fetch_product_enrollment_mmebers_list_arr_response_changed',
	FETCH_PRODUCT_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED:
		'fetch_product_enrollment_members_list_arr_updated_changed',

	// Fetch Center Enrollment members List
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_START: 'fetch_center_enrollment_mmebers_list_start',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_SUCCESS: 'fetch_center_enrollment_mmebers_list_success',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_FAILURE: 'fetch_center_enrollment_mmebers_list_failure',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_RESET:
		'fetch_center_enrollment_mmebers_list_response_reset',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_RESPONSE_CHANGED:
		'fetch_center_enrollment_mmebers_list_response_changed',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_RESPONSE_CHANGED:
		'fetch_center_enrollment_mmebers_list_arr_response_changed',
	FETCH_CENTER_ENROLLMENT_MEMBERS_LIST_ARR_UPDATED_CHANGED:
		'fetch_center_enrollment_members_list_arr_updated_changed',

	MEMBER_ID: 'MEMBER_ID',
	MEMBER_SUBMIT_TAB_VALUE_CHANGED: 'MEMBER_SUBMIT_TAB_VALUE_CHANGED',
	MEMBER_IS_OPEN_OFFCANVAS: 'MEMBER_IS_OPEN_OFFCANVAS',
	MEMBER_IS_OPEN_ADVANCE_SEARCH_OFFCANVAS: 'MEMBER_IS_OPEN_ADVANCE_SEARCH_OFFCANVAS',
	IS_EDIT_MEMBER_DETAILS: 'IS_EDIT_MEMBER_DETAILS',
	MEMBER_TYPE: 'MEMBER_TYPE',
	MEMBER_MARITAL_STATUS: 'MEMBER_MARITAL_STATUS',
};
export default MemberActionTypes;
