/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChitIndexActionTypes from './chitIndex.types';
import {
	chitIndexDetailsAdd,
	chitIndexMembersDetailsAdd,
	chitIndexDetailsUpdate,
	fetchChitIndexList,
	chitIndexDetailsFetch,
	chitIndexDetailsDelete,
} from './chitIndex.service';

import {
	addChitIndexDetailsSuccess,
	addChitIndexDetailsFailure,
	addChitIndexDetailsResponseChanged,
	addChitIndexMembersDetailsSuccess,
	addChitIndexMembersDetailsFailure,
	addChitIndexMembersDetailsResponseChanged,
	updateChitIndexDetailsSuccess,
	updateChitIndexDetailsFailure,
	updateChitIndexDetailsResponseChanged,
	fetchChitIndexListSuccess,
	fetchChitIndexListFailure,
	fetchChitIndexListResponseChanged,
	fetchChitIndexDetailsSuccess,
	fetchChitIndexDetailsFailure,
	fetchChitIndexDetailsResponseChanged,
	deleteChitIndexDetailsSuccess,
	deleteChitIndexDetailsFailure,
	deleteChitIndexDetailsResponseChanged,
} from './chitIndex.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD CHIT INDEX DETAILS  ******************** */
export function* createChitIndex({ payload }) {
	try {
		const key = 'AddChitIndexDetailKey';
		const jsonData = {
			BranchID: payload.branchID,
			ProductID: payload.productID,
			GroupID: payload.groupID,
			ChitProposalDate: `${payload.chitDate} ${payload.groupAuctionTime}`,
			ChitAuctionDateTime: `${payload.chitDate} ${payload.groupAuctionTime}`,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(chitIndexDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addChitIndexDetailsFailure(responseData.data));
		} else {
			yield put(addChitIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addChitIndexDetailsFailure(error));
	}
}

export function* resetAddChitIndexResponse() {
	yield put(addChitIndexDetailsResponseChanged());
}

export function* onCreateChitIndexDetails() {
	yield takeLatest(ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_START, createChitIndex);
}

export function* onCreateChitIndexResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.ADD_CHIT_INDEX_DETAILS_RESPONSE_RESET,
		resetAddChitIndexResponse,
	);
}
/** *********************  END ADD CHIT INDEX DETAILS ******************** */

/** *********************  START ADD CHIT INDEX MEMBERS DETAILS  ******************** */
export function* createChitIndexMembers({ payload }) {
	try {
		const key = 'AddChitIndexMembersDetailKey';
		const jsonData = {
			ChitIndexMembers: payload.ChitIndexMembers,
			chitIndexSchedule: payload.chitIndexSchedule,
			lastInsertChitIndexID: payload.lastInsertChitIndexID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(chitIndexMembersDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addChitIndexMembersDetailsFailure(responseData.data));
		} else {
			yield put(addChitIndexMembersDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addChitIndexMembersDetailsFailure(error));
	}
}

export function* resetAddChitIndexMembersResponse() {
	yield put(addChitIndexMembersDetailsResponseChanged());
}

export function* onCreateChitIndexMembersDetails() {
	yield takeLatest(
		ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_START,
		createChitIndexMembers,
	);
}

export function* onCreateChitIndexMembersResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.ADD_CHIT_INDEX_MEMBERS_DETAILS_RESPONSE_RESET,
		resetAddChitIndexMembersResponse,
	);
}
/** *********************  END ADD CHIT INDEX MEMBERS DETAILS ******************** */

/** *********************  START UPDATE CHIT INDEX DETAILS  ******************** */
export function* chitIndexUpdate({ payload }) {
	try {
		const key = 'UpdateChitIndexDetailsKey';
		const jsonData = {
			ProductTypeID: payload.productTypeID,
			ProductTypeName: payload.productTypeName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(chitIndexDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateChitIndexDetailsFailure(responseData.data));
		} else {
			yield put(updateChitIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateChitIndexDetailsFailure(error));
	}
}

export function* resetUpdateChitIndexDetailsResponse() {
	yield put(updateChitIndexDetailsResponseChanged());
}

export function* onUpdateChitIndexDetails() {
	yield takeLatest(ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_START, chitIndexUpdate);
}

export function* onUpdateChitIndexResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.UPDATE_CHIT_INDEX_DETAILS_RESPONSE_RESET,
		resetUpdateChitIndexDetailsResponse,
	);
}
/** *********************  END UPDATE CHIT INDEX DETAILS ******************** */

/** *********************  START FETCH CHIT INDEX LIST  ******************** */
export function* fetchChitIndexListDetails({ payload }) {
	try {
		const key = 'FetchChitIndexListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchChitIndexList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchChitIndexListFailure(responseData.data));
		} else {
			yield put(fetchChitIndexListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchChitIndexListFailure(error));
	}
}

export function* resetFetchChitIndexListResponse() {
	yield put(fetchChitIndexListResponseChanged());
}

export function* onFetchChitIndexListDetails() {
	yield takeLatest(ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_START, fetchChitIndexListDetails);
}

export function* onFetchChitIndexListResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.FETCH_CHIT_INDEX_LIST_RESPONSE_RESET,
		resetFetchChitIndexListResponse,
	);
}
/** *********************  END FETCH CHIT INDEX LIST ******************** */

/** *********************  START FETCH CHIT INDEX DETAILS  ******************** */
export function* fetchChitIndexData({ payload }) {
	try {
		const key = 'FetchChitIndexDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ChitIndexID: payload.chitIndexID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(chitIndexDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchChitIndexDetailsFailure(responseData.data));
		} else {
			yield put(fetchChitIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchChitIndexDetailsFailure(error));
	}
}

export function* resetFetchChitIndexDetailsResponse() {
	yield put(fetchChitIndexDetailsResponseChanged());
}

export function* onFetchChitIndexDetails() {
	yield takeLatest(ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_START, fetchChitIndexData);
}

export function* onFetchChitIndexResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.FETCH_CHIT_INDEX_DETAILS_RESPONSE_RESET,
		resetFetchChitIndexDetailsResponse,
	);
}
/** *********************  END FETCH CHIT INDEX DETAILS ******************** */

/** *********************  START DELETE CHIT INDEX DETAILS  ******************** */
export function* chitIndexDelete({ payload }) {
	try {
		const key = 'DeleteChitIndexDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ChitIndexID: payload.chitIndexID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(chitIndexDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteChitIndexDetailsFailure(responseData.data));
		} else {
			yield put(deleteChitIndexDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteChitIndexDetailsFailure(error));
	}
}

export function* resetDeleteChitIndexDetailsResponse() {
	yield put(deleteChitIndexDetailsResponseChanged());
}

export function* onDeleteChitIndexDetail() {
	yield takeLatest(ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_START, chitIndexDelete);
}

export function* onDeleteChitIndexResponseReset() {
	yield takeLatest(
		ChitIndexActionTypes.DELETE_CHIT_INDEX_DETAILS_RESPONSE_RESET,
		resetDeleteChitIndexDetailsResponse,
	);
}
/** *********************  END DELETE CHIT INDEX DETAILS ******************** */

export function* chitIndexSaga() {
	yield all([
		call(onCreateChitIndexDetails),
		call(onCreateChitIndexResponseReset),
		call(onCreateChitIndexMembersDetails),
		call(onCreateChitIndexMembersResponseReset),
		call(onUpdateChitIndexDetails),
		call(onUpdateChitIndexResponseReset),
		call(onFetchChitIndexListDetails),
		call(onFetchChitIndexListResponseReset),
		call(onFetchChitIndexDetails),
		call(onFetchChitIndexResponseReset),
		call(onDeleteChitIndexDetail),
		call(onDeleteChitIndexResponseReset),
	]);
}
