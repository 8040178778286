/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const groupDetailsAdd = (addData) => {
	const data = {
		AddGroupDetailsToken: addData,
	};

	const url = `${API_URL}addGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addMapMembersToGroupService = (addData) => {
	const data = {
		AddMapMembersToGroupDetailsToken: addData,
	};

	const url = `${API_URL}addMapMembersToGroup`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupDetailsUpdate = (updateData) => {
	const data = {
		UpdateGroupDetailsToken: updateData,
	};

	const url = `${API_URL}updateGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupDetailsDelete = (deleteData) => {
	const data = {
		DeleteGroupDetailsToken: deleteData,
	};

	const url = `${API_URL}deleteGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupListGet = (listData) => {
	const data = {
		FetchGroupListToken: listData,
	};
	const url = `${API_URL}FetchGroupListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupSearchListGet = (listData) => {
	const data = {
		FetchSearchGroupListToken: listData,
	};
	const url = `${API_URL}FetchSearchGroupListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupInfoGet = (groupData) => {
	const data = {
		FetchGroupInfoToken: groupData,
	};
	const url = `${API_URL}FetchGroupInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGroupUniqueDetails = (fetchGroupUniqueData) => {
	const data = {
		GroupUniqueIdFetchToken: fetchGroupUniqueData,
	};
	const url = `${API_URL}getGroupUniqueID`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allGroupFetch = (fetchGroupData) => {
	const data = {
		FetchAllGroupToken: fetchGroupData,
	};
	const url = `${API_URL}FetchAllGroupInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loadProductGroupList = (listData) => {
	const data = {
		FetchProductGroupListToken: listData,
	};
	const url = `${API_URL}FetchProductGroupsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loadBranchGroupList = (listData) => {
	const data = {
		FetchBranchGroupListToken: listData,
	};
	const url = `${API_URL}FetchBranchGroupsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGroupMembersInfo = (fetchGroupData) => {
	const data = {
		FetchGroupMembersInfoToken: fetchGroupData,
	};
	const url = `${API_URL}FetchGroupMembersInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allApprovedMemberFetch = (fetchApprovedMemberData) => {
	const data = {
		GetBranchAllOfficerToken: fetchApprovedMemberData,
	};
	const url = `${API_URL}GetAllApprovedMemberNotInGroup`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allOfficerGet = (listData) => {
	const data = {
		GetAllOfficerListToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedOnLevel`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allGroupDetailsGet = (listData) => {
	const data = {
		GetAllGroupKeyToken: listData,
	};

	const url = `${API_URL}GetGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchOfficerListGet = (listData) => {
	const data = {
		CenterOfficerListBasedOnCompany: listData,
	};

	const url = `${API_URL}GetAllOfficerUserBasedoncompanyWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
