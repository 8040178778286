const GroupActionTypes = {
	// Add New Group
	ADD_GROUP_DETAILS_START: 'add_group_details_start',
	ADD_GROUP_DETAILS_SUCCESS: 'add_group_details_success',
	ADD_GROUP_DETAILS_FAILURE: 'add_group_details_failure',
	ADD_GROUP_DETAILS_RESPONSE_RESET: 'add_group_details_response_reset',
	ADD_GROUP_DETAILS_RESPONSE_CHANGED: 'add_group_details_response_changed',

	// Add Exist Group
	UPDATE_GROUP_DETAILS_START: 'update_group_details_start',
	UPDATE_GROUP_DETAILS_SUCCESS: 'update_group_details_success',
	UPDATE_GROUP_DETAILS_FAILURE: 'update_group_details_failure',
	UPDATE_GROUP_DETAILS_RESPONSE_RESET: 'update_group_details_response_reset',
	UPDATE_GROUP_DETAILS_RESPONSE_CHANGED: 'update_group_details_response_changed',

	// Fetch Group List
	GET_GROUP_LIST_START: 'GET_GROUP_LIST_START',
	GET_GROUP_LIST_SUCCESS: 'GET_GROUP_LIST_SUCCESS',
	GET_GROUP_LIST_FAILURE: 'GET_GROUP_LIST_FAILURE',
	GET_GROUP_LIST_RESPONSE_RESET: 'GET_GROUP_LIST_RESPONSE_RESET',
	GET_GROUP_LIST_RESPONSE_CHANGED: 'GET_GROUP_LIST_RESPONSE_CHANGED',

	// Fetch SEARCH Group List
	GET_SEARCH_GROUP_LIST_START: 'GET_SEARCH_GROUP_LIST_START',
	GET_SEARCH_GROUP_LIST_SUCCESS: 'GET_SEARCH_GROUP_LIST_SUCCESS',
	GET_SEARCH_GROUP_LIST_FAILURE: 'GET_SEARCH_GROUP_LIST_FAILURE',
	GET_SEARCH_GROUP_LIST_RESPONSE_RESET: 'GET_SEARCH_GROUP_LIST_RESPONSE_RESET',
	GET_SEARCH_GROUP_LIST_RESPONSE_CHANGED: 'GET_SEARCH_GROUP_LIST_RESPONSE_CHANGED',

	// Fetch Group Info
	GET_GROUP_INFO_START: 'get_group_Info_start',
	GET_GROUP_INFO_SUCCESS: 'get_group_Info_success',
	GET_GROUP_INFO_FAILURE: 'get_group_Info_failure',
	GET_GROUP_INFO_RESPONSE_RESET: 'get_group_Info_response_reset',
	GET_GROUP_INFO_RESPONSE_CHANGED: 'get_group_Info_response_changed',

	// All Group List
	FETCH_ALL_GROUP_START: 'fetch_all_group_start',
	FETCH_ALL_GROUP_SUCCESS: 'fetch_all_group_success',
	FETCH_ALL_GROUP_FAILURE: 'fetch_all_group_failure',
	FETCH_ALL_GROUP_RESPONSE_RESET_START: 'fetch_all_group_response_reset',
	FETCH_ALL_GROUP_RESPONSE_CHANGED: 'fetch_all_group_response_changed',

	// Product Group List
	FETCH_PRODUCT_GROUP_LIST_START: 'fetch_product_group_list_start',
	FETCH_PRODUCT_GROUP_LIST_SUCCESS: 'fetch_product_group_list_success',
	FETCH_PRODUCT_GROUP_LIST_FAILURE: 'fetch_product_group_list_failure',
	FETCH_PRODUCT_GROUP_LIST_RESPONSE_RESET: 'fetch_product_group_list_response_reset',
	FETCH_PRODUCT_GROUP_LIST_RESPONSE_CHANGED: 'fetch_product_group_list_response_changed',

	// Branch Group List
	FETCH_BRANCH_GROUP_LIST_START: 'FETCH_BRANCH_GROUP_LIST_START',
	FETCH_BRANCH_GROUP_LIST_SUCCESS: 'FETCH_BRANCH_GROUP_LIST_SUCCESS',
	FETCH_BRANCH_GROUP_LIST_FAILURE: 'FETCH_BRANCH_GROUP_LIST_FAILURE',
	FETCH_BRANCH_GROUP_LIST_RESPONSE_RESET: 'FETCH_BRANCH_GROUP_LIST_RESPONSE_RESET',
	FETCH_BRANCH_GROUP_LIST_RESPONSE_CHANGED: 'FETCH_BRANCH_GROUP_LIST_RESPONSE_CHANGED',

	// Fetcg Group UniqueID
	FETCH_GROUP_UNIQUE_ID_START: 'FETCH_GROUP_UNIQUE_ID_START',
	FETCH_GROUP_UNIQUE_ID_SUCCESS: 'FETCH_GROUP_UNIQUE_ID_SUCCESS',
	FETCH_GROUP_UNIQUE_ID_FAILURE: 'FETCH_GROUP_UNIQUE_ID_FAILURE',
	FETCH_GROUP_UNIQUE_ID_RESPONSE_RESET: 'FETCH_GROUP_UNIQUE_ID_RESPONSE_RESET',
	FETCH_GROUP_UNIQUE_ID_RESPONSE_CHANGED: 'FETCH_GROUP_UNIQUE_ID_RESPONSE_CHANGED',

	// Delete Exist Group
	DELETE_GROUP_DETAILS_START: 'delete_group_details_start',
	DELETE_GROUP_DETAILS_SUCCESS: 'delete_group_details_success',
	DELETE_GROUP_DETAILS_FAILURE: 'delete_group_details_failure',
	DELETE_GROUP_DETAILS_RESPONSE_RESET: 'delete_group_details_response_reset',
	DELETE_GROUP_DETAILS_RESPONSE_CHANGED: 'delete_group_details_response_changed',

	// Add Map Members to Group
	ADD_MAP_MEMBERS_TO_GROUP_DETAILS_START: 'add_map_members_to_group_details_start',
	ADD_MAP_MEMBERS_TO_GROUP_DETAILS_SUCCESS: 'add_map_members_to_group_details_success',
	ADD_MAP_MEMBERS_TO_GROUP_DETAILS_FAILURE: 'add_map_members_to_group_details_failure',
	ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_RESET:
		'add_map_members_to_group_details_response_reset',
	ADD_MAP_MEMBERS_TO_GROUP_DETAILS_RESPONSE_CHANGED:
		'add_map_members_to_group_details_response_changed',

	// All Approved Member List
	FETCH_ALL_APPROVED_MEMBER_START: 'fetch_all_approved_member_start',
	FETCH_ALL_APPROVED_MEMBER_SUCCESS: 'fetch_all_approved_member_success',
	FETCH_ALL_APPROVED_MEMBER_FAILURE: 'fetch_all_approved_member_failure',
	FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START: 'fetch_all_approved_member_response_reset',
	FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED: 'fetch_all_approved_member_response_changed',

	// Fetch Group Members List
	FETCH_GROUP_MEMBERS_LIST_START: 'fetch_group_members_list_start',
	FETCH_GROUP_MEMBERS_LIST_SUCCESS: 'fetch_group_members_list_success',
	FETCH_GROUP_MEMBERS_LIST_FAILURE: 'fetch_group_members_list_failure',
	FETCH_GROUP_MEMBERS_LIST_RESPONSE_RESET_START: 'fetch_group_members_list_response_reset',
	FETCH_GROUP_MEMBERS_LIST_RESPONSE_CHANGED: 'fetch_group_members_list_response_changed',
	UPDATE_MEMBERS_FOR_GROUP_CHIT_START: 'update_members_for_group_chit_start',

	// All Officer List
	GET_ALL_OFFICER_START: 'get_all_officer_start',
	GET_ALL_OFFICER_SUCCESS: 'get_all_officer_success',
	GET_ALL_OFFICER_FAILURE: 'get_all_officer_failure',
	GET_ALL_OFFICER_RESPONSE_RESET: 'get_all_officer_response_reset',
	GET_ALL_OFFICER_RESPONSE_CHANGED: 'get_all_officer_response_changed',

	// All Group Details
	GET_ALL_GROUP_DETAILS_START: 'get_all_group_details_start',
	GET_ALL_GROUP_DETAILS_SUCCESS: 'get_all_group_details_success',
	GET_ALL_GROUP_DETAILS_FAILURE: 'get_all_group_details_failure',
	GET_ALL_GROUP_DETAILS_RESPONSE_RESET: 'get_all_group_details_response_reset',
	GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED: 'get_all_group_details_response_changed',

	// branch Officer List
	GET_BRANCH_OFFICER_START: 'GET_BRANCH_OFFICER_START',
	GET_BRANCH_OFFICER_SUCCESS: 'GET_BRANCH_OFFICER_SUCCESS',
	GET_BRANCH_OFFICER_FAILURE: 'GET_BRANCH_OFFICER_FAILURE',
	GET_BRANCH_OFFICER_RESPONSE_RESET: 'GET_BRANCH_OFFICER_RESPONSE_RESET',
	GET_BRANCH_OFFICER_RESPONSE_CHANGED: 'GET_BRANCH_OFFICER_RESPONSE_CHANGED',

	FETCH_GROUP_ID_START: 'fetch_group_id_start',
};
export default GroupActionTypes;
