/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import StaffActionTypes from './staff.types';

/** ***************** ADD STAFF DETAILS START *********************** */
export const addStaffDetailsStart = (addData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_START,
	payload: addData,
});

export const addStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const addStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const addStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_RESET,
});

export const addStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD STAFF DETAILS END *********************** */

/** ***************** UPDATE STAFF DETAILS START *********************** */
export const updateStaffDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_START,
	payload: updateData,
});

export const updateStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_RESET,
});

export const updateStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF DETAILS END *********************** */

/** ***************** UPDATE STAFF ADDRESS DETAILS START *********************** */
export const updateStaffAddressDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_START,
	payload: updateData,
});

export const updateStaffAddressDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffAddressDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffAddressDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_RESET,
});

export const updateStaffAddressDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_ADDRESS_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF ADDRESS DETAILS END *********************** */

/** ***************** UPDATE STAFF PROOF DETAILS START *********************** */
export const updateStaffProofDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_START,
	payload: updateData,
});

export const updateStaffProofDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffProofDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffProofDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_RESPONSE_RESET,
});

export const updateStaffProofDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_PROOF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF PROOF DETAILS END *********************** */

/** ***************** UPDATE STAFF EMERGENCY DETAILS START *********************** */
export const updateStaffEmergencyDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_START,
	payload: updateData,
});

export const updateStaffEmergencyDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffEmergencyDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffEmergencyDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_RESET,
});

export const updateStaffEmergencyDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_EMERGENCY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF EMERGENCY DETAILS END *********************** */

/** ***************** UPDATE STAFF OFFICIAL DETAILS START *********************** */
export const updateStaffOfficialDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_START,
	payload: updateData,
});

export const updateStaffOfficialDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffOfficialDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffOfficialDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_RESET,
});

export const updateStaffOfficialDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_OFFICIAL_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF OFFICIAL DETAILS END *********************** */

/** ***************** UPDATE STAFF TARGET DETAILS START *********************** */
export const updateStaffTargetDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_START,
	payload: updateData,
});

export const updateStaffTargetDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffTargetDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffTargetDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_RESPONSE_RESET,
});

export const updateStaffTargetDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_TARGET_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF OFFICIAL DETAILS END *********************** */

/** ***************** ADD STAFF TARGET DETAILS START *********************** */
export const addStaffTargetDetailsStart = (addData) => ({
	type: StaffActionTypes.ADD_STAFF_TARGET_DETAILS_START,
	payload: addData,
});

export const addStaffTargetDetailsSuccess = (successData) => ({
	type: StaffActionTypes.ADD_STAFF_TARGET_DETAILS_SUCCESS,
	payload: successData,
});

export const addStaffTargetDetailsFailure = (failureData) => ({
	type: StaffActionTypes.ADD_STAFF_TARGET_DETAILS_FAILURE,
	payload: failureData,
});

export const addStaffTargetDetailsResponseResetStart = () => ({
	type: StaffActionTypes.ADD_STAFF_TARGET_DETAILS_RESPONSE_RESET,
});

export const addStaffTargetDetailsResponseChanged = () => ({
	type: StaffActionTypes.ADD_STAFF_TARGET_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD STAFF TARGET DETAILS END *********************** */

/** ***************** GET STAFF LIST START *********************** */
export const getStaffListStart = (getData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_START,
	payload: getData,
});

export const getStaffListSuccess = (successData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_SUCCESS,
	payload: successData,
});

export const getStaffListFailure = (failureData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_FAILURE,
	payload: failureData,
});

export const getStaffListResponseResetStart = () => ({
	type: StaffActionTypes.GET_STAFF_LIST_RESPONSE_RESET,
});

export const getStaffListResponseChanged = () => ({
	type: StaffActionTypes.GET_STAFF_LIST_RESPONSE_CHANGED,
});

/** ***************** GET STAFF LIST END *********************** */

/** ***************** SEARCH STAFF LIST START *********************** */
export const searchStaffListStart = (getData) => ({
	type: StaffActionTypes.FETCH_SEARCH_STAFF_LIST_START,
	payload: getData,
});

export const searchStaffListSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_SEARCH_STAFF_LIST_SUCCESS,
	payload: successData,
});

export const searchStaffListFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_SEARCH_STAFF_LIST_FAILURE,
	payload: failureData,
});

export const searchStaffListResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_SEARCH_STAFF_LIST_RESPONSE_RESET,
});

export const searchStaffListResponseChanged = () => ({
	type: StaffActionTypes.FETCH_SEARCH_STAFF_LIST_RESPONSE_CHANGED,
});

/** ***************** SEARCH STAFF LIST END *********************** */

/** ***************** FETCH STAFF DETAILS START *********************** */
export const fetchStaffDetailsStart = (fetchData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_START,
	payload: fetchData,
});

export const fetchStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_RESET,
});

export const fetchStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH STAFF DETAILS END *********************** */

/** ***************** DELETE STAFF DETAILS START *********************** */
export const deleteStaffDetailsStart = (deleteData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_START,
	payload: deleteData,
});

export const deleteStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_RESET,
});

export const deleteStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE STAFF DETAILS END *********************** */

/** ***************** DELETE STAFF DETAILS START *********************** */
export const updateIsPublishStatusStart = (publishData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_START,
	payload: publishData,
});

export const updateIsPublishStatusSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_SUCCESS,
	payload: successData,
});

export const updateIsPublishStatusFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_FAILURE,
	payload: failureData,
});

export const updateIsPublishStatusResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET,
});

export const updateIsPublishStatusResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED,
});

/** ***************** DELETE STAFF DETAILS END *********************** */

/** ***************** FETCH ALL STAFF START *********************** */
export const fetchAllStaffStart = (fetchStaffs) => ({
	type: StaffActionTypes.FETCH_ALL_STAFF_START,
	payload: fetchStaffs,
});

export const fetchAllStaffSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_ALL_STAFF_SUCCESS,
	payload: successData,
});

export const fetchAllStaffFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_ALL_STAFF_FAILURE,
	payload: failureData,
});

export const fetchAllStaffResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_RESET,
});

export const fetchAllStaffResponseChanged = () => ({
	type: StaffActionTypes.FETCH_ALL_STAFF_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL STAFF END *********************** */

/** ***************** FETCH AGENT STAFF START *********************** */
export const fetchAgentStaffStart = (fetchStaffs) => ({
	type: StaffActionTypes.FETCH_AGENT_STAFF_START,
	payload: fetchStaffs,
});

export const fetchAgentStaffSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_AGENT_STAFF_SUCCESS,
	payload: successData,
});

export const fetchAgentStaffFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_AGENT_STAFF_FAILURE,
	payload: failureData,
});

export const fetchAgentStaffResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_AGENT_STAFF_RESPONSE_RESET,
});

export const fetchAgentStaffResponseChanged = () => ({
	type: StaffActionTypes.FETCH_AGENT_STAFF_RESPONSE_CHANGED,
});
/** ***************** FETCH AGENT STAFF END *********************** */

/** ***************** FETCH STAFF TARGET START *********************** */
export const fetchStaffTargetListStart = (fetchStaffs) => ({
	type: StaffActionTypes.FETCH_STAFF_TARGET_LIST_START,
	payload: fetchStaffs,
});

export const fetchStaffTargetListSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_STAFF_TARGET_LIST_SUCCESS,
	payload: successData,
});

export const fetchStaffTargetListFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_STAFF_TARGET_LIST_FAILURE,
	payload: failureData,
});

export const fetchStaffTargetListResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_STAFF_TARGET_LIST_RESPONSE_RESET,
});

export const fetchStaffTargetListResponseChanged = () => ({
	type: StaffActionTypes.FETCH_STAFF_TARGET_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH STAFF TARGET END *********************** */

export const staffIDStart = (staffID) => ({
	type: StaffActionTypes.STAFF_ID,
	payload: staffID,
});

export const staffDetailsEditStart = (isEdit) => ({
	type: StaffActionTypes.IS_EDIT_STAFF_DETAILS,
	payload: isEdit,
});

export const updateStaffCanvasValueStart = (canvas) => ({
	type: StaffActionTypes.STAFF_IS_OPEN_OFFCANVAS,
	payload: canvas,
});

export const updateStaffTabValueStart = (updatedTab) => ({
	type: StaffActionTypes.STAFF_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});
