/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const branchDetailsAdd = (addData) => {
	const data = {
		AddBranchDetailsToken: addData,
	};
	const url = `${API_URL}addBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankAccountDetailsAdd = (addData) => {
	const data = {
		AddBranchBankAccountDetailsToken: addData,
	};
	const url = `${API_URL}addBranchBankAccountDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankChequeBookDetailsAdd = (addData) => {
	const data = {
		AddBranchBankChequeBookDetailsToken: addData,
	};
	const url = `${API_URL}addBranchBankChequeBookDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsUpdate = (updateData) => {
	const data = {
		UpdateBranchDetailsToken: updateData,
	};
	const url = `${API_URL}updateBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankAccountDetailsUpdate = (updateData) => {
	const data = {
		UpdateBranchBankAccountDetailsToken: updateData,
	};
	const url = `${API_URL}updateBranchBankAccountDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankChequeBookDetailsUpdate = (updateData) => {
	const data = {
		UpdateBranchBankChequeBookDetailsToken: updateData,
	};
	const url = `${API_URL}updateBranchBankChequeBookDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchListGet = (listData) => {
	const data = {
		FetchBranchListToken: listData,
	};
	const url = `${API_URL}FetchBranchListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchSearchBranchListGet = (listData) => {
	const data = {
		FetchSearchBranchListToken: listData,
	};
	const url = `${API_URL}FetchSearchBranchListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsFetch = (fetchData) => {
	const data = {
		FetchBranchInfoToken: fetchData,
	};
	const url = `${API_URL}FetchBranchInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankAccountListFetch = (listData) => {
	const data = {
		FetchBranchBankAccountListToken: listData,
	};
	const url = `${API_URL}FetchBranchBankAccountListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankChequeBookListFetch = (listData) => {
	const data = {
		FetchBranchBankChequeBookListToken: listData,
	};
	const url = `${API_URL}FetchBranchBankChequeBookListInfo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchDetailsDelete = (deleteData) => {
	const data = {
		DeleteBranchDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankDetailsDelete = (deleteData) => {
	const data = {
		DeleteBranchBankDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBranchBankDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchBankChequeBookDetailsDelete = (deleteData) => {
	const data = {
		DeleteBranchBankChequeBookDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBranchBankChequeBookDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
