/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import JWT from 'expo-jwt';
import ReactS3 from 'react-s3';
import { decodeTokenJson } from '../enode-decode';

// Decode
export const getDecodedJsonData = (jsonData, key) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

export const decodeApiResponse = (jsonData, key) => {
	const decodedData = decodeTokenJson(jsonData, key);
	return decodedData;
};

export const docodeResponse = (response, key) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};

export const langData = [
	{
		key: 0,
		isSelected: false,
		label: 'English',
		value: 'ENGLISH',
	},
	{
		key: 1,
		isSelected: false,
		label: 'Tamil',
		value: 'TAMIL',
	},
	{
		key: 2,
		isSelected: false,
		label: 'Telugu',
		value: 'TELUGU',
	},
	{
		key: 3,
		isSelected: false,
		label: 'Kannada',
		value: 'KANNADA',
	},
	{
		key: 4,
		isSelected: false,
		label: 'Malayalam',
		value: 'MALAYALAM',
	},
	{
		key: 5,
		isSelected: false,
		label: 'Hindi',
		value: 'HINDI',
	},
];

export const dateDiffInDays = (date1, date2) => {
	// Parse the input dates into Date objects
	const d1 = new Date(date1);
	const d2 = new Date(date2);

	// Calculate the time difference in milliseconds
	const timeDiff = d2 - d1;

	// Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
	const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

	return daysDiff + 1;
};

export const getCurrentDate = () => {
	const newDate = new Date();
	const date = newDate.getDate();
	const month = newDate.getMonth() + 1;
	const year = newDate.getFullYear();
	const separator = '-';
	return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
};

export const imageUploadToS3 = async (result, path) => {
	// Create a new Blob with the same content and the desired name
	const newFile = new Blob([result], { type: 'image/png' });
	newFile.name = `${Date.now()}-S3.png`;

	const config = {
		dirName: `${path}`,
		bucketName: 'squarenow-production',
		region: 'ap-south-1',
		accessKeyId: 'AKIAXLKVNHKWXKTDS3RW',
		secretAccessKey: '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',
		successActionStatus: 201,
	};

	let fetchResponse = '';
	const imageUrl = ReactS3.uploadFile(newFile, config).then((response) => {
		if (response.location) {
			fetchResponse = response.location;
		}
		return fetchResponse;
	});
	return imageUrl;
};

export const calculateProductSchedule = (productScheduleArray, item) => {
	const getInstalmentAmount = item.productAmount / item.numberOfInstalment;
	let sNo = 0;
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < item.numberOfInstalment; i++) {
		// eslint-disable-next-line no-plusplus
		sNo++;
		const data = {
			instalmentNumber: sNo,
			bidAmount: '',
			dividendAmount: '',
			instalmentAmount: getInstalmentAmount,
			productAmount: item.productAmount,
			numberOfInstalment: item.numberOfInstalment,
		};
		productScheduleArray.push(data);
	}
	return productScheduleArray;
};

export const updateProductScheduleList = (productScheduleArray, item) => {
	const existingProductScheduleItem = productScheduleArray.find(
		(scheduleItem) => scheduleItem.instalmentNumber === item.instalmentNumber,
	);

	if (existingProductScheduleItem) {
		let getDividendAmount = (item.bidAmount - item.instalmentAmount) / item.numberOfInstalment;
		if (getDividendAmount < 0) {
			getDividendAmount = 0;
		}
		return productScheduleArray.map((scheduleItem) =>
			scheduleItem.instalmentNumber === item.instalmentNumber
				? {
						...scheduleItem,
						bidAmount: item.bidAmount,
						dividendAmount: getDividendAmount,
				  }
				: scheduleItem,
		);
	}

	return productScheduleArray;
};

export const addBankAccountArr = (listArr, item) => {
	const data = {
		bankAccCount: item.bankAccCount,
		bankName: item.bankName,
		bankBranchName: item.bankBranchName,
		bankAccountNumber: item.bankAccountNumber,
		bankAccountName: item.bankAccountName,
		bankIfscCode: item.bankIfscCode,
		bankAccountType: item.bankAccountType,
	};
	listArr.push(data);
	return listArr;
};

export const updateBankAccountArr = (bankAccountListArr, item) => {
	const existingBankAccountItem = bankAccountListArr.find(
		(bankAccountItem) => bankAccountItem.bankAccCount === item.bankAccCount,
	);

	if (existingBankAccountItem) {
		return bankAccountListArr.map((bankAccountItem) =>
			bankAccountItem.bankAccCount === item.bankAccCount
				? {
						...bankAccountItem,
						bankName: item.bankName,
						bankBranchName: item.bankBranchName,
						bankAccountNumber: item.bankAccountNumber,
						bankAccountName: item.bankAccountName,
						bankIfscCode: item.bankIfscCode,
						bankAccountType: item.bankAccountType,
				  }
				: bankAccountItem,
		);
	}

	return bankAccountListArr;
};

export const removeBankAccountArr = (bankAccountListArr, item) => {
	const existingBankAccountItem = bankAccountListArr.find(
		(bankAccountItem) => bankAccountItem.bankAccCount === item.bankAccCount,
	);

	if (existingBankAccountItem) {
		return bankAccountListArr.filter(
			(bankAccountItem) => bankAccountItem.bankAccCount !== item.bankAccCount,
		);
	}

	return bankAccountListArr;
};

export const addBankChequeBookArr = (bankChequeBookListArr, item) => {
	const data = {
		chequeBookCount: item.chequeBookCount,
		chequeFromNo: item.chequeFromNo,
		chequeEndNo: item.chequeEndNo,
		chequeIssueDate: item.chequeIssueDate,
	};
	bankChequeBookListArr.push(data);
	return bankChequeBookListArr;
};

export const updateBankChequeBookArr = (bankChequeBookListArr, item) => {
	const existingBankChequeBookItem = bankChequeBookListArr.find(
		(chequeBookItem) => chequeBookItem.chequeBookCount === item.chequeBookCount,
	);

	if (existingBankChequeBookItem) {
		return bankChequeBookListArr.map((chequeBookItem) =>
			chequeBookItem.chequeBookCount === item.chequeBookCount
				? {
						...chequeBookItem,
						chequeFromNo: item.chequeFromNo,
						chequeEndNo: item.chequeEndNo,
						chequeIssueDate: item.chequeIssueDate,
				  }
				: chequeBookItem,
		);
	}

	return bankChequeBookListArr;
};

export const removeBankChequeBookArr = (bankChequeBookListArr, item) => {
	const existingBankAccountItem = bankChequeBookListArr.find(
		(chequeBookItem) => chequeBookItem.chequeBookCount === item.chequeBookCount,
	);

	if (existingBankAccountItem) {
		return bankChequeBookListArr.filter(
			(chequeBookItem) => chequeBookItem.chequeBookCount !== item.chequeBookCount,
		);
	}

	return bankChequeBookListArr;
};
