/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProductActionTypes from './product.types';
import {
	productDetailsAdd,
	productDetailsUpdate,
	productListGet,
	productSearchListGet,
	productDetailsFetch,
	productDetailsDelete,
	productScheduleDetailsAdd,
	productScheduleDetailsUpdate,
	updateProductAdditionalCharges,
	loadAllProductList,
} from './product.service';

import {
	addProductDetailsSuccess,
	addProductDetailsFailure,
	addProductDetailsResponseChanged,
	addProductScheduleSuccess,
	addProductScheduleFailure,
	addProductScheduleResponseChanged,
	updateProductDetailsSuccess,
	updateProductDetailsFailure,
	updateProductDetailsResponseChanged,
	updateProductScheduleSuccess,
	updateProductScheduleFailure,
	updateProductScheduleResponseChanged,
	updateProductAdditionalChargesSuccess,
	updateProductAdditionalChargesFailure,
	updateProductAdditionalChargesResponseChanged,
	getProductListSuccess,
	getProductListFailure,
	getProductListResponseChanged,
	fetchSearchProductListSuccess,
	fetchSearchProductListFailure,
	fetchSearchProductListResponseChanged,
	fetchProductDetailsSuccess,
	fetchProductDetailsFailure,
	fetchProductDetailsResponseChanged,
	fetchAllProductsSuccess,
	fetchAllProductsFailure,
	fetchAllProductsResponseChanged,
	deleteProductDetailsSuccess,
	deleteProductDetailsFailure,
	deleteProductDetailsResponseChanged,
} from './product.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PRODUCT DETAILS  ******************** */
export function* createProduct({ payload }) {
	try {
		const key = 'AddProductDetailKey';
		const jsonData = {
			ProductUID: payload.uniqueProdutID,
			ProductName: payload.productName,
			ProductAmount: payload.productAmount,
			InstalmentAmount: payload.instalmentAmount,
			DividendAmount: payload.DividendAmount,
			InterestPercentage: payload.collectionPrcentage,
			Type: payload.productInstallmentType,
			NumberOfInstalments: payload.numberOfInstalment,
			ProductTypeID: payload.productType,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductDetailsFailure(responseData.data));
		} else {
			yield put(addProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductDetailsFailure(error));
	}
}

export function* resetAddProductResponse() {
	yield put(addProductDetailsResponseChanged());
}

export function* onCreateProductDetails() {
	yield takeLatest(ProductActionTypes.ADD_PRODUCT_DETAILS_START, createProduct);
}

export function* onCreateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_RESET,
		resetAddProductResponse,
	);
}
/** *********************  END ADD PRODUCT DETAILS ******************** */

/** *********************  START ADD PRODUCT SCHEDULE DETAILS  ******************** */
export function* createProductSchedule({ payload }) {
	try {
		const key = 'AddProductScheduleDetailKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductTypeID: payload.productTypeID,
			ProductSchedule: payload.productSchedule,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productScheduleDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductScheduleFailure(responseData.data));
		} else {
			yield put(addProductScheduleSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductScheduleFailure(error));
	}
}

export function* resetAddProductScheduleResponse() {
	yield put(addProductScheduleResponseChanged());
}

export function* onCreateProductScheduleDetails() {
	yield takeLatest(ProductActionTypes.ADD_PRODUCT_SCHEDULE_START, createProductSchedule);
}

export function* onCreateProductScheduleDetailsResponseReset() {
	yield takeLatest(
		ProductActionTypes.ADD_PRODUCT_SCHEDULE_RESPONSE_RESET,
		resetAddProductScheduleResponse,
	);
}
/** *********************  END ADD PRODUCT SCHEDULE DETAILS ******************** */

/** *********************  START UPDATE PRODUCT DETAILS  ******************** */
export function* productUpdate({ payload }) {
	try {
		const key = 'UpdateProductDetailsKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductUID: payload.uniqueProdutID,
			ProductName: payload.productName,
			ProductAmount: payload.productAmount,
			InstalmentAmount: payload.instalmentAmount,
			DividendAmount: payload.DividendAmount,
			InterestPercentage: payload.collectionPrcentage,
			Type: payload.productInstallmentType,
			NumberOfInstalments: payload.numberOfInstalment,
			ProductTypeID: payload.productType,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductDetailsFailure(responseData.data));
		} else {
			yield put(updateProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductDetailsFailure(error));
	}
}

export function* resetUpdateProductDetailsResponse() {
	yield put(updateProductDetailsResponseChanged());
}

export function* onUpdateProductDetails() {
	yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_DETAILS_START, productUpdate);
}

export function* onUpdateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetUpdateProductDetailsResponse,
	);
}
/** *********************  END UPDATE PRODUCT DETAILS ******************** */

/** *********************  START UPDATE PRODUCT SCHEDULE DETAILS  ******************** */
export function* updateProductSchedule({ payload }) {
	try {
		const key = 'UpdateProductScheduleDetailsKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductTypeID: payload.productTypeID,
			ProductSchedule: payload.productSchedule,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserName: payload.userName,
			ModifiedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productScheduleDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductScheduleFailure(responseData.data));
		} else {
			yield put(updateProductScheduleSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductScheduleFailure(error));
	}
}

export function* resetUpdateProductScheduleResponse() {
	yield put(updateProductScheduleResponseChanged());
}

export function* onUpdateProductScheduleDetails() {
	yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_START, updateProductSchedule);
}

export function* onUpdateProductScheduleDetailsResponseReset() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_RESPONSE_RESET,
		resetUpdateProductScheduleResponse,
	);
}
/** *********************  END UPDATE PRODUCT SCHEDULE DETAILS ******************** */

/** *********************  START UPDATE PRODUCT ADDITIONAL CHARGES DETAILS  ******************** */
export function* productAdditionalChargesUpdate({ payload }) {
	try {
		const key = 'UpdateProductAdditionalChargesKey';
		const jsonData = {
			ProductID: payload.getProductID,
			DocumentCharges: payload.documentCharges,
			ProcessingCharges: payload.processingCharges,
			ApplicationAmount: payload.applicationAmount,
			InsuranceAmount: payload.insuranceAmount,
			IgstAmount: payload.igstAmount,
			CgstAmount: payload.cgstAmount,
			SgstAmount: payload.sgstAmount,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateProductAdditionalCharges, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductAdditionalChargesFailure(responseData.data));
		} else {
			yield put(updateProductAdditionalChargesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductAdditionalChargesFailure(error));
	}
}

export function* resetUpdateProductAdditionalChargesResponse() {
	yield put(updateProductAdditionalChargesResponseChanged());
}

export function* onUpdateProductAdditionalCharges() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_START,
		productAdditionalChargesUpdate,
	);
}

export function* onUpdateProductAdditionalChargesResponseReset() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_RESET,
		resetUpdateProductAdditionalChargesResponse,
	);
}
/** *********************  END UPDATE PRODUCT ADDITIONAL CHARGES DETAILS ******************** */

/** *********************  START GET PRODUCT LIST  ******************** */
export function* productDetailsListGet({ payload }) {
	try {
		const key = 'FetchProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductListFailure(responseData.data));
		} else {
			yield put(getProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductListFailure(error));
	}
}

export function* resetGetProductListResponse() {
	yield put(getProductListResponseChanged());
}

export function* onGetProductListDetails() {
	yield takeLatest(ProductActionTypes.GET_PRODUCT_LIST_START, productDetailsListGet);
}

export function* onGetProductListResponseReset() {
	yield takeLatest(
		ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
		resetGetProductListResponse,
	);
}
/** *********************  END GET PRODUCT LIST ******************** */

/** *********************  START FETCH SEARCH PRODUCT LIST  ******************** */
export function* searchProductDetailsListGet({ payload }) {
	try {
		const key = 'FetchSearchProductListKey';
		const jsonData = {
			SearchValue: payload.SearchValue,
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			UserLevel: payload.UserLevel,
			DatabaseName: payload.DatabaseName,
			Limit: '0',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productSearchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSearchProductListFailure(responseData.data));
		} else {
			yield put(fetchSearchProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSearchProductListFailure(error));
	}
}

export function* resetFetchSearchProductListResponse() {
	yield put(fetchSearchProductListResponseChanged());
}

export function* onFetchSearchProductListDetails() {
	yield takeLatest(
		ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_START,
		searchProductDetailsListGet,
	);
}

export function* onFetchSearchProductListResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_RESPONSE_RESET,
		resetFetchSearchProductListResponse,
	);
}
/** *********************  END FETCH SEARCH PRODUCT LIST ******************** */

/** *********************  START FETCH PRODUCT DETAILS  ******************** */
export function* fetchProductData({ payload }) {
	try {
		const key = 'FetchProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductDetailsFailure(error));
	}
}

export function* resetFetchProductDetailsResponse() {
	yield put(fetchProductDetailsResponseChanged());
}

export function* onFetchProductDetails() {
	yield takeLatest(ProductActionTypes.FETCH_PRODUCT_DETAILS_START, fetchProductData);
}

export function* onFetchProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET,
		resetFetchProductDetailsResponse,
	);
}
/** *********************  END FETCH PRODUCT DETAILS ******************** */

/** *********************  START DELETE PRODUCT DETAILS  ******************** */
export function* productDelete({ payload }) {
	try {
		const key = 'DeleteProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			ProductName: payload.productName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteProductDetailsFailure(responseData.data));
		} else {
			yield put(deleteProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteProductDetailsFailure(error));
	}
}

export function* resetDeleteProductDetailsResponse() {
	yield put(deleteProductDetailsResponseChanged());
}

export function* onDeleteProductDetail() {
	yield takeLatest(ProductActionTypes.DELETE_PRODUCT_DETAILS_START, productDelete);
}

export function* onDeleteProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetDeleteProductDetailsResponse,
	);
}
/** *********************  END DELETE PRODUCT DETAILS ******************** */

/** *********************  START FETCH ALL PRODUCTS  ******************** */
export function* fetchAllProductDetails({ payload }) {
	try {
		const key = 'FetchAllProductsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loadAllProductList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductsFailure(responseData.data));
		} else {
			yield put(fetchAllProductsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductsFailure(error));
	}
}

export function* resetFetchAllProductsResponse() {
	yield put(fetchAllProductsResponseChanged());
}

export function* onFetchAllProductsDetails() {
	yield takeLatest(ProductActionTypes.FETCH_ALL_PRODUCT_START, fetchAllProductDetails);
}

export function* onFetchAllProductsResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET,
		resetFetchAllProductsResponse,
	);
}
/** *********************  END FETCH ALL PRODUCTS ******************** */

export function* productMasterSaga() {
	yield all([
		call(onCreateProductDetails),
		call(onCreateProductResponseReset),
		call(onCreateProductScheduleDetails),
		call(onCreateProductScheduleDetailsResponseReset),
		call(onUpdateProductScheduleDetails),
		call(onUpdateProductScheduleDetailsResponseReset),
		call(onUpdateProductDetails),
		call(onUpdateProductResponseReset),
		call(onUpdateProductAdditionalCharges),
		call(onUpdateProductAdditionalChargesResponseReset),
		call(onGetProductListDetails),
		call(onGetProductListResponseReset),
		call(onFetchSearchProductListDetails),
		call(onFetchSearchProductListResponseReset),
		call(onFetchProductDetails),
		call(onFetchProductResponseReset),
		call(onDeleteProductDetail),
		call(onDeleteProductResponseReset),
		call(onFetchAllProductsDetails),
		call(onFetchAllProductsResponseReset),
	]);
}
