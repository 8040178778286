/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ProductActionTypes from './product.types';
import {
	docodeResponse,
	calculateProductSchedule,
	updateProductScheduleList,
} from '../../common/common.utils';

const INITIAL_STATE = {
	addProductDetailsResponse: null,
	addProductDetailsResponseArr: [],
	addProductScheduleResponse: null,
	updateProductDetailsResponse: null,
	updateProductScheduleResponse: null,
	updateProductAdditionalChargesResponse: null,
	getProductListResponse: null,
	getProductListArr: [],
	fetchSearchProductListResponse: null,
	fetchSearchProductListArr: [],
	fetchProductDetailsResponse: null,
	fetchProductDetailsArr: [],
	fetchProductsResponse: null,
	fetchProductsArr: [],
	deleteProductDetailsResponse: null,
	productScheduleListArr: [],
	productTabChangeValue: 'Product Details',
	getProductID: '',
	getProductTypeID: '',
	isShowProductOffCanvas: false,
	isProductEdit: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProductActionTypes.ADD_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				addProductDetailsResponse: action.payload,
				addProductDetailsResponseArr: docodeResponse(action.payload, 'AddProductDetailKey'),
			};

		case ProductActionTypes.ADD_PRODUCT_DETAILS_FAILURE:
			return { ...state, addProductDetailsResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addProductDetailsResponse: null };

		case ProductActionTypes.ADD_PRODUCT_SCHEDULE_SUCCESS:
			return { ...state, addProductScheduleResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_SCHEDULE_FAILURE:
			return { ...state, addProductScheduleResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_SCHEDULE_RESPONSE_CHANGED:
			return { ...state, addProductScheduleResponse: null };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_FAILURE:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateProductDetailsResponse: null };

		case ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_SUCCESS:
			return { ...state, updateProductScheduleResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_FAILURE:
			return { ...state, updateProductScheduleResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_SCHEDULE_RESPONSE_CHANGED:
			return { ...state, updateProductScheduleResponse: null };

		case ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_SUCCESS:
			return { ...state, updateProductAdditionalChargesResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_FAILURE:
			return { ...state, updateProductAdditionalChargesResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_ADD_CHARGES_RESPONSE_CHANGED:
			return { ...state, updateProductAdditionalChargesResponse: null };

		case ProductActionTypes.GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getProductListResponse: action.payload,
				getProductListArr: docodeResponse(action.payload, 'FetchProductListKey'),
			};

		case ProductActionTypes.GET_PRODUCT_LIST_FAILURE:
			return { ...state, getProductListResponse: action.payload };

		case ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getProductListResponse: null };

		case ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				fetchSearchProductListResponse: action.payload,
				fetchSearchProductListArr: docodeResponse(
					action.payload,
					'FetchSearchProductListKey',
				),
			};

		case ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_FAILURE:
			return { ...state, fetchSearchProductListResponse: action.payload };

		case ProductActionTypes.FETCH_SEARCH_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, fetchSearchProductListResponse: null };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductDetailsResponse: action.payload,
				fetchProductDetailsArr: docodeResponse(action.payload, 'FetchProductDetailsKey'),
			};

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
			return { ...state, fetchProductDetailsResponse: action.payload };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchProductDetailsResponse: null, fetchProductDetailsArr: [] };

		case ProductActionTypes.FETCH_ALL_PRODUCT_SUCCESS:
			return {
				...state,
				fetchProductsResponse: action.payload,
				fetchProductsArr: docodeResponse(action.payload, 'FetchAllProductsKey'),
			};

		case ProductActionTypes.FETCH_ALL_PRODUCT_FAILURE:
			return { ...state, fetchProductsResponse: action.payload };

		case ProductActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED:
			return { ...state, fetchProductsResponse: null };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_FAILURE:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteProductDetailsResponse: null };

		case ProductActionTypes.PRODUCT_SCHEDULE_LIST:
			return {
				...state,
				productScheduleListArr: calculateProductSchedule(
					state.productScheduleListArr,
					action.payload,
				),
			};

		case ProductActionTypes.PRODUCT_SCHEDULE_LIST_RESET:
			return { ...state, productScheduleListArr: [] };

		case ProductActionTypes.PRODUCT_SCHEDULE_LIST_FETCH:
			return { ...state, productScheduleListArr: action.payload };

		case ProductActionTypes.PRODUCT_SCHEDULE_LIST_UPDATE:
			return {
				...state,
				productScheduleListArr: updateProductScheduleList(
					state.productScheduleListArr,
					action.payload,
				),
			};

		case ProductActionTypes.PRODUCT_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, productTabChangeValue: action.payload };

		case ProductActionTypes.PRODUCT_IS_OPEN_OFFCANVAS:
			return { ...state, isShowProductOffCanvas: action.payload };

		case ProductActionTypes.PRODUCT_ID:
			return { ...state, getProductID: action.payload };

		case ProductActionTypes.PRODUCT_TYPE_ID:
			return { ...state, getProductTypeID: action.payload };

		case ProductActionTypes.IS_EDIT_PRODUCT_DETAILS:
			return { ...state, isProductEdit: action.payload };

		default:
			return state;
	}
};
