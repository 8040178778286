/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchDemandCollectionList = (listData) => {
	const data = {
		FetchDemandCollectionListToken: listData,
	};
	const url = `${API_URL}fetchBranchDemandCollectionList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const toUpdateDemandCollectionDetails = (listData) => {
	const data = {
		ToUpdateDemandCollectionDetailsToken: listData,
	};
	const url = `${API_URL}ToUpdateDemandCollectionDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
